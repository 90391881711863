import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AccountTypeAPI } from "../../../api";
import { useGlobalOrganizationContext } from "../../../hooks/useGlobalOrganizationContext";
import { AccountType } from "../../../model/AccountType";
import PATHS from "../../../components/navigation/_paths";

type AccountTypeRoute = {
  accountTypeKey: string;
};

// Get account type by key from route param
export default () => {
  const navigate = useNavigate();
  const { accountTypeKey } = useParams<AccountTypeRoute>();
  const [accountType, setAccountType] = useState<AccountType | undefined>();

  const { organization } = useGlobalOrganizationContext();

  useEffect(() => {
    if (organization?.id && accountTypeKey) {
      AccountTypeAPI.byKey(organization?.id, accountTypeKey).then(
        (at) => {
          setAccountType(at);
        },
        () => {
          navigate(PATHS.ACCOUNTS.link);
        },
      );
    }
  }, [organization?.id, accountTypeKey, navigate]);
  return accountType;
};
