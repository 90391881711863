// This component sets the background for elevated containers and allows for a topographic image to be displayed.
import { FC, PropsWithChildren } from "react";
import { Paper, SxProps, PaperProps } from "@mui/material";

interface CommonContainerProps extends PaperProps {
  sx?: SxProps;
}

const CommonContainer: FC<PropsWithChildren<CommonContainerProps>> = ({
  variant,
  elevation,
  sx = [],
  children,
}) => (
  <Paper
    data-cy={"paper-container"}
    elevation={elevation ?? 4}
    variant={variant}
    sx={[
      //standard styles
      {
        position: "relative",
        display: "inline-block",
        zIndex: 1,
        backgroundColor: "background.paper",
        borderRadius: "10px",
      },
      //apply any styles passed
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
  >
    {children}
  </Paper>
);

export default CommonContainer;
