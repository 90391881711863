export const scrollbarStyles = {
  "::-webkit-scrollbar": {
    backgroundColor: "transparent",
    width: 6,
  },
  "::-webkit-scrollbar-thumb": {
    backgroundColor: "divider",
    borderRadius: 10,
  },
};
