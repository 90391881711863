import { Container, Grid } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import RegisterComponent from "./components/RegisterComponent";
import useAppNavigation from "../../hooks/useAppNavigation";

export default function RegisterPage() {
  const navigate = useNavigate();
  const { navigateHome } = useAppNavigation();
  const [searchParams] = useSearchParams();

  const loginLink = "/sign-in";

  return (
    <Container component="main" maxWidth={false} sx={{ height: 1 }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        maxWidth="xs"
        sx={{ height: 1 }}
      >
        <Container maxWidth="xs">
          <RegisterComponent
            onComplete={navigateHome}
            email={searchParams.get("email") ?? undefined}
            onClickLogin={() => navigate(loginLink)}
          />
        </Container>
      </Grid>
    </Container>
  );
}
