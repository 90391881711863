/**
 * This is for listing the organizations that an user has accounts in.
 */
import { FC, useState, useEffect, useMemo } from "react";
import { CommonDatagridWrapper } from "../../../../components/elements";
import { useAlert } from "../../../../lib/alert";
import debounce from "lodash.debounce";
import { Box } from "@mui/material";
import { AccountProvider } from "../../../../providers";
import { accountOrganizationsListColumns } from "./columns";
import { useGlobalOrganizationContext } from "../../../../hooks/useGlobalOrganizationContext";
import StyledDataGrid from "../../../../components/elements/datagrid/StyledDataGrid";

type CommonAccountOrganizationsProps = {
  accounts: AccountProvider;
  userId: string;
};

const CommonAccountOrganizations: FC<CommonAccountOrganizationsProps> = (
  props,
) => {
  const { accountListIsMemberOf, setAccountListIsMemberOf } =
    useGlobalOrganizationContext();
  const { handleRejectionWithError } = useAlert();

  const [isLoaded, setIsLoaded] = useState(false);

  const loaded = (
    <StyledDataGrid
      disableRowSelectionOnClick
      rows={accountListIsMemberOf}
      pageSizeOptions={[10]}
      getRowHeight={() => "auto"}
      columnVisibilityModel={{
        id: false,
      }}
      columns={accountOrganizationsListColumns}
    />
  );

  const reload = useMemo(
    () =>
      debounce(
        () => {
          setIsLoaded(false);
          Promise.all([
            props.accounts.listAccountsIsMemberOf(props.userId),
          ]).then((result) => {
            reload.cancel();
            const [accounts] = result;
            setAccountListIsMemberOf(accounts);
            setIsLoaded(true);
          }, handleRejectionWithError("Failed to load organizations user has accounts in"));
        },
        5000,
        { leading: true },
      ),
    [
      props.accounts,
      props.userId,
      handleRejectionWithError,
      setAccountListIsMemberOf,
    ],
  );

  useEffect(() => {
    reload();
  }, [reload]);

  return (
    <CommonDatagridWrapper
      menu={<Box></Box>}
      isLoaded={isLoaded}
      loaded={loaded}
      title="Affiliated Organizations"
    />
  );
};

export default CommonAccountOrganizations;
