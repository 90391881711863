import { useDisplayOptions } from "../../../hooks/useDisplayOptions";
import { environment } from "../../../util";
import { Organization } from "../../../model";

type PublicOrganizationDisplayContainerProps = {
  organization?: Organization;
};

const PublicOrganizationDisplayContainer = ({
  children,
  organization,
}: React.PropsWithChildren<PublicOrganizationDisplayContainerProps>) => {
  useDisplayOptions({
    title: organization?.name ?? environment.app.name,
    displayIcon: organization?.theme?.faviconUrl ?? environment.app.logoIcon,
  });

  return <>{children}</>;
};
export default PublicOrganizationDisplayContainer;
