import { useMemo } from "react";
import { PageHeader } from "../../../../components/layout/page";
import PATHS from "../../../../components/navigation/_paths";
import { useFormBuilderContext } from "./FormBuilderProvider";
import { BreadcrumbLink } from "../../../../components/core/Breadcrumbs/Breadcrumbs";

export const FormBuilderHeader = () => {
  const { task, parentElement } = useFormBuilderContext();

  const breadcrumbLinks = useMemo(() => {
    const links: BreadcrumbLink[] = [
      {
        link: PATHS.PROCESSES.link,
        title: "Processes",
        titleIntl: PATHS.PROCESSES.titleIntl,
      },
      {
        link: PATHS.PROCESS.linkTo(task.root_process_id ?? task.process_id),
        title: "Process",
        titleIntl: PATHS.PROCESS.titleIntl,
      },
    ];
    if (parentElement && task.id) {
      links.push({
        link: PATHS.PROCESS_TASK.linkTo(task.process_id, task.id),
        title: "Parent Form",
      });
    }
    return links;
  }, [parentElement, task.id, task.process_id, task.root_process_id]);

  return (
    <PageHeader
      title={
        parentElement?.label
          ? task.name + " - " + parentElement?.label
          : task.name
      }
      links={breadcrumbLinks}
      breadcrumbTitle={`Edit Form - ${parentElement?.label ?? task.name}`}
    />
  );
};
