import { FC } from "react";
import { useDisplayOptions } from "../../hooks/useDisplayOptions";
import {
  OrganizationAccountInvitationsProvider,
  OrganizationAccountsProvider,
} from "../../providers";
import OrganizationScopedPage from "../../components/OrganizationScopedPage";
import { CommonOrganizationAccounts } from "./components/";
import { useGlobalOrganizationContext } from "../../hooks/useGlobalOrganizationContext";

const AccountListPage: FC<unknown> = () => {
  useDisplayOptions({});

  const { organization } = useGlobalOrganizationContext();

  return (
    <OrganizationScopedPage
      category="accounts"
      displayOrganizationActionMenu={false}
    >
      {organization && (
        <CommonOrganizationAccounts
          organizationId={organization.id}
          accounts={OrganizationAccountsProvider}
          fetchAccounts={(download) =>
            OrganizationAccountsProvider.fetchAccountsByOrganizationId(
              organization.id,
              download,
            )
          }
          invitations={OrganizationAccountInvitationsProvider}
          allowInvitation={false}
        />
      )}
    </OrganizationScopedPage>
  );
};

export default AccountListPage;
