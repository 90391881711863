import { Typography } from "@mui/material";
import { FC } from "react";
import { formatTimestamp } from "../../../utils";

const TimestampCell: FC<{ time: string }> = ({ time }) => {
  const formattedTime = formatTimestamp(time);

  return <Typography variant="body1">{formattedTime}</Typography>;
};

export default TimestampCell;
