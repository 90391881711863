import RegisterComponent from "../../../../../user/components/RegisterComponent";
import {
  Task,
  TaskExecution,
  TaskExecutionComponentProps,
  TaskExecutionState,
} from "../../../../types";

const RegisterExecutionComponent = (
  props: TaskExecutionComponentProps<Task, TaskExecution>,
) => {
  return (
    <RegisterComponent
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      email={(props.taskExecution as any)?.data?.email}
      disabled={props.taskExecution?.state === TaskExecutionState.Completed}
      onComplete={(user) => {
        const executionData = {
          data: {
            email: user.email,
          },
        };
        const execution = {
          ...props.taskExecution,
          task_id: props.task.id,
          state: TaskExecutionState.Completed,
          ...executionData,
        };

        props.onExecute?.(execution as TaskExecution);
        props.onValueChange?.({ ...props.task, ...executionData } as Task);
      }}
    />
  );
};

export default RegisterExecutionComponent;
