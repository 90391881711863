import { Box, Card, Typography } from "@mui/material";
import { FC } from "react";
import { environment } from "../../util";
import { CardActionBar } from "../../components/elements";

type DashboardOrganizationCardProps = {
  title: string;
  description?: string;
  shortLogoURL?: string;
  actions?: JSX.Element[];
};

const DashboardOrganizationCard: FC<DashboardOrganizationCardProps> = ({
  title,
  description,
  shortLogoURL,
  actions,
}) => {
  return (
    <Card
      sx={{
        height: "300px",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          width: "100%",
          p: 3,
          pb: 1,
          flex: 1,
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
          }}
        >
          <img
            src={shortLogoURL ?? environment.app.logoIcon}
            style={{
              border: "1px solid #D2D2D2",
              borderRadius: "10px",
              height: "70px",
              width: "70px",
            }}
          />
          <Typography
            variant="h3"
            sx={{
              ml: 1,
              maxHeight: "75px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "none",
              fontWeight: "bold",
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box
          sx={{
            overflow: "auto",
            flex: 1,
          }}
        >
          <Typography
            sx={{
              mt: 1,
              fontStyle: !description ? "italic" : "normal",
            }}
          >
            {description ?? "No description provided"}
          </Typography>
        </Box>
      </Box>
      <CardActionBar>{actions}</CardActionBar>
    </Card>
  );
};

export default DashboardOrganizationCard;
