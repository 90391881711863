import { Box, Button, Card, Container, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ProcessCategory } from "../../../model/ProcessCategory";
import { CardActionBar, CardTitle } from "../../../components/elements";
import { CARD_HEIGHT } from "./constants";

export type OrganizationCategoryCardsProps = {
  categories: ProcessCategory[];
};

const OrganizationCategoryCards = ({
  categories,
}: OrganizationCategoryCardsProps) => {
  const navigate = useNavigate();
  const navigateToCategory = (category: ProcessCategory) => {
    navigate(`category/${category.id}`);
  };
  return (
    <Container maxWidth="lg">
      <Grid
        container
        sx={{
          alignContent: "center",
          alignItems: "center",
          justifyContent: "flex-start",
          p: 3,
        }}
        spacing={3}
      >
        {categories.map((category) => (
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            xl={6}
            key={category.id}
            sx={{
              minWidth: "224px",
            }}
          >
            <Card
              elevation={1}
              sx={{
                minHeight: CARD_HEIGHT,
                maxHeight: CARD_HEIGHT,
                position: "relative",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box sx={{ p: 2, pb: 0, flex: 0 }}>
                <CardTitle variant="h2" component="h1">
                  {category.label}
                </CardTitle>
              </Box>
              <Box sx={{ p: 2, flex: 1, overflow: "scroll" }}>
                <Typography>{category.description}</Typography>
              </Box>
              <CardActionBar>
                <Button
                  variant="contained"
                  onClick={() => navigateToCategory(category)}
                >
                  Continue
                </Button>
              </CardActionBar>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
export default OrganizationCategoryCards;
