import {
  CheckBox,
  FontDownload,
  RadioButtonChecked,
  FileUpload,
  List as ListIcon,
  LineStyle,
  CheckBoxOutlined,
  CalendarMonth,
  Label,
  AddBox,
  Folder,
  Search,
  DynamicFormOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  InputAdornment,
  List,
  ListItem,
  ListSubheader,
  Menu,
  MenuItem,
  Popover,
  SvgIconProps,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { FormElementType } from "../../types";
import React, { useCallback, useEffect, useState } from "react";
import { AccountTypeAPI, OrganizationAPI, ProcessAPI } from "../../../../api";
import { useGlobalOrganizationContext } from "../../../../hooks/useGlobalOrganizationContext";
import FieldDefinition, {
  FieldDefinitionTypes,
} from "../../../../model/FieldDefinition";
import { useFormBuilderContext } from "./FormBuilderProvider";
import { AccountType } from "../../../../model/AccountType";

type ElementMenuOption = {
  type: FormElementType;
  title: string;
  icon?: React.ComponentType<SvgIconProps>;
  disabled?: boolean;
  fieldDefinition?: FieldDefinition;
  selectedFieldDef?: boolean;
};

const DefaultFormElementMenuOptions: ElementMenuOption[] = [
  {
    type: FormElementType.Heading,
    title: "Heading",
    icon: Label,
  },
  {
    type: FormElementType.MultiSelection,
    title: "Multi Select",
    icon: CheckBox,
  },
  {
    type: FormElementType.Date,
    title: "Date",
    icon: CalendarMonth,
  },
  {
    type: FormElementType.Radio,
    title: "Radio",
    icon: RadioButtonChecked,
  },
  {
    type: FormElementType.Selection,
    title: "Selection",
    icon: ListIcon,
  },
  {
    type: FormElementType.Text,
    title: "Text",
    icon: FontDownload,
  },
  {
    type: FormElementType.Upload,
    title: "Upload",
    icon: FileUpload,
  },
  {
    type: FormElementType.NestedForm,
    title: "Nested Form",
    icon: LineStyle,
  },
  {
    type: FormElementType.Checkbox,
    title: "Checkbox",
    icon: CheckBoxOutlined,
  },
  {
    type: FormElementType.Autocomplete,
    title: "Autocomplete",
    icon: DynamicFormOutlined,
  },
];

const FormElementMenu = ({
  open,
  onClose,
  onSelect,
  anchorEl,
  disabledTypes,
}: {
  open: boolean;
  onClose: () => void;
  onSelect: (
    type: FormElementType,
    fieldDefOptions?: {
      fieldDef?: FieldDefinition;
      entity?: { id: string; type: FieldDefinitionTypes };
    },
  ) => void;
  anchorEl: Element | null | undefined;
  disabledTypes?: FormElementType[];
}) => {
  const fieldDefsDisabled = disabledTypes?.includes(
    FormElementType.FieldDefinition,
  );
  const { organization } = useGlobalOrganizationContext();
  const { task } = useFormBuilderContext();

  const [accountType, setAccountType] = useState<AccountType | undefined>();
  const [accountTypeMenuAnchorEl, setAccountTypeMenuAnchorEl] =
    useState<null | HTMLButtonElement>(null);

  const [searchText, setSearchText] = useState("");
  const [searchRegex, setSearchRegex] = useState<RegExp>(/.*/);

  const [customElementOptions, setCustomElementOptions] = useState<
    Array<{ label: string; elements: ElementMenuOption[] }>
  >([]);

  useEffect(() => {
    if (fieldDefsDisabled || (!accountType && !organization)) return;

    (accountType
      ? AccountTypeAPI.getFieldDefinitions(accountType.id)
      : OrganizationAPI.getFieldDefinitions(organization!.id)
    ).then(({ selected, available }) => {
      const options = Object.values(available).reduce<{
        organization: ElementMenuOption[];
        accountType: ElementMenuOption[];
      }>(
        (options, current) => {
          const element: ElementMenuOption = {
            type: FormElementType.FieldDefinition,
            title: current.label,
            icon: Folder,
            fieldDefinition: current,
            selectedFieldDef: selected.includes(current.id),
          };
          switch (current.entity_type) {
            case FieldDefinitionTypes.AccountType:
              options.accountType.push(element);
              break;
            case FieldDefinitionTypes.Organization:
              options.organization.push(element);
              break;
          }
          return options;
        },
        { organization: [], accountType: [] },
      );
      setCustomElementOptions([
        {
          label: `Custom ${organization?.name ?? "Organization"} Fields`,
          elements: options.organization,
        },
        {
          label: `Custom ${accountType?.name ?? "Account Type"} Fields`,
          elements: options.accountType,
        },
      ]);
    });
  }, [fieldDefsDisabled, open, organization, accountType]);

  useEffect(() => {
    ProcessAPI.get(task.process_id).then((process) => {
      if (process.entity_type == "account_type" && process.entity_id)
        AccountTypeAPI.get(process.entity_id).then((accountType) =>
          setAccountType(accountType),
        );
    });
  }, [task]);

  const getMenuSection = useCallback(
    (sectionLabel: string, options: ElementMenuOption[]) => (
      <>
        <ListSubheader
          sx={{
            fontWeight: "bold",
            fontSize: "large",
            color: (theme) => theme.palette.text.primary,
            mb: -1,
            pl: 5,
            display: fieldDefsDisabled ? "center" : "unset",
          }}
        >
          {sectionLabel}
        </ListSubheader>
        <ListItem
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: fieldDefsDisabled ? "space-evenly" : "flex-start",
            columnGap: "6px",
            width: "400px",
            maxWidth: "400px",
            flexWrap: "wrap",
          }}
        >
          {options
            .filter(
              (option) =>
                !option.disabled &&
                !disabledTypes?.includes(option.type) &&
                searchRegex.test(option.title.toLowerCase()),
            )
            .map((option, index) => {
              return (
                <Box key={`${option.type}-${index}-${option.title}`}>
                  <Button
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "16px",
                      marginTop: "16px",
                      marginBottom: "16px",
                      maxWidth: "100px",
                      width: "100px",
                      "&:hover": {
                        boxShadow: 3,
                      },
                    }}
                    size="large"
                    onClick={() => {
                      onSelect(option.type, {
                        fieldDef: option.fieldDefinition,
                      });
                      onClose();
                    }}
                  >
                    {option.icon && <option.icon fontSize="large" />}
                    <Typography
                      sx={{
                        fontSize: "10px",
                        wordBreak: "break-word",
                        whiteSpace: "normal",
                      }}
                      variant="button"
                    >
                      {option.title}
                      {option.selectedFieldDef && "*"}
                    </Typography>
                  </Button>
                </Box>
              );
            })}
        </ListItem>
      </>
    ),
    [disabledTypes, fieldDefsDisabled, onClose, onSelect, searchRegex],
  );

  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
    >
      {!fieldDefsDisabled && (
        <TextField
          label="Search Fields"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          sx={{ width: "80%", mx: "10%", mb: 1, mt: 3 }}
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
            setSearchRegex(
              new RegExp(
                e.target.value
                  .replace(/([.\\+*?[^\]$(){}=!<>|:-])/g, "\\$1")
                  .toLowerCase()
                  .split("")
                  .join(".*"),
              ),
            );
          }}
        />
      )}
      <List
        sx={{
          width: "400px",
          height: "fit-content",
          maxHeight: "70vh",
          overflow: "scroll",
        }}
        subheader={<li />}
      >
        {getMenuSection("Standard Fields", DefaultFormElementMenuOptions)}
        {!fieldDefsDisabled &&
          customElementOptions.map(
            ({ label, elements }) =>
              elements.length > 0 && getMenuSection(label, elements),
          )}
      </List>
      {!fieldDefsDisabled && (
        <>
          <Button
            variant="outlined"
            startIcon={<AddBox />}
            sx={{
              width: "80%",
              mb: 2,
              mx: "10%",
            }}
            onClick={(e) => setAccountTypeMenuAnchorEl(e.currentTarget)}
          >
            Create Custom Field
          </Button>
          <Menu
            open={accountTypeMenuAnchorEl != null}
            anchorEl={accountTypeMenuAnchorEl}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={() => setAccountTypeMenuAnchorEl(null)}
          >
            <MenuItem
              disabled={!organization}
              onClick={() => {
                setAccountTypeMenuAnchorEl(null);
                onSelect(FormElementType.FieldDefinition, {
                  entity: {
                    id: organization?.id ?? "",
                    type: FieldDefinitionTypes.Organization,
                  },
                });
                onClose();
              }}
            >
              Organization Field
            </MenuItem>
            <Tooltip
              title={"No account type attached to this process"}
              placement={"right"}
              arrow
              disableHoverListener={accountType != undefined}
            >
              <span>
                <MenuItem
                  disabled={!accountType}
                  onClick={() => {
                    setAccountTypeMenuAnchorEl(null);
                    onSelect(FormElementType.FieldDefinition, {
                      entity: {
                        id: accountType?.id ?? "",
                        type: FieldDefinitionTypes.AccountType,
                      },
                    });
                    onClose();
                  }}
                >
                  {accountType ? accountType.name : "Account Type"} Field
                </MenuItem>
              </span>
            </Tooltip>
          </Menu>
        </>
      )}
    </Popover>
  );
};
export default FormElementMenu;
