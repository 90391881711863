/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuditEvent, AuditEventType } from "../../../model/AuditEvent";
import { AuditLogEvent, AuditEventDto } from "../types";
import getUserFullName from "./getUserFullName";
import formatTimestamp from "./formatTimestamp";
import {
  ArrowForward,
  CancelScheduleSend,
  Done,
  DoneAll,
  Edit,
  Email,
  Error,
} from "@mui/icons-material";
import { TaskExecution, TaskEmailExecution } from "../../task";
import { TaskType } from "../../task/types";

const startProcessAuditEventRenderer = (
  auditEvent: AuditEventDto,
): AuditLogEvent => {
  return {
    icon: <ArrowForward />,
    title: `Started ${auditEvent.process?.name ?? ""}`,
    userPart: getUserFullName(auditEvent.user),
    timestampPart: formatTimestamp(auditEvent.timestamp),
  };
};

const completeProcessAuditEventRenderer = (
  auditEvent: AuditEventDto,
): AuditLogEvent => {
  return {
    icon: <DoneAll />,
    title: `Finished ${auditEvent.process?.name ?? ""}`,
    userPart: getUserFullName(auditEvent.user),
    timestampPart: formatTimestamp(auditEvent.timestamp),
  };
};

const renderEmailTaskComplete = (
  auditEvent: AuditEventDto,
  taskExecution: TaskExecution,
): AuditLogEvent => {
  return {
    icon: <Email />,
    title: taskExecution.name ?? "",
    userPart: `Sent to ${(taskExecution as TaskEmailExecution).data?.to.join(
      ", ",
    )}`,
    timestampPart: formatTimestamp(auditEvent.timestamp),
  };
};

const renderEmailTaskError = (
  auditEvent: AuditEventDto,
  taskExecution: TaskExecution,
): AuditLogEvent => {
  return {
    icon: <CancelScheduleSend />,
    title: taskExecution.name ?? "",
    userPart: `Failed to send message to ${(
      taskExecution as TaskEmailExecution
    ).data?.to.join(", ")}`,
    timestampPart: formatTimestamp(auditEvent.timestamp),
    error: (auditEvent.metadata as any).message,
  };
};

const completeTaskRenderers: Map<
  TaskType,
  (auditEvent: AuditEvent, taskExecution: TaskExecution) => AuditLogEvent
> = new Map([[TaskType.Email, renderEmailTaskComplete]]);

const errorTaskRenderers: Map<
  TaskType,
  (auditEvent: AuditEvent, taskExecution: TaskExecution) => AuditLogEvent
> = new Map([[TaskType.Email, renderEmailTaskError]]);

const taskExecutionCompleteAuditEventRenderer = (
  auditEvent: AuditEvent,
): AuditLogEvent => {
  const taskExecution: TaskExecution = (auditEvent.metadata as any)
    .taskExecution as TaskExecution;
  const taskType: TaskType | undefined = taskExecution.type;

  if (taskType) {
    const renderer = completeTaskRenderers.get(taskType);
    if (renderer) {
      return renderer(auditEvent, taskExecution);
    }
  }

  return {
    icon: <Done />,
    title: `Completed "${taskExecution.name}"`,
    userPart: getUserFullName((auditEvent.metadata as any).user),
    timestampPart: formatTimestamp(auditEvent.timestamp),
  };
};

const taskExecutionErrorAuditEventRenderer = (
  auditEvent: AuditEvent,
): AuditLogEvent => {
  const taskExecution: TaskExecution = (auditEvent.metadata as any)
    .taskExecution as TaskExecution;
  const taskType: TaskType | undefined = taskExecution.type;

  if (taskType) {
    const renderer = errorTaskRenderers.get(taskType);
    if (renderer) {
      return renderer(auditEvent, taskExecution);
    }
  }

  return {
    icon: <Error />,
    title: `Error completing "${taskExecution.name}"`,
    userPart: getUserFullName((auditEvent.metadata as any).user),
    timestampPart: formatTimestamp(auditEvent.timestamp),
    error: (auditEvent.metadata as any).message,
  };
};

const taskExecutionDataSubmittedAuditEventRenderer = (
  auditEvent: AuditEvent,
): AuditLogEvent => {
  const taskExecution: TaskExecution = (auditEvent.metadata as any)
    .new as TaskExecution;

  return {
    icon: <Edit />,
    title: `Edited "${taskExecution.name}"`,
    userPart: getUserFullName((auditEvent.metadata as any).user),
    timestampPart: formatTimestamp(auditEvent.timestamp),
  };
};

const defaultAuditEventRenderer = (
  auditEvent: AuditEventDto,
): AuditLogEvent => {
  return {
    icon: <Done />,
    title: auditEvent.event_type,
    userPart: getUserFullName(auditEvent.user),
    timestampPart: formatTimestamp(auditEvent.timestamp),
  };
};

const getAuditLogEventInfo = (auditEvent: AuditEvent) => {
  const auditEventRenderers: Map<
    AuditEventType,
    (auditEvent: AuditEvent) => AuditLogEvent
  > = new Map([
    [
      AuditEventType.TASK_EXECUTION_DATA_SUBMITTED,
      taskExecutionDataSubmittedAuditEventRenderer,
    ],
    [AuditEventType.PROCESS_EXECUTION_START, startProcessAuditEventRenderer],
    [
      AuditEventType.TASK_EXECUTION_COMPLETE,
      taskExecutionCompleteAuditEventRenderer,
    ],
    [
      AuditEventType.PROCESS_EXECUTION_COMPLETE,
      completeProcessAuditEventRenderer,
    ],
    [AuditEventType.TASK_EXECUTION_ERROR, taskExecutionErrorAuditEventRenderer],
  ]);

  let renderer = auditEventRenderers.get(auditEvent.event_type);
  if (!renderer) {
    renderer = defaultAuditEventRenderer;
  }

  const structure = renderer(auditEvent);
  return structure;
};

export default getAuditLogEventInfo;
