import {
  Typography,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Button,
} from "@mui/material";
import { CheckboxFormElement, TaskForm } from "../../../types";
import { useNavigate } from "react-router-dom";
import PATHS from "../../../../../components/navigation/_paths";
import FormElementTaskDescription from "../FormElementTaskDescription";

export const CheckboxInlineDisplay = ({
  element,
  task,
}: {
  element: CheckboxFormElement;
  task: TaskForm;
}) => {
  const navigate = useNavigate();
  return (
    <>
      <FormGroup>
        <FormControlLabel
          label={
            <>
              {element.label}
              {element.required && (
                <Typography sx={{ display: "inline", color: "red" }}>
                  {" *"}
                </Typography>
              )}
            </>
          }
          control={<Checkbox disabled={true} checked={false} />}
        />
        <FormElementTaskDescription>
          {element.description}
        </FormElementTaskDescription>
      </FormGroup>
      <Button
        variant="outlined"
        onClick={() => {
          navigate(
            PATHS.PROCESS_TASK_ELEMENT.linkTo(
              task.process_id,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              task.id!,
              element.id,
            ),
          );
        }}
      >
        {element.elements && element.elements.length > 0
          ? "View/Edit Form"
          : "Build Form"}
      </Button>
    </>
  );
};
