import {
  Autocomplete,
  Box,
  CircularProgress,
  DialogContent,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { AccountAPI, RoleAPI } from "../../../api";
import UserAssignment, {
  UserAssignmentStruct,
} from "../../../components/core/UserAssignment/UserAssignment";
import Dialog from "../../../components/Dialog";
import { useAlert } from "../../../lib/alert";
import { Role } from "../../../model";

type TeamMemberInvitationDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  organizationId: string;
  accountId: string;
  onAssignmentComplete: () => void;
};

const TeamMemberInvitationDialog: FC<TeamMemberInvitationDialogProps> = (
  props,
) => {
  const { success, error } = useAlert();

  const [targetRole, setTargetRole] = useState<Role | undefined>();
  const [organizationRoles, setOrganizationRoles] = useState<
    Role[] | undefined
  >();

  const handleRoleChange = (_: unknown, newValue: Role | null) => {
    if (newValue) {
      setTargetRole(newValue);
    }
  };

  const reload = useCallback(() => {
    RoleAPI.byOrganization(props.organizationId).then((roles) => {
      setOrganizationRoles(roles);
    });
  }, [props.organizationId]);

  useEffect(() => {
    if (props.open) {
      reload();
    }
  }, [reload, props.open]);

  return (
    <Dialog
      open={props.open}
      title={"Invite Team Members"}
      confirmText={"Close"}
      handleConfirm={(): Promise<void> => {
        props.setOpen(false);
        return Promise.resolve();
      }}
    >
      <DialogContent>
        <Typography variant="body1">
          Select a role for the new team member(s).
        </Typography>
        {organizationRoles ? (
          <Autocomplete
            sx={{ my: 1 }}
            id="member-select"
            limitTags={4}
            disableClearable
            options={organizationRoles}
            getOptionLabel={(role) => role.name}
            value={targetRole}
            onChange={handleRoleChange}
            noOptionsText="No roles in organization"
            PaperComponent={(props) => <Paper elevation={4} {...props} />}
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id;
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        ) : (
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress />
          </Box>
        )}
        {targetRole && (
          <UserAssignment
            targetRoleId={targetRole.id}
            onExecute={function (userAssignment: UserAssignmentStruct): void {
              props.setOpen(false);
              Promise.all(
                (userAssignment.assignees ?? []).map((assignee) => {
                  return AccountAPI.assignAccountRole(
                    props.accountId,
                    assignee,
                    targetRole.id,
                  );
                }),
              ).then(
                () => {
                  success("Success");
                  props.onAssignmentComplete();
                },
                (err) => {
                  console.error(err);
                  error("Error assigning team members to selected role");
                  props.onAssignmentComplete();
                },
              );
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default TeamMemberInvitationDialog;
