import { Button, CircularProgress } from "@mui/material";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useAlert } from "../../../../../../lib/alert";

type StripeSubmitButtonProps = {
  disabled?: boolean;
  clientSecret?: string;
  onSubmit: () => void;
};

const StripeSubmitButton = ({
  disabled,
  onSubmit,
  clientSecret,
}: StripeSubmitButtonProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const { error } = useAlert();

  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Button
      sx={{ my: 2 }}
      variant={loading ? "outlined" : "contained"}
      disabled={disabled || loading || !stripe || !elements || !clientSecret}
      fullWidth
      onClick={async () => {
        setLoading(true);
        if (!stripe || !elements || !clientSecret) {
          setLoading(false);
          return;
        }

        const { error: submitError } = await elements.submit();

        if (submitError) {
          console.error(submitError.message);
          error(
            "Sorry, we could not confirm your credit card information. Please check your payment details and try again.",
            60000,
          );
          setLoading(false);
          return;
        }

        const { error: confirmError } = await stripe.confirmSetup({
          elements,
          clientSecret,
          // This parameter stops us from having to process redirects.  It is required
          redirect: "if_required",
          confirmParams: {
            // This redirect url is required but is never actually used.  We
            // only accept card payments therefore we do not process redirects.
            return_url: process.env.REACT_APP_REDIRECT_SIGN_IN_URI,
          },
        });

        if (confirmError) {
          console.error(confirmError.message);
          error(
            "Sorry, we could not confirm your credit card information. Please check your payment details and try again.",
            60000,
          );
          setLoading(false);
          return;
        }

        onSubmit();
        setLoading(false);
      }}
    >
      {loading ? <CircularProgress /> : "Confirm Payment Details"}
    </Button>
  );
};

export default StripeSubmitButton;
