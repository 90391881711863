import {
  FieldDefinitionFormElement,
  FormDefinitionType,
  FormElementType,
} from "../../../types";
import { Container, Tooltip, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { FormBuilderAddButton } from "../FormBuilderAddElement";
import { useCallback, useMemo, useState } from "react";
import { useFormBuilderContext } from "../FormBuilderProvider";
import { FieldDefinitionAPI } from "../../../../../api";
import FormBuilderChild from "../FormBuilderChild";
import { DragEndEvent } from "@dnd-kit/core";
import DraggableList from "../../../../../components/core/Draggable/DraggableList";
import Draggable from "../../../../../components/core/Draggable/Draggable";
import { Warning } from "@mui/icons-material";

export const FieldDefinitionInlineDisplay = ({
  element,
}: {
  element: FieldDefinitionFormElement;
}) => {
  const { onFormElementChange, selectedElementId, setSelectedElementId } =
    useFormBuilderContext();
  const [addElementDialogOpen, setAddElementDialogOpen] = useState(false);
  const [dragActiveId, setDragActiveId] = useState("");

  const updateFieldDefinition = useCallback(async () => {
    if (!element.field_definition_id) {
      console.error("No field definition set");
      return;
    }

    await FieldDefinitionAPI.updateElements(
      element.field_definition_id,
      element.elements ?? [],
    );

    onFormElementChange({
      changeType: "update",
      newElement: element,
    });
  }, [element, onFormElementChange]);

  // Add Element
  const onAddElement = useCallback(
    (newElement: FormDefinitionType) => {
      if (newElement && element.field_definition_id) {
        updateFieldDefinition();

        if (!element.elements) element.elements = [];
        element.elements.push(newElement);
      }
    },
    [element, updateFieldDefinition],
  );

  // Reorder Elements
  const overlayComponent = useMemo(() => {
    const activeElement = element.elements?.find(
      (el) => el.id === dragActiveId,
    );
    if (!activeElement) {
      return <></>;
    }
    return (
      <FormBuilderChild
        element={activeElement}
        isSelected={dragActiveId == selectedElementId}
      />
    );
  }, [dragActiveId, element.elements, selectedElementId]);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (over?.id && active.id !== over?.id) {
        const activeIndex = element.elements?.findIndex(
          (el) => el.id === active.id,
        );
        const overIndex = element.elements?.findIndex(
          (el) => el.id === over.id,
        );
        if (activeIndex == undefined || overIndex == undefined) return;

        const newElementOrder: FormDefinitionType[] = [];
        const movingElement = (element.elements ?? [])[activeIndex];
        element.elements?.map((element, index) => {
          if (index != activeIndex) {
            if (index != overIndex) {
              newElementOrder.push(element);
            } else {
              newElementOrder.push(
                ...(activeIndex < overIndex
                  ? [element, movingElement]
                  : [movingElement, element]),
              );
            }
          }
        });
        element.elements = newElementOrder;

        updateFieldDefinition();
      }
    },
    [element, updateFieldDefinition],
  );

  return (
    <Container
      disableGutters
      sx={{
        borderStyle: "dashed",
        borderColor: grey[500],
        py: 1,
      }}
    >
      <Container disableGutters sx={{ display: "flex" }}>
        <Container disableGutters sx={{ justifySelf: "start" }}>
          <Typography
            sx={{
              display: "inline",
              ml: 3,
              color: grey[700],
            }}
          >
            {element.label}
          </Typography>
          {element.required && (
            <Typography
              sx={{
                display: "inline",
                color: "red",
                ml: 0.5,
              }}
            >
              *
            </Typography>
          )}
        </Container>
        {!element.label && (
          <Container
            disableGutters
            sx={{ justifySelf: "end", width: "min-content", pr: 1 }}
          >
            <Tooltip title={"Missing label"}>
              <Warning color="warning" />
            </Tooltip>
          </Container>
        )}
      </Container>
      {element.description && (
        <Typography sx={{ pt: 1, pb: 2, ml: 3, fontSize: "14px" }}>
          {element.description}
        </Typography>
      )}
      <Container
        disableGutters
        sx={{ display: "flex", flexDirection: "column", gap: 1.25, my: 1 }}
      >
        <DraggableList
          listItemIds={element.elements?.map((e) => e.id) ?? []}
          handleDragStart={(event) =>
            setDragActiveId(event.active.id.toString())
          }
          handleDragEnd={handleDragEnd}
          overlayComponent={overlayComponent}
        >
          {element.elements?.map((element) => (
            <Draggable key={element.id} id={element.id}>
              <FormBuilderChild
                element={element}
                onClick={(element) => setSelectedElementId(element.id)}
              />
            </Draggable>
          ))}
        </DraggableList>
      </Container>
      <FormBuilderAddButton
        open={addElementDialogOpen}
        setOpen={setAddElementDialogOpen}
        onClick={onAddElement}
        disabledTypes={[
          FormElementType.FieldDefinition,
          FormElementType.NestedForm,
        ]}
      />
    </Container>
  );
};
