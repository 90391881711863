import { Warning } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import {
  Task,
  TaskEmailExecution,
  TaskExecutionComponentProps,
} from "../../../types";
import { DEFAULT_RENDER } from "../TaskBody";

const EmailExecutionComponent = ({
  children = DEFAULT_RENDER,
  ...props
}: TaskExecutionComponentProps<Task, TaskEmailExecution>) => {
  const emails = props.taskExecution?.data?.to ?? [];

  const generateText = () => {
    if (props.taskExecution?.error) {
      return (
        <Box display="flex">
          <Warning
            sx={{
              color: "#C35631",
              fontSize: "15px",
              marginRight: "5px",
              marginTop: "2px",
            }}
          />
          Automated email failed to send.
        </Box>
      );
    } else {
      return `Automated email ${
        props.taskExecution?.state === "COMPLETED" ? "" : "will be"
      } sent to ${emails.join(", ")}`;
    }
  };

  const content = (
    <Typography
      variant="caption"
      sx={{
        color: props.taskExecution?.error ? "#A62A00" : "#656565",
        position: "relative",
        bottom: 7,
      }}
    >
      {generateText()}
    </Typography>
  );
  return children({ content });
};

export default EmailExecutionComponent;
