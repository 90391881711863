import { useCallback, useEffect, useMemo, useState } from "react";
import { Account, Organization } from "../../../model";
import { AccountType } from "../../../model/AccountType";
import { AccountAPI, OrganizationAPI } from "../../../api";
import { getAccountName } from "../../accounts/utils";

const useOrganizationDirectory = (
  organization: Organization,
  accountType?: AccountType,
  hideOrganizations?: boolean,
) => {
  const [query, setQuery] = useState<string>();
  const [allAccounts, setAllAccounts] = useState<Account[]>([]);
  const [allOrganizations, setAllOrganizations] = useState<Organization[]>();

  const showOrganizations = accountType && !hideOrganizations;

  const loadAccounts = useCallback(async () => {
    let accounts: Account[];

    if (accountType) {
      accounts = await AccountAPI.byOrganizationAndAccountType(
        organization.id,
        accountType.id,
      );
    } else {
      accounts = await AccountAPI.byOrganizationId(organization.id);
    }

    setAllAccounts(accounts);
  }, [accountType, organization]);

  const loadOrganizations = useCallback(async () => {
    if (showOrganizations && accountType.org_role_id) {
      const organizations = await OrganizationAPI.byOrgRole(
        accountType?.org_role_id,
      );

      setAllOrganizations(organizations);
    }
  }, [accountType, showOrganizations]);

  useEffect(() => {
    loadAccounts();
    loadOrganizations();
  }, [loadAccounts, loadOrganizations]);

  const externalOrganizationIds = useMemo((): Set<string> => {
    return new Set<string>();
  }, []);

  const matchedAccounts = useMemo(() => {
    const matchedAccounts: Account[] = [];

    allAccounts?.forEach((account) => {
      if (account.external_org_id)
        externalOrganizationIds.add(account.external_org_id);

      if (
        account.account_name
          ?.toUpperCase()
          .indexOf(query?.toUpperCase() ?? "") != -1
      ) {
        matchedAccounts.push(account);
      }
    });

    return matchedAccounts;
  }, [allAccounts, externalOrganizationIds, query]);

  const matchedOrganizations = useMemo(() => {
    return (
      allOrganizations?.filter(
        (organization) =>
          organization.name.toUpperCase().indexOf(query?.toUpperCase() ?? "") !=
            -1 && !externalOrganizationIds.has(organization.id),
      ) ?? []
    );
  }, [allOrganizations, externalOrganizationIds, query]);

  const addAccounts = (newAccounts: Account[]): void => {
    setAllAccounts([...allAccounts, ...newAccounts]);
  };

  const findAccount = (accountChecked: Account): Account | undefined => {
    return allAccounts.find(
      (account) => getAccountName(account) === getAccountName(accountChecked),
    );
  };

  return {
    accounts: matchedAccounts,
    organizations: matchedOrganizations,
    query,
    setQuery,
    addAccounts,
    findAccount,
  };
};

export default useOrganizationDirectory;
