import OnboardingBasePage from "./OnboardingBasePage";
import { FC, createContext, useCallback, useEffect, useState } from "react";
import FinalizePanel from "./panels/FinalizePanel";
import ManageInvitationPanel from "./panels/ManageInvitationPanel";
import RegisterPanel from "./panels/RegisterPanel";
import SignInPanel from "./panels/SignInPanel";
import { useLocation } from "react-router-dom";
import { User } from "../../model";
import { InvitationAPI, OrganizationAPI, ProcessExecutionAPI } from "../../api";
import Invitation from "../../model/Invitation";
import {
  TaskAcceptInvitationExecution,
  TaskExecutionState,
  TaskType,
} from "../task";
import { ProcessExecution } from "../../model/ProcessExecution";
import { DefaultAlertSnackbar } from "../../lib/alert";
import { useGlobalOrganizationContext } from "../../hooks/useGlobalOrganizationContext";
import { login } from "../../lib/auth";
import { CognitoUser } from "amazon-cognito-identity-js";
import { ExecutionState } from "../../model/Process";
import useAppNavigation from "../../hooks/useAppNavigation";
import { AxiosError } from "axios";

type ManageInvitationPageContextType = {
  email?: string;
  cognitoUser?: CognitoUser;
  invitation?: Invitation;
  invitationId?: string;
  invitationExecution?: ProcessExecution;
  invitingOrgId?: string;
};

export const ManageInvitationPageContext =
  createContext<ManageInvitationPageContextType>(
    {} as ManageInvitationPageContextType,
  );

const ManageInvitationPage: FC = () => {
  const { setOrganization } = useGlobalOrganizationContext();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [loginShowing, setLoginShowing] = useState<boolean>(false);
  const [invitationContext, setInvitationContext] =
    useState<ManageInvitationPageContextType>();

  const { navigateHome } = useAppNavigation();
  const location = useLocation();

  const handleRegisterOrLogin = useCallback(
    async (user: User) => {
      // find the invitation
      let invitation: Invitation | undefined = invitationContext?.invitation;
      if (!invitation) {
        await InvitationAPI.get(invitationContext?.invitationId ?? "")
          .then((inv) => (invitation = inv))
          .catch((err) => console.error(err));
      }
      // find the invitation process execution
      const execution = (await ProcessExecutionAPI.byUserId(user.id)).payload
        ?.filter(
          (ex) =>
            ex.subject_id == invitation?.organization_id &&
            ex.owner?.id == invitation?.target_user_id &&
            ex.next_task_instance?.type == TaskType.AcceptInvitation,
        )
        .at(0);

      if (!execution) {
        console.error(
          `Invitation for ${invitationContext?.email} cannot be accepted by ${user.email}`,
        );
        navigateHome();
      }

      if (
        !!(await OrganizationAPI.byMemberOrAccountUser(user.id)).find(
          (org) => org.id == invitationContext?.invitingOrgId,
        ) ||
        execution?.state == ExecutionState.Completed
      ) {
        navigateHome();
      } else {
        // set the context and move to the next step
        setInvitationContext((context) => ({
          ...context,
          invitation,
          invitationExecution: execution,
        }));
        setActiveStep(1);
      }
    },
    [invitationContext, navigateHome],
  );

  useEffect(() => {
    const state = location.state;
    if (!state) {
      setInvitationContext({});
      return;
    }

    const setupContext = async () => {
      // first setup
      const newContext: ManageInvitationPageContextType = {
        invitingOrgId: state.organization_id,
        invitationId: state.invitation_id,
        email: state.user,
      };

      // update global organization
      if (state.organization_id) {
        OrganizationAPI.get(state.organization_id)
          .then((org) => setOrganization(org))
          .catch(() =>
            console.error(`Error loading org ${state.organization_id}`),
          );
      }

      // login
      if (state.user) {
        if (state.password) {
          const loginResult = await login(state.user, state.password);
          if (loginResult.changePasswordState) {
            newContext.cognitoUser =
              loginResult.changePasswordState.cognitoUser;
          }
        } else {
          setLoginShowing(true);
        }
      }

      // get invitation
      InvitationAPI.get(state.invitationId)
        .then((invitation) => (newContext.invitation = invitation))
        .catch((err: AxiosError) => {
          // we ignore 403 because that's what happens when the invitation has already been accepted
          if (err.response?.status != 403) console.error(err);
        });

      setInvitationContext(newContext);
    };
    setupContext().catch((err) => {
      console.error(err);
      setInvitationContext({});
    });
  }, [location.state, setOrganization]);

  const getTaskExecution = (
    execution: ProcessExecution,
    accept: boolean,
    invitationId: string,
  ): TaskAcceptInvitationExecution => {
    return {
      ...execution,
      task_id: execution.next_task_id,
      state: TaskExecutionState.Completed,
      data: {
        accept: accept,
        invitationId: invitationId,
      },
    };
  };

  const handleInvitationAccept = (accept: boolean) => {
    const invitation = invitationContext?.invitation;
    const execution = invitationContext?.invitationExecution;

    if (invitationContext && invitation && execution)
      Promise.all([
        accept
          ? InvitationAPI.accept(invitation.id)
          : InvitationAPI.delete(invitation.id),
        ProcessExecutionAPI.submitTask(
          execution.id,
          execution.next_task_id,
          getTaskExecution(execution, accept, invitation.id),
        ),
      ])
        .then(() => {
          ProcessExecutionAPI.completeTask(
            execution.id,
            execution.next_task_id,
          );
        })
        .finally(() => {
          if (accept) {
            setActiveStep(2);
          } else {
            navigateHome();
          }
        });
  };

  return (
    <DefaultAlertSnackbar>
      {invitationContext && (
        <ManageInvitationPageContext.Provider value={invitationContext}>
          <OnboardingBasePage
            steps={[
              {
                title: "Create Account/Login",
                element: !loginShowing ? (
                  <RegisterPanel
                    onRegister={(user) => handleRegisterOrLogin(user)}
                    onClickLogin={() => setLoginShowing(true)}
                  />
                ) : (
                  <SignInPanel
                    onSignIn={(user) => handleRegisterOrLogin(user)}
                    onClickCreate={() => setLoginShowing(false)}
                  />
                ),
              },
              {
                title: "Accept Invitation",
                element: (
                  <ManageInvitationPanel
                    onAccept={() => handleInvitationAccept(true)}
                    onIgnore={() => handleInvitationAccept(false)}
                  />
                ),
              },
              {
                title: "Finish",
                element: <FinalizePanel />,
              },
            ]}
            activeStep={activeStep}
          />
        </ManageInvitationPageContext.Provider>
      )}
    </DefaultAlertSnackbar>
  );
};

export default ManageInvitationPage;
