import { FC, PropsWithChildren } from "react";
import Box from "@mui/material/Box";
import Scrollable from "../Scrollable";

export type MainContentProps = {
  top?: number;
  height?: string;
};

const MainContent: FC<PropsWithChildren<MainContentProps>> = ({
  top = 0,
  height,
  children,
}) => {
  return (
    <Scrollable
      component="main"
      sx={(theme) => ({
        flex: 1,
        backgroundColor:
          theme.palette.mode === "light"
            ? theme.palette.grey[50]
            : theme.palette.grey[900],
        pt: top,
        height,
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down("sm")]: {
          pt: top > 0 ? top - 1 : 0,
        },
      })}
    >
      <Box
        component="section"
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
        }}
      >
        {children}
      </Box>
    </Scrollable>
  );
};

export default MainContent;
