import API from "./base";
import { ApiKey } from "../model/ApiKey";

const getAll = (userId: string): Promise<ApiKey[]> => {
  return new Promise((resolve, reject) => {
    API.get(`/api-keys/byUserId/${userId}`).then(
      (result) => resolve(result.data.payload as ApiKey[]),
      reject,
    );
  });
};

const create = (name: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    API.post("/api-keys/", {}, { params: { name: name } }).then(
      (result) => resolve(result.data.payload as string),
      reject,
    );
  });
};

const revokeByName = (name: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    API.post(`/api-keys/revokeByName/${name}`).then(() => resolve(), reject);
  });
};

const elevate = (): Promise<string> => {
  return new Promise((resolve, reject) => {
    API.post(`/api-keys/elevate-cm-user`).then(
      (result) => resolve(result.data.payload as string),
      reject,
    );
  });
};

export default {
  getAll,
  create,
  revokeByName,
  elevate,
};
