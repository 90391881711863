import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { FC } from "react";
import { useIntl } from "react-intl";
import { IconButtonDropdownMenu } from "../../../../components/elements";
import { PageHeader } from "../../../../components/layout/page";
import { Process } from "../../../../model/Process";
import messages from "../../messages";
import SingleProcessVisualizeMenu from "./SingleProcessVisualizeMenu";
import { SingleProcessViewType } from "./SingleProcessWrapper";
import PATHS from "../../../../components/navigation/_paths";

type SingleProcessHeaderProps = {
  process: Process;
  view: SingleProcessViewType;
  executeProcess: (processId: string, title: string) => void;
};

const SingleProcessHeader: FC<SingleProcessHeaderProps> = ({
  process,
  view,
  executeProcess,
}) => {
  const intl = useIntl();
  const processesMsg = intl.formatMessage(messages.processes.props);

  return (
    <PageHeader
      links={[
        { link: PATHS.PROCESSES.link, title: processesMsg },
        { link: `../table`, title: `Executions for ${process.name}` },
      ]}
      title={`${
        view == SingleProcessViewType.TABLE ? "Table View" : "Board View"
      }`}
      actions={
        <Button
          variant="contained"
          onClick={() => {
            executeProcess(
              process.meta ?? process.id ?? "",
              process.name ?? "",
            );
          }}
        >
          Start New Process
        </Button>
      }
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h4">
          Executions for
          <span style={{ fontWeight: "bold" }}>{" " + process.name}</span>
        </Typography>
        <IconButtonDropdownMenu
          element={<KeyboardArrowDownOutlined />}
          closed={false}
          setClosed={() => {
            return;
          }}
        >
          <SingleProcessVisualizeMenu />
        </IconButtonDropdownMenu>
      </Box>
    </PageHeader>
  );
};

export default SingleProcessHeader;
