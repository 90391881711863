/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useState } from "react";
import { AccountTypeAPI } from "../../../api";
import { ProcessCategoryAPI } from "../../../api";
import { useGlobalOrganizationContext } from "../../../hooks/useGlobalOrganizationContext";
import { useAlert } from "../../../lib/alert";
import { AccountType } from "../../../model/AccountType";
import { ProcessCategory } from "../../../model/ProcessCategory";
import OrganizationAccountTypePublicProcessCards from "./OrganizationAccountTypePublicProcessCards";
import OrganizationAccountTypeListPublicPage from "./OrganizationAccountTypeListPublicPage";
import OrganizationCategoryCards from "./OrganizationCategoryCards";
import ChatUI from "../../../components/chat/ChatUI";
import OrganizationCategoryPublicProcessCards from "./OrganizationCategoryPublicProcessCards";

const PublicOrganizationPage = () => {
  const { organization } = useGlobalOrganizationContext();
  const { handleRejectionWithError } = useAlert();
  const [accountTypes, setAccountTypes] = useState<AccountType[]>();
  const [categories, setCategories] = useState<ProcessCategory[] | undefined>(
    undefined,
  );
  // Get portal categories if exist
  useEffect(() => {
    const loadPortalCategories = () => {
      ProcessCategoryAPI.byOrganization(organization!.id!).then(
        (categories) => {
          // 1. Check for Portal category and display all child categories
          const portalCategory = categories.find((c) => c.label == "Portal");
          if (portalCategory) {
            const portalCategories = categories.filter(
              (c) => c.parent_id == portalCategory.id,
            );
            if (portalCategories.length > 0) {
              setCategories(portalCategories);
            } else {
              setCategories([portalCategory]);
            }
          } else {
            // 2. Check for categories in portal config
            if (organization?.config?.portal?.group == "category") {
              setCategories(organization.config.portal.categories);
            } else {
              setCategories([]);
            }
          }
        },
        handleRejectionWithError,
      );
    };
    if (organization) {
      if (!categories) {
        loadPortalCategories();
      }
    }
  }, [categories, handleRejectionWithError, organization]);
  // Get account types
  useEffect(() => {
    const loadAccountTypes = () => {
      AccountTypeAPI.byOrganizationId(organization!.id!).then(
        (accountTypes) => {
          setAccountTypes(accountTypes);
        },
        handleRejectionWithError,
      );
    };
    if (organization) {
      if (!accountTypes) {
        loadAccountTypes();
      }
    }
  }, [accountTypes, handleRejectionWithError, organization]);

  if (!organization) {
    return <></>;
  }

  if (categories && categories.length > 0) {
    if (categories.length === 1) {
      return (
        <OrganizationCategoryPublicProcessCards categoryId={categories[0].id} />
      );
    }
    return <OrganizationCategoryCards categories={categories} />;
  }

  // 3. Show account type cards
  if (accountTypes?.length === 1) {
    return (
      <>
        <OrganizationAccountTypePublicProcessCards
          accountType={accountTypes[0]}
        />
        <ChatUI />
      </>
    );
  }
  return accountTypes ? (
    <>
      <OrganizationAccountTypeListPublicPage accountTypes={accountTypes} />
      <ChatUI />
    </>
  ) : (
    <></>
  );
};
export default PublicOrganizationPage;
