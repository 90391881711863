import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { ProcessExecution } from "../../../../model/ProcessExecution";

type CardProgressBarProps = {
  execution: ProcessExecution;
};

// Displays a progress bar on the card,
// very similar to ProcessProgress component except downscaled

const CardProgressBar: FC<CardProgressBarProps> = (props) => {
  if (props.execution.completed_tasks && props.execution.total_tasks) {
    const [tasksComplete, totalTasks] = [
      parseFloat(props.execution.completed_tasks),
      parseFloat(props.execution.total_tasks),
    ];

    const percentage = totalTasks ? (tasksComplete / totalTasks) * 100 : 0;

    return (
      <Box
        sx={{
          textAlign: "center",
          height: "3vh",
          border: "1px solid black",
          borderRadius: ".5vh",
          background: (theme) =>
            `linear-gradient(to right, ${theme.palette.progress.progress} 0% ${percentage}%, ${theme.palette.progress.remaining} ${percentage}% ${percentage}%)`,
          transition: "background .25s",
        }}
      >
        <Typography>
          {tasksComplete}/{totalTasks} Tasks Complete
        </Typography>
      </Box>
    );
  }

  return <Box />;
};

export default CardProgressBar;
