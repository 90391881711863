import { Delete } from "@mui/icons-material";
import { Button, FormGroup, Grid, IconButton, TextField } from "@mui/material";
import { FormDefinitionType, FormElement, SelectionOption } from "../../types";
import { FormElementChangeType } from "./FormBuilder";

export const SideSectionSelectionOptionsDisplay = ({
  element,
  onChange,
}: {
  element: FormElement & {
    options: Array<SelectionOption>;
    field_definition_id?: string;
  };
  onChange: (props: FormElementChangeType) => void;
}) => {
  return (
    <>
      {element.options.map((option, index) => {
        return (
          <FormGroup row sx={{ gap: 2, alignItems: "center" }} key={index}>
            <TextField
              sx={{ flex: 1 }}
              label={"Display Name"}
              value={option.name ?? ""}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                const newOptions = [...element.options];
                newOptions[index] = {
                  ...newOptions[index],
                  name: e.target.value,
                };
                onChange({
                  changeType: "update",
                  newElement: {
                    ...element,
                    options: newOptions,
                  } as FormDefinitionType,
                });
              }}
            />
            <TextField
              sx={{ flex: 1 }}
              label={"Value"}
              value={option.id ?? ""}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                const newOptions = [...element.options];
                newOptions[index] = {
                  ...newOptions[index],
                  id: e.target.value,
                };
                onChange({
                  changeType: "update",
                  newElement: {
                    ...element,
                    options: newOptions,
                  } as FormDefinitionType,
                });
              }}
            />
            <Grid>
              <IconButton
                onClick={() => {
                  const newOptions = [...element.options];
                  newOptions.splice(index, 1);
                  onChange({
                    changeType: "update",
                    newElement: {
                      ...element,
                      options: newOptions,
                    } as FormDefinitionType,
                  });
                }}
              >
                <Delete />
              </IconButton>
            </Grid>
          </FormGroup>
        );
      })}
      <Button
        variant="contained"
        onClick={() => {
          const newOptions = [...element.options];
          newOptions.push({ id: "", name: "" });
          onChange({
            changeType: "update",
            newElement: {
              ...element,
              options: newOptions,
            } as FormDefinitionType,
          });
        }}
      >
        {"Add Option"}
      </Button>
    </>
  );
};
