import Accounts from "../api/accounts";
import Invitations from "../api/invitations";
import Organizations from "../api/organizations";
import { InvitationProvider, AccountProvider } from ".";

const accountProvider = {
  fetchAccountsByOrganizationId: (
    organizationId: string,
    download?: boolean,
  ) => {
    if (!organizationId) {
      return Promise.resolve([]);
    }
    return Accounts.activeByOrganizationId(
      organizationId,
      download ? { format: "xlsx" } : {},
    );
  },
  fetchAccountsByOrganizationAndAccountType: (
    organizationId: string,
    accountTypeId: string,
    download?: boolean,
  ) => {
    if (!organizationId) {
      return Promise.resolve([]);
    }
    return Accounts.byOrganizationAndAccountType(
      organizationId,
      accountTypeId,
      download ? { format: "xlsx" } : {},
    );
  },
  fetchByAccountId: (accountId: string) => {
    if (!accountId) {
      return Promise.resolve([]);
    }
    const result = Accounts.get(accountId);
    return result;
  },
  fetchAccountOrganization: (organizationId: string) => {
    if (!organizationId) {
      return Promise.resolve([]);
    }
    return Organizations.get(organizationId);
  },
  listAccountsIsMemberOf: (userId: string) => {
    if (!userId) return Promise.resolve([]);
    return Accounts.listAccountsByMember(userId);
  },

  getAuditEvents: (accountId: string) => {
    if (!accountId) return Promise.resolve([]);
    return Accounts.auditEventsBySubjectId(accountId);
  },

  // currently cannot unassign a role from an 'Account'
} as AccountProvider;

const invitationProvider = {
  fetch: (organizationId: string) => {
    if (!organizationId) {
      return Promise.resolve([]);
    }
    return Invitations.accountsByOrganization(organizationId);
  },
  delete: (id: string) => {
    return Invitations.delete(id).then((result) => {
      return result;
    });
  },
  assignRole: (roleId: string, invitationId: string) => {
    return Invitations.assignRole(roleId, invitationId);
  },
  unassignRole: (roleId: string, invitationId: string) => {
    return Invitations.unassignRole(roleId, invitationId);
  },
} as InvitationProvider;

export { accountProvider, invitationProvider };
