import * as React from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Link,
  Paper,
  SxProps,
  TextField,
  Typography,
} from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import { authenticated, login, logout } from "../../../lib/auth";
import { grey } from "@mui/material/colors";
import { mapCognitoError } from "../../../lib/cognito";
import { useTheme } from "@mui/material/styles";
import { GoogleAuthButton } from "./GoogleAuth";
import { currentUserId } from "../../../lib/auth";
import { UserAPI } from "../../../api";
import { User } from "../../../model";
import { useNavigate } from "react-router-dom";

type SignInComponentProps = {
  includeFooter?: boolean;
  email?: string;
  sx?: SxProps;
  onSuccess: (user: User) => void;
  onClickRegister?: () => void;
};

export default function SignInComponent({
  onClickRegister,
  ...props
}: SignInComponentProps) {
  const theme = useTheme();
  const navigate = useNavigate();

  const forgotPasswordLink = "/forgot-password";

  const [emailAddress, setEmailAddress] = useState("");

  const [authenticating, setAuthenticating] = useState<boolean>(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const onSuccess = useCallback(() => {
    setSuccess(true);
    UserAPI.get(currentUserId()).then((user) => props.onSuccess(user));
  }, [props]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const emailValue = props.email ?? data.get("email");
    const passwordValue = data.get("password");

    if (!emailValue) {
      setError("Invalid email address");
      return;
    }

    if (!passwordValue) {
      setError("Invalid password");
      return;
    }

    setAuthenticating(true);
    login(emailValue.toString(), passwordValue.toString())
      .then(
        (result) => {
          if (result.success) {
            onSuccess();
            return;
          }

          setError("Unable to complete login, please try again");
          console.error(result);
        },
        (err) => setError(mapCognitoError(err)),
      )
      .finally(() => setAuthenticating(false));
  };

  useEffect(() => {
    if (success) return;
    if (authenticated()) {
      onSuccess();
    } else {
      login().then(onSuccess, logout);
    }
  }, [onSuccess, success]);

  // If the user is already authenticated let them through to the dashboard
  if (success) {
    return <></>;
  }

  return (
    <Paper elevation={9} sx={{ ...props.sx }}>
      {/* main content */}
      {authenticating ? (
        <Box
          sx={{
            width: "100%",
            height: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ px: 4, py: 4 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography component="h1" variant="h4">
              Sign In
            </Typography>
            {onClickRegister && (
              <Typography component="h2" variant="body2" sx={{ mt: 1 }}>
                {"Not registered yet? "}
                <Link
                  onClick={onClickRegister}
                  component="button"
                  underline="hover"
                >
                  Create an account
                </Link>
              </Typography>
            )}

            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              {error && (
                <Alert sx={{ width: "100%" }} severity="error">
                  {error}
                </Alert>
              )}
              {/* form fields */}
              <TextField
                data-cy="email-field"
                sx={{ fontSize: theme.typography.body2.fontSize }}
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={props.email ?? emailAddress}
                disabled={!!props.email} // disabled from email since it cant be changed anyways
                autoFocus
                onChange={(e) => setEmailAddress(e.target.value as string)}
              />
              <TextField
                data-cy="password-field"
                sx={{ fontSize: theme.typography.body2.fontSize }}
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link
                    onClick={() => navigate(forgotPasswordLink)}
                    component="button"
                    variant="body2"
                  >
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>

              <Button
                data-cy="sign-in-btn"
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 3,
                  py: 1.5,
                  textTransform: "capitalize",
                }}
              >
                Sign In
              </Button>

              <Divider>
                <Typography component="p" variant="caption">
                  OR
                </Typography>
              </Divider>
              <GoogleAuthButton
                setAuthenticating={(state) => setAuthenticating(state)}
                origin={`${window.location.origin}${window.location.pathname}`}
                label="Sign In"
              />
            </Box>
          </Box>
        </Box>
      )}

      {/* footer */}
      {props.includeFooter !== false && (
        <Box
          sx={{
            backgroundColor: grey[100],
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: 2,
          }}
        >
          <Typography component="h2" variant="body2">
            <Link href="/terms.html" underline="hover" color="inherit">
              Terms &amp; Conditions
            </Link>{" "}
            &middot;{" "}
            <Link href="/privacy.html" underline="hover" color="inherit">
              Privacy Policy
            </Link>
          </Typography>
        </Box>
      )}
    </Paper>
  );
}
