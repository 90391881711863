import RegisterComponent from "../../../../../user/components/RegisterComponent";

const RegisterInlineEditor = () => {
  return (
    <RegisterComponent
      onComplete={() => {
        return;
      }}
      disabled={true}
    />
  );
};

export default RegisterInlineEditor;
