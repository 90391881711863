import { TextField } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useEffect, useRef, useState } from "react";
import { AccountTypeAPI } from "../../../api";
import { AccountType } from "../../../model/AccountType";

const OPTIONS_LIMIT = 10;
const filterOptions = createFilterOptions<AccountType>({
  limit: OPTIONS_LIMIT,
  ignoreCase: false,
  ignoreAccents: false,
});

export type AccountTypeSelectProps = {
  organizationId: string;
  onChange: (account: AccountType | undefined) => void;
  value?: string;
  label?: string;
};

const fetchAccountTypesFromOrganization = async (organizationId: string) => {
  return await AccountTypeAPI.byOrganizationId(organizationId);
};

const AccountTypeSelect = ({
  organizationId,
  onChange,
  value,
  label = "Select an Account Type",
}: AccountTypeSelectProps) => {
  const [accountTypes, setAccountTypes] = useState<Array<AccountType>>([]);
  const accountTypesFetch = useRef(false);
  const [inputValue, setInputValue] = useState("");
  const [selectedAccountType, setSelectedAccountType] = useState<
    AccountType | undefined
  >();

  useEffect(() => {
    if (!accountTypesFetch.current) {
      accountTypesFetch.current = true;
      fetchAccountTypesFromOrganization(organizationId).then((accountTypes) => {
        setAccountTypes(accountTypes);
        if (accountTypes.length === 1) {
          onChange(accountTypes[0]);
        }
      });
    }
  }, [organizationId, onChange]);

  useEffect(() => {
    if (value && accountTypes.length > 0) {
      setSelectedAccountType(
        accountTypes.find((accountType) => accountType.key === value),
      );
    } else {
      setSelectedAccountType(undefined);
    }
  }, [accountTypes, value]);

  const handleOnChange = (
    event: React.SyntheticEvent,
    accountType: AccountType | null,
  ) => {
    onChange(accountType as AccountType | undefined);
  };

  return (
    <>
      <Autocomplete<AccountType>
        sx={{ flex: 2 }}
        filterOptions={filterOptions}
        value={selectedAccountType ?? null}
        onChange={handleOnChange}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id="account-type-selection"
        options={accountTypes}
        getOptionLabel={(accountType) => accountType.name ?? accountType.key}
        noOptionsText={`No ${inputValue} account type found`}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              style: {
                paddingLeft: 0,
              },
            }}
            label={label}
          />
        )}
      />
    </>
  );
};

export default AccountTypeSelect;
