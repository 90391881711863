import {
  Autocomplete,
  DialogContentText,
  MenuItem,
  TextField,
} from "@mui/material";
import Dialog from "../../../components/Dialog";
import { FC, useEffect, useState } from "react";
import { useAlert } from "../../../lib/alert";
import { AccountTypeAPI } from "../../../api";
import keyify from "../../../lib/keyify";
import {
  AccountRegistrationType,
  AccountType,
} from "../../../model/AccountType";
import pluralize from "pluralize";
import FieldDefinition from "../../../model/FieldDefinition";

export type EditAccountTypeDialogProps = {
  open: boolean;
  organizationId: string;
  accountType?: AccountType;
  accountId?: string;
  onClose?: () => void;
  onSave?: (accountType: AccountType) => void;
  save?: (accountType: AccountType) => Promise<AccountType>;
  title?: string;
  confirmText?: string;
};
const saveAccountType = (accountType: AccountType): Promise<AccountType> => {
  return AccountTypeAPI.update(accountType);
};
const EditAccountTypeDialog: FC<EditAccountTypeDialogProps> = ({
  title = "Edit Account Type",
  confirmText = "Save",
  accountType,
  save = saveAccountType,
  ...props
}) => {
  const { handleRejectionWithError } = useAlert();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [registrationType, setRegistrationType] = useState(
    AccountRegistrationType.InviteOnly,
  );
  const [fieldDefinitionOptions, setFieldDefinitionOptions] = useState<
    FieldDefinition[]
  >([]);
  const [selectedFieldDefinitions, setSelectedFieldDefinitions] = useState<
    FieldDefinition[]
  >([]);

  useEffect(() => {
    if (accountType) {
      setName(accountType.name);
      setDescription(accountType.description ?? "");
      setRegistrationType(
        accountType.registration_type ?? AccountRegistrationType.InviteOnly,
      );
      AccountTypeAPI.getFieldDefinitions(accountType.id).then((result) => {
        setFieldDefinitionOptions(Object.values(result.available));
        setSelectedFieldDefinitions(
          result.selected.map((id) => result.available[id]),
        );
      });
    }
  }, [accountType]);

  return (
    <Dialog
      open={props.open}
      title={title}
      confirmText={confirmText}
      cancelText={"Cancel"}
      maxWidth="xs"
      fullWidth={true}
      handleConfirm={(): Promise<void> => {
        if (!props.organizationId) {
          return Promise.resolve();
        }

        return save({
          id: accountType?.id ?? "",
          organization_id: props.organizationId,
          name: pluralize.singular(name),
          key: keyify(pluralize.singular(name)),
          registration_type: registrationType,
          description,
        }).then((accountType) => {
          AccountTypeAPI.setSelectedFieldDefinitions(
            accountType.id,
            selectedFieldDefinitions.map((f) => f.id),
          ).then(() => {
            props.onClose && props.onClose();
            props.onSave && props.onSave(accountType);
          }, handleRejectionWithError("Failed to update field definitions"));
        }, handleRejectionWithError("Failed to save account type"));
      }}
      handleCancel={function (): void {
        props.onClose && props.onClose();
      }}
    >
      <DialogContentText>
        Enter a name for the type of accounts
      </DialogContentText>
      <TextField
        autoFocus
        margin="dense"
        id="name"
        label="Name"
        type="text"
        fullWidth
        variant="standard"
        value={name}
        onChange={(e) => setName(e.target.value as string)}
      />
      <TextField
        id="registration-type"
        value={registrationType}
        label="Registration Type"
        onChange={(e) =>
          setRegistrationType(e.target.value as AccountRegistrationType)
        }
        select
        fullWidth
        variant="standard"
        sx={{ marginTop: "5px" }}
      >
        <MenuItem value={AccountRegistrationType.InviteOnly}>
          Invite Only
        </MenuItem>
        <MenuItem value={AccountRegistrationType.SelfRegistration}>
          Self-Registration
        </MenuItem>
      </TextField>
      <TextField
        margin="dense"
        id="description"
        label="Description"
        type="text"
        fullWidth
        rows={3}
        multiline={true}
        variant="standard"
        value={description}
        onChange={(e) => setDescription(e.target.value as string)}
      />
      <Autocomplete
        multiple
        options={fieldDefinitionOptions}
        value={selectedFieldDefinitions}
        onChange={(e, newValues) => setSelectedFieldDefinitions([...newValues])}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.label ? option.label : "<Empty>"}
          </li>
        )}
        renderInput={(params) => (
          <TextField {...params} variant="standard" label="Field Definitions" />
        )}
        getOptionLabel={(option) => option.label ?? "Field Definition"}
        limitTags={3}
        sx={{ marginTop: "5px" }}
      />
    </Dialog>
  );
};

export default EditAccountTypeDialog;
