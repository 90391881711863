import { Box, Button, Card, CardHeader, Grid, Typography } from "@mui/material";
import { FC, Suspense, lazy } from "react";
import { Process } from "../../../../model/Process";
import messages from "../../../process/messages";
import { Checklist } from "@mui/icons-material";

export type AccountProcessCardProps = {
  processes: Process[];
  onProcessClick: (process: Process) => void;
};

const DynamicIconComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "DynamicIcon" */ "../../../../components/core/DynamicIcon"
    ),
);

const AccountProcessCard: FC<AccountProcessCardProps> = ({
  processes,
  onProcessClick,
}) => {
  return (
    <Card
      raised={true}
      elevation={2}
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Typography variant="h2" sx={{ px: 2, pt: 1.25 }}>
        {messages.processes}
      </Typography>
      <Box
        height="100%"
        sx={{ overflow: "auto", backgroundColor: "background.drawer" }}
      >
        <Suspense>
          <Grid container spacing={1} sx={{ p: 1 }}>
            {processes.map((process) => (
              <Grid item xs={12} key={process.id}>
                <Card sx={{ boxShadow: 0 }}>
                  <CardHeader
                    avatar={
                      process.icon ? (
                        <DynamicIconComponent
                          name={process.icon}
                          sx={{ flex: 1 }}
                        />
                      ) : (
                        <Checklist sx={{ flex: 1 }} />
                      )
                    }
                    action={
                      <Button onClick={() => onProcessClick(process)}>
                        Start
                      </Button>
                    }
                    title={process.name}
                    sx={{ p: 1 }}
                  />
                </Card>
              </Grid>
            ))}
          </Grid>
        </Suspense>
      </Box>
    </Card>
  );
};
export default AccountProcessCard;
