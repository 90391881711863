import { Box, SxProps, Theme } from "@mui/material";
import { PropsWithChildren } from "react";

export type CardActionBarProps = PropsWithChildren<{
  align?: string;
  sx?: SxProps<Theme>;
}>;
const CardActionBar = ({
  align = "left",
  children,
  sx,
}: CardActionBarProps) => {
  return (
    <Box
      sx={{
        height: "64px",
        minHeight: "64px",
        width: "100%",
        flex: 0,
        p: 2,
        bgcolor: "background.menu",
        borderTop: (theme) => `1px solid ${theme.palette.grey[300]}`,
        display: "flex",
        justifyContent: align,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
export default CardActionBar;
