import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FilePresent } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { FileUploadResponse } from "../../transfer/FileUploadResponse";
import { useAlert } from "../../lib/alert";
import downloadApiFile from "../../util/downloadApiFile";

type DownloadableAttachmentProps = {
  file: FileUploadResponse;
};

function getFileName(fragment: string) {
  const parts = fragment.split("/");
  return parts[parts.length - 1];
}

const DownloadableAttachment = ({ file }: DownloadableAttachmentProps) => {
  const { handleRejectionWithError } = useAlert();
  const [hover, setHover] = React.useState(false);
  const theme = useTheme();

  const handleDownload = () => {
    fetch(
      `${process.env.REACT_APP_REST_API}/files/${encodeURIComponent(file.key)}`,
    )
      .then((response) => response.blob())
      .then((blob) => downloadApiFile(blob, getFileName(file.key)))
      .catch(handleRejectionWithError);
  };

  return (
    <Box
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onClick={handleDownload}
      style={{ cursor: "pointer" }}
    >
      <FilePresent
        fontSize="large"
        style={{ color: hover ? theme.palette.primary.main : "inherit" }}
      />
      <Typography>{getFileName(file.key)}</Typography>
    </Box>
  );
};

export default DownloadableAttachment;
