import { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowSelectionModel,
  GridRowId,
} from "@mui/x-data-grid";
import {
  CommonDatagridWrapper,
  IconButtonDropdownMenu,
} from "../../components/elements";
import debounce from "lodash.debounce";
import { UserProvider } from "../../providers";
import { UserOrganizationRelationshipType } from "../../types";
import Integrations from "../../api/integrations/integrations";
import Integration from "../../model/Integration";
import { useAlert } from "../../lib/alert";
import { MoreVert } from "@mui/icons-material";
import { MenuItem } from "@mui/material";
import AddIntegrationDialog from "./AddIntegrationDialog";

type IntegrationsGridProps = {
  organizationId: string;
  users: UserProvider;
  userOrganizationRelationshipType: UserOrganizationRelationshipType;
};

const IntegrationsGrid: FC<IntegrationsGridProps> = (props) => {
  const { handleRejectionWithWarning, handleRejectionWithError, success } =
    useAlert();
  const [isLoaded, setIsLoaded] = useState(false);
  const [integrationSelectionModel, setIntegrationSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [integrationsList, setIntegrationsList] = useState<Integration[]>([]);
  const [integrationsDropdownClosed, setIntegrationsDropdownClosed] =
    useState(false);
  const [addIntegrationDialogOpen, setAddIntegrationDialogOpen] =
    useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState<Integration>();

  const fetchIntegrationsForOrganization = useCallback(() => {
    if (!props.organizationId) {
      return Promise.resolve([]);
    }

    return Integrations.getAll(props.organizationId).then(
      (integrations) => {
        setIntegrationsList(integrations);
        return integrations;
      },
      (err) => {
        handleRejectionWithWarning(
          "Could not load integrations for organization",
        )(err);
        return [];
      },
    );
  }, [props.organizationId, handleRejectionWithWarning]);

  const reload = useMemo(
    () =>
      debounce(
        () => {
          setIsLoaded(false);
          fetchIntegrationsForOrganization().then((result) => {
            // Cancel any queued calls now that we've finished loading
            reload.cancel();
            setIntegrationsList(result);
            setIsLoaded(true);
          });
        },
        5000,
        { leading: true },
      ),
    [fetchIntegrationsForOrganization],
  );

  useEffect(() => {
    reload();
  }, [reload]);

  const integrationsColumns: GridColDef[] = [
    {
      field: "integration",
      headerName: "Integration",
      sortable: true,
      flex: 1,
      minWidth: 150,
      renderCell: (params: GridRenderCellParams) => {
        return <>{params.row.type}</>;
      },
    },
    {
      field: "apiKey",
      headerName: "API Key",
      sortable: true,
      flex: 1,
      minWidth: 150,
      renderCell: () => {
        return <>*********************</>;
      },
    },
  ];

  const deleteSelectedIntegrations = () => {
    const promises = integrationSelectionModel.map((id: GridRowId) => {
      const found = integrationsList.find((item) => item.id === id.toString());

      if (!found) {
        console.warn(
          "Unable to find matching model object for invitation or user id: " +
            id.toString(),
        );
        return;
      }

      return Integrations.remove(found.id, props.organizationId);
    });

    Promise.all(promises).then(() => {
      setIntegrationsDropdownClosed(true);
      success("Integration deleted");
      reload();
    }, handleRejectionWithError("Failed to delete all integrations"));
  };

  const loaded = (
    <DataGrid
      checkboxSelection
      disableRowSelectionOnClick
      rows={integrationsList}
      pageSizeOptions={[10]}
      getRowHeight={() => "auto"}
      columns={integrationsColumns}
      onRowSelectionModelChange={(newSelectionModel) => {
        setIntegrationSelectionModel(newSelectionModel);
      }}
      rowSelectionModel={integrationSelectionModel}
    />
  );

  const menu = (
    <IconButtonDropdownMenu
      element={<MoreVert />}
      closed={integrationsDropdownClosed}
      setClosed={setIntegrationsDropdownClosed}
    >
      <MenuItem
        onClick={() => {
          setAddIntegrationDialogOpen(true);
          setIntegrationsDropdownClosed(true);
        }}
      >
        Add Integration
      </MenuItem>
      <MenuItem
        onClick={() => {
          setSelectedIntegration(
            integrationsList.find(
              (integration) =>
                integration.id === integrationSelectionModel.toString(),
            ) as Integration,
          );
          setAddIntegrationDialogOpen(true);
          setIntegrationsDropdownClosed(true);
        }}
        disabled={
          !integrationSelectionModel.length ||
          integrationSelectionModel.length > 1
        }
      >
        Update Selected Integration
      </MenuItem>
      <MenuItem
        onClick={deleteSelectedIntegrations}
        disabled={
          !integrationSelectionModel.length ||
          integrationSelectionModel.length > 1
        }
      >
        Delete Selected Integration
      </MenuItem>
    </IconButtonDropdownMenu>
  );

  return (
    <>
      <CommonDatagridWrapper
        isLoaded={isLoaded}
        loaded={loaded}
        title="Integrations"
        menu={menu}
      />
      {addIntegrationDialogOpen && (
        <AddIntegrationDialog
          organizationId={props.organizationId}
          open={addIntegrationDialogOpen}
          setAddIntegrationDialogOpen={(openState) => {
            setAddIntegrationDialogOpen(openState);
          }}
          integration={selectedIntegration}
          onClose={() => {
            setSelectedIntegration(undefined);
          }}
          onConfirm={() => {
            reload();
          }}
        />
      )}
    </>
  );
};

export default IntegrationsGrid;
