import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { PropsWithChildren } from "react";

const stripe = loadStripe(process.env.REACT_APP_STRIPE_API_KEY ?? "", {
  apiVersion: "2022-11-15",
});

type StripeContainer = {
  clientSecret?: string;
};

const StripeContainer = ({
  children,
  clientSecret,
}: PropsWithChildren<StripeContainer>) => {
  if (!clientSecret) {
    return <></>;
  }

  return (
    <Elements stripe={stripe} options={{ clientSecret: clientSecret }}>
      {children}
    </Elements>
  );
};

export default StripeContainer;
