import { FC, useEffect, useState } from "react";
import {
  FormControl,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import {
  SelectionOption,
  SelectionFormElement,
  TaskExecutionState,
} from "../../../../types";

type TaskSelectionFormType = {
  formId: string | undefined;
  onValueChange: (value: SelectionOption) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  execution?: any;
  disabled?: boolean;
} & SelectionFormElement;

const TaskSelectionFormComponent: FC<TaskSelectionFormType> = ({
  formId,
  required,
  options,
  disabled,
  execution,
  onValueChange,
  taskExecutionState,
  ...props
}) => {
  // Use states
  const [selectValue, setSelectValue] = useState<string>(
    execution?.value?.name ||
      execution?.selection?.name ||
      execution?.value ||
      "",
  );

  // Handlers
  const handleOnChange = (e: SelectChangeEvent) => {
    const newValue: SelectionOption = {
      id: e.target.name,
      name: e.target.value,
    };
    setSelectValue(newValue.name);
    onValueChange(newValue);
  };

  useEffect(() => {
    if (execution?.selection) {
      // selection is used for changes made to the component itself
      onValueChange(execution.selection);
    } else if (execution?.value) {
      // value is what we store in the execution version of this object
      onValueChange(execution.value);
    }
  }, [execution?.selection, execution?.value, onValueChange]);

  const label = props.label + (required ? "*" : "");

  // Readonly view
  if (disabled && taskExecutionState === TaskExecutionState.Completed) {
    return (
      <Grid item container gap={1} alignItems={"center"}>
        <Typography variant="subtitle1">{label}:</Typography>
        <Typography>{selectValue}</Typography>
      </Grid>
    );
  }

  return (
    <Grid item>
      <FormGroup id={formId}>
        <FormControl>
          <InputLabel id={props.id}>{label}</InputLabel>
          <Select
            labelId={props.id}
            label={label}
            value={selectValue}
            onChange={handleOnChange}
            disabled={disabled ?? false}
          >
            {!required && (
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
            )}
            {options.map(({ id, name }) => {
              return (
                <MenuItem key={id} id={id} value={name}>
                  {name}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>{props.description}</FormHelperText>
        </FormControl>
      </FormGroup>
    </Grid>
  );
};

export default TaskSelectionFormComponent;
