import { Organization, User } from "../model";
import FieldDefinition from "../model/FieldDefinition";
import API from "./base";

const create = (organization: Organization): Promise<Organization> => {
  return new Promise((resolve, reject) => {
    API.post("/organizations", organization).then(
      (result) => resolve(result.data.payload as Organization),
      reject,
    );
  });
};

const get = (organizationId: string): Promise<Organization> => {
  return new Promise((resolve, reject) => {
    API.get("/organizations/" + organizationId, { public: true }).then(
      (result) => {
        resolve(result.data.payload as Organization);
      },
      reject,
    );
  });
};

const byKey = (organizationKey: string): Promise<Organization> => {
  return new Promise((resolve, reject) => {
    API.get("/organizations/byKey/" + organizationKey, { public: true }).then(
      (result) => {
        resolve(result.data.payload as Organization);
      },
      reject,
    );
  });
};

const update = (organization: Organization): Promise<Organization> => {
  return new Promise((resolve, reject) => {
    API.put("/organizations/" + organization.id, organization).then(
      (result) => {
        resolve(result.data.payload as Organization);
      },
      reject,
    );
  });
};

// Delete not allowed as variable name
const remove = (organizationId: string): Promise<void> => {
  return API.delete("/organizations/" + organizationId);
};

const byOwner = (userId: string): Promise<Array<Organization>> => {
  return new Promise((resolve, reject) => {
    API.get("/organizations/byOwner/" + userId).then((result) => {
      resolve(result.data.payload as Array<Organization>);
    }, reject);
  });
};

const getMembers = (
  organizationId: string,
  filter?: string,
): Promise<Array<User>> => {
  return new Promise((resolve, reject) => {
    API.get("/organizations/" + organizationId + "/users", {
      params: { filter: filter ? `${filter}` : undefined },
    }).then((result) => {
      resolve(result.data.payload as Array<User>);
    }, reject);
  });
};

const byMember = (userId: string): Promise<Array<Organization>> => {
  return new Promise((resolve, reject) => {
    API.get("/organizations/byMember/" + userId).then((result) => {
      resolve(result.data.payload as Array<Organization>);
    }, reject);
  });
};

const byMemberOrAccountUser = (
  userId: string,
): Promise<Array<Organization>> => {
  return new Promise((resolve, reject) => {
    API.get("/organizations/byMemberOrAccountUser/" + userId).then((result) => {
      resolve(result.data.payload as Array<Organization>);
    }, reject);
  });
};

const removeMember = (
  organizationId: string,
  userId: string,
): Promise<void> => {
  return new Promise((resolve, reject) => {
    API.delete("/organizations/" + organizationId + "/users/" + userId).then(
      () => resolve(),
      reject,
    );
  });
};

const fromWebsite = (website: string): Promise<Organization> => {
  return new Promise((resolve, reject) => {
    API.post("/organizations/fromWebsite", { url: website }).then(
      (result) => resolve(result.data.payload as Organization),
      reject,
    );
  });
};

const confirmEmail = (
  organizationId: string,
  confirmationCode: string,
): Promise<void> => {
  return new Promise((resolve, reject) => {
    API.post(`/email-consumer/${organizationId}/confirm`, undefined, {
      params: {
        code: confirmationCode,
      },
    }).then(() => {
      resolve();
    }, reject);
  });
};

const byOrgRole = (orgRoleId: string): Promise<Organization[]> => {
  return new Promise((resolve, reject) => {
    API.get(`/organizations/byOrgRole/${orgRoleId}`).then((result) => {
      resolve(result.data.payload as Organization[]);
    }, reject);
  });
};

const getFieldDefinitions = (
  organizationId: string,
): Promise<{
  selected: string[];
  available: { [key: string]: FieldDefinition };
}> => {
  return new Promise((resolve, reject) => {
    API.get(`/organizations/${organizationId}/fields`).then((result) => {
      resolve(
        result.data.payload as {
          selected: string[];
          available: { [key: string]: FieldDefinition };
        },
      );
    }),
      reject;
  });
};

const addSelectedFieldDefinition = (
  organizationId: string,
  fieldDefinitionId: string,
): Promise<void> => {
  return new Promise((resolve, reject) => {
    API.post(
      `/organizations/${organizationId}/fields/${fieldDefinitionId}`,
    ).then(() => resolve(), reject);
  });
};

const removeSelectedFieldDefinition = (
  organizationId: string,
  fieldDefinitionId: string,
): Promise<void> => {
  return new Promise((resolve, reject) => {
    API.delete(
      `/organizations/${organizationId}/fields/${fieldDefinitionId}`,
    ).then(() => resolve(), reject);
  });
};

const setSelectedFieldDefinitions = (
  organizationId: string,
  fieldDefinitionIds: string[],
): Promise<void> => {
  return new Promise((resolve, reject) => {
    API.put(`/organizations/${organizationId}/fields`, fieldDefinitionIds).then(
      () => resolve(),
      reject,
    );
  });
};

export default {
  create: create,
  get: get,
  byKey,
  update: update,
  byOwner: byOwner,
  delete: remove,
  getMembers: getMembers,
  byMember: byMember,
  byMemberOrAccountUser: byMemberOrAccountUser,
  removeMember: removeMember,
  fromWebsite: fromWebsite,
  confirmEmail: confirmEmail,
  byOrgRole: byOrgRole,
  getFieldDefinitions: getFieldDefinitions,
  addSelectedFieldDefinition: addSelectedFieldDefinition,
  removeSelectedFieldDefinition: removeSelectedFieldDefinition,
  setSelectedFieldDefinitions: setSelectedFieldDefinitions,
};
