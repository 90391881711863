import { FC, useContext } from "react";
import RegisterComponent from "../../user/components/RegisterComponent";
import BasePanel from "./BasePanel";
import { Link, Typography } from "@mui/material";
import { ManageInvitationPageContext } from "../ManageInvitationPage";
import { User } from "../../../model";

const RegisterPanel: FC<{
  onRegister: (user: User) => void;
  onClickLogin: () => void;
}> = ({ onRegister, onClickLogin }) => {
  const { email, cognitoUser } = useContext(ManageInvitationPageContext);

  return (
    <>
      <BasePanel sx={{ p: 0 }}>
        <RegisterComponent
          onComplete={(user) => onRegister(user)}
          sx={{
            bgcolor: "transparent",
            borderRadius: "10px",
          }}
          email={email}
          cognitoUser={cognitoUser}
          includeFooter={false}
        />
      </BasePanel>
      <Typography sx={{ width: "100%", textAlign: "center", mt: 2 }}>
        {`Already have an account? `}
        <Link
          onClick={onClickLogin}
          sx={{
            textDecoration: "none",
            "&:hover": {
              cursor: "pointer",
              textDecoration: "underline",
            },
          }}
        >
          {`Login now`}
        </Link>
      </Typography>
    </>
  );
};

export default RegisterPanel;
