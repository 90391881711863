import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import debounce from "lodash.debounce";
import { Role } from "../../../model";
import { ProcessAPI, TaskAPI } from "../../../api";
import { Task } from "../types";

type TaskContextType = {
  selectedTask?: Task;
  setSelectedTask: (value?: Task) => void;
  getTasks: (processId: string) => Array<Task>;
  setTasks: (processId: string, value: Array<Task>) => void;
  setValue: (taskPatch: TaskPatchType) => void;
  fetchTasks: (processId: string) => Promise<void>;
};

const TaskContext = createContext<TaskContextType>({
  setSelectedTask: () => {
    // Do Nothing
  },
  getTasks: () => {
    return [];
  },
  setTasks: () => {
    // Do Nothing
  },
  setValue: () => {
    // Do Nothing
  },
  fetchTasks: async () => {
    // Do Nothing
  },
});

export type TaskPatchType = {
  name?: string;
  description?: string;
  identifier?: string;
  is_context?: boolean;
  type?: string;
  role?: Role;
  data?: Record<string, unknown>;
  execution_data?: Record<string, unknown>;
};

export const TaskProvider = (props: React.PropsWithChildren<unknown>) => {
  const [selectedTask, setSelectedTask] = useState<Task>();
  const [processTasks, setProcessTasks] = useState<{
    [processId: string]: Array<Task>;
  }>({});

  const updateTask = useMemo(
    () =>
      debounce(
        async (task: Task, then: (task: Task) => void) => {
          updateTask.cancel();
          then(await TaskAPI.update(task));
        },
        1000,
        { trailing: true },
      ),
    [],
  );

  const setValue = useCallback(
    (taskPatch: TaskPatchType) => {
      if (!selectedTask) {
        return;
      }
      const processId = selectedTask.process_id;
      const tasks = processTasks[processId];

      const selectedTaskIndex = tasks.findIndex(
        (task) => task.id == selectedTask?.id,
      );
      if (selectedTaskIndex < 0) {
        return;
      }
      if (taskPatch.name != undefined) {
        selectedTask.name = taskPatch.name;
      }
      if (taskPatch.description != undefined) {
        selectedTask.description = taskPatch.description;
      }
      if (taskPatch.identifier != undefined) {
        selectedTask.task_identifier_name = taskPatch.identifier;
      }
      if (taskPatch.is_context != undefined) {
        selectedTask.is_context = taskPatch.is_context;
      }
      if (taskPatch.role != undefined) {
        selectedTask.execution_role_id = taskPatch.role.id;
        selectedTask.role = taskPatch.role;
      }
      if (taskPatch.data != undefined) {
        selectedTask.data = {
          ...selectedTask.data,
          ...taskPatch.data,
        };
      }
      if (taskPatch.execution_data != undefined) {
        selectedTask.execution_data = {
          ...taskPatch.execution_data,
        };
      }
      tasks[selectedTaskIndex] = selectedTask;
      updateTask(selectedTask, (updatedTask) => {
        // Special handling for used_variables
        tasks[selectedTaskIndex].used_variables = [
          ...(updatedTask.used_variables ?? []),
        ];
        setProcessTasks((prev) => ({
          ...prev,
          [processId]: [...tasks],
        }));
      });

      setProcessTasks((prev) => ({
        ...prev,
        [processId]: [...tasks],
      }));
    },
    [selectedTask, processTasks, updateTask],
  );

  const setTasks = (processId: string, values: Array<Task>) => {
    setProcessTasks((prev) => ({
      ...prev,
      [processId]: values,
    }));
  };

  const fetchTasks = async (processId: string) => {
    const tasks = await ProcessAPI.getTasks(processId);
    setTasks(processId, tasks);
  };

  return (
    <TaskContext.Provider
      value={{
        selectedTask,
        setSelectedTask,
        getTasks: (processId) => {
          return processTasks[processId] ?? [];
        },
        setTasks,
        setValue,
        fetchTasks,
      }}
    >
      {props.children}
    </TaskContext.Provider>
  );
};

export const useTaskContext = () => useContext(TaskContext);
