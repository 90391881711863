import React, { createContext, useContext, useState } from "react";
import { Role } from "../../../model";
import { RoleAPI } from "../../../api";
import { CommonRole } from "../../../types";

type ProcessRoleContextType = {
  orgId: string;
  roles: Role[];
  fetchRoles: (orgId: string) => Promise<void>;
  setRoles: (value: Array<Role>) => void;
};

const ProcessRoleContext = createContext<ProcessRoleContextType>({
  orgId: "",
  roles: [],
  fetchRoles: async () => {
    // Do Nothing
  },
  setRoles: () => {
    // Do Nothing
  },
});

export const ProcessRoleProvider = (
  props: React.PropsWithChildren<unknown>,
) => {
  const [roles, setRoles] = useState<Array<Role>>([]);
  const [orgId, setOrgId] = useState("");
  const commonRoles = React.useMemo(() => {
    return [
      ...Object.entries(CommonRole).map(([key, value]) => ({
        id: value,
        name: key
          .toLowerCase()
          .split("_")
          .map((value) => value.charAt(0).toUpperCase() + value.substring(1))
          .join(" "),
        org_id: "",
      })),
    ];
  }, []);

  const fetchRoles = React.useCallback(
    async (orgId: string) => {
      const fetchedRoles = await RoleAPI.byOrganization(orgId);
      setRoles([...fetchedRoles, ...commonRoles]);
      setOrgId(orgId);
    },
    [commonRoles],
  );

  return (
    <ProcessRoleContext.Provider
      value={{
        orgId,
        roles,
        setRoles,
        fetchRoles,
      }}
    >
      {props.children}
    </ProcessRoleContext.Provider>
  );
};

export const useProcessRoleContext = () => useContext(ProcessRoleContext);
