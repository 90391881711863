import { FC } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Confirmation } from "../../../task";

export type ConfirmationModalProps = {
  openModal: boolean;
  handleCloseModal: () => void;
  executeModal: () => void;
  confirmationData?: Confirmation;
};

const ConfirmationModal: FC<ConfirmationModalProps> = ({
  openModal,
  handleCloseModal,
  executeModal,
  confirmationData,
}) => {
  // fallback if no confirmation info, proceed to next task
  if (!confirmationData) {
    return <></>;
  }

  return (
    <Dialog open={openModal} onClose={handleCloseModal}>
      <DialogTitle id="alert-dialog-title">
        {confirmationData.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {confirmationData.body}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseModal}
          variant="text"
          color="inherit"
          sx={{ textTransform: "none" }}
        >
          {confirmationData.cancelLabelText ?? "Cancel"}
        </Button>
        <Button
          onClick={() => {
            handleCloseModal();
            executeModal();
          }}
          autoFocus
          variant="text"
          sx={{ textTransform: "none" }}
        >
          {confirmationData.continueLabelText ?? "Send"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
