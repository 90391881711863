import { Box } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ProcessExecution } from "../../../../model/ProcessExecution";
import ProcessExecutions from "../../../../api/process_executions";
import Processes from "../../../../api/processes";
import { useAlert } from "../../../../lib/alert";
import debounce from "lodash.debounce";
import {
  Task,
  TaskExecution,
  TaskExecutionContainer,
  TaskExecutionState,
  TaskType,
} from "../../../task";
import { AssignedRoleUserDto } from "../../../../types";
import { User } from "../../../../model";
import { currentUserId } from "../../../../lib/auth";
import { useProcessExecutionContext } from "../../hooks";

type FlatProcessExecutionProps = {
  assignedRoles: AssignedRoleUserDto[];
  processExecution: ProcessExecution;
  taskExecutions: Array<TaskExecution>;
  stepId?: string;
  onShare?: () => void;
};

const FlatProcessExecution = ({
  assignedRoles,
  processExecution,
  taskExecutions,
  stepId,
  ...props
}: FlatProcessExecutionProps) => {
  const { success, error } = useAlert();
  const { executeTask, reload } = useProcessExecutionContext();
  const [tasks, setTasks] = useState<Array<Task>>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const mapAssignedUser = (execution?: TaskExecution) => {
    let assignedUser: User | undefined;
    assignedRoles.map((roleAndUser) => {
      const currentUser = roleAndUser.user;
      if (currentUser.id === execution?.assigned_to_user_id) {
        assignedUser = currentUser;
      }
    });
    return assignedUser;
  };

  const fetchTasks = useCallback(() => {
    if (!stepId) {
      return Promise.resolve([]);
    }
    return Processes.getTasks(stepId).then(
      (newTasks: Array<Task>) => {
        setTasks(newTasks);
        return newTasks;
      },
      () => {
        error("Could not load tasks for process");
        return [];
      },
    );
  }, [stepId, error]);

  // when first loading the page
  const initialLoad = useMemo(
    () =>
      debounce(
        () => {
          setIsLoaded(false);
          fetchTasks().then((tasks) => {
            setTasks(tasks);
            // Cancel any queued calls now that we've finished loading
            initialLoad.cancel();
            setIsLoaded(true);
          });
        },
        5000,
        { leading: true },
      ),
    [fetchTasks],
  );

  useEffect(() => {
    initialLoad();
  }, [initialLoad]);

  const handleExecution = async (
    execution: TaskExecution,
    completeTask?: boolean,
  ) => {
    setIsLoaded(false);
    try {
      await executeTask(execution, completeTask);
    } finally {
      setIsLoaded(true);
    }
  };

  const retryErrorTask = async (s: TaskExecution) => {
    if (!s.task_id) {
      error(`Failed to submit task: missing task_id for task ${s.id}`);
      return;
    }
    setIsLoaded(false);
    ProcessExecutions.retryErrorTask(processExecution.id, s.task_id)
      .then(() => {
        success("Task was successfully completed on retry.");
        reload();
      })
      .catch((e) => {
        console.error(e);
        error("Failed to complete task on retry.");
      })
      .finally(() => {
        setIsLoaded(true);
      });
  };

  return (
    <Box component="section">
      <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
        {tasks.map((task, index) => {
          const execution = taskExecutions.find((s) => s.task_id == task.id);
          const isNextTask = processExecution.next_task_id == task.id;
          const assignedUser = mapAssignedUser(execution);
          // Expand logic:
          //  - Task is from another user
          //  - Task is a form task
          //  - Task is a previously completed task
          const isForm = task.type === TaskType.Form;
          const isComplete = execution?.state === TaskExecutionState.Completed;
          const isOtherUser =
            execution?.submitted_by_user_id !== currentUserId();
          const expand = (isForm && isComplete && isOtherUser) ?? undefined;

          return (
            <TaskExecutionContainer
              key={task.id}
              expand={expand}
              assignedUser={assignedUser}
              task={task}
              taskExecution={execution}
              processExecution={processExecution}
              isNext={isNextTask}
              isLoaded={isLoaded}
              onExecute={handleExecution}
              onValueChange={(updatedTask) => {
                tasks[index] = updatedTask;
              }}
              retryErrorTask={() => execution && retryErrorTask(execution)}
              reload={reload}
              onShare={props.onShare}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default FlatProcessExecution;
