import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useParams, Navigate, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { ExecutionState } from "../../model/Process";
import { Task, TaskBody, TaskExecution, TaskExecutionState } from "../task";
import {
  loadTaskExecutionsByPublicExecutorId as loadTaskExecutionsByPublicExecutorIdHelper,
  loadTasksByPublicExecutorId as loadTasksByPublicExecutorIdHelper,
} from "./index";
import PublicExecutor from "../../api/public_executor";
import { DefaultAlertSnackbar, useAlert } from "../../lib/alert";
import { MainContent } from "../../components/layout";
import { GlobalOrganizationProvider } from "../organization";
import { PublicOrganizationDisplayContainer } from "../organization/";
import { environment } from "../../util";
import { Organization } from "../../model";
import { OrganizationAPI } from "../../api";
import PATHS from "../../components/navigation/_paths";
import NotFound from "../app/NotFound";

const OnboardingExecutorPage: FC<unknown> = () => {
  const { publicExecutorId } = useParams<{ publicExecutorId: string }>();
  const { handleRejectionWithError } = useAlert();
  const navigate = useNavigate();

  const [tasks, setTasks] = useState<Array<Task>>([]);
  const [taskExecutions, setTaskExecutions] = useState<Array<TaskExecution>>(
    [],
  );

  const [organization, setOrganization] = useState<Organization | undefined>();
  const { orgKey } = useParams<{ orgKey: string }>();

  const key = `onboarding-${orgKey}-${publicExecutorId}`;

  const [executionId, setExecutionId] = useState<string | undefined>(
    localStorage.getItem(key) ?? undefined,
  );

  const [show404, setShow404] = useState<boolean>(false);

  const taskIndex = useMemo(() => {
    // If there are no task executions or tasks return 0.
    if (!tasks.length || !taskExecutions.length) {
      return 0;
    }

    // If every task execution is completed, return the length of the tasks
    if (taskExecutions.every((t) => t.state === TaskExecutionState.Completed)) {
      return tasks.length;
    }

    // The first task execution that is not completed is the
    // one that we are currently working on. If no such task execution
    // exists then return 0
    const taskExecution = taskExecutions.find(
      (t) => t.state !== TaskExecutionState.Completed,
    );

    if (!taskExecution) {
      return 0;
    }

    return tasks.findIndex((t) => t.id === taskExecution.task_id);
  }, [tasks, taskExecutions]);

  const executeTask = useCallback(
    async (taskExecution: TaskExecution): Promise<void> => {
      if (!publicExecutorId || !organization) {
        return;
      }

      const response = await PublicExecutor.execute(
        publicExecutorId,
        [taskExecution],
        organization.id,
        undefined,
        executionId,
      ).catch(handleRejectionWithError("Failed to execute task"));

      if (!response) {
        return;
      }

      setExecutionId(response.id);
      if (response.id) {
        if (response.state !== ExecutionState.Completed) {
          localStorage.setItem(key, response.id);
        }
      }

      const taskExecutions = await loadTaskExecutionsByPublicExecutorIdHelper(
        publicExecutorId,
        tasks,
        organization.id,
        executionId,
      ).catch(handleRejectionWithError("Failed to load task executions"));
      setTaskExecutions(taskExecutions || []);
    },
    [
      key,
      handleRejectionWithError,
      tasks,
      publicExecutorId,
      executionId,
      organization,
    ],
  );

  const initializeTaskExecutions = useCallback(
    async (organization: Organization) => {
      if (!publicExecutorId) {
        return;
      }

      const result = await loadTasksByPublicExecutorIdHelper(
        publicExecutorId,
        organization.id,
      ).catch(handleRejectionWithError("Failed to load tasks"));

      if (!result) {
        setShow404(true);
        return [];
      }

      const { process, tasks } = result;

      if (process.org_id !== organization.id) {
        setShow404(true);
        return [];
      }

      setTasks(tasks || []);

      if (executionId) {
        // Get the process execution for this execution id and
        // ensure that it matches the process id
        try {
          const processExecution =
            await PublicExecutor.getProcessExecutionByPublicExecutorIdAndProcessExecutionId(
              publicExecutorId,
              organization.id,
              executionId,
            );
          if (processExecution.process_id !== process.id) {
            // Reset the execution id
            setExecutionId(undefined);
            localStorage.removeItem(key);
            return;
          }

          // If the process execution is completed, we can
          // clear the execution id
          if (processExecution.state === ExecutionState.Completed) {
            setExecutionId(undefined);
            localStorage.removeItem(key);
            return;
          }
        } catch (e) {
          // Reset the execution id
          setExecutionId(undefined);
          localStorage.removeItem(key);
          return;
        }
      }

      const taskExecutions = await loadTaskExecutionsByPublicExecutorIdHelper(
        publicExecutorId,
        tasks,
        organization.id,
        executionId,
      );

      // These task executions are for these tasks, we can
      // initialize them which will initialize the rest of the state
      setTaskExecutions(taskExecutions || []);
    },
    [executionId, publicExecutorId, key, handleRejectionWithError],
  );

  useEffect(() => {
    if (environment.organization?.organizationId) {
      // 1. a) Load organization from environment
      const o = new Organization();
      o.name = environment.organization.name;
      o.theme = {
        longLogoUrl: environment.app.logo,
        shortLogoUrl: environment.app.logoIcon,
      };
      setOrganization(o);
      initializeTaskExecutions(o);
    } else if (orgKey) {
      // 1. b) Load organization from backend
      OrganizationAPI.byKey(orgKey)
        .then(async (o) => {
          setOrganization(o);
          initializeTaskExecutions(o);
        })
        .catch(handleRejectionWithError("Failed to load organization"));
    } else {
      navigate("/");
    }
  }, [
    orgKey,
    navigate,
    publicExecutorId,
    executionId,
    initializeTaskExecutions,
    handleRejectionWithError,
  ]);

  if (!publicExecutorId) {
    return <Navigate to={"/"}></Navigate>;
  }

  return show404 ? (
    <NotFound />
  ) : (
    <PublicOrganizationDisplayContainer organization={organization}>
      <GlobalOrganizationProvider organization={organization}>
        <MainContent>
          <DefaultAlertSnackbar>
            <Box sx={{ flex: 1, height: "100vh", width: "100%" }}>
              <Box
                sx={{
                  flex: 1,
                  height: "100%",
                }}
              >
                <Grid container sx={{ height: "100%", flexGrow: 1 }}>
                  <Grid
                    item
                    xs={4}
                    sm={3}
                    md={3}
                    lg={3}
                    xl={3}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                          ? theme.palette.common.white
                          : theme.palette.common.black,
                      maxHeight: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        marginLeft: "50px",
                        paddingTop: "50px",
                        paddingRight: "50px",
                        overflowY: "auto",
                        height: "100%",
                      }}
                    >
                      {organization && (
                        <img
                          src={
                            organization.theme?.longLogoUrl ??
                            environment.app.logo
                          }
                          style={{
                            marginTop: "60px",
                            marginBottom: "80px",
                            maxHeight: "50px",
                            maxWidth: "100%",
                          }}
                          alt="light"
                        />
                      )}
                      <Stepper activeStep={taskIndex} orientation="vertical">
                        {tasks.map((task) => (
                          <Step key={task.id}>
                            <StepLabel>
                              <Typography
                                variant="body1"
                                sx={{ fontSize: "16px" }}
                              >
                                {task.name}
                              </Typography>
                            </StepLabel>
                            <StepContent />
                          </Step>
                        ))}
                      </Stepper>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    sm={9}
                    md={9}
                    lg={9}
                    xl={9}
                    sx={{
                      maxHeight: "100%",
                      overflowY: "auto",
                      backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                          ? theme.palette.grey[100]
                          : theme.palette.grey[900],
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        height: "calc(100% - 60px)",
                      }}
                    >
                      {tasks.length && taskIndex >= tasks.length ? (
                        <Paper
                          elevation={2}
                          sx={{
                            backgroundColor: (theme) =>
                              theme.palette.common.white,
                            width: "700px",
                            height: "280px",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            display: "flex",
                            borderRadius: "10px",
                          }}
                        >
                          <Box sx={{ textAlign: "center", width: "70%" }}>
                            {/* This text cannot be white since the background of the box is white */}
                            <Typography variant="h1">
                              Cleared for takeoff!
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ my: 3, color: "grey.600" }}
                            >
                              Your account has been created and your
                              organization is set up. It&#39;s time to onboard
                              some members and start collaborating.
                            </Typography>
                            <Button
                              variant="contained"
                              color="primary"
                              href={PATHS.ORGANIZATION_EXECUTIONS.link}
                            >
                              Lets Go
                            </Button>
                          </Box>
                        </Paper>
                      ) : tasks[taskIndex] ? (
                        <Box sx={{ maxWidth: "800px", margin: "auto" }}>
                          <TaskBody
                            variant="execution"
                            task={tasks[taskIndex]}
                            taskExecution={taskExecutions.find(
                              (t) => t.task_id === tasks[taskIndex].id,
                            )}
                            onExecute={async (taskExecution: TaskExecution) => {
                              await executeTask(taskExecution);
                            }}
                            isShortTaskForm={true}
                            reload={function (): void {
                              throw new Error("Function not implemented.");
                            }}
                            hideCompleteButton={true}
                          />
                        </Box>
                      ) : (
                        <CircularProgress />
                      )}
                    </Box>
                    <Box
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.mode === "light"
                            ? theme.palette.common.white
                            : theme.palette.common.black,
                        width: "100%",
                        height: "60px",
                        textAlign: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <Typography sx={{ fontSize: "0.875rem" }}>
                        {`Already have an account? `}
                        <Link
                          href="/sign-in"
                          sx={{
                            textDecoration: "none",
                            "&:hover": {
                              cursor: "pointer",
                              textDecoration: "underline",
                            },
                          }}
                        >
                          {`Login now`}
                        </Link>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </DefaultAlertSnackbar>
        </MainContent>
      </GlobalOrganizationProvider>
    </PublicOrganizationDisplayContainer>
  );
};

export default OnboardingExecutorPage;
