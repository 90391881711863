/* eslint-disable react/prop-types */
import {
  AutocompleteFormElement,
  FieldDefinitionFormElement,
  CheckboxFormElement,
  DateFormElement,
  FormElement,
  FormElementType,
  FormExecutionType,
  HeadingFormElement,
  MultiSelectionFormElement,
  NestedFormElement,
  RadioFormElement,
  SelectionFormElement,
  TaskForm,
  TextFormElement,
  TextFormElementExecution,
  UploadFormElement,
} from "../../types";
import { FormElementChangeType } from "./FormBuilder";
import { MultiSelectionInlineDisplay } from "./multi-selection/MultiSelectInlineDisplay";
import { RadioInlineDisplay } from "./radio/RadioInlineDisplay";
import { SelectionInlineDisplay } from "./selection/SelectionInlineDisplay";
import { SideSectionSelectionOptionsDisplay } from "./SideSectionSelectionOptionsDisplay";
import { TextInlineDisplay } from "./text/TextInlineDisplay";
import { TextSideSectionDisplay } from "./text/TextSideSectionDisplay";
import { UploadInlineDisplay } from "./upload/UploadInlineDisplay";
import { NestedFormInlineDisplay } from "./nested-form/NestedFormInlineDisplay";
import { CheckboxInlineDisplay } from "./checkbox/CheckboxInlineDisplay";
import { DateInlineDisplay } from "./date/DateInlineDisplay";
import { DateSideSectionDisplay } from "./date/DateSideSectionDisplay";
import { HeadingInlineDisplay } from "./heading/HeadingInlineDisplay";
import { FieldDefinitionInlineDisplay } from "./field-definition/FieldDefinitionInlineDisplay";
import AutocompleteInlineDisplay from "./autocomplete/AutocompleteInlineDisplay";
import AutocompleteSideSectionDisplay from "./autocomplete/AutocompleteSideSectionDisplay";

type FormBuilderElementInlineType = {
  element: FormElement;
  executionData?: FormExecutionType;
  task: TaskForm;
};
type FormBuilderElementSideSectionType<T extends FormElement = FormElement> = {
  element: T;
  executionData?: FormExecutionType;
  onChange: (props: FormElementChangeType) => void;
};

const FormBuilderElements = new Map<
  FormElementType,
  {
    Inline: (props: FormBuilderElementInlineType) => JSX.Element;
    SideSection: (props: FormBuilderElementSideSectionType) => JSX.Element;
  }
>([
  [
    FormElementType.Heading,
    {
      Inline: (props) => (
        <HeadingInlineDisplay
          {...props}
          element={props.element as HeadingFormElement}
        />
      ),
      SideSection: () => <></>,
    },
  ],
  [
    FormElementType.MultiSelection,
    {
      Inline: (props) => (
        <MultiSelectionInlineDisplay
          {...props}
          element={props.element as MultiSelectionFormElement}
        />
      ),
      SideSection: (props) => (
        <SideSectionSelectionOptionsDisplay
          {...props}
          element={props.element as MultiSelectionFormElement}
        />
      ),
    },
  ],
  [
    FormElementType.Radio,
    {
      Inline: (props) => (
        <RadioInlineDisplay
          {...props}
          element={props.element as RadioFormElement}
        />
      ),
      SideSection: (props) => (
        <SideSectionSelectionOptionsDisplay
          {...props}
          element={props.element as RadioFormElement}
        />
      ),
    },
  ],
  [
    FormElementType.Selection,
    {
      Inline: (props) => (
        <SelectionInlineDisplay
          {...props}
          element={props.element as SelectionFormElement}
        />
      ),
      SideSection: (props) => (
        <SideSectionSelectionOptionsDisplay
          {...props}
          element={props.element as SelectionFormElement}
        />
      ),
    },
  ],
  [
    FormElementType.Text,
    {
      Inline: (props) => (
        <TextInlineDisplay
          {...props}
          element={props.element as TextFormElement}
          executionData={props.executionData as TextFormElementExecution}
        />
      ),
      SideSection: (props) => (
        <TextSideSectionDisplay
          {...props}
          element={props.element as TextFormElement}
          executionData={props.executionData as TextFormElementExecution}
        />
      ),
    },
  ],
  [
    FormElementType.Date,
    {
      Inline: (props) => (
        <DateInlineDisplay
          {...props}
          element={props.element as DateFormElement}
        />
      ),
      SideSection: (props) => (
        <DateSideSectionDisplay
          {...props}
          element={props.element as DateFormElement}
        />
      ),
    },
  ],
  [
    FormElementType.Autocomplete,
    {
      Inline: (props) => (
        <AutocompleteInlineDisplay
          {...props}
          element={props.element as AutocompleteFormElement}
        />
      ),
      SideSection: (props) => (
        <AutocompleteSideSectionDisplay
          {...props}
          element={props.element as AutocompleteFormElement}
        />
      ),
    },
  ],
  [
    FormElementType.Upload,
    {
      Inline: (props) => (
        <UploadInlineDisplay
          {...props}
          element={props.element as UploadFormElement}
        />
      ),
      SideSection: () => <></>,
    },
  ],
  [
    FormElementType.NestedForm,
    {
      Inline: (props) => (
        <NestedFormInlineDisplay
          {...props}
          element={props.element as NestedFormElement}
        />
      ),
      SideSection: () => <></>,
    },
  ],
  [
    FormElementType.Checkbox,
    {
      Inline: (props) => (
        <CheckboxInlineDisplay
          {...props}
          element={props.element as CheckboxFormElement}
        />
      ),
      SideSection: () => <></>,
    },
  ],
  [
    FormElementType.FieldDefinition,
    {
      Inline: (props) => (
        <FieldDefinitionInlineDisplay
          {...props}
          element={props.element as FieldDefinitionFormElement}
        />
      ),
      SideSection: () => <></>,
    },
  ],
]);

export type FormBuilderElementVariant = "inline" | "side-section";

type FormBuilderElementType<V extends FormBuilderElementVariant> = {
  variant: V;
} & (V extends "inline"
  ? FormBuilderElementInlineType
  : V extends "side-section"
    ? FormBuilderElementSideSectionType
    : never);

const FormBuilderElement = <V extends FormBuilderElementVariant>({
  variant,
  ...props
}: FormBuilderElementType<V>) => {
  const FormBuilderElementRenderer = props.element.type
    ? FormBuilderElements.get(props.element.type)
    : undefined;
  if (!FormBuilderElementRenderer) {
    return <></>;
  }
  switch (variant) {
    case "inline":
      return (
        <FormBuilderElementRenderer.Inline
          {...(props as unknown as FormBuilderElementInlineType)}
        />
      );
    case "side-section":
      return (
        <FormBuilderElementRenderer.SideSection
          {...(props as unknown as FormBuilderElementSideSectionType)}
        />
      );
  }
  return <></>;
};

export default FormBuilderElement;
