import { Button, ButtonProps, styled } from "@mui/material";
import { MouseEventHandler } from "react";

const StyledButton = styled(Button)<ButtonProps>(({ color }) => ({
  color: !color ? "inherit" : undefined,
  backgroundColor: !color ? "#E0E0E0" : undefined,
  marginLeft: "12px",
  "&:hover": {
    backgroundColor: "#BDBDBD",
  },
}));

type ToolbarButtonProps = React.PropsWithChildren<{
  textContent: React.ReactNode;
  onClick: MouseEventHandler<HTMLButtonElement>;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  disabled?: boolean;
  dataCy?: string;
  color?: ButtonProps["color"];
  variant?: ButtonProps["variant"];
}>;

export default function ToolbarButton({
  textContent,
  onClick,
  startIcon,
  endIcon,
  disabled,
  dataCy,
  color,
  variant = "contained",
}: ToolbarButtonProps) {
  return (
    <StyledButton
      data-cy={dataCy}
      variant={variant}
      disableElevation
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={onClick}
      color={color}
      disabled={disabled ? true : false}
    >
      {!!textContent && textContent}
    </StyledButton>
  );
}
