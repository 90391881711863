import {
  Button,
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
  alpha,
} from "@mui/material";
import Dropzone from "../Dropzone/Dropzone";
import { Close, FileUpload } from "@mui/icons-material";
import { useRef } from "react";
import { v4 as uuid } from "uuid";

type UploadFileComponentProps<FileType> = {
  handleDrop?: (e: React.DragEvent<Element>) => void;
  files: FileType[];
  handleFileChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  accept?: string;
  multiple?: boolean;
  text?: string;
  uploadText?: string;
  onRemove?: (file: FileType, index: number) => void;
  disabled?: boolean;
  size?: "small" | "large";
  renderer: (file: FileType) => JSX.Element | undefined;
};

const UploadFileComponent = <FileType,>({
  handleDrop,
  files,
  handleFileChange,
  multiple,
  accept,
  text,
  uploadText,
  onRemove,
  disabled,
  size,
  renderer,
}: UploadFileComponentProps<FileType>) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  return (
    <Grid item sx={{ width: "100%" }}>
      <Dropzone
        variant={disabled ? "subtle" : "obvious"}
        onDrop={(e) => {
          if (disabled) {
            return false;
          }
          handleDrop?.(e);
        }}
      >
        <ImageList
          variant="masonry"
          sx={{
            display: !files.length ? "none" : "grid",
            gridTemplateColumns: multiple
              ? {
                  xs: "repeat(1, 1fr) !important",
                  sm: "repeat(2, 1fr) !important",
                  md: "repeat(3, 1fr) !important",
                  lg: "repeat(4, 1fr) !important",
                  xl: "repeat(5, 1fr) !important",
                }
              : {
                  xs: "repeat(1, 1fr) !important",
                },
          }}
          gap={8}
        >
          {files
            .filter((file) => file)
            .map((file) => (
              <ImageListItem
                key={uuid()}
                cols={1}
                rows={1}
                sx={{
                  width:
                    size == "large"
                      ? { xs: 350, md: 250 }
                      : { xs: 100, md: 100 },
                }}
                draggable="true"
                onDragStart={async (e) => {
                  const dataTransfer = e.dataTransfer;
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  const fileRef = file as any;
                  const downloadUrl = fileRef.source.replaceAll(
                    process.env.REACT_APP_REST_API,
                    `${window.location.protocol}//${window.location.host}/`,
                  );
                  dataTransfer.setData(
                    "DownloadURL",
                    // TODO - get content type and file name from file object
                    `application/pdf:${fileRef.key}:${downloadUrl}`,
                  );
                }}
              >
                {renderer(file)}
                {!disabled && (
                  <ImageListItemBar
                    sx={{
                      background: (theme) => `linear-gradient( 
                            ${alpha(theme.palette.common.white, 0.5)}, 
                            ${alpha(theme.palette.common.white, 0.3)},
                            ${alpha(theme.palette.common.white, 0)}
                    );`,
                      width:
                        size == "large"
                          ? { xs: 350, md: 250 }
                          : { xs: 100, md: 100 },
                    }}
                    position="top"
                    actionIcon={
                      <Close
                        onClick={() => {
                          const fileIndex = files.indexOf(file);
                          if (fileIndex >= 0) {
                            onRemove?.(files[fileIndex], fileIndex);
                          }
                        }}
                      />
                    }
                  />
                )}
              </ImageListItem>
            ))}
        </ImageList>
        <input
          id="fileToUpload"
          ref={inputRef}
          accept={accept ?? "image/*"}
          multiple={multiple}
          hidden
          type="file"
          onChange={handleFileChange}
        />
        {text && (
          <Typography
            variant="body1"
            sx={{ fontWeight: "bold", color: "gray" }}
          >
            {text}
          </Typography>
        )}
        {(multiple || !files.filter((file) => file).length) && (
          <Button
            data-cy="file-upload-btn"
            variant="contained"
            size="medium"
            component="label"
            disabled={disabled}
            onClick={() => {
              if (inputRef && inputRef.current) {
                inputRef.current?.click();
              }
            }}
            startIcon={<FileUpload />}
            sx={{
              width: "7rem",
              textTransform: "unset",
              fontWeight: 600,
              p: 1,
              mt: 1,
            }}
          >
            {uploadText}
          </Button>
        )}
      </Dropzone>
    </Grid>
  );
};

export default UploadFileComponent;
