import { hslToRgb, rgbToHex, useTheme } from "@mui/material";
import { sha256 } from "js-sha256";
import { User } from "../model";

const getGravatarProfilePicture = (email: string, defaultImageUrl?: string) => {
  const hash = sha256(email.trim().toLowerCase());
  return `https://www.gravatar.com/avatar/${hash}?d=${defaultImageUrl || "mp"}`;
};

function emailToHexColor(email: string) {
  const hash = sha256(email.trim().toLowerCase());
  const hue = parseInt(hash.substring(1), 16) % 360;
  const saturation = 70;
  const lightness = 60;

  return rgbToHex(hslToRgb(`hsl(${hue}, ${saturation}, ${lightness})`));
}

const useUserProfilePicture = (user: User | undefined): string | undefined => {
  const theme = useTheme();
  if (!user) {
    return undefined;
  }
  if (user.profile_picture) {
    return user.profile_picture;
  }

  const backgroundColor = emailToHexColor(user.email);
  const textColor = theme.palette.getContrastText(backgroundColor);
  const firstInitial = user.first_name
    ? user.first_name.charAt(0)
    : user.email.charAt(0);

  const hexTextColor = rgbToHex(textColor);
  const defaultImageUrl = encodeURIComponent(
    `https://ui-avatars.com/api/${firstInitial}/80/${backgroundColor.replace(
      "#",
      "",
    )}/${hexTextColor.substring(1, 7)}/`,
  );

  const profilePictureURL = getGravatarProfilePicture(
    user.email,
    defaultImageUrl,
  );

  return profilePictureURL;
};

export default useUserProfilePicture;
