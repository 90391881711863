import { FC, useEffect, useState } from "react";
import { Container, Grid, Typography } from "@mui/material";
import {
  AutocompleteFormElement,
  AutocompleteFormElementExecution,
  CheckboxFormElement,
  CheckboxFormElementExecution,
  DateFormElement,
  FieldDefinitionFormElement,
  FormElement,
  FormElementType,
  FormExecutionType,
  HeadingFormElement,
  MultiSelectionFormElement,
  MultiSelectionFormElementExecution,
  RadioFormElement,
  RadioFormElementExecution,
  SelectionFormElement,
  SelectionFormElementExecution,
  TextFormElement,
  TextFormElementExecution,
  UploadFormElement,
  UploadFormElementExecution,
} from "../../../../types";
import { FieldDefinitionAPI } from "../../../../../../api";
import TaskHeadingFormComponent from "./TaskHeadingFormComponent";
import TaskTextFormComponent from "./TaskTextFormComponent";
import TaskSelectionFormComponent from "./TaskSelectionFormComponent";
import TaskRadioFormComponent from "./TaskRadioFormComponent";
import TaskDateFormComponent from "./TaskDateFormComponent";
import TaskUploadFormComponent from "./TaskUploadFormComponent";
import TaskCheckboxFormComponent from "./TaskCheckboxFormComponent";
import TaskMultiSelectFormComponent from "./TaskMultiSelectFormComponent";
import TaskAutocompleteFormComponent from "./TaskAutocompleteFormComponent";
import { useStore } from "jotai";
import { eventsAtom } from "../../../../hooks/useEvents";
import EventEmitter from "eventemitter3";
import ScopedProvider from "../../../../../../providers/ScopedProvider";

type TaskFieldDefinitionFormType = {
  disabled: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onValueChange: (value: {
    elementId: string;
    value: FormExecutionType;
  }) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  execution?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formExecutionComponentProps: any;
} & FieldDefinitionFormElement;

const TaskFieldDefinitionFormComponent: FC<TaskFieldDefinitionFormType> = ({
  field_definition_id,
  formExecutionComponentProps,
  onValueChange,
  execution,
  disabled,
  taskExecutionState,
  // ...props
}) => {
  const store = useStore();
  const [fieldDefElements, setFieldDefElements] = useState<FormElement[]>([]);

  useEffect(() => {
    if (!field_definition_id) return;

    FieldDefinitionAPI.get(field_definition_id).then((fieldDefinition) =>
      setFieldDefElements(fieldDefinition.data.elements),
    );
  }, [field_definition_id]);
  useEffect(() => {
    store.set(eventsAtom, new EventEmitter());
  }, [store]);
  // TODO - FUTURE - why are we using execution?.elementValues for field definitions instead of execution?.value
  const executionValue = execution?.value ?? execution?.elementValues;

  const childExecutionComponents = fieldDefElements.map((element) => {
    switch (element.type) {
      case FormElementType.Selection:
        return (
          <TaskSelectionFormComponent
            formId={undefined}
            {...(element as SelectionFormElement)}
            key={element.id}
            disabled={disabled}
            taskExecutionState={taskExecutionState}
            onValueChange={(value) => {
              if (onValueChange) {
                onValueChange({
                  elementId: element.id,
                  value: { ...element, value } as SelectionFormElementExecution,
                });
              }
            }}
            execution={executionValue?.find(
              (value: SelectionFormElementExecution) => value.id == element.id,
            )}
          />
        );
      case FormElementType.MultiSelection:
        return (
          <TaskMultiSelectFormComponent
            {...(element as MultiSelectionFormElement)}
            key={element.id}
            disabled={disabled}
            taskExecutionState={taskExecutionState}
            onValueChange={(value) => {
              if (onValueChange) {
                onValueChange({
                  elementId: element.id,
                  value: {
                    ...element,
                    value,
                  } as MultiSelectionFormElementExecution,
                });
              }
            }}
            execution={executionValue?.find(
              (value: MultiSelectionFormElementExecution) =>
                value.id == element.id,
            )}
          />
        );
      case FormElementType.Radio:
        return (
          <TaskRadioFormComponent
            {...(element as RadioFormElement)}
            disabled={disabled}
            taskExecutionState={taskExecutionState}
            formExecutionComponentProps={formExecutionComponentProps}
            key={element.id}
            onValueChange={(value) => {
              if (onValueChange) {
                onValueChange({
                  elementId: element.id,
                  value: { ...element, value } as RadioFormElementExecution,
                });
              }
            }}
            execution={executionValue?.find(
              (value: RadioFormElementExecution) => value.id == element.id,
            )}
          />
        );
      case FormElementType.Text:
        return (
          <TaskTextFormComponent
            {...(element as TextFormElement)}
            key={element.id}
            disabled={disabled}
            taskExecutionState={taskExecutionState}
            onValueChange={(value) => {
              if (onValueChange) {
                onValueChange({
                  elementId: element.id,
                  value: { ...element, value } as TextFormElementExecution,
                });
              }
            }}
            execution={executionValue?.find(
              (value: TextFormElementExecution) => value.id == element.id,
            )}
          />
        );
      case FormElementType.Date:
        return (
          <TaskDateFormComponent
            {...(element as DateFormElement)}
            key={element.id}
            disabled={disabled}
            taskExecutionState={taskExecutionState}
            onValueChange={(value) => {
              if (onValueChange) {
                onValueChange({
                  elementId: element.id,
                  value: { ...element, value } as TextFormElementExecution,
                });
              }
            }}
            execution={executionValue?.find(
              (value: TextFormElementExecution) => value.id == element.id,
            )}
          />
        );
      case FormElementType.Heading:
        return (
          <TaskHeadingFormComponent
            {...(element as HeadingFormElement)}
            key={element.id}
          />
        );
      case FormElementType.Upload:
        return (
          <TaskUploadFormComponent
            {...(element as UploadFormElement)}
            key={element.id}
            disabled={disabled}
            taskExecutionState={taskExecutionState}
            onValueChange={(value) => {
              if (onValueChange) {
                onValueChange({
                  elementId: element.id,
                  value: { ...element, value } as UploadFormElementExecution,
                });
              }
            }}
            execution={executionValue?.find(
              (value: UploadFormElementExecution) => value.id == element.id,
            )}
          />
        );
      case FormElementType.Checkbox:
        return (
          <TaskCheckboxFormComponent
            {...(element as CheckboxFormElement)}
            key={element.id}
            disabled={disabled}
            taskExecutionState={taskExecutionState}
            formExecutionComponentProps={formExecutionComponentProps}
            onValueChange={(value) => {
              if (onValueChange) {
                onValueChange({
                  elementId: element.id,
                  value: { ...element, value } as CheckboxFormElementExecution,
                });
              }
            }}
            execution={executionValue?.find(
              (value: CheckboxFormElementExecution) => value.id == element.id,
            )}
          />
        );
      case FormElementType.Autocomplete:
        return (
          <TaskAutocompleteFormComponent
            {...(element as AutocompleteFormElement)}
            key={element.id}
            disabled={false}
            onValueChange={(value) => {
              if (onValueChange) {
                onValueChange({
                  elementId: element.id,
                  value: {
                    ...element,
                    value,
                  } as AutocompleteFormElementExecution,
                });
              }
            }}
            execution={executionValue?.find(
              (value: AutocompleteFormElementExecution) =>
                value.id == element.id,
            )}
          />
        );
      default:
        console.error(
          `Unable to map form task element with type ${element.type} and id ${element.id}`,
        );
        return <Typography key={element.id}>{element.label}</Typography>;
    }
  });

  return (
    <ScopedProvider>
      <Grid item>
        <Container
          disableGutters
          sx={{ display: "flex", flexDirection: "column", gap: 1 }}
        >
          {childExecutionComponents}
        </Container>
      </Grid>
    </ScopedProvider>
  );
};

export default TaskFieldDefinitionFormComponent;
