import { Box, CircularProgress } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";

import { Process } from "../../../model/Process";
import { TaskCheckExecution, TaskExecutionState } from "../../task";
import { ProcessExecution } from "../../../model/ProcessExecution";

import ProcessExecutions from "../../../api/process_executions";

import KanbanBoardControls from "./KanbanBoardControls";

import { useAlert } from "../../../lib/alert";
import { getSections, Section } from "./section-components/sections";
import KanbanBoardSection from "./KanbanBoardSection";

type KanbanBoardViewProps = {
  process: Process;
};

const KanbanBoardView: FC<KanbanBoardViewProps> = ({ process }) => {
  // state variables
  const [sectionList, setSectionList] = useState<Section[]>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(true);
  const sectionRefs = useRef<(HTMLElement | null)[]>([]);
  const scrollBox = useRef<HTMLElement>();
  const [currentSectionIndex, setCurrentSectionIndex] = useState<number>(0);
  // handles drag and drop functionality between components

  const { success, error } = useAlert();

  const reload = async () => {
    if (process) {
      try {
        setSectionList(await getSections(process));
      } catch (e) {
        error("An error occurred while reloading");
        console.error(e);
      }
    }
  };

  // creates a TaskCheckExecution object from an execution & task id
  const getTaskExecution = (
    execution: ProcessExecution,
    taskId: string,
  ): TaskCheckExecution => {
    return {
      ...execution,
      task_id: taskId,
      state: TaskExecutionState.Completed,
      data: {
        value: true,
      },
    };
  };

  // attempts to submit the execution on its current task
  // (resolves as a boolean so the caller method can read whether it was a successful submission)
  const submit = async (execution: ProcessExecution, taskId: string) => {
    try {
      await ProcessExecutions.submitTask(
        execution.id,
        taskId,
        getTaskExecution(execution, taskId),
      );
      await ProcessExecutions.completeTask(execution.id, taskId);

      success("Successfully submitted task");
      return true;
    } catch (e) {
      error("An error occurred while submitting");
      console.error(e);
      return false;
    }
  };

  // calculates how much of a section is on screen,
  // sets the current section based on the max number
  const handleScroll = () => {
    if (scrollBox.current) {
      const scrollTargetBound = scrollBox.current.getBoundingClientRect();

      const firstBound = sectionRefs.current[0]?.getBoundingClientRect();
      const lastBound =
        sectionRefs.current[
          sectionRefs.current.length - 1
        ]?.getBoundingClientRect();

      if (firstBound && lastBound) {
        const leftOffscreen = scrollTargetBound.x - firstBound.x;
        const rightOffscreen = lastBound.right - scrollTargetBound.right;
        const scrollWidth = leftOffscreen + rightOffscreen; // total scrollable amt

        // calculates what percent is already scrolled based on leftOffscreen/scrollWidth
        // the index of the two numbers that its between is the currently selected section
        const scrollPercentCutoff = getScrollPercentCutoffs();
        const scrollPercent = leftOffscreen / scrollWidth;
        for (let i = 0; i < scrollPercentCutoff.length; i++) {
          if (
            scrollPercent >= scrollPercentCutoff[i] &&
            scrollPercent <= scrollPercentCutoff[i + 1]
          ) {
            setCurrentSectionIndex(i);
            return;
          }
        }
      }
    }
  };

  const getScrollPercentCutoffs = (): number[] => {
    const scrollPercentCutoff: number[] = [];
    if (scrollBox.current) {
      const scrollTargetBound = scrollBox.current.getBoundingClientRect();

      const firstBound = sectionRefs.current[0]?.getBoundingClientRect();
      const lastBound =
        sectionRefs.current[
          sectionRefs.current.length - 1
        ]?.getBoundingClientRect();

      if (firstBound && lastBound) {
        const fullWidth = lastBound.right - firstBound.x; // full container width including offscreen left->right

        scrollPercentCutoff.push(0);
        for (let i = 0; i < sectionRefs.current.length; i++) {
          const elem = sectionRefs.current[i];
          if (elem) {
            // base width plus half of one margin (2.5% of container size)
            // adds the width of the second margin so that it corresponds properly if its between first and last
            let width =
              elem.getBoundingClientRect().width +
              scrollTargetBound.width * 0.025;
            if (i > 0 && i < sectionRefs.current.length - 1) {
              width += scrollTargetBound.width * 0.025;
            }

            scrollPercentCutoff.push(
              scrollPercentCutoff[i] + width / fullWidth,
            );
          }
        }
      }
    }
    return scrollPercentCutoff;
  };

  const handleTabChange = (index: number) => {
    const scrollTargetBound = scrollBox.current?.getBoundingClientRect();
    const firstBound = sectionRefs.current[0]?.getBoundingClientRect();
    const lastBound =
      sectionRefs.current[
        sectionRefs.current.length - 1
      ]?.getBoundingClientRect();

    if (firstBound && lastBound && scrollTargetBound) {
      const leftOffscreen = scrollTargetBound.x - firstBound.x;
      const rightOffscreen = lastBound.right - scrollTargetBound.right;
      const scrollWidth = leftOffscreen + rightOffscreen; // total scrollable amt

      const scrollPercentCutoffs = getScrollPercentCutoffs();
      scrollBox.current?.scroll({
        top: 0,
        left: scrollWidth * scrollPercentCutoffs[index] + 5,
        behavior: "smooth",
      });
    }
  };

  // fires whenever a process is selected
  useEffect(() => {
    if (!process || !process.id) return;
    // loads "categories" for a given process (i.e. task & execution list)
    setIsLoaded(false);
    getSections(process).then((sections) => {
      setSectionList(sections);
      setIsLoaded(true);
    });
  }, [process]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <KanbanBoardControls
        process={process}
        sectionList={sectionList ? sectionList : []}
        currentSectionIndex={currentSectionIndex}
        handleTabChange={handleTabChange}
      />
      <Box
        sx={{
          display: "flex",
          overflow: "scroll",
          flexGrow: 1,
          mt: "2vh",
        }}
        onScroll={handleScroll}
        ref={scrollBox}
      >
        {/* Task and Execution Display */}
        {isLoaded && sectionList ? (
          sectionList.map((section, index) => {
            return (
              <KanbanBoardSection
                section={section}
                functions={{
                  reload: reload,
                  submit: submit,
                }}
                key={section.step.id}
                ref={(elem) => (sectionRefs.current[index] = elem)}
                complete={section.step.id == "complete"}
              />
            );
          })
        ) : process ? (
          <CircularProgress sx={{ m: "auto" }} />
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default KanbanBoardView;
