import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { OrganizationAPI } from "../../api";
import { useSearchParams } from "react-router-dom";
import useAppNavigation from "../../hooks/useAppNavigation";

const ConfirmOrganizationEmailPage: FC<unknown> = () => {
  // Get the confirmation code from the page's query params
  const [searchParams] = useSearchParams();
  const { organizationId } = useParams();

  const { navigateHome } = useAppNavigation();

  const [loading, setLoading] = useState(false);

  const [isConfirmed, setIsConfirmed] = useState(false);

  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (!organizationId) {
      return;
    }

    const code = searchParams.get("code");
    if (!code) {
      return;
    }

    setLoading(true);
    // Make a request to the backend to confirm the email
    OrganizationAPI.confirmEmail(organizationId, code)
      .then(() => {
        setIsConfirmed(true);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsError(true);
        setLoading(false);
      });
  }, [organizationId, searchParams]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <Box
        sx={{
          mt: 2,
          maxWidth: "600px",
          minWidth: "300px",
          mx: "auto",
          textAlign: "center",
        }}
      >
        {loading && <CircularProgress />}
        {isConfirmed && (
          <Typography variant="h1">
            Your organization&#39;s email has been confirmed!
          </Typography>
        )}
        {isError && (
          <Box>
            <Typography variant="h1">
              There was an error confirming your organization&#39;s email.
            </Typography>
          </Box>
        )}
        {!loading && (
          <Button
            sx={{ mt: 2 }}
            variant="contained"
            color="primary"
            onClick={() => navigateHome()}
          >
            Return to Dashboard{" "}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ConfirmOrganizationEmailPage;
