import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Dashboard from "../../model/Dashboard";
import { DashboardAPI } from "../../api";
import { useGlobalOrganizationContext } from "../../hooks/useGlobalOrganizationContext";
import { CircularProgress } from "@mui/material";
import { useAlert } from "../../lib/alert";
import NotFound from "../app/NotFound";
import { CreateDashboardDialog } from "./components/dialogs/CreateDashboardDialog";
import { DashboardPage } from "./DashboardPage";

export const DashboardRoute = () => {
  const { dashboardKey } = useParams<{ dashboardKey: string }>();
  const { organization } = useGlobalOrganizationContext();
  const { error } = useAlert();
  const [currentDashboard, setCurrentDashboard] = useState<Dashboard>();
  const [loading, setLoading] = useState(true);
  const [openCreateDialog, setOpenCreateDialog] = useState(true);

  useEffect(() => {
    if (!organization || !dashboardKey) {
      setCurrentDashboard(undefined);
      setLoading(false);
      return;
    }
    setLoading(true);
    DashboardAPI.byKey(organization.key, dashboardKey)
      .then((dashboard) => {
        setCurrentDashboard(dashboard);
      })
      .catch((err) => {
        if (err.response.status == 404) {
          setCurrentDashboard(undefined);
        } else {
          error(err.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dashboardKey, error, organization]);

  if (loading) {
    return <CircularProgress data-cy={"circular-progress"} />;
  }

  if (!loading && !currentDashboard) {
    return (
      <>
        <NotFound />
        {dashboardKey && (
          <CreateDashboardDialog
            open={openCreateDialog}
            handleConfirm={(newDashboard) => {
              setCurrentDashboard(newDashboard);
            }}
            handleCancel={() => {
              setOpenCreateDialog(false);
            }}
            dashboardKey={dashboardKey}
          />
        )}
      </>
    );
  }

  return (
    <DashboardPage
      dashboard={currentDashboard!}
      setDashboard={setCurrentDashboard}
    />
  );
};
