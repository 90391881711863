import { environment } from "../util";
import { useGlobalOrganizationContext } from "./useGlobalOrganizationContext";

// Proxy method so that we can use this in contexts that react doesn't
// like to allow us to use hooks in
export const getFeature = (
  feature: string,
  defaultValue?: boolean,
): boolean => {
  // TODO - store features in organization settings - driven through Stripe subscription
  const dv =
    environment.features[feature] !== undefined
      ? environment.features[feature]
      : defaultValue;
  if (environment.features[feature]) {
    return true;
  }
  const localState = localStorage.getItem(`runway.${feature}`);
  if (localState === null) {
    return dv !== undefined ? dv : false;
  }
  return localState.toLowerCase() === "true";
};

export default (feature: string, defaultValue?: boolean): boolean => {
  const { organization } = useGlobalOrganizationContext();
  if (organization?.config) {
    const featureConfig = organization.config.features;
    if (featureConfig && featureConfig[feature] !== undefined) {
      return featureConfig[feature];
    }
  }
  return getFeature(feature, defaultValue);
};
