/**
 * This custom hook that is used on individual pages that are displayed on the dashboard to set what elements get displayed through the useGlobalPageContext.
 */

import { useEffect } from "react";
import { useGlobalPageContext } from "./useGlobalPageContext";

export type DisplayOptions = {
  showDrawer?: boolean;
  showTopBorder?: boolean;
  topBorderColor?: string;
  title?: string;
  displayIcon?: string;
};

export const useDisplayOptions = ({
  showDrawer = true,
  showTopBorder = false,
  topBorderColor,
  title,
  displayIcon,
}: DisplayOptions) => {
  const { setDisplay } = useGlobalPageContext();
  useEffect(() => {
    setDisplay({
      drawer: showDrawer,
      topBarBorder: showTopBorder,
      topBarBorderColor: topBorderColor,
    });
    if (title) {
      document.title = title;
    }
  }, [setDisplay, showDrawer, showTopBorder, topBorderColor, title]);

  useEffect(() => {
    if (displayIcon) {
      let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.getElementsByTagName("head")[0].appendChild(link);
      }
      link.href = displayIcon;
    }
  }, [displayIcon]);
};
