import { TaskType } from "./TaskType";
import { TaskAcceptInvitationExecution } from "./task-types/TaskAcceptInvitation";
import { TaskAssignmentExecution } from "./task-types/TaskAssignment";
import { TaskCalendlyEventExecution } from "./task-types/TaskCalendlyEvent";
import { TaskCalendlyScheduledEventExecution } from "./task-types/TaskCalendlyScheduledEvent";
import { TaskCheckExecution } from "./task-types/TaskCheck";
import { TaskCompleteProcessDependencyExecution } from "./task-types/TaskCompleteProcessDependency";
import { TaskCreateAccountExecution } from "./task-types/TaskCreateAccount";
import { TaskDocuSignEventExecution } from "./task-types/TaskDocuSignEvent";
import { TaskEmailExecution } from "./task-types/TaskEmail";
import { TaskFormExecution } from "./task-types/TaskFormExecution";
import { TaskInvitationExecution } from "./task-types/TaskInvitation";
import { TaskProcessMonitorExecution } from "./task-types/TaskProcessMonitor";
import { TaskProcessStartExecution } from "./task-types/TaskProcessStart";
import { TaskShortCircuitExecution } from "./task-types/TaskShortCircuit";
import { TaskSquareFetchExecution } from "./task-types/TaskSquareFetch";
import { TaskSquareLinkExecution } from "./task-types/TaskSquareLink";
import { TaskOAuthLinkExecution } from "./task-types/TaskOAuthLink";
import { TaskUserAssignmentExecution } from "./task-types/TaskUserAssignment";
import { TaskRunwayApiCallExecution } from "./task-types/TaskRunwayApiCall";
import { TaskBeginPhaseExecution } from "./task-types/TaskBeginPhase";
import { Task } from "./Task";
import { TaskContentExecution } from "./task-types/TaskContent";

export type TaskExecutionError = {
  message?: string;
} | null;

export enum TaskExecutionState {
  InProgress = "IN_PROGRESS",
  Completed = "COMPLETED",
  Error = "ERROR",
}

enum NotificationEventType {
  Bounce = "Bounce",
  Complaint = "Complaint",
  Delivery = "Delivery",
  Send = "Send",
  Reject = "Reject",
  Open = "Open",
  Click = "Click",
  RenderingFailure = "Rendering Failure",
  DeliveryDelay = "DeliveryDelay",
  Subscription = "Subscription",
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type TaskBaseExecutionData = {
  notificationEvents?: Record<NotificationEventType, boolean>;
};

// TODO: this type is temporary for marking task executions as seen. It should be combined with notification events above at some point.
export type TaskExecutionNotificationEventsTemp =
  | { timestamp: string; eventType: string }
  | Record<string, never>;

export type TaskBaseExecution<
  T extends TaskType,
  E extends TaskBaseExecutionData,
> = {
  id?: string;
  name?: string;
  task_id?: string;
  task?: Task;
  process_execution_id?: string;
  type?: T;
  started_by_user_id?: string;
  submitted_by_user_id?: string;
  completed_by_user_id?: string;
  started_at?: Date;
  submitted_at?: Date;
  completed_at?: Date;
  assigned_to_user_id?: string;
  state?: TaskExecutionState;
  error?: TaskExecutionError;
  data?: E;
};

export type TaskExecution =
  | TaskCheckExecution
  | TaskFormExecution
  | TaskEmailExecution
  | TaskAssignmentExecution
  | TaskShortCircuitExecution
  | TaskCalendlyEventExecution
  | TaskCalendlyScheduledEventExecution
  | TaskSquareFetchExecution
  | TaskSquareLinkExecution
  | TaskDocuSignEventExecution
  | TaskOAuthLinkExecution
  | TaskInvitationExecution
  | TaskAcceptInvitationExecution
  | TaskUserAssignmentExecution
  | TaskCompleteProcessDependencyExecution
  | TaskProcessStartExecution
  | TaskProcessMonitorExecution
  | TaskCreateAccountExecution
  | TaskRunwayApiCallExecution
  | TaskBeginPhaseExecution
  | TaskContentExecution;
