import { useEffect, useRef, useState } from "react";
import AddTaskMenuButton from "../../task/components/editor/AddTaskMenuButton";
import Processes from "../../../api/processes";
import { useTaskContext } from "../../task/hooks/TaskContext";
import { CircularProgress, Grid } from "@mui/material";
import { ResourceModificationOperationType } from "../../../types";
import { TaskInlineEditorContainer } from "../../task/components";
import { DragEndEvent } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import Draggable from "../../../components/core/Draggable/Draggable";
import DraggableList from "../../../components/core/Draggable/DraggableList";
import { Process } from "../../../model/Process";
import { ValidationError } from "class-validator";

type ProcessTasksEditorProps = {
  process: Process;
  stepOrProcessId: string;
  validationFailures?: ValidationError[];
};

const ProcessTasksEditor = ({
  stepOrProcessId,
  process,
  validationFailures,
}: ProcessTasksEditorProps) => {
  const taskContext = useTaskContext();
  const fetching = useRef<boolean>(false);
  const [activeId, setActiveId] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!fetching.current) {
      fetching.current = true;
      taskContext.fetchTasks(stepOrProcessId).finally(() => {
        fetching.current = false;
      });
    }
    /*
     * We intentionally leave off taskContext as a dependency in order
     * to prevent re-fectching the tasks whenever the task context is updated.
     * Task context may change for a variety of reasons but the tasks will already
     * be correctly updated at that point and do not need to be re-fetched from the backend
     * and cause a re-render of the entire process editor.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepOrProcessId]);

  const tasks = taskContext.getTasks(stepOrProcessId);
  const taskIds = tasks.map((task) => task.id ?? "");

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const activeIndex = active?.data?.current?.sortable.index;
      const overIndex = over?.data?.current?.sortable.index;

      const newTasks = arrayMove(tasks, activeIndex, overIndex);
      taskContext.setTasks(stepOrProcessId, newTasks);
      const tasksForUpdate = newTasks.map((task) => {
        return { task, operation: ResourceModificationOperationType.Put };
      });
      Processes.updateTasks(stepOrProcessId, tasksForUpdate).then(() => {
        taskContext.fetchTasks(stepOrProcessId);
      });
    }
  };

  if (fetching.current) {
    return <CircularProgress />;
  }

  return (
    <Grid item pt={1}>
      {tasks && taskIds && (
        <DraggableList
          listItemIds={taskIds}
          handleDragEnd={handleDragEnd}
          handleDragStart={(event) => {
            setActiveId(event.active.id.toString());
          }}
          overlayComponent={
            activeId && (
              <TaskInlineEditorContainer
                key={activeId}
                stepOrProcessId={stepOrProcessId}
                process={process}
                task={tasks.filter((task) => task.id === activeId)[0]}
                validationFailures={validationFailures}
              />
            )
          }
        >
          {tasks?.map((task) => {
            return (
              <Draggable key={task.id} id={task.id ?? ""}>
                <TaskInlineEditorContainer
                  key={task.id ?? task.name}
                  stepOrProcessId={stepOrProcessId}
                  process={process}
                  task={task}
                  validationFailures={validationFailures}
                />
              </Draggable>
            );
          })}
        </DraggableList>
      )}

      <AddTaskMenuButton
        open={open}
        setOpen={setOpen}
        previousTask={tasks.length > 0 ? tasks[tasks.length - 1] : undefined}
        process={process}
        stepOrProcessId={stepOrProcessId}
      />
    </Grid>
  );
};
export default ProcessTasksEditor;
