import { DialogContentText, DialogProps } from "@mui/material";
import { FC } from "react";
import { Organization } from "../../model";
import Dialog from "../Dialog";
import { OrganizationAPI } from "../../api";
import { useAlert } from "../../lib/alert";

interface DeleteOrganizationDialogProps extends DialogProps {
  organization: Organization;
  setDialogOpen: (open: boolean) => void;
  onOrganizationDeleted?: (organization: Organization) => void;
}

const DeleteOrganizationDialog: FC<DeleteOrganizationDialogProps> = (props) => {
  const { handleRejectionWithError } = useAlert();

  return (
    <Dialog
      open={props.open}
      title={props.title ?? "Delete Organization?"}
      confirmText={"Yes"}
      cancelText={"No"}
      handleConfirm={() => {
        return OrganizationAPI.delete(props.organization.id).then(() => {
          props.setDialogOpen(false);
          props.onOrganizationDeleted?.(props.organization);
        }, handleRejectionWithError("Failed to delete organization"));
      }}
      handleCancel={() => {
        props.setDialogOpen(false);
      }}
    >
      <DialogContentText>
        Are you sure you want to delete this organization?
      </DialogContentText>
    </Dialog>
  );
};

export default DeleteOrganizationDialog;
