import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Navigate, useLocation } from "react-router-dom";
import VerificationComponent from "./components/VerificationComponent";

type RegistrationState = {
  username: string;
  password: string;
};

const VerificationPage = () => {
  const location = useLocation();

  const state = location.state as RegistrationState & { origin: string };

  if (!state) {
    console.error(
      "VerificationPage: state was not defined from registration page",
    );
    return <Navigate to={"/register"} />;
  }

  const userId = state.username;
  const password = state.password;

  if (!userId || !password) {
    console.error(
      "VerificationPage: Either userId or password was not defined from registration page",
    );
    return <Navigate to={"/register"} />;
  }

  return (
    <>
      <Container
        component="main"
        maxWidth={false}
        sx={{ mt: { xs: 3, md: 18 } }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          maxWidth="xs"
          sx={{
            mt: 8,
          }}
        >
          <VerificationComponent
            userId={userId}
            password={password}
            onComplete={() => (window.location.href = state.origin)}
          />
        </Grid>
      </Container>
    </>
  );
};

export default VerificationPage;
