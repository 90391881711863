type CalendlyIntegrationData = {
  token: string;
};
type DocuSignIntegrationData = {
  baseUri: string;
  oAuthBasePath: string;
  docusignUserId: string;
  accountId: string;
};
type QuickBooksIntegrationData = {
  client_id: string;
  client_secret: string;
  refresh_token: string;
  bearer_token: string;
  token_uri: string;
  auth_uri: string;
};
type SquareIntegrationData = {
  token: string;
};
// Union type for all possible integration types
export type IntegrationData = Partial<
  CalendlyIntegrationData &
    DocuSignIntegrationData &
    QuickBooksIntegrationData &
    SquareIntegrationData
>;

// Types of integration
export enum IntegrationType {
  Calendly = "CALENDLY",
  DocuSign = "DOCUSIGN",
  QuickBooks = "QUICKBOOKS",
  Square = "SQUARE",
  Runway = "RUNWAY",
}

export default class Integration {
  public id = "";
  public organization_id = "";
  public type: IntegrationType = IntegrationType.Calendly;
  public data?: IntegrationData;
}
