import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Chip,
  DialogContentText,
  FormControlLabel,
  TextField,
  Typography,
  accordionSummaryClasses,
} from "@mui/material";
import Dialog from "../../../../components/Dialog";
import { FC, useEffect, useState } from "react";
import { useAlert } from "../../../../lib/alert";
import { Account } from "../../../../model";
import { AccountAPI, AccountTypeAPI } from "../../../../api";
import { AccountDto } from "../../../../model/Account";
import {
  FormElementType,
  FormExecutionType,
  SelectionOption,
} from "../../../task";
import TaskFieldDefinitionFormComponent from "../../../task/components/task-types/form/task-form-elements/TaskFieldDefinitionFormComponent";
import { FieldValue } from "../../../../model/FieldDefinition";
import { ExpandMore } from "@mui/icons-material";

type EditAccountDialogProps = {
  open: boolean;
  accountDto: AccountDto;
  onClose?: () => void;
  onUpdate?: (account: Account) => void;
};
const EditAccountDialog: FC<EditAccountDialogProps> = ({
  accountDto,
  ...props
}) => {
  const { handleRejectionWithError } = useAlert();
  const [name, setName] = useState(accountDto?.account_name ?? "");
  const [emailAddress, setEmailAddress] = useState<string | undefined>(
    accountDto.primary_email,
  );
  const [isTest, setIsTest] = useState<boolean>(accountDto?.is_test ?? false);
  const [errorText, setErrorText] = useState<string | undefined>(undefined);

  const [updatedFieldDefs, setUpdatedFieldDefs] = useState<
    Array<{
      fieldDefId: string;
      elementId: string;
      value: FieldValue | undefined;
    }>
  >([]);
  const [fieldDefinitionInfo, setFieldDefinitionInfo] = useState<
    Array<{
      id: string;
      label: string;
      elements: Array<{
        id: string;
        type?: FormElementType;
        valueLabel?: string;
        execution?: FormExecutionType;
      }>;
      accordionOpen: boolean;
    }>
  >([]);

  const title = accountDto.account_name
    ? `Update ${accountDto.account_name}`
    : "Update Account";

  const getFieldDefValueLabel = (
    elementType: FormElementType | undefined,
    elementValue: FieldValue | undefined,
  ) => {
    switch (elementType) {
      case FormElementType.Selection:
      case FormElementType.Radio:
        return (elementValue as SelectionOption)?.name;
      case FormElementType.Autocomplete:
      case FormElementType.Text:
        return elementValue as string;
      case FormElementType.Checkbox:
        return (elementValue as boolean) ? "True" : "False";
      default:
        return undefined;
    }
  };

  useEffect(() => {
    if (accountDto.primary_email) {
      setEmailAddress(
        (emailAddress) => emailAddress ?? accountDto?.primary_email,
      );
    }
    if (accountDto.account_name) {
      setName((name) => name ?? accountDto?.account_name);
    }
    if (accountDto.account_type_id) {
      AccountTypeAPI.getFieldDefinitions(accountDto.account_type_id).then(
        ({ selected, available }) => {
          setFieldDefinitionInfo(
            selected.map((fieldDefId) => {
              const fieldDef = available[fieldDefId];

              const accountFieldDefValue = accountDto.fields
                ? accountDto.fields[fieldDefId]
                  ? accountDto.fields[fieldDefId].value
                  : undefined
                : undefined;

              return {
                id: fieldDef.id,
                label: fieldDef.label,
                elements: fieldDef.data.elements.map((element) => {
                  const elementValue = accountFieldDefValue
                    ? accountFieldDefValue[element.id]
                    : undefined;

                  return {
                    id: element.id,
                    type: element.type,
                    valueLabel: getFieldDefValueLabel(
                      element.type,
                      elementValue,
                    ),
                    execution: {
                      ...element,
                      value: elementValue,
                    } as FormExecutionType,
                  };
                }),
                accordionOpen: false,
              };
            }),
          );
        },
      );
    }
  }, [accountDto]);

  return (
    <Dialog
      open={props.open}
      title={title}
      confirmText={"Update"}
      cancelText={"Cancel"}
      handleConfirm={(): Promise<void> => {
        if (!name) {
          const errorText = "Account name must be specified";
          setErrorText(errorText);
          return Promise.reject(errorText);
        }

        const updatedFieldObject = accountDto.fields ?? {};
        updatedFieldDefs.map((updatedFieldDef) => {
          // set up object for field definition if does not exist
          if (!updatedFieldObject[updatedFieldDef.fieldDefId]) {
            updatedFieldObject[updatedFieldDef.fieldDefId] = {
              updated_at: Date.now(),
              value: {},
            };
          } else {
            updatedFieldObject[updatedFieldDef.fieldDefId].updated_at =
              Date.now();
          }
          // update value
          if (updatedFieldDef.value != undefined) {
            updatedFieldObject[updatedFieldDef.fieldDefId].value[
              updatedFieldDef.elementId
            ] = updatedFieldDef.value;
          } else {
            delete updatedFieldObject[updatedFieldDef.fieldDefId].value[
              updatedFieldDef.elementId
            ];
          }
        });

        return AccountAPI.update({
          id: accountDto.id,
          org_id: accountDto.org_id,
          account_type_id: accountDto.account_type_id,
          account_type: accountDto.account_type,
          account_name: name,
          primary_email: emailAddress,
          is_test: isTest,
          fields:
            Object.keys(updatedFieldObject).length != 0
              ? updatedFieldObject
              : undefined,
        }).then((account) => {
          setUpdatedFieldDefs([]);
          props.onClose?.();
          props.onUpdate?.(account);
        }, handleRejectionWithError("Failed to update account"));
      }}
      handleCancel={function (): void {
        props.onClose && props.onClose();
      }}
    >
      <DialogContentText>
        Change the name for the account and the email of the primary contact:
        {errorText && (
          <Typography variant="body1" sx={{ color: "red" }}>
            {errorText}
          </Typography>
        )}
      </DialogContentText>
      <TextField
        autoFocus
        margin="dense"
        id="name"
        label="Account Name"
        type="text"
        fullWidth
        variant="standard"
        value={name}
        onChange={(e) => setName(e.target.value as string)}
      />
      <TextField
        autoFocus
        margin="dense"
        id="email"
        label="Email Address"
        type="text"
        fullWidth
        variant="standard"
        value={emailAddress ?? ""}
        onChange={(e) => setEmailAddress(e.target.value as string)}
      />
      <FormControlLabel
        control={
          <Checkbox checked={isTest} onChange={() => setIsTest(!isTest)} />
        }
        label="Mark as test account"
      />
      {fieldDefinitionInfo.length != 0 && (
        <DialogContentText sx={{ mt: 2, mb: 1 }}>
          {accountDto.account_name ?? "Account"} Fields:
        </DialogContentText>
      )}
      {fieldDefinitionInfo.map(({ id, label, elements }, index) => (
        <Accordion
          key={index}
          onChange={(e, expanded) =>
            setFieldDefinitionInfo((prev) => {
              prev[index].accordionOpen = expanded;
              return prev;
            })
          }
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            sx={{
              [`& .${accordionSummaryClasses.content}`]: {
                overflow: "hidden",
                alignItems: "center",
              },
            }}
          >
            <Typography
              sx={{
                flexShrink: 0,
                height: "fit-content",
              }}
            >
              {label}
            </Typography>
            {!fieldDefinitionInfo[index].accordionOpen &&
              elements.length == 1 &&
              elements[0].valueLabel && (
                <Chip
                  label={
                    <Typography
                      sx={{
                        fontSize: "small",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {elements[0].valueLabel}
                    </Typography>
                  }
                  sx={{
                    ml: 1,
                    overflow: "hidden",
                  }}
                />
              )}
          </AccordionSummary>
          <AccordionDetails>
            <TaskFieldDefinitionFormComponent
              field_definition_id={id}
              id={id}
              name={label}
              label={label}
              disabled={false}
              onValueChange={({ elementId, value: newExecution }) => {
                const newValue = (newExecution as { value?: FieldValue }).value;
                setFieldDefinitionInfo((prev) => {
                  prev[index].elements = prev[index].elements.map((e) => {
                    if (e.id == elementId)
                      return {
                        ...e,
                        execution: newExecution,
                        valueLabel: getFieldDefValueLabel(e.type, newValue),
                      };
                    else return e;
                  });
                  return prev;
                });
                setUpdatedFieldDefs((prev) => {
                  prev = prev.filter(
                    (e) => !(e.fieldDefId == id && e.elementId == elementId),
                  );
                  prev.push({
                    fieldDefId: id,
                    elementId: elementId,
                    value: newValue,
                  });
                  return prev;
                });
              }}
              execution={{ value: elements.map((e) => e.execution) }}
              formExecutionComponentProps={{}}
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </Dialog>
  );
};

export default EditAccountDialog;
