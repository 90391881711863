import { FC, useCallback } from "react";
import Processes from "../../../api/processes";
import { useDisplayOptions } from "../../../hooks/useDisplayOptions";
import OrganizationProcesses from "./OrganizationProcesses";
import OrganizationScopedPage from "../../../components/OrganizationScopedPage";
import { useGlobalOrganizationContext } from "../../../hooks/useGlobalOrganizationContext";
import ProcessListActionBar from "./ProcessListActionBar";
import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import PATHS from "../../../components/navigation/_paths";

const ProcessPage: FC<unknown> = () => {
  useDisplayOptions({ title: "Processes" });

  // State to manage currently selected organization for page operations
  const { organization } = useGlobalOrganizationContext();

  const fetchProcessesForOrganization = useCallback(() => {
    if (!organization?.id) {
      return Promise.resolve([]);
    }

    return Processes.byOrganization(organization.id);
  }, [organization?.id]);

  return (
    <OrganizationScopedPage
      category="processes"
      displayOrganizationActionMenu={false}
    >
      {organization && (
        <OrganizationProcesses
          fetch={fetchProcessesForOrganization}
          currentOrganization={organization}
          PageHeaderProps={{
            actions: <ProcessListActionBar />,
          }}
          extraColumns={[
            {
              field: "entity_type_name",
              headerName: `Account Type`,
              flex: 1,
              minWidth: 150,
              renderCell: (params) =>
                params.row.entity_type_name ? (
                  <Link
                    underline="hover"
                    key={params.row.id}
                    component={RouterLink}
                    to={PATHS.ACCOUNTS_TYPE_PROCESSES.linkTo(
                      params.row.entity_type_key,
                    )}
                    sx={{ color: (theme) => theme.palette.text.primary }}
                  >
                    {params.row.entity_type_name}
                  </Link>
                ) : (
                  <></>
                ),
            },
          ]}
        />
      )}
    </OrganizationScopedPage>
  );
};

export default ProcessPage;
