import { Delete } from "@mui/icons-material";
import {
  Executor,
  ExecutorType,
} from "../../../features/task/components/execution/types/ExecutorModel";
import {
  ListItem,
  TextField,
  Select,
  MenuItem,
  IconButton,
  ListItemSecondaryAction,
  SelectChangeEvent,
} from "@mui/material";
import { useMemo, useState } from "react";
import _ from "lodash";

type ExecutorItemProps = {
  executor: Executor;
  onUpdate: (id: string, executor: Executor) => void;
  onDelete: (executor: Executor) => void;
};

const ExecutorItem = ({ executor, onUpdate, onDelete }: ExecutorItemProps) => {
  // State for containing the executor id
  const [id, setId] = useState<string>(executor.id);

  // State for containing the executor type
  const [executorType, setExecutorType] = useState<ExecutorType>(
    executor.executor_type,
  );

  // Debounced update function which calls onUpdate every 2000 ms
  // using the loaash debounce function
  const debouncedUpdate = useMemo(
    () =>
      _.debounce(onUpdate, 2000, {
        trailing: true,
      }),
    [onUpdate],
  );

  return (
    <ListItem disableGutters>
      <TextField
        value={id}
        onChange={(e) => {
          setId(e.target.value);
          debouncedUpdate.cancel();
          debouncedUpdate(executor.id, { ...executor, id: e.target.value });
        }}
      />
      <ListItemSecondaryAction>
        <Select
          value={executorType}
          onChange={(e: SelectChangeEvent) => {
            setExecutorType(e.target.value as ExecutorType);
            debouncedUpdate.cancel();
            debouncedUpdate(executor.id, {
              ...executor,
              executor_type: e.target.value as ExecutorType,
            });
          }}
        >
          <MenuItem value="public">Public</MenuItem>
        </Select>
        <IconButton sx={{ mx: 1 }} onClick={() => onDelete(executor)}>
          <Delete />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default ExecutorItem;
