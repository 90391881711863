import { FC, useEffect, useState } from "react";
import { Typography } from "@mui/material";
import CommonContainer from "../../../components/core/common-container";
import HeroProgressBar from "../../accounts/components/HeroProgressBar";
import { ProcessAPI, ProcessExecutionAPI } from "edo-platform-frontend/src/api";
import HeroContainer from "../../../components/elements/HeroContainer";
import { Task, TaskExecution, TaskType } from "../../task";
import { ProcessExecution } from "../../../model/ProcessExecution";

type ProcessExecutionHeroProps = {
  processExecutionId: string;
  reloadTrigger?: number;
};

const ProcessExecutionHero: FC<ProcessExecutionHeroProps> = ({
  processExecutionId,
  reloadTrigger,
}) => {
  const [taskExecutions, setTaskExecutions] = useState<TaskExecution[]>();
  const [tasks, setTasks] = useState<Task[]>();
  const [processComplete, setProcessComplete] = useState<boolean>(false);
  const [processExecutionDto, setProcessExecutionDto] =
    useState<ProcessExecution>();

  useEffect(() => {
    const getTaskExecutions = async () => {
      const taskExecutions =
        await ProcessExecutionAPI.getTaskExecutions(processExecutionId);
      setTaskExecutions(taskExecutions);
    };

    const getTasks = async () => {
      const processExecutionDto =
        await ProcessExecutionAPI.getDto(processExecutionId);

      setProcessExecutionDto(processExecutionDto);
      setProcessComplete(!processExecutionDto.next_task_id);

      const tasks = await ProcessAPI.getTasks(processExecutionDto.process_id);

      setTasks(tasks.filter((task) => task.type == TaskType.BeginPhase));
    };

    getTaskExecutions();
    getTasks();
  }, [processExecutionId, reloadTrigger]);

  if (!taskExecutions || taskExecutions.length === 0) {
    return <></>;
  }

  return (
    <HeroContainer displayTopographic>
      <Typography variant="h4">Innovation Journey</Typography>
      <Typography variant="body1">
        {processExecutionDto?.process_description}
      </Typography>
      {taskExecutions && tasks && (
        <CommonContainer
          sx={{
            mx: 0,
            py: 2,
            mt: 2,
            px: 0,
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {tasks.map((task, index) => {
            const nextTask =
              index + 1 < taskExecutions.length ? tasks[index + 1] : undefined;
            const nextTaskExecution = nextTask
              ? taskExecutions.find(
                  (taskExecution) => taskExecution.task_id == nextTask.id,
                )
              : undefined;
            const taskExecution = taskExecutions.find(
              (taskExecution) => taskExecution.task_id == task.id,
            );

            return (
              <HeroProgressBar
                task={task}
                key={task.id}
                taskExecution={taskExecution}
                nextTask={nextTask}
                nextTaskExecution={nextTaskExecution}
                processComplete={processComplete}
              />
            );
          })}
        </CommonContainer>
      )}
    </HeroContainer>
  );
};

export default ProcessExecutionHero;
