import { useGlobalOrganizationContext } from "./useGlobalOrganizationContext";
import { useGlobalRoleContext } from "./useGlobalRoleContext";

export default () => {
  const { organization, organizationsLoaded, isMember } =
    useGlobalOrganizationContext();
  const { roles } = useGlobalRoleContext();
  if (!organizationsLoaded || !organization) {
    return false;
  }
  const member = isMember(organization.id);
  if (!member) {
    return false;
  }
  return roles.some(
    (role) => role.org_id === organization.id && role.name === "Administrator",
  );
};
