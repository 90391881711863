import CreateOrganizationComponent from "../../../../../organization/components/CreateOrganizationComponent";

const CreateOrganizationInlineEditor = () => {
  return (
    <CreateOrganizationComponent
      disabled={true}
      hideStepper={false}
      onCreate={() => {
        return;
      }}
    />
  );
};

export default CreateOrganizationInlineEditor;
