import React, { useState, useRef } from "react";
import { Typography, Popover, Box } from "@mui/material";
import { TaskExecution, TaskExecutionState } from "../../types";
import { User } from "../../../../model";
import { getUserFullName } from "../../../activity-log";
import { UserSelectionByRole } from "../../../../components/core/Selection";
import { UserOrInvitedUser } from "../../../../components/core/Selection/UserSelection";
import { ProcessExecutionAPI } from "../../../../api";
import { useAlert } from "../../../../lib/alert";
import Invitation from "../../../../model/Invitation";
import { CommonRole } from "../../../../types";

interface TaskExecutionAssignmentProps {
  processExecutionId: string;
  taskExecution?: TaskExecution;
  assignedUser?: User;
  targetRoleId?: string;
}

const TaskExecutionAssignment: React.FC<TaskExecutionAssignmentProps> = ({
  taskExecution,
  assignedUser,
  targetRoleId,
  processExecutionId,
}) => {
  const [stateAssignedUser, setStateAssignedUser] = useState<User | undefined>(
    assignedUser,
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const typographyRef = useRef(null);

  const { handleRejectionWithError } = useAlert();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    targetRoleId && setAnchorEl(event.currentTarget);
  };

  const handleRoleAssignment = (users: UserOrInvitedUser[]) => {
    if (!taskExecution?.task_id) {
      return;
    }

    if (!users.length) {
      // This is an unassignment
      ProcessExecutionAPI.unassign(
        processExecutionId,
        taskExecution.task_id,
      ).then(() => {
        setStateAssignedUser(undefined);
      }, handleRejectionWithError);
    } else {
      // This is an assignment
      ProcessExecutionAPI.assign(
        processExecutionId,
        taskExecution.task_id,
        users[0].id,
      ).then(() => {
        setStateAssignedUser(users[0]);
      }, handleRejectionWithError);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const isCompleted = taskExecution?.state === TaskExecutionState.Completed;

  if (
    targetRoleId &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (Object as any).values(CommonRole).includes(targetRoleId)
  ) {
    return <></>;
  }

  return (
    <div>
      <Typography
        variant={isCompleted ? "body2" : "body1"}
        sx={(theme) => ({
          textDecoration: isCompleted ? "none" : "underline",
          color: isCompleted ? theme.palette.grey[500] : "inherit",
          cursor: isCompleted ? "default" : "pointer",
        })}
        onClick={(e) => {
          e.stopPropagation();
          if (!isCompleted) {
            handleClick(e);
          }
        }}
        ref={typographyRef}
      >
        {stateAssignedUser ? getUserFullName(stateAssignedUser) : "Unassigned"}
      </Typography>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ p: 2, minWidth: "400px" }}>
          {targetRoleId && (
            <UserSelectionByRole
              values={stateAssignedUser ? [stateAssignedUser.id] : undefined}
              onInvite={(invitation: Invitation) => {
                if (!taskExecution?.task_id) {
                  return;
                }

                ProcessExecutionAPI.assign(
                  processExecutionId,
                  taskExecution.task_id,
                  invitation.target_user_id,
                ).then(() => {
                  setStateAssignedUser({
                    id: invitation.target_user_id,
                    email: invitation.target_user_email,
                  } as User);
                }, handleRejectionWithError);
              }}
              multiple={false}
              targetRoleId={targetRoleId}
              onChange={handleRoleAssignment}
            />
          )}
        </Box>
      </Popover>
    </div>
  );
};

export default TaskExecutionAssignment;
