import { Box, Typography, TypographyProps } from "@mui/material";
import { lazy, PropsWithChildren } from "react";

type CardTitleProps = PropsWithChildren<{
  variant?: TypographyProps["variant"];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: any;
  icon?: string;
  sx?: TypographyProps["sx"];
}>;

const DynamicIconComponent = lazy(
  () => import(/* webpackChunkName: "DynamicIcon" */ "../core/DynamicIcon"),
);

const CardTitle = ({
  children,
  component = "h1",
  variant = "h2",
  icon,
  sx,
}: CardTitleProps) => {
  return (
    <Typography
      data-cy="card-title"
      variant={variant}
      component={component}
      sx={{ mb: 2, display: "flex", alignItems: "center", ...sx }}
    >
      {icon && (
        <DynamicIconComponent
          name={icon}
          fontSize="inherit"
          sx={{ mr: 0.75 }}
        />
      )}
      <Box sx={{ flex: 1 }}>{children}</Box>
    </Typography>
  );
};
export default CardTitle;
