import { createContext, useContext } from "react";
import { ProcessExecutionContextType } from "./useProcessExecution";

const ProcessExecutionContext = createContext<
  ProcessExecutionContextType | Record<string, never>
>({});

const useProcessExecutionContext = () => {
  return useContext(ProcessExecutionContext);
};

export { useProcessExecutionContext };
export default ProcessExecutionContext;
