import { Button } from "@mui/material";

const ContentInlineEditor = () => {
  return (
    <>
      <Button sx={{ mt: 2 }} variant="outlined" disabled>
        {"Complete"}
      </Button>
    </>
  );
};
export default ContentInlineEditor;
