import {
  Autocomplete,
  TextField,
  InputAdornment,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import React from "react";
import { lazy, Suspense, useEffect, useMemo, useState } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let MuiIcons: any;
const DynamicIcon = lazy(() => import("../DynamicIcon"));

type RecentIconType = {
  name: string;
  recent?: true;
};

type IconSelectorProps = {
  defaultIcon?: string;
  onChange?: (option: string) => void;
  iconStyle?: "TwoTone" | "Sharp" | "Outlined" | "Rounded" | "Filled";
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

const IconSelector = ({
  defaultIcon,
  onChange,
  iconStyle = "TwoTone",
}: IconSelectorProps) => {
  const [cachedMuiIcons, setCachedMuiIcons] = useState(MuiIcons);
  const [selectedIcon, setSelectedIcon] = useState<string>(defaultIcon ?? "");

  useEffect(() => {
    const loadIcons = async () => {
      const DynamicIconModule = await import("../DynamicIcon");
      MuiIcons = DynamicIconModule.MuiIcons;
      setCachedMuiIcons(MuiIcons);
    };
    if (!MuiIcons) {
      loadIcons();
    }
  }, []);

  const options: Array<RecentIconType> = useMemo(() => {
    if (!cachedMuiIcons) {
      return [];
    }
    const muiOptions = Object.keys(cachedMuiIcons)
      .filter((iconName) => {
        if (iconName === "Filled") {
          return !(
            iconName.endsWith("TwoTone") ||
            iconName.endsWith("Sharp") ||
            iconName.endsWith("Outlined") ||
            iconName.endsWith("Rounded")
          );
        }
        return iconName.endsWith(iconStyle);
      })
      .map((iconName) => {
        const newOption: RecentIconType = {
          name: iconName,
          recent: iconName == defaultIcon ? true : undefined,
        };
        return newOption;
      });
    muiOptions.unshift({ name: "" });
    return muiOptions;
  }, [cachedMuiIcons, defaultIcon, iconStyle]);

  if (!cachedMuiIcons) {
    return <CircularProgress />;
  }

  return (
    <Autocomplete
      fullWidth
      options={options.sort((o1, o2) => {
        if (o1.recent) return -1;
        if (o2.recent) return 1;
        return 0;
      })}
      groupBy={(option) => (option.recent ? "Recent Icons" : "Icons")}
      value={{ name: selectedIcon }}
      onChange={(_, value) => {
        const newValue = (value as RecentIconType)?.name ?? "";
        setSelectedIcon(newValue);
        onChange && onChange(newValue);
      }}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => {
        if (!option.name) {
          return <React.Fragment key={option.name}></React.Fragment>;
        }
        return (
          <Tooltip key={option.name} title={option.name} placement={"top"}>
            <li
              {...props}
              onClick={(e) => {
                option.recent = true;
                props.onClick && props.onClick(e);
              }}
              style={{
                display: "inline-block",
                paddingLeft: "1rem",
                paddingRight: "1rem",
              }}
            >
              <Suspense>
                <DynamicIcon name={option.name} fontSize={"large"} />
              </Suspense>
            </li>
          </Tooltip>
        );
      }}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label="Icon"
          variant="standard"
          placeholder="Search"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Suspense>
                  <DynamicIcon name={selectedIcon} />
                </Suspense>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default IconSelector;
