import { Box, Button, Typography } from "@mui/material";
import BasePanel from "./BasePanel";
import { ManageInvitationPageContext } from "../ManageInvitationPage";
import { useContext } from "react";
import useAppNavigation from "../../../hooks/useAppNavigation";

const FinalizePanel = () => {
  const { invitation } = useContext(ManageInvitationPageContext);
  const { navigateHome } = useAppNavigation();

  return (
    <BasePanel>
      <Typography variant="h2" sx={{ mb: 2 }}>
        Prepare for takeoff!
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ color: (theme) => theme.palette.grey[700] }}
      >
        {`Your account has been created and you are now part of the ${invitation?.display?.organization_name} organization. Head over to the dashboard to view your assigned tasks.`}
      </Typography>
      <Box
        sx={{
          my: "5%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Button
          variant="contained"
          sx={{ fontWeight: "bold", textTransform: "none" }}
          onClick={navigateHome}
        >
          {`Go to Dashboard`}
        </Button>
      </Box>
    </BasePanel>
  );
};

export default FinalizePanel;
