import { Box, Button } from "@mui/material";
import {
  TaskExecutionComponentProps,
  TaskExecutionState,
  TaskProcessStart,
  TaskProcessStartExecution,
} from "../../../types";
import { DEFAULT_RENDER } from "../TaskBody";

const ProcessStartExecutionComponent = ({
  task,
  taskExecution,
  isDisabled,
  children = DEFAULT_RENDER,
  ...props
}: TaskExecutionComponentProps<
  TaskProcessStart,
  TaskProcessStartExecution
>) => {
  const onExecute = () => {
    props.onExecute &&
      props.onExecute({
        ...taskExecution,
        task_id: task.id,
        state: TaskExecutionState.Completed,
      });
  };

  const actions = (
    <Box>
      <Button
        variant="contained"
        size="small"
        sx={{ m: 1 }}
        disabled={isDisabled}
        onClick={() => onExecute()}
      >
        Start Process
      </Button>
    </Box>
  );

  return children({ actions });
};

export default ProcessStartExecutionComponent;
