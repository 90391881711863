import { useEffect, useState } from "react";
import { Outlet, useParams, useSearchParams } from "react-router-dom";
import { OrganizationAPI } from "../../../api";
import { MainContent } from "../../../components/layout";
import { DefaultAlertSnackbar } from "../../../lib/alert";
import { Organization, Role } from "../../../model";

import { environment } from "../../../util";
import GlobalOrganizationProvider from "../components/GlobalOrganizationProvider";
import PublicOrganizationNavbar from "../components/PublicOrganizationNavbar";
import NotFound from "../../app/NotFound";
import PublicOrganizationDisplayContainer from "./PublicOrganizationDisplayContainer";
import { GlobalRoleContext } from "../../../hooks/useGlobalRoleContext";
import { Provider, createStore } from "jotai";
import { publicPageAtom } from "../state/publicPageAtom";

const PublicOrganizationLayout = ({
  children,
}: React.PropsWithChildren<Record<string, unknown>>) => {
  const publicOrganizationStore = createStore();
  publicOrganizationStore.set(publicPageAtom, true);
  const [searchParams] = useSearchParams();
  const embed = searchParams.get("embed") !== null;
  const { orgKey } = useParams<{ orgKey: string }>();
  const [organization, setOrganization] = useState<Organization | undefined>();
  const [roles, setRoles] = useState<Array<Role>>([]);
  const [show404, setShow404] = useState(false);
  useEffect(() => {
    const organizationKey = environment.organization?.key ?? orgKey;
    if (organizationKey) {
      // 1. b) Load organization from backend
      OrganizationAPI.byKey(organizationKey).then(
        (org) => {
          if (environment.organization?.key) {
            setOrganization({
              ...org,
              name: environment.organization.name ?? org.name,
              theme: {
                longLogoUrl: environment.app.logo,
                shortLogoUrl: environment.app.logoIcon,
              },
            });
          } else {
            setOrganization(org);
          }
        },
        () => {
          // 2. Show 404 if organization not found.
          setShow404(true);
        },
      );
    }
  }, [orgKey]);

  return (
    <PublicOrganizationDisplayContainer organization={organization}>
      {organization && (
        <GlobalOrganizationProvider organization={organization}>
          <GlobalRoleContext.Provider value={{ roles, setRoles }}>
            {!embed && <PublicOrganizationNavbar organization={organization} />}
            <MainContent top={embed ? 0 : 8} height="100%">
              <DefaultAlertSnackbar>
                <Provider store={publicOrganizationStore}>
                  {show404 && <NotFound />}
                  {!show404 && (children ? children : <Outlet />)}
                </Provider>
              </DefaultAlertSnackbar>
            </MainContent>
          </GlobalRoleContext.Provider>
        </GlobalOrganizationProvider>
      )}
    </PublicOrganizationDisplayContainer>
  );
};

export default PublicOrganizationLayout;
