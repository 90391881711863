import { Typography } from "@mui/material";
import { FormDefinitionType } from "../../types";

const FormBuilderStep = ({ element }: { element?: FormDefinitionType }) => {
  return (
    <Typography variant="h4" component="h2" sx={{ lineHeight: "34px", ml: 1 }}>
      {element?.label ? element.label : <>&nbsp;</>}
    </Typography>
  );
};

export default FormBuilderStep;
