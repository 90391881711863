import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { ProcessExecution } from "../../../model/ProcessExecution";

const ProcessGridSelector: FC<ProcessExecution> = (execution) => {
  const navigate = useNavigate();

  return (
    <Box>
      <Typography
        variant="inherit"
        sx={{
          overflow: "ellipsis",
          "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
          },
        }}
        onClick={() => {
          navigate(`../executions/${execution.process_id}/table`);
        }}
      >
        {execution.process_name}
      </Typography>
    </Box>
  );
};

export default ProcessGridSelector;
