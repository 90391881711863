import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Chip,
  Button,
  FormLabel,
} from "@mui/material";
import { RadioFormElement, TaskForm } from "../../../types";
import { useNavigate } from "react-router-dom";
import PATHS from "../../../../../components/navigation/_paths";
import React from "react";
import FormElementTaskDescription from "../FormElementTaskDescription";

export const RadioInlineDisplay = ({
  element,
  task,
}: {
  element: RadioFormElement;
  task: TaskForm;
}) => {
  const navigate = useNavigate();
  return (
    <RadioGroup>
      <FormLabel required={element.required}>{element.label}</FormLabel>
      <FormElementTaskDescription>
        {element.description}
      </FormElementTaskDescription>
      {element.options.map(({ id, name, elements }, index) => {
        return (
          <React.Fragment key={id}>
            <FormControlLabel
              key={index}
              id={id}
              value={name}
              control={<Radio id={id} disabled />}
              label={<Chip label={name} sx={{ lineHeight: "15px" }} />}
            />
            <Button
              variant="outlined"
              onClick={() => {
                navigate(
                  PATHS.PROCESS_TASK_ELEMENT_FORM.linkTo(
                    task.process_id,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    task.id!,
                    element.id,
                    id,
                  ),
                );
              }}
            >
              {elements && elements.length > 0
                ? "View/Edit Form"
                : "Build Form"}
            </Button>
          </React.Fragment>
        );
      })}
    </RadioGroup>
  );
};
