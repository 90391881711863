import API from "./base";
import { FileUploadResponse } from "../transfer/FileUploadResponse";

const upload = (fileArray: FileList): Promise<FileUploadResponse[]> => {
  return new Promise((resolve, reject) => {
    API.post("/files/", fileArray, { public: true }).then((result) => {
      resolve(result.data.payload as FileUploadResponse[]);
    }, reject);
  });
};

// can't name a variable 'delete'
const deleteFile = (key: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    API.delete("/files/" + key).then(() => {
      resolve();
    }, reject);
  });
};

export default {
  uploadFile: upload,
  deleteFile: deleteFile,
};
