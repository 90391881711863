import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Account } from "../../../model";
import PATHS from "../../../components/navigation/_paths";

export type AccountLinkProps = {
  account: Account;
  name?: string;
};

const AccountLink = ({ account }: AccountLinkProps) => {
  return (
    <Link
      underline="hover"
      component={RouterLink}
      to={PATHS.ACCOUNT_PROFILE.linkTo(account.id)}
      sx={{ color: (theme) => theme.palette.text.secondary }}
    >
      {account.account_name}
    </Link>
  );
};
export default AccountLink;
