import API from "./base";
import { ProcessCategory } from "../model/ProcessCategory";

const create = (category: ProcessCategory): Promise<ProcessCategory> => {
  return new Promise((resolve, reject) => {
    API.post("/process-categories", category).then(
      (result) => resolve(result.data.payload as ProcessCategory),
      reject,
    );
  });
};

const get = (categoryId: string): Promise<ProcessCategory> => {
  return new Promise((resolve, reject) => {
    API.get("/process-categories/" + categoryId).then((result) => {
      resolve(result.data.payload as ProcessCategory);
    }, reject);
  });
};

const update = (processCategory: ProcessCategory): Promise<ProcessCategory> => {
  return new Promise((resolve, reject) => {
    API.put("/process-categories/" + processCategory.id, processCategory).then(
      (result) => {
        resolve(result.data.payload as ProcessCategory);
      },
      reject,
    );
  });
};

// Delete not allowed as variable name
const remove = (categoryId: string): Promise<void> => {
  return API.delete("/process-categories/" + categoryId);
};

const byOrganization = (
  organizationId: string,
): Promise<Array<ProcessCategory>> => {
  return new Promise((resolve, reject) => {
    API.get("/process-categories/byOrganization/" + organizationId, {
      public: true,
    }).then((result) => {
      resolve(result.data.payload as Array<ProcessCategory>);
    }, reject);
  });
};

export default {
  create,
  get,
  update,
  delete: remove,
  byOrganization,
};
