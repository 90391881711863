import { styled } from "@mui/system";

const TypingIndicatorContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const Dot = styled("div")(() => ({
  width: "8px",
  height: "8px",
  borderRadius: "50%",
  backgroundColor: "#000", // Change this color if needed
  margin: "0 4px",
  animation: "bounce 1.4s infinite ease-in-out both",
  "&:nth-of-type(1)": {
    animationDelay: "-0.32s",
  },
  "&:nth-of-type(2)": {
    animationDelay: "-0.16s",
  },
  "@keyframes bounce": {
    "0%, 80%, 100%": {
      transform: "scale(0)",
    },
    "40%": {
      transform: "scale(1)",
    },
  },
}));

const TypingIndicator = () => {
  return (
    <TypingIndicatorContainer>
      <Dot />
      <Dot />
      <Dot />
    </TypingIndicatorContainer>
  );
};

export default TypingIndicator;
