import { useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { useAlert } from "../../../../lib/alert";
import ConfirmationModal, {
  ConfirmationModalProps,
} from "../../../process/components/confirmation-modal/ConfirmationModal";
import { TaskBody, TaskContainer } from "..";
import { ProcessExecution } from "../../../../model/ProcessExecution";
import { User } from "../../../../model";
import {
  Task,
  TaskExecution,
  TaskExecutionRenderProps,
  TaskExecutionState,
  TaskType,
} from "../../types";
import { CopilotAPI, TaskExecutionAPI } from "../../../../api";
import { useNavigate } from "react-router-dom";
import { useTaskExecutionEditingState } from "../../hooks/useTaskExecutionEditingState";

type TaskExecutionContainerProps = {
  task: Task;
  assignedUser?: User;
  taskExecution?: TaskExecution;
  processExecution?: ProcessExecution;
  isNext?: boolean;
  isLoaded?: boolean;
  expand?: boolean;
  onExecute?: (
    submission: TaskExecution,
    completeTask?: boolean,
  ) => Promise<void>;
  onValueChange?: (value: Task) => void;
  retryErrorTask?: () => void;
  reload: () => void;
  onShare?: () => void;
};

const TaskExecutionContainer = ({
  assignedUser,
  task,
  taskExecution,
  processExecution,
  isNext,
  expand = false,
  isLoaded: processIsLoaded,
  onExecute,
  onValueChange,
  retryErrorTask,
  reload,
  ...props
}: TaskExecutionContainerProps) => {
  const { success, error } = useAlert();
  const navigate = useNavigate();
  const { editable } = useTaskExecutionEditingState(taskExecution, task);

  // Confirmation Modal States
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalTaskExecution, setModalTaskExecution] = useState<TaskExecution>(
    {},
  );

  const handleCloseModal = () => setOpenModal(false);
  const executeModal = () => onExecute?.(modalTaskExecution);
  const confirmExecution = async (s: TaskExecution) => {
    setOpenModal(true);
    setModalTaskExecution(s);
  };

  const confirmationProps = {
    openModal,
    handleCloseModal,
    executeModal,
    confirmationData: task.data?.confirmation,
  } as ConfirmationModalProps;

  const taskTypesThatHaveBuiltinConfirmationModal = [
    TaskType.ShortCircuit,
    TaskType.Form,
  ];

  if (!task.type) {
    error("Task Type could not be found");
    return <></>;
  }
  if (!onExecute) {
    error("Task does not have an execution function");
    return <></>;
  }

  const isSpecialTaskType = taskTypesThatHaveBuiltinConfirmationModal.includes(
    task.type,
  );
  const hasConfirmation = !!task.data?.confirmation && !isSpecialTaskType;

  return (
    <>
      <TaskContainer
        execution={taskExecution}
        processExecutionId={processExecution?.id}
        label={taskExecution?.name ?? task.name}
        description={
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (taskExecution?.data as any)?.description ?? task.description
        }
        type={task.type}
        assignedUser={assignedUser}
        isComplete={
          taskExecution?.state === TaskExecutionState.Completed &&
          !taskExecution.error
        }
        isLocked={
          taskExecution?.state !== TaskExecutionState.Completed &&
          !(isNext && editable) &&
          !taskExecution?.error
        }
        showChildren={isNext || expand}
        role={task.role}
        error={!!taskExecution?.error}
        retryErrorTask={retryErrorTask}
        onShare={
          taskExecution?.type == TaskType.Form
            ? () => {
                if (taskExecution?.id) {
                  TaskExecutionAPI.share(taskExecution.id)
                    .then(() => {
                      success("Task data has been shared");
                      props.onShare?.();
                    })
                    .catch((e) => {
                      console.error(e);
                      error("Could not share task data");
                    });
                }
              }
            : undefined
        }
        onCopilot={
          taskExecution?.type == TaskType.Form
            ? () => {
                if (taskExecution?.id) {
                  CopilotAPI.processWithCopilot(taskExecution.id)
                    .then((newProcessExecution) => {
                      // Redirect the user to the new process execution
                      // that was started from this task data
                      navigate(
                        `/user/organization/executions/${newProcessExecution.process_id}/${newProcessExecution.id}`,
                      );
                    })
                    .catch((e) => {
                      console.error(e);
                      error("Could not process task data with copilot");
                    });
                }
              }
            : undefined
        }
        icon={
          task.type == TaskType.Check &&
          taskExecution?.state !== TaskExecutionState.Completed &&
          isNext &&
          editable &&
          !taskExecution?.error ? (
            <TaskBody
              variant="execution"
              task={task}
              taskExecution={taskExecution}
              processExecution={processExecution}
              onExecute={hasConfirmation ? confirmExecution : onExecute}
              isDisabled={!isNext}
              onValueChange={onValueChange}
              reload={reload}
            >
              {({ actions, content }: TaskExecutionRenderProps) => {
                return (
                  <>
                    <Box py={3}>{content}</Box>

                    <Box flex={0} px={3} mt={1}>
                      {actions}
                    </Box>
                  </>
                );
              }}
            </TaskBody>
          ) : undefined
        }
      >
        {task.type != TaskType.Check &&
          (processIsLoaded ? (
            <>
              <TaskBody
                variant="execution"
                task={task}
                taskExecution={taskExecution}
                processExecution={processExecution}
                onExecute={hasConfirmation ? confirmExecution : onExecute}
                isDisabled={!isNext}
                onValueChange={onValueChange}
                reload={reload}
              >
                {({ actions, content }: TaskExecutionRenderProps) => {
                  return (
                    <>
                      {content}

                      <Box flex={0} mt={2}>
                        {actions}
                      </Box>
                    </>
                  );
                }}
              </TaskBody>
            </>
          ) : (
            <CircularProgress />
          ))}
      </TaskContainer>
      {hasConfirmation && <ConfirmationModal {...confirmationProps} />}
    </>
  );
};

export default TaskExecutionContainer;
