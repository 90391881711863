import { FC, useContext } from "react";
import SignInComponent from "../../user/components/SignInComponent";
import BasePanel from "./BasePanel";
import { Link, Typography } from "@mui/material";
import { ManageInvitationPageContext } from "../ManageInvitationPage";
import User from "../../../model/User";

const SignInPanel: FC<{
  onClickCreate: () => void;
  onSignIn: (user: User) => void;
}> = ({ onClickCreate, onSignIn }) => {
  const { email } = useContext(ManageInvitationPageContext);

  return (
    <>
      <BasePanel sx={{ p: 0 }}>
        <SignInComponent
          includeFooter={false}
          email={email}
          onSuccess={(user) => onSignIn(user)}
          sx={{ bgcolor: "transparent", borderRadius: "10px" }}
        />
      </BasePanel>
      <Typography sx={{ width: "100%", textAlign: "center", mt: 2 }}>
        {`Not registered yet? `}
        <Link
          onClick={onClickCreate}
          sx={{
            textDecoration: "none",
            "&:hover": {
              cursor: "pointer",
              textDecoration: "underline",
            },
          }}
        >
          {`Create an account`}
        </Link>
      </Typography>
    </>
  );
};

export default SignInPanel;
