import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { SelectionFormElement } from "../../../types";

export const SelectionInlineDisplay = ({
  element,
}: {
  element: SelectionFormElement;
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel>{element.label}</InputLabel>
      <Select label={element.label} fullWidth disabled value="">
        {!element.required && (
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
        )}
        {element.options.map(({ id, name }, index) => {
          return (
            <MenuItem key={index} id={id} value={name}>
              {name}
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText>{element.description}</FormHelperText>
    </FormControl>
  );
};
