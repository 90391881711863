import { Task } from "../features/task";
import { Process, ProcessStatus } from "../model/Process";
import { EntityRef } from "../model/EntityRef";
import { Step } from "../model/step";
import TaskDto from "../transfer/TaskDto";
import { ProcessDto } from "../types";
import API from "./base";
import { ProcessCategory } from "../model/ProcessCategory";

const create = (process: Process, entityRef?: EntityRef): Promise<Process> => {
  return new Promise((resolve, reject) => {
    API.post("/processes", {
      ...process,
      ...entityRef,
    }).then((result) => resolve(result.data.payload as Process), reject);
  });
};

const get = (processId: string): Promise<Process> => {
  return new Promise((resolve, reject) => {
    API.get("/processes/" + processId).then((result) => {
      resolve(result.data.payload as Process);
    }, reject);
  });
};

const exportProcess = (processId: string): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    API.get(`/processes/${processId}/export`).then((result) => {
      resolve(new Blob([JSON.stringify(result.data.payload)]));
    }, reject);
  });
};

const importProcesses = (
  organizationId: string,
  files: File[],
  overwrite?: boolean,
): Promise<void> => {
  return API.postForm(`/processes/${organizationId}/import`, {
    files: files,
    overwrite,
  });
};

const getForEdit = (processId: string): Promise<Process> => {
  return new Promise((resolve, reject) => {
    API.get("/processes/" + processId + "/edit").then((result) => {
      resolve(result.data.payload as Process);
    }, reject);
  });
};

const update = (process: ProcessDto): Promise<Process> => {
  return new Promise((resolve, reject) => {
    API.put("/processes/" + process.id, process).then((result) => {
      resolve(result.data.payload as Process);
    }, reject);
  });
};

const publish = (processId: string): Promise<Process> => {
  return new Promise((resolve, reject) => {
    API.post("/processes/" + processId + "/publish").then((result) => {
      resolve(result.data.payload as Process);
    }, reject);
  });
};

// Delete not allowed as variable name
const remove = (processId: string): Promise<void> => {
  return API.delete("/processes/" + processId);
};

const byOrganization = (organizationId: string): Promise<Array<Process>> => {
  return new Promise((resolve, reject) => {
    API.get("/processes/byOrganization/" + organizationId).then((result) => {
      resolve(result.data.payload as Array<Process>);
    }, reject);
  });
};

const byCategory = (
  organizationId: string,
  categoryId: string,
): Promise<Array<Process>> => {
  return new Promise((resolve, reject) => {
    API.get(
      `/processes/byOrganization/${organizationId}/byCategory/${categoryId}`,
      { public: true },
    ).then((result) => {
      resolve(result.data.payload as Array<Process>);
    }, reject);
  });
};

const byEntity = (
  organizationId: string,
  entityRef: EntityRef,
  status = ProcessStatus.Published,
): Promise<Array<Process>> => {
  const canExecuteFlag =
    entityRef.counterparty_can_execute !== undefined
      ? `&counterparty_can_execute=${entityRef.counterparty_can_execute}`
      : "";
  return new Promise((resolve, reject) => {
    API.get(
      `/processes/byOrganization/${organizationId}/byType/${entityRef.entity_type}/${entityRef.entity_id}?status=${status}${canExecuteFlag}`,
      { public: true },
    ).then((result) => {
      resolve(result.data.payload as Array<Process>);
    }, reject);
  });
};

const updateTasks = (
  processId: string,
  tasks: Array<TaskDto>,
): Promise<Array<Task>> => {
  return new Promise((resolve, reject) => {
    const taskUpdateRequest = {
      tasks: tasks,
    };
    API.put("/processes/" + processId + "/tasks", taskUpdateRequest).then(
      (result) => {
        resolve(result.data.payload as Array<Task>);
      },
      reject,
    );
  });
};

const getTasks = (processId: string): Promise<Array<Task>> => {
  return new Promise((resolve, reject) => {
    API.get("/processes/" + processId + "/tasks").then((result) => {
      resolve(result.data.payload as Array<Task>);
    }, reject);
  });
};

const fetchLatestTasks = async (
  processMeta: string,
  status?: ProcessStatus,
): Promise<Array<Task>> => {
  const latestProcess = await fetchLatest(processMeta, status);
  return await getTasks(latestProcess.id);
};

const getSteps = (processId: string): Promise<Array<Step>> => {
  return new Promise((resolve, reject) => {
    API.get("/processes/" + processId + "/steps").then((result) => {
      resolve(result.data.payload as Array<Step>);
    }, reject);
  });
};

const createStep = (processId: string, step: Step): Promise<Step> => {
  return new Promise((resolve, reject) => {
    API.post("/processes/" + processId + "/steps", step).then((result) => {
      resolve(result.data.payload as Step);
    }, reject);
  });
};

// fetches latest process by meta and optional status (Published by default)
const fetchLatest = (
  meta: string,
  status?: ProcessStatus,
): Promise<Process> => {
  return new Promise((resolve, reject) => {
    API.get(`/processes/${meta}/latest`, {
      params: { status: status },
    }).then((result) => {
      resolve(result.data.payload as Process);
    }, reject);
  });
};

// Delete not allowed as variable name
const deleteStep = (processId: string, stepId: string): Promise<void> => {
  return API.delete(`/processes/${processId}/steps/${stepId}`);
};

const updateStep = (step: Step): Promise<Step> => {
  return new Promise((resolve, reject) => {
    API.put(`/processes/${step.parent_process_id}/steps/${step.id}`, step).then(
      (result) => {
        resolve(result.data.payload as Step);
      },
      reject,
    );
  });
};

const getCategory = (processId: string): Promise<ProcessCategory> => {
  return new Promise((resolve, reject) => {
    API.get("/processes/" + processId + "/category").then((result) => {
      resolve(result.data.payload as ProcessCategory);
    }, reject);
  });
};

const getByKey = (
  processKey: string,
  organizationId: string,
): Promise<Process> => {
  return new Promise((resolve, reject) => {
    API.get(`/processes/key/${processKey}`, {
      params: { organizationId: organizationId },
    }).then((result) => {
      resolve(result.data.payload as Process);
    }, reject);
  });
};

export default {
  create,
  createStep,
  deleteStep,
  get,
  getForEdit,
  getSteps,
  getTasks,
  fetchLatestTasks,
  export: exportProcess,
  import: importProcesses,
  publish,
  fetchLatest,
  update,
  updateStep,
  updateTasks,
  byEntity,
  byOrganization,
  byCategory,
  getCategory,
  delete: remove,
  getByKey: getByKey,
};
