import { Box, Tab, Tabs, Tooltip } from "@mui/material";
import { ProcessExecution } from "../../../../model/ProcessExecution";
import {
  AttachFile,
  Forum,
  History,
  SettingsAccessibility,
} from "@mui/icons-material";
import { useState } from "react";
import { ProcessExecutionActivityLog } from ".";
import ProcessExecutionSharedTaskExecutions from "../ProcessExecutionSharedTaskExecutions";
import ProcessExecutionRolesComponent from "../side-details/ProcessExecutionRolesComponent";
import { AssignedRoleUserDto } from "../../../../types";
import SubjectChat from "../../../messaging/SubjectChat";
import { useGlobalOrganizationContext } from "../../../../hooks/useGlobalOrganizationContext";

export type ProcessExecutionDetailTabsProps = {
  processExecution: ProcessExecution;
  organizationId: string;
  attachmentReloadTrigger: number;
  assignedRoleUsers?: Array<AssignedRoleUserDto>;
};

enum DetailTab {
  Chat,
  Attachments,
  ActivityLog,
  Roles,
}

const ProcessExecutionDetailTabs = ({
  processExecution,
  organizationId,
  assignedRoleUsers,
  attachmentReloadTrigger,
}: ProcessExecutionDetailTabsProps) => {
  const { isMember } = useGlobalOrganizationContext();
  const [selectedTab, setSelectedTab] = useState(DetailTab.Chat);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        overflow: "hidden",
      }}
    >
      <Tabs
        value={selectedTab}
        onChange={(event, value) => {
          setSelectedTab(value);
        }}
      >
        <Tab
          icon={
            <Tooltip title="Chat" placement="top" arrow>
              <Forum />
            </Tooltip>
          }
          value={DetailTab.Chat}
        ></Tab>
        <Tab
          icon={
            <Tooltip title="Attachments" placement="top" arrow>
              <AttachFile />
            </Tooltip>
          }
          value={DetailTab.Attachments}
        ></Tab>
        {!!isMember(processExecution.org_id) && (
          <Tab
            icon={
              <Tooltip title="Roles" placement="top" arrow>
                <SettingsAccessibility />
              </Tooltip>
            }
            value={DetailTab.Roles}
          ></Tab>
        )}
        {!!isMember(processExecution.org_id) && (
          <Tab
            icon={
              <Tooltip title="Activity Log" placement="top" arrow>
                <History />
              </Tooltip>
            }
            value={DetailTab.ActivityLog}
          ></Tab>
        )}
      </Tabs>
      <Box sx={{ position: "relative", flex: 1, overflow: "auto" }}>
        {selectedTab === DetailTab.Chat && (
          <SubjectChat subjectId={processExecution.id} />
        )}
        {selectedTab !== DetailTab.Chat && (
          <Box sx={{ px: 3, py: 1 }}>
            {selectedTab === DetailTab.Attachments && (
              <ProcessExecutionSharedTaskExecutions
                processId={processExecution.process_id}
                processExecutionId={processExecution.id}
                attachmentReloadTrigger={attachmentReloadTrigger}
              />
            )}
            {selectedTab === DetailTab.Roles && assignedRoleUsers && (
              <ProcessExecutionRolesComponent
                assignedRoles={assignedRoleUsers}
              />
            )}
            {selectedTab === DetailTab.ActivityLog && (
              <ProcessExecutionActivityLog
                processExecution={processExecution}
                organizationId={organizationId}
              />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default ProcessExecutionDetailTabs;
