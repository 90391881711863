import { useSortable } from "@dnd-kit/sortable";
import { AccountProgram } from "../../../../../model/AccountProgram";
import { Process } from "../../../../../model/Process";
import { ProcessCategory } from "../../../../../model/ProcessCategory";
import { useState } from "react";
import { useNavigate } from "react-router";
import { Box, Switch, Tooltip, Typography } from "@mui/material";
import { CSS } from "@dnd-kit/utilities";
import { DragIndicator } from "@mui/icons-material";
import PATHS from "../../../../../components/navigation/_paths";

const ProcessCard = ({
  card,
  program,
  category,
  currentPanel,
  toggleVisibility,
  overlay,
}: {
  card: Process;
  program?: AccountProgram;
  category?: ProcessCategory;
  currentPanel?: string;
  toggleVisibility?: (process: Process, visible: boolean) => void;
  overlay?: boolean;
}) => {
  const {
    setNodeRef,
    setActivatorNodeRef,
    listeners,
    attributes,
    transition,
    transform,
    isDragging,
  } = useSortable({
    id: card.id,
    data: {
      card: card,
      category: category,
      currentPanel: currentPanel,
    },
  });

  const [switchChecked, setSwitchChecked] = useState<boolean>(
    program?.visible ?? false,
  );
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "30vw",
        height: "50px",
        my: currentPanel === "builder" ? 1 : 0.5,
        border: "1px solid black",
        borderRadius: "7px",
        bgcolor: (theme) => theme.palette.background.paper,
        transition: transition,
        transform: CSS.Translate.toString(transform),
        visibility: isDragging ? "hidden" : "visible",
        "&:hover": {
          boxShadow:
            "0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12)",
        },
      }}
      ref={setNodeRef}
      {...attributes}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          bgcolor: "#E6EDF2",
          borderRadius: "7px 0 0 7px",
          height: "100%",
          width: "5%",
          "&:hover": {
            bgcolor: "#D5E0E7",
            cursor: !overlay ? "grab" : "grabbing",
          },
        }}
        ref={setActivatorNodeRef}
        {...listeners}
      >
        <DragIndicator />
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "column", width: "100%", ml: 1 }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            "&:hover": {
              cursor: "pointer",
              textDecoration: "underline",
            },
          }}
          onClick={() => navigate(PATHS.PROCESS.linkTo(card.meta ?? card.id))}
        >
          {card.name}
        </Typography>
        {category && currentPanel === "builder" && (
          <Typography variant="subtitle2">{category.label}</Typography>
        )}
      </Box>
      {currentPanel === "builder" && (
        <Tooltip
          title={
            switchChecked
              ? "Client is able to view this process"
              : "Client is not able to view this process"
          }
          placement="top"
          arrow
        >
          <Switch
            checked={switchChecked}
            onChange={() => {
              toggleVisibility && toggleVisibility(card, !switchChecked);
              setSwitchChecked(!switchChecked);
            }}
          />
        </Tooltip>
      )}
    </Box>
  );
};

export default ProcessCard;
