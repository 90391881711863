import { useState, useEffect } from "react";
import { Tooltip, Box } from "@mui/material";
import { FC } from "react";
import { ProcessExecution } from "../../../model/ProcessExecution";

const ProcessPhaseChip: FC<ProcessExecution> = (processExecution) => {
  const [matchesPhaseNames, setMatchesPhaseNames] = useState<boolean>(true);
  const processNameWithoutVersionRegex = /([a-zA-Z\s]+)\s-\s[0-9\s]+/;
  const processName: string = processExecution.process_name;
  const phaseName = processName.replace(processNameWithoutVersionRegex, "$1");

  useEffect(() => {
    if (
      phaseName.toLowerCase() != "quick explore" &&
      phaseName.toLowerCase() != "validation" &&
      phaseName.toLowerCase() != "acceleration" &&
      phaseName.toLowerCase() != "incubation"
    ) {
      setMatchesPhaseNames(false);
    }
  }, [phaseName]);
  if (!processExecution.display) {
    return <></>;
  }
  return (
    <Tooltip title={phaseName}>
      <Box
        component={"span"}
        sx={{
          borderRadius: "25px",
          backgroundColor: matchesPhaseNames
            ? processExecution.display?.fillColor
            : "#343434",
          color: matchesPhaseNames
            ? processExecution.display?.fontColor
            : "#FFFFFF",
          typography: "body1",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          textAlign: "center",
          width: 1,
          p: 1,
          pl: 2,
          pr: 2,
          fontSize: "14px",
          fontWeight: "bold",
        }}
      >
        {phaseName}
      </Box>
    </Tooltip>
  );
};

export default ProcessPhaseChip;
