/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useState } from "react";
import { ProcessAPI } from "../../../api";
import { useGlobalOrganizationContext } from "../../../hooks/useGlobalOrganizationContext";
import { useAlert } from "../../../lib/alert";
import { Process } from "../../../model/Process";
import OrganizationPublicProcessCards from "./OrganizationPublicProcessCards";
type OrganizationAccountTypePublicPageProps = {
  categoryId: string;
};
const OrganizationCategoryPublicProcessCards = ({
  categoryId,
}: OrganizationAccountTypePublicPageProps) => {
  const { organization } = useGlobalOrganizationContext();
  const [processes, setProcesses] = useState<Process[] | undefined>();
  const { handleRejectionWithError } = useAlert();
  useEffect(() => {
    const loadPublicProcesses = () => {
      ProcessAPI.byCategory(organization!.id, categoryId).then((processes) => {
        setProcesses(processes);
      }, handleRejectionWithError);
    };
    if (!processes) {
      loadPublicProcesses();
    }
  }, [categoryId, handleRejectionWithError, organization, processes]);
  if (!processes) {
    return <></>;
  }
  return <OrganizationPublicProcessCards processes={processes} />;
};
export default OrganizationCategoryPublicProcessCards;
