import { Check } from "@mui/icons-material";
import { Box, Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { CalendlyAPI } from "../../../../../../api";
import { useAlert } from "../../../../../../lib/alert";
import { formatTextFormElementData } from "../../../../../../lib/format";
import {
  Task,
  TaskCalendlyScheduledEventExecution,
  TaskExecutionComponentProps,
  TaskExecutionState,
  TextFormDataType,
} from "../../../../types";

const CalendlyScheduledEventExecutionComponent = (
  props: TaskExecutionComponentProps<Task, TaskCalendlyScheduledEventExecution>,
) => {
  const { handleRejectionWithError } = useAlert();

  // state to indicate if a reminder email was sent
  const [reminderSent, setReminderSent] = useState<boolean>(false);

  // state to manage the task execution
  const [execution, setExecution] = useState<
    TaskCalendlyScheduledEventExecution | undefined
  >(props.taskExecution);

  useEffect(() => {
    setExecution(props.taskExecution);
  }, [props.taskExecution]);

  const onExecute = () => {
    const execution = {
      ...props.taskExecution,
      task_id: props.task.id,
      state: TaskExecutionState.Completed,
    };
    setExecution(execution);
    if (props.onExecute) {
      props.onExecute(execution);
    }
  };

  const sendReminderEmail = async () => {
    if (!execution || !execution.id) {
      return;
    }

    CalendlyAPI.sendReminder(execution.id).then(() => {
      setReminderSent(true);
    }, handleRejectionWithError);
  };

  return (
    <>
      <Box>
        <TextField
          disabled
          id="filled-disabled"
          label="Meeting Date"
          defaultValue={formatTextFormElementData(
            execution?.data?.startTime,
            TextFormDataType.Date,
          )}
          variant="filled"
          size="small"
        />
        {execution && (
          <Button
            variant="contained"
            size="small"
            sx={{ m: 1, textTransform: "none" }}
            disabled={props.isDisabled}
            onClick={() => sendReminderEmail()}
          >
            Send Reminder {reminderSent && <Check sx={{ pl: 1 }} />}
          </Button>
        )}
        <Button
          variant="contained"
          size="small"
          sx={{ m: 1, textTransform: "none" }}
          disabled={props.isDisabled}
          onClick={() => onExecute()}
        >
          Mark Complete
        </Button>
      </Box>
    </>
  );
};

export default CalendlyScheduledEventExecutionComponent;
