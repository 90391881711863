import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  WithNestedElements,
  FormDefinitionType,
  FormExecutionType,
  FormElementType,
  TaskCheck,
  TaskForm,
  TaskType,
  findNestedElement,
  FieldDefinitionFormElement,
} from "../../types";
import debounce from "lodash.debounce";
import { FieldDefinitionAPI, TaskAPI } from "../../../../api";
import Dialog from "../../../../components/Dialog";
import { FormElementChangeType } from "./FormBuilder";
import { findFieldDefinitionChildren } from "./FormBuilderSideSectionContainer";
import { FieldDefinitionTypes } from "../../../../model/FieldDefinition";
import { useGlobalOrganizationContext } from "../../../../hooks/useGlobalOrganizationContext";

const updateFormTask = debounce(
  (
    task: TaskForm | TaskCheck,
    elements: Array<FormDefinitionType> | undefined,
    nestedFormElementId: string | undefined,
    elementKey: string | undefined,
    executionData: Array<FormExecutionType> | undefined,
  ) => {
    let taskElements: Array<FormDefinitionType> | undefined = elements;
    if (task.type == TaskType.Form && nestedFormElementId) {
      findNestedElement(
        task.data?.form ?? {},
        nestedFormElementId,
        elementKey,
        (found) => {
          found.elements = elements;
        },
      );
      taskElements = task.data?.form?.elements ?? [];
      executionData = undefined;
    }
    if (task.type == TaskType.Check) {
      return TaskAPI.update({
        ...task,
        data: {
          ...task.data,
          form: {
            ...(task.data?.form ?? {}),
            yes: taskElements,
          },
        },
      });
    }
    if (task.type == TaskType.Form) {
      const updatedTask: TaskForm = {
        ...task,
      };
      if (taskElements) {
        updatedTask.data = {
          ...updatedTask.data,
          form: {
            ...(updatedTask.data?.form ?? {}),
            elements: taskElements,
          },
        };
      }
      if (executionData) {
        updatedTask.execution_data = {
          ...(updatedTask.execution_data ?? {}),
          form: {
            ...(updatedTask.execution_data?.form ?? {}),
            elements: executionData,
          },
        };
      }
      return TaskAPI.update(updatedTask);
    }
  },
  1000,
);

const updateFieldDefinitionElements = debounce(
  (fieldDefinitionId: string, elements: Array<FormDefinitionType>) => {
    FieldDefinitionAPI.updateElements(fieldDefinitionId, elements);
  },
  1000,
);

const updateFieldDefinition = debounce(
  async (fieldDefElement: FieldDefinitionFormElement) => {
    if (!fieldDefElement.field_definition_id) return;

    const fieldDefinition = await FieldDefinitionAPI.getWithoutParentElements(
      fieldDefElement.field_definition_id,
    );
    await FieldDefinitionAPI.update({
      ...fieldDefinition,
      label: fieldDefElement.label,
    });
  },
  1000,
);

const forEachChildElement = (
  elements: Array<FormDefinitionType>,
  headingId: string,
  callback: (child: FormDefinitionType) => void,
) => {
  let found = false;
  for (let i = 0; i < elements.length; i++) {
    if (found) {
      if (elements[i].type == FormElementType.Heading) {
        found = false;
      } else {
        callback(elements[i]);
      }
    }
    if (headingId == elements[i].id) {
      found = true;
    }
  }
};

export const FormBuilderContext = React.createContext<{
  task: TaskForm;
  parentElement?: WithNestedElements & { label?: string };
  elements: Array<FormDefinitionType>;
  executionData: Array<FormExecutionType>;
  selectedElementId?: string;
  setSelectedElementId: (id?: string) => void;
  onFormElementChange: (change: FormElementChangeType) => Promise<void>;
  hideChildren: ElementIsHidden;
  toggleChildren: (parentElementId: string, hide: boolean) => void;
}>({
  task: {
    process_id: "",
    name: "",
    task_identifier_name: "",
  },
  elements: [],
  executionData: [],
  selectedElementId: "",
  setSelectedElementId: () => {
    throw new Error("Function not implemented.");
  },
  onFormElementChange: () => {
    throw new Error("Function not implemented.");
  },
  hideChildren: {},
  toggleChildren: () => {
    throw new Error("Function not implemented.");
  },
});

type FormBuilderProviderProps = {
  task: TaskForm;
  elementId?: string;
  elementKey?: string;
};
type ElementIsHidden = {
  [elementId: string]: boolean;
};

export const FormBuilderProvider = ({
  task,
  elementId,
  elementKey,
  children,
}: React.PropsWithChildren<FormBuilderProviderProps>) => {
  const { organization } = useGlobalOrganizationContext();
  const [parentElement, setParentElement] = useState<WithNestedElements>();
  const [elements, setElements] = useState<Array<FormDefinitionType>>([]);
  const [selectedElementId, setSelectedElementId] = useState<string>();
  const [executionData, setExecutionData] = useState<Array<FormExecutionType>>(
    [],
  );
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const [hideChildren, setHideChildren] = useState<ElementIsHidden>({});

  useEffect(() => {
    if (elementId) {
      findNestedElement(
        task.data?.form ?? {},
        elementId,
        elementKey,
        (found) => {
          setElements(found.elements ?? []);
          setParentElement(found);
        },
      );
      findNestedElement(
        task.execution_data?.form ?? {},
        elementId,
        elementKey,
        (found) => {
          setExecutionData(found.elements ?? []);
        },
      );
    } else {
      if (task.data?.form?.elements) {
        setElements(
          task.data.form.elements.map((e) => {
            if (
              e.type == FormElementType.FieldDefinition &&
              e.field_definition_id
            ) {
              FieldDefinitionAPI.getWithoutParentElements(
                e.field_definition_id,
              ).then((fieldDefinition) => {
                e.elements = fieldDefinition.data.elements;
                return e;
              });
            }
            return e;
          }),
        );
      } else {
        setElements([]);
      }
      setExecutionData(task.execution_data?.form?.elements ?? []);
      setParentElement(undefined);
    }
  }, [elementId, elementKey, task.data?.form, task.execution_data?.form]);

  const onFormElementChange = useCallback(
    async (formChange: FormElementChangeType) => {
      switch (formChange.changeType) {
        case "add":
          if (formChange.newElement.type == FormElementType.FieldDefinition) {
            if (formChange.newElement.field_definition_id) {
              formChange.newElement.elements = (
                await FieldDefinitionAPI.getWithoutParentElements(
                  formChange.newElement.field_definition_id,
                )
              ).data.elements;
            } else {
              const newFieldDef = await FieldDefinitionAPI.create({
                id: "",
                meta: "",
                label: "Field Definition",
                entity_type: FieldDefinitionTypes.Organization,
                entity_id: organization?.id ?? "",
                data: {
                  elements: [],
                },
              });
              formChange.newElement.field_definition_id = newFieldDef.id;
            }
          }
          setElements((prev) => {
            let newElements = [formChange.newElement, ...prev];
            if (formChange.prevElementId) {
              newElements = prev.reduce((prev, element) => {
                prev.push(element);
                if (element.id == formChange.prevElementId) {
                  prev.push({ ...formChange.newElement });
                }
                return prev;
              }, [] as Array<FormDefinitionType>);
            }
            updateFormTask(
              task,
              newElements,
              elementId,
              elementKey,
              executionData,
            );
            return newElements;
          });
          setSelectedElementId(formChange.newElement.id);
          break;
        case "remove":
          setShowDeleteDialog(true);
          break;
        case "update":
          if (formChange.newElement) {
            setElements((prev) => {
              let fieldDefId =
                formChange.newElement?.type == FormElementType.FieldDefinition
                  ? formChange.newElement?.field_definition_id
                  : undefined;
              let fieldDefLabelChange = false;
              let fieldDefElementsChange = false;
              let fieldDefElementsReorder = false;
              let newElements = prev.reduce((prev, element) => {
                if (element.id == formChange.newElement?.id) {
                  if (
                    element.type == FormElementType.FieldDefinition &&
                    element.label != formChange.newElement?.label
                  ) {
                    fieldDefLabelChange = true;
                  }
                  prev.push(formChange.newElement);
                } else if (element.type == FormElementType.FieldDefinition) {
                  if (
                    element.elements?.find(
                      (el) => el.id == formChange.newElement?.id,
                    )
                  ) {
                    fieldDefElementsChange = true;
                    fieldDefId = element.field_definition_id;
                  }
                  prev.push(element);
                } else {
                  prev.push(element);
                }
                return prev;
              }, [] as Array<FormDefinitionType>);
              if (
                fieldDefId &&
                !(fieldDefLabelChange || fieldDefElementsChange)
              ) {
                fieldDefElementsReorder = true;
              }

              // update all instances of the changed field definition
              if (
                fieldDefLabelChange ||
                fieldDefElementsChange ||
                fieldDefElementsReorder
              ) {
                let fieldDefNewElements: FormDefinitionType[] | undefined =
                  undefined;
                newElements = newElements.map((element) => {
                  if (
                    element.type != FormElementType.FieldDefinition ||
                    element.field_definition_id != fieldDefId
                  ) {
                    return element;
                  }
                  if (fieldDefLabelChange) {
                    element.label = formChange.newElement?.label ?? "";
                  }
                  if (fieldDefElementsChange) {
                    if (!fieldDefNewElements)
                      fieldDefNewElements = element.elements?.map((e) =>
                        e.id == formChange.newElement?.id
                          ? formChange.newElement
                          : e,
                      );
                    element.elements = fieldDefNewElements;
                  }
                  if (fieldDefElementsReorder) {
                    if (
                      !fieldDefNewElements &&
                      formChange.newElement?.type ==
                        FormElementType.FieldDefinition
                    )
                      fieldDefNewElements = formChange.newElement.elements;
                    element.elements = fieldDefNewElements;
                  }
                  return element;
                });
                if (fieldDefLabelChange) {
                  updateFieldDefinition(
                    formChange.newElement as FieldDefinitionFormElement,
                  );
                }
                if (fieldDefElementsChange || fieldDefElementsReorder) {
                  updateFieldDefinitionElements(
                    fieldDefId ?? "",
                    fieldDefNewElements ?? [],
                  );
                }
              }

              updateFormTask(
                task,
                newElements,
                elementId,
                elementKey,
                executionData,
              );
              return newElements;
            });
          }
          if (formChange.newExecutionData) {
            setExecutionData((prev) => {
              const elementToExecutionData = elements.map((el) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                let foundExecutionElement: any = prev.find(
                  (p) => p.id == el?.id,
                );
                if (!foundExecutionElement) {
                  foundExecutionElement = { id: el.id };
                }
                if (
                  foundExecutionElement.id == formChange.newExecutionData?.id
                ) {
                  foundExecutionElement = {
                    ...foundExecutionElement,
                    ...(formChange.newExecutionData ?? {}),
                  };
                }
                return foundExecutionElement;
              });
              updateFormTask(
                task,
                elements,
                elementId,
                elementKey,
                elementToExecutionData,
              );
              return elementToExecutionData;
            });
          }
          break;
        case "reorder":
          setElements((prev) => {
            let deleteCount = 1;
            forEachChildElement(
              prev,
              prev[formChange.activeIndex].id,
              (child) => {
                if (hideChildren[child.id]) {
                  deleteCount++;
                }
              },
            );
            let startIndex = formChange.overIndex;
            if (formChange.activeIndex < formChange.overIndex) {
              forEachChildElement(
                prev,
                prev[formChange.overIndex].id,
                (child) => {
                  if (hideChildren[child.id]) {
                    startIndex++;
                  }
                },
              );
            }
            const elementsToMove = prev.splice(
              formChange.activeIndex,
              deleteCount,
            );
            prev.splice(startIndex, 0, ...elementsToMove);
            updateFormTask(
              task,
              [...prev],
              elementId,
              elementKey,
              executionData,
            );
            return [...prev];
          });
      }
    },
    [
      elementId,
      elementKey,
      elements,
      executionData,
      hideChildren,
      organization?.id,
      task,
    ],
  );

  const selectedElementCount = useMemo(() => {
    if (!selectedElementId) {
      return 0;
    }
    let count = 0;
    forEachChildElement(elements, selectedElementId, (child) => {
      if (hideChildren[child.id]) {
        count++;
      }
    });
    return count;
  }, [elements, hideChildren, selectedElementId]);

  return (
    <FormBuilderContext.Provider
      value={{
        task,
        parentElement,
        elements,
        executionData,
        selectedElementId,
        setSelectedElementId,
        onFormElementChange,
        hideChildren,
        toggleChildren: (parentElementId: string, hide: boolean) => {
          setHideChildren((prev) => {
            forEachChildElement(elements, parentElementId, (child) => {
              prev = {
                ...prev,
                [child.id]: hide,
              };
            });
            return prev;
          });
        },
      }}
    >
      {children}
      <Dialog
        open={showDeleteDialog}
        title={"Delete"}
        confirmText={"Delete"}
        cancelText={"Cancel"}
        handleConfirm={async () => {
          setElements((prev) => {
            if (!selectedElementId) {
              return prev;
            }
            const selectedElement = prev.find((e) => e.id == selectedElementId);
            if (!selectedElement) {
              // Deleting a field definition element
              findFieldDefinitionChildren(selectedElementId, prev).then(
                ({ child, fieldDefinition }) => {
                  if (!fieldDefinition || !child) {
                    return;
                  }
                  const childIndex = fieldDefinition.data.elements.findIndex(
                    (e) => e.id == child.id,
                  );
                  if (childIndex < 0) {
                    return;
                  }
                  fieldDefinition.data.elements.splice(childIndex, 1);
                  setElements((prev) => {
                    return prev.map((e) => {
                      if (e.type == FormElementType.FieldDefinition) {
                        return {
                          ...e,
                          elements: fieldDefinition.data.elements,
                        };
                      }
                      return e;
                    });
                  });
                  return FieldDefinitionAPI.update(fieldDefinition);
                },
              );
              return prev;
            }
            // Remove execution data
            const foundExecutionIndex = executionData.findIndex(
              (ex) => ex.id == selectedElementId,
            );
            executionData.splice(foundExecutionIndex, 1);

            let deleteCount = 1;
            forEachChildElement(elements, selectedElementId, (child) => {
              if (hideChildren[child.id]) {
                deleteCount++;
                // Remove execution data
                const foundExecutionIndex = executionData.findIndex(
                  (ex) => ex.id == child.id,
                );
                executionData.splice(foundExecutionIndex, 1);
              }
            });
            const indexOfFormChangeElement = prev.findIndex(
              (p) => p.id == selectedElementId,
            );
            prev.splice(indexOfFormChangeElement, deleteCount);
            updateFormTask(
              task,
              [...prev],
              elementId,
              elementKey,
              executionData,
            );
            return [...prev];
          });
          setShowDeleteDialog(false);
        }}
        handleCancel={() => {
          setShowDeleteDialog(false);
        }}
      >
        {selectedElementCount > 0
          ? `You are about to delete this heading and the ${selectedElementCount} form element(s) that it contains. Do you want to continue?`
          : `You are about to delete this form element. Do you want to continue?`}
      </Dialog>
    </FormBuilderContext.Provider>
  );
};

export const useFormBuilderContext = () => React.useContext(FormBuilderContext);
