import {
  DragEvent,
  DragEventHandler,
  PropsWithChildren,
  useState,
} from "react";
import { Box, BoxProps, SxProps, Theme } from "@mui/material";

type DropzoneProps = BoxProps & {
  onDrop: DragEventHandler<HTMLDivElement>;
  variant?: "obvious" | "subtle";
};
const OBVIOUS_STYLE: SxProps<Theme> = {
  border: "4px dashed lightgray",
  borderRadius: "20px",
  textAlign: "center",
  p: "2vh 2vw",
  width: "100%",
};

const Dropzone = ({
  onDrop,
  children,
  variant = "obvious",
  ...boxProps
}: PropsWithChildren<DropzoneProps>) => {
  const [dragActive, setDragActive] = useState(false);

  const handleDrag = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    onDrop(e);
  };

  return (
    <Box
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
      sx={{
        ...(variant === "obvious" ? OBVIOUS_STYLE : {}),
        backgroundColor: dragActive ? "lightgray" : "inherit",
      }}
      {...boxProps}
    >
      {children}
    </Box>
  );
};

export default Dropzone;
