import { Alert, Divider, Grid } from "@mui/material";
import {
  Task,
  TaskExecution,
  TaskExecutionComponentProps,
  TaskExecutionRenderProps,
  TaskInlineEditorProps,
  TaskSideSectionEditorProps,
  TaskView,
} from "../../types";
import { getTaskByType } from "./getTaskByType";
import { ErrorBoundary } from "react-error-boundary";
import ScopedProvider from "../../../../providers/ScopedProvider";
import DefaultSideSectionEditor from "../editor/DefaultSideSectionEditor";

const DEFAULT_RENDER = ({ actions, content }: TaskExecutionRenderProps) => {
  return (
    <>
      {content}
      {actions}
    </>
  );
};

export { DEFAULT_RENDER };

type TaskBodyVariant = "execution" | "inline" | "side-section";

type TaskBodyProps<
  V extends TaskBodyVariant,
  T extends Task,
  E extends TaskExecution,
> = {
  variant: V;
  view?: TaskView;
  overrideEditable?: boolean;
} & (V extends "execution"
  ? TaskExecutionComponentProps<T, E>
  : V extends "inline"
    ? TaskInlineEditorProps<T>
    : V extends "side-section"
      ? TaskSideSectionEditorProps<T>
      : never);

export const TaskBody = <
  V extends TaskBodyVariant,
  T extends Task,
  E extends TaskExecution,
>({
  variant,
  view = "condensed",
  ...props
}: TaskBodyProps<V, T, E>) => {
  if (!props.task.type) {
    return <></>;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let TaskBody: ((props: any) => JSX.Element) | undefined = undefined;
  switch (variant) {
    case "execution":
      TaskBody = getTaskByType(props.task.type)?.ExecutionComponent;
      break;
    case "inline":
      TaskBody = getTaskByType(props.task.type)?.InlineEditor;
      break;
    case "side-section":
      TaskBody =
        getTaskByType(props.task.type)?.SideSectionEditor ??
        DefaultSideSectionEditor;
      if (TaskBody) {
        return (
          <>
            <TaskBody view={view} {...props} />
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </>
        );
      }
      break;
  }
  if (!TaskBody) {
    return <></>;
  }

  return (
    <ErrorBoundary
      key={props.task.id}
      fallback={
        <Alert severity="warning" sx={{ width: "100%" }}>
          {`Something went wrong displaying ${props.task.name ?? "task"}`}
        </Alert>
      }
    >
      <ScopedProvider>
        <TaskBody view={view} {...props} />
      </ScopedProvider>
    </ErrorBoundary>
  );
};
