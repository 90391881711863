import { FC } from "react";
import { Tooltip, Chip } from "@mui/material";

type PhaseChipProps = {
  name: string;
  display: {
    fillColor?: string;
    fontColor?: string;
    borderColor?: string;
  };
};

const ProcessPhaseChipCell: FC<PhaseChipProps> = ({ name, display }) => {
  return (
    <Tooltip title={name}>
      <Chip
        label={name}
        sx={{
          mr: 4,
          width: 1,
          maxWidth: 200,
          backgroundColor: display?.fillColor ?? "#343434",
          borderColor: display?.borderColor ?? "transparent",
          color: display?.fontColor ?? "common.white",
          fontSize: (theme) => theme.typography.body1.fontSize,
          fontWeight: "bold",
        }}
      />
    </Tooltip>
  );
};

export default ProcessPhaseChipCell;
