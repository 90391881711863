import { Box, Button, FormLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { NestedFormElement, TaskForm } from "../../../types";
import PATHS from "../../../../../components/navigation/_paths";
import FormElementTaskDescription from "../FormElementTaskDescription";

export const NestedFormInlineDisplay = ({
  element,
  task,
}: {
  element: NestedFormElement;
  task: TaskForm;
}) => {
  const navigate = useNavigate();
  return (
    <Box component="section">
      <Box sx={{ mb: 1 }}>
        <FormLabel required={element.required}>{element.label}</FormLabel>
        <FormElementTaskDescription>
          {element.description}
        </FormElementTaskDescription>
      </Box>
      <Button
        variant="outlined"
        onClick={() => {
          navigate(
            PATHS.PROCESS_TASK_ELEMENT.linkTo(
              task.process_id,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              task.id!,
              element.id,
            ),
          );
        }}
      >
        {element.elements && element.elements.length > 0
          ? "View/Edit Form"
          : "Build Form"}
      </Button>
    </Box>
  );
};
