import {
  Task,
  TaskExecutionComponentProps,
  TaskProcessMonitorExecution,
} from "../../../types";
import ProcessExecutionProgressComponent from "./ProcessExecutionProgressComponent";

const ProcessMonitorExecutionComponent = (
  props: TaskExecutionComponentProps<Task, TaskProcessMonitorExecution>,
) => {
  const processExecutionId =
    props.taskExecution?.data?.targetProcessExecutionId;

  return (
    <ProcessExecutionProgressComponent
      processExecutionId={processExecutionId}
    />
  );
};

export default ProcessMonitorExecutionComponent;
