import { PropsWithChildren } from "react";

import {
  DndContext,
  closestCenter,
  DragEndEvent,
  DragStartEvent,
  useSensors,
  useSensor,
  PointerSensor,
  DragOverlay,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";

type DraggableListProps = {
  listItemIds: string[];
  handleDragEnd: (event: DragEndEvent) => void;
  handleDragStart?: (event: DragStartEvent) => void;
  overlayComponent?: React.ReactNode;
};

const DraggableList = (props: PropsWithChildren<DraggableListProps>) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToVerticalAxis]}
      collisionDetection={closestCenter}
      onDragEnd={props.handleDragEnd}
      onDragStart={props.handleDragStart}
    >
      <SortableContext
        items={props.listItemIds}
        strategy={verticalListSortingStrategy}
      >
        {props.children}
      </SortableContext>
      {props.overlayComponent && (
        <DragOverlay zIndex={10}>{props.overlayComponent}</DragOverlay>
      )}
    </DndContext>
  );
};
export default DraggableList;
