import { AddCircle } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { useIntl } from "react-intl";
import messages from "../messages";
import { useState } from "react";
import CreateProcessDialog from "./CreateProcessDialog";
import { Process, ProcessStatus } from "../../../model/Process";
import Processes from "../../../api/processes";
import { ACCOUNT_TYPE } from "../../../model/EntityRef";
import { useGlobalOrganizationContext } from "../../../hooks/useGlobalOrganizationContext";
import { useNavigate } from "react-router-dom";
import { useAlert } from "../../../lib/alert";
import PATHS from "../../../components/navigation/_paths";

export type ProcessListHeaderProps = {
  entityTypeId?: string;
};

const ProcessListActionBar = ({ entityTypeId }: ProcessListHeaderProps) => {
  const navigate = useNavigate();
  const { organization } = useGlobalOrganizationContext();
  const { info, handleRejectionWithError } = useAlert();
  const intl = useIntl();
  const process = intl.formatMessage(messages.process.props);
  const [processCreateDialogOpen, setProcessCreateDialogOpen] = useState(false);
  return (
    <>
      <Box display="flex" gap="24px">
        <Button
          data-cy="create-process-btn"
          variant="contained"
          onClick={() => setProcessCreateDialogOpen(true)}
        >
          <AddCircle sx={{ mr: "0.25em" }} />
          {`Create ${process}`}
        </Button>
      </Box>
      <CreateProcessDialog
        open={processCreateDialogOpen}
        showSubjectField={false}
        handleConfirm={function (process): Promise<void> {
          if (!organization) {
            info("You must create an organization before creating a process");
            return Promise.resolve();
          }

          const request: Partial<Process> = {
            ...process,
            org_id: organization.id,
            status: ProcessStatus.Unpublished,
          };

          return Processes.create(
            request as Process,
            entityTypeId
              ? {
                  entity_type: ACCOUNT_TYPE,
                  entity_id: entityTypeId,
                  counterparty_can_execute: true,
                }
              : undefined,
          ).then((result) => {
            navigate(PATHS.PROCESS.linkTo(result.id));
          }, handleRejectionWithError("Could not load process page"));
        }}
        handleCancel={function (): void {
          setProcessCreateDialogOpen(false);
        }}
      />
    </>
  );
};
export default ProcessListActionBar;
