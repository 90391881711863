import { Box, SxProps } from "@mui/material";
import { FC, PropsWithChildren } from "react";

const BasePanel: FC<PropsWithChildren<{ sx?: SxProps }>> = ({
  children,
  sx,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "75%",
        bgcolor: "white",
        p: "7.5%",
        borderRadius: "10px",
        boxShadow:
          "0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default BasePanel;
