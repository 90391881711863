import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Paper,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import { useContext } from "react";
import { AddCircleOutline } from "@mui/icons-material";
import {
  AccountSelectTaskContext,
  AccountSelectTaskOption,
} from "../AccountSelectWrapper";

type AccountSelectCondensedProps = {
  setSelected: (option: AccountSelectTaskOption) => void;
  setDialogOpen: (open: boolean) => void;
  disabled?: boolean;
  label?: string;
  showOrganizations?: boolean;
};

const OPTIONS_LIMIT = 10;
const filterOptions = createFilterOptions<AccountSelectTaskOption>({
  limit: OPTIONS_LIMIT,
  ignoreCase: true,
  ignoreAccents: false,
});

const AccountSelectCondensed = ({
  setSelected,
  setDialogOpen,
  label = "Select or Create Account",
}: AccountSelectCondensedProps) => {
  const { options, selected, disabled, query, setQuery } = useContext(
    AccountSelectTaskContext,
  );

  const CustomPaper = (props: object) => {
    return (
      <Paper sx={{ backgroundColor: "common.white" }}>
        <Box {...props} />
      </Paper>
    );
  };
  return (
    <>
      <Autocomplete
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={params.inputProps}
            label={label}
            disabled={disabled}
          />
        )}
        getOptionLabel={(option) => option.label}
        groupBy={(option) => option.type}
        renderGroup={(params) => {
          if (params.group === "organization") {
            return (
              <div key={params.group}>
                <Divider />
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "bold", mt: 1, ml: 1 }}
                >
                  External Organizations
                </Typography>
                {params.children}
              </div>
            );
          } else {
            return params.children;
          }
        }}
        renderOption={(props, option) => (
          <Typography {...props} variant="inherit">
            {option.label}
          </Typography>
        )}
        value={selected ?? null}
        inputValue={query}
        onInputChange={(_, newQuery) => {
          setQuery(newQuery);
        }}
        options={options}
        isOptionEqualToValue={(option1, option2) => {
          return (
            option1.type === option2.type && option1.data.id === option2.data.id
          );
        }}
        onChange={(_, option) => {
          if (option) {
            setSelected(option);
            setQuery(option.label);
          }
        }}
        noOptionsText={
          <Button
            onClick={() => {
              setDialogOpen(true);
            }}
            sx={{
              width: "100%",
            }}
          >
            <AddCircleOutline sx={{ mr: 1 }} />
            <Typography
              variant="body2"
              sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
            >
              {query && query.trim().length > 0
                ? `Create New Account: ${query?.trim()}`
                : `Create a new Account`}
            </Typography>
          </Button>
        }
        filterOptions={filterOptions}
        PaperComponent={CustomPaper}
      />
    </>
  );
};

export default AccountSelectCondensed;
