import { Button, Checkbox, FormControlLabel, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { TaskForm, TaskSideSectionEditorProps } from "../../../types";
import PATHS from "../../../../../components/navigation/_paths";
import { useTaskContext } from "../../../hooks";

const FormSideSectionEditor = ({
  task,
}: TaskSideSectionEditorProps<TaskForm>) => {
  const navigate = useNavigate();
  const { setValue } = useTaskContext();
  return (
    <>
      <Grid item xs={12}>
        <FormControlLabel
          control={<Checkbox checked={!!task.data?.autofill} />}
          onChange={(event: React.BaseSyntheticEvent) => {
            setValue({
              data: {
                ...task.data,
                autofill: event.target.checked ? true : undefined,
              },
            });
          }}
          label="Auto-fill with execution data"
        />
        <FormControlLabel
          control={<Checkbox checked={!!task.data?.processWithAi} />}
          onChange={(event: React.BaseSyntheticEvent) => {
            setValue({
              data: {
                ...task.data,
                processWithAi: event.target.checked ? true : undefined,
              },
            });
          }}
          label="Invoke Process from this task with Copilot"
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          fullWidth
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            navigate(PATHS.PROCESS_TASK.linkTo(task.process_id, task.id!));
          }}
        >
          {task.data?.form && task.data?.form?.elements.length > 0
            ? "View/Edit Form"
            : "Build Form"}
        </Button>
      </Grid>
    </>
  );
};

export default FormSideSectionEditor;
