import { atom } from "jotai";
import { DragEventHandler } from "react";

const onDropAtom = atom<{ fn: DragEventHandler<HTMLDivElement> }>({
  fn: () => {
    console.log("Drop not handled.");
  },
});
export default atom<
  DragEventHandler<HTMLDivElement>,
  [DragEventHandler<HTMLDivElement>],
  void
>(
  (get) => get(onDropAtom).fn,
  (_get, set, newOnDrop) => set(onDropAtom, { fn: newOnDrop }),
);
