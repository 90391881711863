import { FC, PropsWithChildren, useState } from "react";
import { Box, Modal } from "@mui/material";

type LightBoxProps = {
  src: string;
  alt: string;
};

const LightBox: FC<PropsWithChildren<LightBoxProps>> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Box
      sx={{
        maxHeight: { xs: 233, md: 167 },
        width: { xs: 350, md: 250 },
      }}
    >
      <Box
        component="img"
        sx={{
          height: "auto",
          width: "auto",
          maxHeight: { xs: 233, md: 167 },
          maxWidth: { xs: 350, md: 250 },
        }}
        onClick={toggleIsOpen}
        src={props.src}
        alt={props.alt}
      />
      <Modal open={isOpen}>
        <Box
          onClick={toggleIsOpen}
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            component="img"
            sx={{
              height: "100vh",
              width: "100vw",
              padding: 5,
              objectFit: "contain",
            }}
            src={props.src}
            alt={props.alt}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default LightBox;
