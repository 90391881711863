import ReactQuill from "./ReactQuill";
import { styled } from "@mui/material";
import useToggle from "../../../hooks/useToggle";
import { useEffect, useState } from "react";

type ReactQuillViewerProps = {
  value: string;
  className?: string;
  variant?: "regular" | "small";
  collapseHeight?: number;
};

const modules = {
  clipboard: {
    matchVisual: false,
  },
};

const ReactQuillViewer = styled(
  ({
    value,
    className,
  }: ReactQuillViewerProps & {
    isReadMore: boolean;
    collapseHeight?: number;
    show: boolean;
  }) => {
    return (
      <ReactQuill
        readOnly={true}
        value={value}
        className={className}
        modules={modules}
      />
    );
  },
)(({ isReadMore, show, collapseHeight = 70, variant = "small" }) => {
  const editorProps =
    variant === "small"
      ? {}
      : {
          paddingLeft: "0px",
          paddingRight: "0px",
          paddingTop: "3px",
          paddingBottom: "3px",
        };
  const paraProps =
    variant === "small"
      ? {}
      : {
          fontSize: "1rem",
          lineHeight: "1.5rem",
          fontFamily: "Roboto",
        };
  return {
    "& .ql-editor":
      isReadMore && show
        ? {
            overflow: "hidden",
            display: "-webkit-box",
            maxHeight: `${collapseHeight}px`,
            WebkitBoxOrient: "vertical",
            ...editorProps,
          }
        : editorProps,
    "& p": paraProps,
  };
});

const ReactQuillViewerComplete = ({
  value,
  variant,
  collapseHeight = 80,
}: ReactQuillViewerProps) => {
  const [isReadMore, toggleReadMore] = useToggle(true);
  const [height, setHeight] = useState(0);
  useEffect(() => {
    if (height === 0 || !isReadMore) {
      Array.from(document.getElementsByClassName("ql-editor")).forEach(
        (editor) => {
          if (
            editor.parentElement?.className.includes("ql-bubble") &&
            editor.innerHTML == value
          ) {
            setHeight(editor.scrollHeight);
          }
        },
      );
    }
  });
  const showReadMore =
    collapseHeight === -1
      ? false
      : height && height > collapseHeight
        ? true
        : false;

  return (
    <>
      <ReactQuillViewer
        value={value}
        isReadMore={isReadMore}
        show={showReadMore}
        variant={variant}
        collapseHeight={collapseHeight - 16}
      />
      {showReadMore ? (
        <span
          style={{
            display: "inline-block",
            marginLeft: "16px",
            marginBottom: "12px",
            textDecoration: "underline",
          }}
          onClick={toggleReadMore}
        >
          {isReadMore ? "Read more" : "Show less"}
        </span>
      ) : null}
    </>
  );
};

export default ReactQuillViewerComplete;
