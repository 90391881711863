import { useCallback, useEffect, useMemo, useState } from "react";
import { Step } from "../../../model/step";
import { Process } from "../../../model/Process";
import { ProcessExecution } from "../../../model/ProcessExecution";
import {
  Task,
  TaskExecution,
  TaskExecutionNotificationEventsTemp,
  TaskExecutionState,
} from "../../task";
import { AssignedRoleUserDto } from "../../../types";
import ProcessExecutions from "../../../api/process_executions";
import Processes from "../../../api/processes";
import { useGlobalOrganizationContext } from "../../../hooks/useGlobalOrganizationContext";
import { environment } from "../../../util";
import { useAlert } from "../../../lib/alert";
import { User } from "../../../model";
import { currentUserId } from "../../../lib/auth";
import {
  OrganizationAPI,
  ProcessExecutionAPI,
  PublicProcessExecutionAPI,
} from "../../../api";
import { atom } from "jotai";
import { createTaskExecution } from "../../task/components/task-types/getTaskByType";

export enum ProcessExecutionViewState {
  Loading,
  Loaded,
  NotFound,
}

export enum ProcessExecutionViewMode {
  List,
  Focused,
}

export const processExecutionViewModeAtom = atom<ProcessExecutionViewMode>(
  ProcessExecutionViewMode.Focused,
);

const useProcessExecutionObjects = (isPublic?: {
  publicExecutorId: string;
}) => {
  const { organization } = useGlobalOrganizationContext();
  const [processExecution, setProcessExecution] = useState<ProcessExecution>();
  const [stepExecutions, setStepExecutions] = useState<Array<ProcessExecution>>(
    [],
  );
  const [taskExecutions, setTaskExecutions] = useState<
    Array<TaskExecution | undefined>
  >([]);
  const [currentTaskExecution, setCurrentTaskExecution] =
    useState<TaskExecution>();

  const [selectedTaskExecution, setSelectedTaskExecution] = useState<
    TaskExecution | undefined
  >();

  const getProcessExecutionPromise = useCallback(
    (processExecutionId: string) => {
      if (isPublic) {
        return PublicProcessExecutionAPI.getProcessExecutionByPublicExecutorIdAndProcessExecutionId(
          isPublic.publicExecutorId,
          organization?.id ?? "",
          processExecutionId,
        );
      }
      return ProcessExecutions.getDto(processExecutionId);
    },
    [isPublic, organization?.id],
  );

  const getStepExecutions = useCallback(
    (processExecutionId: string) => {
      if (isPublic) {
        return Promise.resolve([]);
      }
      return ProcessExecutions.getStepExecutions(processExecutionId);
    },
    [isPublic],
  );

  const getTasksAndExecutions = useCallback(
    async (processExecution: ProcessExecution) => {
      if (isPublic) {
        return await PublicProcessExecutionAPI.getProcessExecutionTasksByPublicExecutorIdAndProcessExecutionId(
          isPublic.publicExecutorId,
          organization?.id ?? "",
          processExecution.id,
        ).then<[TaskExecution[], Task[]]>((taskExecutions) => {
          const tasks: Task[] = taskExecutions.map(
            (taskExecution) => taskExecution.task!,
          );
          return [taskExecutions, tasks];
        });
      }
      return await Promise.all([
        ProcessExecutions.getTaskExecutions(processExecution.id),
        Processes.getTasks(processExecution.process_id),
      ]);
    },
    [isPublic, organization?.id],
  );

  const fetchProcessExecutionObjects = useCallback(
    async (processExecutionId: string) => {
      const processExecution = await getProcessExecutionPromise(
        processExecutionId,
      ).catch((e) => {
        const errorMessage = `Error loading process execution ${processExecutionId}`;
        throw new Error(errorMessage, { cause: e });
      });
      const stepExecutions = await getStepExecutions(processExecutionId).catch(
        (e) => {
          const errorMessage = `Error occurred fetching steps for ${processExecutionId}`;
          throw new Error(errorMessage, { cause: e });
        },
      );
      const [taskExecutions, tasks] = await getTasksAndExecutions(
        processExecution,
      ).catch((e) => {
        const errorMessage = `Error occurred fetching task executions for ${processExecutionId}`;
        throw new Error(errorMessage, { cause: e });
      });

      const orderedTaskExecutions = tasks.map((t) =>
        taskExecutions.find((te) => t.id == te.task_id),
      );

      // Check if the process execution's next task is visible for the current user
      const canViewNextTask =
        !processExecution.next_task_id ||
        !!orderedTaskExecutions.find((te) => {
          return te?.task?.id === processExecution.next_task_id;
        });

      // If the next task is not visible add `undefined` after the last finished task execution
      if (!canViewNextTask) {
        const lastFinishedTaskIndex = [...orderedTaskExecutions].reduce(
          (prev, current, index) => {
            if (current?.state != TaskExecutionState.InProgress) {
              return index;
            }
            return prev;
          },
          -1,
        );
        orderedTaskExecutions.splice(lastFinishedTaskIndex + 1, 0, undefined);
      }
      setProcessExecution(processExecution);
      setStepExecutions(stepExecutions);
      setTaskExecutions(orderedTaskExecutions);
    },
    [getProcessExecutionPromise, getStepExecutions, getTasksAndExecutions],
  );

  const getContextTaskExecution = useCallback(
    (taskExecution?: TaskExecution) => {
      let found = false;
      return [...taskExecutions].reverse().find((tEx) => {
        if (!found) {
          if (taskExecution) {
            found = tEx?.id == taskExecution.id;
            return false;
          } else {
            found = tEx?.state != TaskExecutionState.InProgress;
            if (!found) return false;
          }
        }
        return (
          (tEx?.state === TaskExecutionState.Completed ||
            tEx?.task?.next_task_id === currentTaskExecution?.task_id) &&
          tEx?.task?.is_context
        );
      });
    },
    [currentTaskExecution?.task_id, taskExecutions],
  );

  // This wrapper function helps handle cases where we want to unset the selected task execution
  const selectedTaskExecutionWrapper: typeof selectedTaskExecution =
    useMemo(() => {
      let toReturn = selectedTaskExecution;
      // If we are trying to unset the selected task execution then attempt to use the current task execution
      if (!selectedTaskExecution) {
        toReturn = currentTaskExecution;
      }
      // If there is no selected task and the task is done return the last task as selected
      if (
        !toReturn &&
        !processExecution?.next_task_id &&
        taskExecutions.length > 0
      ) {
        toReturn = taskExecutions.at(-1);
      }
      return toReturn;
    }, [
      currentTaskExecution,
      processExecution?.next_task_id,
      selectedTaskExecution,
      taskExecutions,
    ]);

  useEffect(() => {
    setCurrentTaskExecution(
      taskExecutions.find(
        (execution) => execution?.task_id === processExecution?.next_task_id,
      ),
    );
    setSelectedTaskExecution((prev) => {
      return taskExecutions.find((execution) => execution?.id === prev?.id);
    });
  }, [
    processExecution?.next_task_id,
    taskExecutions,
    setSelectedTaskExecution,
  ]);

  return {
    processExecution,
    stepExecutions,
    taskExecutions,
    fetchProcessExecutionObjects,
    currentTaskExecution,
    selectedTaskExecution: selectedTaskExecutionWrapper,
    setSelectedTaskExecution,
    getContextTaskExecution,
  };
};

const useProcessObjects = () => {
  const [process, setProcess] = useState<Process>();
  const [steps, setSteps] = useState<Array<Step>>([]);
  const fetchProcessObjects = useCallback(async (processId?: string) => {
    if (!processId) return;
    const process = await Processes.get(processId);
    const steps = await Processes.getSteps(processId);
    setProcess(process);
    setSteps(steps);
  }, []);
  return {
    process,
    steps,
    fetchProcessObjects,
  };
};

const useProcessExecution = (
  processExecutionId: string,
  isPublicTemp?: {
    publicExecutorId: string;
  },
) => {
  // adding dependencies here causes an infinite loop???
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isPublic = useMemo(() => isPublicTemp, []);
  const { error, info } = useAlert();
  const { organization, setOrganization } = useGlobalOrganizationContext();
  const {
    fetchProcessExecutionObjects,
    processExecution,
    stepExecutions,
    taskExecutions,
    currentTaskExecution,
    selectedTaskExecution,
    setSelectedTaskExecution,
    getContextTaskExecution,
  } = useProcessExecutionObjects(isPublic);
  const { fetchProcessObjects, process, steps } = useProcessObjects();
  const [viewState, setViewState] = useState<ProcessExecutionViewState>(
    ProcessExecutionViewState.Loading,
  );
  const [notificationEvents, setNotificationEvents] =
    useState<TaskExecutionNotificationEventsTemp>();

  const [assignedRoleUsers, setAssignedRoleUsers] = useState<
    Array<AssignedRoleUserDto>
  >([]);

  // Fetch process execution objects
  useEffect(() => {
    fetchProcessExecutionObjects(processExecutionId)
      .then(() => {
        setViewState(ProcessExecutionViewState.Loaded);
      })
      .catch(() => {
        setViewState(ProcessExecutionViewState.NotFound);
      });
  }, [fetchProcessExecutionObjects, processExecutionId]);

  // fetch process objects
  useEffect(() => {
    if (!isPublic) {
      fetchProcessObjects(processExecution?.process_id);
    }
  }, [fetchProcessObjects, isPublic, processExecution?.process_id]);

  // Force reload
  const reload = useCallback(async () => {
    await fetchProcessExecutionObjects(processExecutionId).catch(() => {
      setViewState(ProcessExecutionViewState.NotFound);
    });
    if (!isPublic) {
      await fetchProcessObjects(processExecution?.process_id);
    }
  }, [
    fetchProcessExecutionObjects,
    fetchProcessObjects,
    isPublic,
    processExecution?.process_id,
    processExecutionId,
  ]);

  // Set organization
  useEffect(() => {
    if (environment.organization?.key || !processExecution?.org_id) return;
    OrganizationAPI.byMemberOrAccountUser(currentUserId()).then(
      (organizations) => {
        const org = organizations.find(
          (org) => org.id === processExecution.org_id,
        );
        if (org) {
          setOrganization(org);
        }
      },
    );
  }, [processExecution?.org_id, setOrganization]);

  // Fetch assigned roles for user
  useEffect(() => {
    const fetchAssignedRoles = async () => {
      try {
        setAssignedRoleUsers(
          await ProcessExecutions.getAssignedRoleUsers(processExecutionId),
        );
      } catch (e) {
        console.error("Unable to fetch assigned role users", e);
        error("Unable to fetch assigned roles");
      }
    };
    if (!isPublic) {
      fetchAssignedRoles();
    }
  }, [error, isPublic, processExecutionId]);

  const submit = useCallback(
    (execution: TaskExecution) => {
      if (isPublic) {
        return PublicProcessExecutionAPI.execute(
          isPublic.publicExecutorId,
          [
            createTaskExecution({
              ...execution.task!,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              data: execution.data as any,
            }),
          ],
          organization?.id ?? "",
          undefined,
          processExecutionId,
        );
      }
      return ProcessExecutions.submitTask(
        processExecutionId,
        execution.task_id!,
        execution,
      );
    },
    [isPublic, organization?.id, processExecutionId],
  );

  const executeTask = useCallback(
    async (execution: TaskExecution, completeTask: boolean = true) => {
      if (!execution.task_id) {
        error(
          `Failed to submit task: missing task_id for task ${execution.id}`,
        );
        return;
      }
      try {
        await submit(execution).catch((e) => {
          const errorMessage = "Failed to complete task";
          error(errorMessage);
          throw new Error(errorMessage, { cause: e });
        });
        if (completeTask && !isPublic) {
          await ProcessExecutions.completeTask(
            processExecutionId,
            execution.task_id,
          );
          info("Successfully submitted task");
          setSelectedTaskExecution(undefined);
        }
      } finally {
        await reload();
      }
    },
    [
      error,
      info,
      isPublic,
      processExecutionId,
      reload,
      setSelectedTaskExecution,
      submit,
    ],
  );

  const getAssignedUser = useCallback(
    (execution?: TaskExecution) => {
      let assignedUser: User | undefined;
      assignedRoleUsers.map((roleAndUser) => {
        const currentUser = roleAndUser.user;
        if (currentUser.id === execution?.assigned_to_user_id) {
          assignedUser = currentUser;
        }
      });
      return assignedUser;
    },
    [assignedRoleUsers],
  );

  const isLocked = useCallback(
    async (execution: TaskExecution) => {
      // TODO: This check does not work with accounts...
      // const canUserExecute = async (execution: TaskExecution) => {
      //   const assignedUser = getAssignedUser(execution);
      //   if (assignedUser?.email && assignedUser.email === user?.email) {
      //     return true;
      //   }
      //   if (!execution.task?.role || execution.task?.role.id === "public") {
      //     return true;
      //   }
      //   const userRoles = await Roles.byUserIdAndOrganizationId(
      //     currentUserId(),
      //     process?.org_id ?? "",
      //   );
      //   return userRoles?.some((e) => e.id === execution.task?.role?.id);
      // };

      return (
        execution.state == TaskExecutionState.InProgress &&
        !(currentTaskExecution?.id == execution.id)
      );
    },
    [currentTaskExecution?.id],
  );

  const pollNotificationEvents = useCallback(() => {
    if (!isPublic) {
      const intervalInSeconds = 5;
      const pollFunction = async () => {
        if (processExecution) {
          const res =
            await ProcessExecutionAPI.getCurrentTaskNotificationEvents(
              processExecution.id,
            );
          setNotificationEvents(res);
        }
      };
      pollFunction(); // Initial request
      return setInterval(pollFunction, intervalInSeconds * 1000);
    }
  }, [isPublic, processExecution]);

  return {
    assignedRoleUsers,
    executeTask,
    processExecution,
    process,
    getContextTaskExecution,
    currentTaskExecution,
    getAssignedUser,
    steps,
    stepExecutions,
    taskExecutions,
    reload,
    viewState,
    selectedTaskExecution,
    setSelectedTaskExecution,
    isLocked,
    pollNotificationEvents,
    notificationEvents,
  };
};

export type ProcessExecutionContextType = ReturnType<
  typeof useProcessExecution
>;
export default useProcessExecution;
