import { CompleteUnstructuredInlineEditor, UnstructuredTypes } from ".";
import { Task, TaskInlineEditorProps } from "../../../types";
import CreateOrganizationInlineEditor from "./create-organization/CreateOrganizationInlineEditor";
import RegisterInlineEditor from "./register/RegisterInlineEditor";
import StripeCheckoutInlineEditor from "./stripe-checkout/StripeCheckoutInlineEditor";

const UnstructuredInlineEditor = ({ task }: TaskInlineEditorProps<Task>) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data = task.data as any;
  switch (data?.type) {
    case UnstructuredTypes.CreateOrganization:
      return <CreateOrganizationInlineEditor />;
    case UnstructuredTypes.Register:
      return <RegisterInlineEditor />;
    case UnstructuredTypes.StripeCheckout:
      return <StripeCheckoutInlineEditor />;
    default:
      return <CompleteUnstructuredInlineEditor />;
  }
};

export default UnstructuredInlineEditor;
