import { useParams } from "react-router-dom";
import OrganizationCategoryPublicProcessCards from "./OrganizationCategoryPublicProcessCards";

const OrganizationCategoryPublicProcessPage = () => {
  const { categoryId } = useParams<{ categoryId: string }>();
  if (!categoryId) {
    return <></>;
  }
  return (
    <OrganizationCategoryPublicProcessCards
      categoryId={categoryId}
    ></OrganizationCategoryPublicProcessCards>
  );
};
export default OrganizationCategoryPublicProcessPage;
