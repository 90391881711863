import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { IconSelector } from "../../../../components/core/IconPicker";
import { Step } from "../../../../model/step";

export type EditStepDialogProps = {
  step: Step;
  open: boolean;
  onClose: () => void;
  onSave: (step: Step) => Promise<void>;
};

const EditStepDialog = ({
  open,
  onClose,
  onSave,
  step,
}: EditStepDialogProps) => {
  const [updatedStep, setUpdatedStep] = useState<Step>({ ...step });
  const [processing, setProcessing] = useState(false);
  useEffect(() => {
    setUpdatedStep(step);
  }, [step]);
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        {step.name ? `Edit Step - ${step.name}` : "Edit Step"}
      </DialogTitle>
      <DialogContent>
        {processing && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {!processing && (
          <Grid container gap={2}>
            <TextField
              autoFocus
              fullWidth
              id="step-title"
              label="Title"
              variant="standard"
              value={updatedStep.name}
              onChange={(e) =>
                setUpdatedStep((step) => ({ ...step, name: e.target.value }))
              }
            />
            <IconSelector
              defaultIcon={updatedStep.icon}
              onChange={(iconName) => {
                setUpdatedStep((step) => ({ ...step, icon: iconName }));
              }}
            />
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={processing}>
          {"Cancel"}
        </Button>
        <Button
          onClick={async () => {
            setProcessing(true);
            await onSave(updatedStep);
            setProcessing(false);
            onClose();
          }}
          disabled={processing}
        >
          {"Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default EditStepDialog;
