import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const Scrollable = styled(Box)(() => ({
  overflow: "auto", // Scroll overflow content
  "@media print": {
    overflow: "visible", // Make all content visible for printing
  },
}));
export default Scrollable;
