import { AccountDto } from "../../../model/Account";

export const getAccountName = (account?: Partial<AccountDto>) => {
  if (!account) return "";
  return `${
    account.account_name
      ? account.account_name
      : account.user_first_name && account.user_last_name
        ? `${account.user_first_name} ${account.user_last_name}`
        : account.primary_email
  }`;
};
