import { Box, Button, Typography } from "@mui/material";
import { FC, useContext } from "react";
import BasePanel from "./BasePanel";
import { ManageInvitationPageContext } from "../ManageInvitationPage";

type ManageInvitationPanelProps = {
  onAccept: () => void;
  onIgnore: () => void;
};

const ManageInvitationPanel: FC<ManageInvitationPanelProps> = ({
  onAccept,
  onIgnore,
}) => {
  const { invitation } = useContext(ManageInvitationPageContext);

  return (
    <BasePanel>
      {invitation && (
        <>
          <Typography variant="h2" sx={{ mb: 2 }}>
            Hello!
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ color: (theme) => theme.palette.grey[700] }}
          >
            {`${invitation.display?.creator_name?.trim() || "A user"} from ${
              invitation.display?.organization_name?.trim() ||
              "this organization"
            } has invited you to collaborate. Use the Accept Invitation button below to get started.`}
          </Typography>
          <Box
            sx={{
              my: "5%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Button
              variant="contained"
              sx={{
                bgcolor: (theme) => theme.palette.grey[300],
                color: (theme) => theme.palette.grey[700],
                "&:hover": {
                  bgcolor: (theme) => theme.palette.grey[400],
                },
                fontWeight: "bold",
                textTransform: "none",
              }}
              onClick={onIgnore}
            >
              Ignore Invitation
            </Button>
            <Button
              variant="contained"
              sx={{ fontWeight: "bold", textTransform: "none" }}
              onClick={onAccept}
            >
              Accept Invitation
            </Button>
          </Box>
        </>
      )}
    </BasePanel>
  );
};

export default ManageInvitationPanel;
