import { Button, Divider, Grid, Popover, Typography } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import { DatePicker } from "../../../components";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { grey } from "@mui/material/colors";
import { CalendarToday } from "@mui/icons-material";
import { useAlert } from "../../../lib/alert";

type DashboardDatePickerProps = {
  dateRange: { start?: Date; end?: Date };
  setDateRange: (
    fn: (prev: { start?: Date; end?: Date }) => { start?: Date; end?: Date },
  ) => void;
};

const DashboardDatePicker: FC<DashboardDatePickerProps> = ({
  dateRange,
  setDateRange,
}) => {
  const { error } = useAlert();

  const [open, setOpen] = useState(false);
  const [editingField, setEditingField] = useState<"start" | "end">("start");
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLButtonElement | null>(
    null,
  );
  const startButtonRef = useRef<HTMLButtonElement | null>(null);
  const endButtonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    setPopoverAnchor(
      editingField == "start" ? startButtonRef.current : endButtonRef.current,
    );
  }, [editingField]);

  const DatePickButton = (type: "start" | "end") => {
    return (
      <Button
        ref={type == "start" ? startButtonRef : endButtonRef}
        variant="outlined"
        onClick={() => {
          if (editingField == type) {
            setOpen(!open);
          } else {
            setEditingField(type);
            setOpen(true);
          }
        }}
        sx={{
          width: "9em",
          px: 1,
          borderColor: grey[600],
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ color: grey[800] }}>
          {type == "start"
            ? dateRange.start && dateRange.start.toLocaleDateString()
            : dateRange.end && dateRange.end.toLocaleDateString()}
        </Typography>

        <CalendarToday fontSize="small" sx={{ color: grey[600] }} />
      </Button>
    );
  };

  return (
    <>
      <Grid container justifyContent={"flex-end"} sx={{ px: 2 }}>
        {DatePickButton("start")}
        <Typography sx={{ lineHeight: 2.25, px: 1, color: grey[800] }}>
          —
        </Typography>
        {DatePickButton("end")}
      </Grid>
      <Popover
        open={open}
        anchorEl={popoverAnchor}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setOpen(false)}
      >
        <Typography
          sx={{
            fontSize: "1.1em",
            fontWeight: "bold",
            textAlign: "center",
            pt: 2,
            pb: 1,
          }}
        >
          {editingField[0].toUpperCase() + editingField.slice(1)} Date
        </Typography>
        <Divider variant="middle" />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            onChange={(value) => {
              if (!value || !value[0]) return;
              const inputDate = new Date(value[0]);
              switch (editingField) {
                case "start":
                  if (
                    dateRange.end &&
                    inputDate.valueOf() > dateRange.end.valueOf()
                  ) {
                    error("Start date cannot be after end date");
                    return;
                  }
                  setDateRange((prev) => ({
                    start: inputDate,
                    end: prev.end,
                  }));
                  break;
                case "end":
                  if (
                    dateRange.start &&
                    inputDate.valueOf() < dateRange.start.valueOf()
                  ) {
                    error("End date cannot be after start date");
                    return;
                  }
                  setDateRange((prev) => ({
                    start: prev.start,
                    end: inputDate,
                  }));
                  break;
              }
              // if choosing a start date and there is no end date, move to
              // selecting an end date otherwise just close
              if (editingField == "start" && !dateRange.end) {
                setEditingField("end");
              } else {
                setOpen(false);
              }
            }}
            values={
              editingField == "start" && dateRange.start
                ? [dateRange.start.toLocaleDateString()]
                : dateRange.end
                  ? [dateRange.end.toLocaleDateString()]
                  : []
            }
          />
          <Button
            sx={{ width: 1, mb: 1, mt: -5 }}
            onClick={() => {
              switch (editingField) {
                case "start":
                  setDateRange((prev) => ({
                    start: undefined,
                    end: prev.end,
                  }));
                  break;
                case "end":
                  setDateRange((prev) => ({
                    start: prev.start,
                    end: undefined,
                  }));
                  break;
              }
              setOpen(false);
            }}
          >
            Clear {editingField.toUpperCase()} Date
          </Button>
        </LocalizationProvider>
      </Popover>
    </>
  );
};

export default DashboardDatePicker;
