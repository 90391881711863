import { Add } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import { InvitationAPI, RoleAPI } from "../../../../api";
import { UserOrInvitedUser } from "../../../../components/core/Selection/UserSelection";
import { useAlert } from "../../../../lib/alert";
import { Role } from "../../../../model";
import RoleCreationDialog from "../../../process/components/process-details-editor/RoleCreationDialog";
import { useProcessRoleContext } from "../../../process/hooks";
import RoleSelect from "../../../role/RoleSelect";

type ProcessBuilderRoleSelectType = {
  onChange: (role: Role) => void;
  value?: Role;
  label?: string;
};

export const ProcessBuilderRoleSelect = ({
  onChange,
  value,
  label,
}: ProcessBuilderRoleSelectType) => {
  const roleContext = useProcessRoleContext();
  const { error, success } = useAlert();
  const [roleDialogOpen, setRoleDialogOpen] = useState<boolean>(false);
  const [newRoleName, setNewRoleName] = useState<string>("");
  const [selectInputValue, setSelectInputValue] = useState("");

  const addRoleToOrg = async (
    name: string,
    selectedUsers: Partial<UserOrInvitedUser>[],
  ) => {
    const newRole = new Role();
    newRole.name = name;
    newRole.org_id = roleContext.orgId;

    try {
      const role = await RoleAPI.create(newRole);
      Promise.all(
        selectedUsers.map((user) => {
          if (user.invitationId) {
            return InvitationAPI.assignRole(role.id, user.invitationId);
          } else if (user.id) {
            return RoleAPI.assignRole(role.id, user.id);
          }
        }),
      );
      roleContext.fetchRoles(roleContext.orgId);
      onChange(role);
      success("Role successfully created");
    } catch {
      error(`New role was not created successfully: Role already exists`);
    }
  };

  const AddNewButton = () => {
    return (
      <Box sx={{ display: "flex" }}>
        <Button
          variant="outlined"
          fullWidth
          startIcon={<Add />}
          onMouseDown={() => {
            setNewRoleName(selectInputValue);
            setRoleDialogOpen(true);
          }}
          sx={{
            m: 1,
            textTransform: "unset",
          }}
        >
          <Typography variant="body2">
            {"Create "}
            <Typography
              component="span"
              variant="body2"
              sx={{ fontWeight: "bold" }}
            >
              {selectInputValue.length > 0 ? selectInputValue : "New"}
            </Typography>
            {" Role"}
          </Typography>
        </Button>
      </Box>
    );
  };

  return (
    <>
      <RoleSelect
        roles={roleContext.roles}
        onChange={onChange}
        onInputChange={(event, newValue) => {
          setSelectInputValue(newValue);
        }}
        value={value}
        label={label}
        paperComponent={<AddNewButton />}
      ></RoleSelect>
      <RoleCreationDialog
        open={roleDialogOpen}
        roleName={newRoleName}
        organizationId={roleContext.orgId}
        handleConfirm={async (roleName, selectedUsers) => {
          const cleanedName = roleName.toString().trim();
          addRoleToOrg(cleanedName, selectedUsers);
          setRoleDialogOpen(false);
        }}
        handleCancel={() => {
          setRoleDialogOpen(false);
        }}
        setNewRoleName={setNewRoleName}
      ></RoleCreationDialog>
    </>
  );
};

export default ProcessBuilderRoleSelect;
