import { useState, useEffect, useCallback } from "react";
import {
  List,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";
import ExecutorItem from "./ExecutorItem";
import {
  Executor,
  ExecutorType,
} from "../../../features/task/components/execution/types/ExecutorModel";
import { ExpandMore } from "@mui/icons-material";
import { ExecutorAPI, ProcessAPI } from "../../../api";
import { useAlert } from "../../../lib/alert";
import { v4 as uuid } from "uuid";

type ProcessExecutorDetailsEditComponentProps = {
  processId: string;
};

const ProcessExecutorDetailsEditComponent = (
  props: ProcessExecutorDetailsEditComponentProps,
) => {
  const { handleRejectionWithError } = useAlert();

  // State for whether or not the executor pane is expanded
  const [expanded, setExpanded] = useState<boolean>(false);

  // State for loading
  const [loading, setLoading] = useState<boolean>(false);

  // State for holding the list of executors
  const [executors, setExecutors] = useState<Executor[]>([]);

  const fetchLatest = useCallback(async () => {
    let currentLatest;

    try {
      const process = await ProcessAPI.get(props.processId);

      if (process.meta) {
        const latestProcess = await ProcessAPI.fetchLatest(process.meta);
        if (latestProcess) {
          currentLatest = latestProcess.id;
        }
      }
    } catch (err) {
      console.error(err);
    }

    if (!currentLatest) {
      currentLatest = props.processId;
    }

    return currentLatest;
  }, [props.processId]);

  const reload = useCallback(async () => {
    if (!expanded) {
      return;
    }

    setLoading(true);

    // Get all of the existing executors for this process
    ExecutorAPI.byProcessId(await fetchLatest()).then((executors) => {
      setExecutors(executors);
      setLoading(false);
    }, handleRejectionWithError("Failed to fetch executors for process"));
  }, [expanded, handleRejectionWithError, fetchLatest]);

  useEffect(() => {
    reload();
  }, [reload]);

  const createExecutor = useCallback(async () => {
    const latest = await fetchLatest();

    const executor: Executor = {
      id: uuid(),
      executor_type: ExecutorType.Public,
      process_id: latest,
    };

    ExecutorAPI.create(latest, executor).then(() => {
      reload();
    }, handleRejectionWithError("Failed to create executor"));
  }, [handleRejectionWithError, reload, fetchLatest]);

  const deleteExecutor = useCallback(
    async (executor: Executor) => {
      const latest = await fetchLatest();
      if (!expanded) {
        return;
      }

      ExecutorAPI.remove(latest, executor.id).then(() => {
        reload();
      }, handleRejectionWithError("Failed to delete executor"));
    },
    [expanded, reload, handleRejectionWithError, fetchLatest],
  );

  const updateExecutor = useCallback(
    (executorId: string, executor: Executor) => {
      if (!expanded) {
        return;
      }

      ExecutorAPI.update(executorId, executor).then(() => {
        setExecutors(
          executors.map((e) => {
            if (e.id === executorId) {
              return executor;
            }

            return e;
          }),
        );
      }, handleRejectionWithError("Failed to update executor"));
    },
    [executors, handleRejectionWithError, expanded],
  );

  if (!props.processId) {
    return <></>;
  }

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography>Executors</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {loading ? (
          <CircularProgress />
        ) : (
          <Box>
            <List>
              {executors.map((executor: Executor) => (
                <ExecutorItem
                  executor={executor}
                  onDelete={deleteExecutor}
                  onUpdate={updateExecutor}
                  key={executor.id}
                />
              ))}
            </List>
            <Button
              variant="contained"
              color="primary"
              onClick={createExecutor}
            >
              Add Executor
            </Button>
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default ProcessExecutorDetailsEditComponent;
