import { Box, Button, Typography } from "@mui/material";
import { FC } from "react";
import { HeadingFormElement } from "../../../../types";
import { v4 as uuid } from "uuid";

type AnchorSectionLinkComponentType = {
  headerLinks: Array<HeadingFormElement>;
};

type AnchorLink = {
  header: HeadingFormElement;
};

const AnchorLink: FC<AnchorLink> = ({ header }) => (
  <Button
    key={header.name}
    onClick={() => {
      document.getElementById(header.id)?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }}
    disableRipple
    variant="text"
    sx={{
      py: 1,
      backgroundColor: "transparent",
      "&::after": {
        content: '""',
        position: "absolute",
        bottom: 0,
        width: (theme) => `calc(100% - ${theme.spacing(2)})`,
        height: "2px",
        background: "transparent",
      },
      "&:hover": {
        background: "transparent",
        "&::after": {
          background: (theme) => theme.palette.common.black,
        },
      },
    }}
  >
    <Typography
      sx={{
        fontSize: "1rem",
        color: "text.primary",
        textTransform: "initial",
      }}
    >
      {header.label}
    </Typography>
  </Button>
);

export const AnchorSectionLinkComponent: FC<AnchorSectionLinkComponentType> = ({
  headerLinks,
}) => {
  if (!headerLinks.length) return <></>;

  const anchoredLinks = headerLinks.map((header) => {
    return <AnchorLink key={uuid()} header={header} />;
  });

  return (
    <Box
      sx={{
        display: "flex",
        pr: 3,
        gap: 1,
        justifyContent: "right",
        background: (theme) =>
          theme.palette.mode === "light"
            ? `linear-gradient(${theme.palette.grey[50]}, ${theme.palette.grey[50]}88)`
            : `linear-gradient(${theme.palette.grey[900]}, ${theme.palette.grey[900]}88)`,
        zIndex: 1,
      }}
    >
      {anchoredLinks}
    </Box>
  );
};

export default AnchorSectionLinkComponent;
