import { Box, Tooltip } from "@mui/material";
import { Role, User } from "../../../model";
import { TaskExecution } from "../types";
import { Warning } from "@mui/icons-material";
import TaskExecutionAssignment from "./execution/TaskExecutionAssignment";
import { RoleIcon } from "../../../components/elements";

const RoleContainer = ({
  assignedUser,
  role,
  hide,
  showWarning,
  execution,
  processExecutionId,
}: {
  assignedUser?: User;
  role?: Role;
  hide?: boolean;
  showWarning?: boolean;
  execution?: TaskExecution;
  processExecutionId?: string;
}) => {
  if (showWarning && !role?.id) {
    return (
      <Tooltip title={"Missing role assignement"}>
        <Warning color="warning" />
      </Tooltip>
    );
  }
  if (!role?.id || hide) {
    return <></>;
  }
  return (
    <Box sx={{ display: "flex", whiteSpace: "nowrap", alignItems: "center" }}>
      {processExecutionId && (
        <Box sx={{ mr: 1 }}>
          <TaskExecutionAssignment
            processExecutionId={processExecutionId}
            assignedUser={assignedUser}
            taskExecution={execution}
            targetRoleId={role.id}
          />
        </Box>
      )}
      <RoleIcon
        role={role}
        assignedUser={assignedUser}
        toolTipPosition="right"
      />
    </Box>
  );
};
export default RoleContainer;
