import { AuditEvent, AuditEventType } from "../../../model/AuditEvent";
import { TaskExecutionState } from "../../task";

const filterAuditEvents = (auditEvents: Array<AuditEvent>) => {
  const ALLOWED_ACTIVITY_AUDIT_EVENT_TYPES = [
    AuditEventType.PROCESS_EXECUTION_COMPLETE,
    AuditEventType.PROCESS_EXECUTION_START,
    AuditEventType.TASK_EXECUTION_COMPLETE,
    AuditEventType.TASK_EXECUTION_ERROR,
    AuditEventType.UPDATE,
  ];

  return auditEvents.filter((auditEvent) => {
    if (ALLOWED_ACTIVITY_AUDIT_EVENT_TYPES.includes(auditEvent.event_type)) {
      return true;
    }

    // Only display logs for task execution data submitted if the
    // task execution has been completed
    if (
      auditEvent.event_type === AuditEventType.TASK_EXECUTION_DATA_SUBMITTED
    ) {
      return (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (auditEvent.metadata as any)?.new?.state ===
        TaskExecutionState.Completed
      );
    }

    return false;
  });
};

export default filterAuditEvents;
