/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Box, Button, Card } from "@mui/material";
import { CardActionBar, CardTitle } from "../../../components/elements";
import {
  Task,
  TaskBody,
  TaskExecution,
  TaskExecutionRenderProps,
  TaskExecutionState,
} from "../../task";
import ReactQuillViewer from "../../../components/core/ReactQuill/ReactQuillViewer";
import {
  getDescription,
  getTitle,
} from "../../task/components/task-types/getTaskByType";
import { Process } from "../../../model/Process";
import { useGlobalOrganizationContext } from "../../../hooks/useGlobalOrganizationContext";
import { Suspense } from "react";
import { useAtom } from "jotai";
import { publicPageAtom } from "../../organization/state/publicPageAtom";

export type SingleTaskExecutionCardProps = {
  taskExecution?: TaskExecution;
  content?: TaskExecutionRenderProps;
  process?: Process;
  task?: Task;
  icon?: string;
  hasNext?: boolean;
  backTitle?: string;
  onExecute: (taskEx: TaskExecution) => Promise<void>;
  onBack?: () => void;
  onNext?: () => void;
  onValueChange?: (task: Task) => void;
  loaded: boolean;
};

const SingleTaskExecutionCard = ({
  taskExecution,
  content,
  process,
  task,
  icon,
  hasNext = false,
  onExecute,
  backTitle,
  onBack,
  onNext = () => {
    /**/
  },
  onValueChange = () => {
    /**/
  },
}: SingleTaskExecutionCardProps) => {
  const { organization } = useGlobalOrganizationContext();
  const [isPublicPage] = useAtom(publicPageAtom);
  const currentTask = task ?? taskExecution?.task;
  const showNextButton =
    taskExecution?.state === TaskExecutionState.Completed && hasNext;
  const showBackButton = !!onBack;
  const taskTitle =
    currentTask && process
      ? getTitle(organization!, process, currentTask, isPublicPage)
      : currentTask?.name;
  const description =
    currentTask && process
      ? getDescription(
          organization!,
          process,
          currentTask,
          taskExecution,
          isPublicPage,
        )
      : currentTask?.description;
  const renderTaskCardContent = ({
    actions,
    title,
    content,
  }: TaskExecutionRenderProps) => {
    const contentArr = content instanceof Array ? content : [content];
    return (
      <>
        <Box
          sx={{
            py: 3,
            px: 4,
            flex: 1,
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
            gap: 2,
          }}
        >
          <CardTitle variant="h2" component="h1" icon={icon}>
            {title ?? taskTitle}
          </CardTitle>
          <Box sx={{ overflow: "auto", flexGrow: 0, pr: 1.5 }}>
            {description && (
              <ReactQuillViewer
                value={description}
                variant="regular"
                collapseHeight={-1}
              />
            )}
          </Box>

          {contentArr.map((element, index) => (
            <Box
              key={index}
              sx={{
                flexGrow: index === contentArr.length - 1 ? 1 : 0,
                display: "flex",
              }}
            >
              {element}
            </Box>
          ))}
        </Box>
        {(actions || showBackButton || showNextButton) && (
          <CardActionBar
            align="space-between"
            sx={{ px: 4, py: 2, bgcolor: "common.white" }}
          >
            {showBackButton && (
              <Button variant="text" onClick={onBack}>
                {backTitle ?? "< Back"}
              </Button>
            )}
            {!showBackButton && <Box sx={{ flex: 1 }}></Box>}
            {taskExecution?.state !== TaskExecutionState.Completed && actions}
            {showNextButton && (
              <Button onClick={onNext} color="primary">
                Next &gt;
              </Button>
            )}
          </CardActionBar>
        )}
      </>
    );
  };
  return (
    <Suspense>
      <Card
        elevation={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          maxHeight: "100%",
          minHeight: "60%",
        }}
      >
        {!content && (
          <TaskBody
            variant="execution"
            view="expanded"
            task={currentTask!}
            taskExecution={taskExecution}
            onValueChange={onValueChange}
            onExecute={onExecute}
            isShortTaskForm={true}
            reload={function (): void {
              throw new Error("Function not implemented.");
            }}
            overrideEditable={
              taskExecution?.state !== TaskExecutionState.Completed
            }
          >
            {renderTaskCardContent}
          </TaskBody>
        )}
        {content && renderTaskCardContent(content)}
      </Card>
    </Suspense>
  );
};
export default SingleTaskExecutionCard;
