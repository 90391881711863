import {
  FormControl,
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  Paper,
  TextField,
} from "@mui/material";
import { FC, useState } from "react";
import { AccountTypeAPI } from "../../../api";
import { useAlert } from "../../../lib/alert";
import { User } from "../../../model";
import { AccountTypeRoleAffinity } from "../../../model/AccountType";
import { getUserFullName } from "../../activity-log";

type AccountTypeUserAffinityRoleDropdownProps = {
  accountId: string;
  accountTypeRoleAffinity: AccountTypeRoleAffinity;
  selectedUser?: User;
};

const AccountTypeUserAffinityRoleDropdown: FC<
  AccountTypeUserAffinityRoleDropdownProps
> = (props) => {
  const { success, error } = useAlert();

  const [selectedUser, setSelectedUser] = useState<User | undefined>(
    props.selectedUser,
  );

  const handleUserSelection = (
    event: React.ChangeEvent<unknown>,
    value: User | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<User> | undefined,
  ) => {
    // no users have been selected or de-selected
    if (!details) {
      return;
    }
    if (!value) {
      return;
    }

    const oldSelection = value;
    switch (reason) {
      case "selectOption": {
        AccountTypeAPI.reassignAccountTypeRole(
          props.accountId,
          props.accountTypeRoleAffinity.id,
          value.id,
        ).then(
          () => success("Success"),
          /* eslint-disable @typescript-eslint/no-explicit-any */
          (err: any) => {
            console.error("Error assigning role", err);
            error("Error when assigning role", err);
            setSelectedUser(oldSelection);
          },
        );
        setSelectedUser(value);
        break;
      }
      case "removeOption":
      case "clear": {
        AccountTypeAPI.reassignAccountTypeRole(
          props.accountId,
          props.accountTypeRoleAffinity.id,
        ).then(
          () => success("Success"),
          /* eslint-disable @typescript-eslint/no-explicit-any */
          (err: any) => {
            error("Error when unassigning role", err);
            setSelectedUser(oldSelection);
          },
        );
        setSelectedUser(undefined);
        break;
      }
      default: {
        console.error("Error: invalid reason for user selection");
      }
    }
  };

  return (
    <FormControl
      sx={{
        m: 1,
        overflow: "hidden",
        width: "100%",
      }}
    >
      <Autocomplete
        limitTags={4}
        options={props.accountTypeRoleAffinity.valid_users}
        value={selectedUser}
        disableClearable
        onChange={handleUserSelection}
        getOptionLabel={(user?: User) => (user ? getUserFullName(user) : "")}
        noOptionsText="No valid users"
        PaperComponent={(props) => <Paper elevation={4} {...props} />}
        isOptionEqualToValue={(option, value) => {
          return option.id === value.id;
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </FormControl>
  );
};

export default AccountTypeUserAffinityRoleDropdown;
