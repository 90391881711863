import { Grid, TextField } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useTaskContext } from "../../../hooks";
import {
  TaskEmail,
  TaskEmailExecutionData,
  TaskSideSectionEditorProps,
} from "../../../types";
import ReactQuillEditor from "../../../../../components/core/ReactQuill/ReactQuillEditor";

const EmailSideSectionEditor = ({
  task,
}: TaskSideSectionEditorProps<TaskEmail>) => {
  const { setValue } = useTaskContext();
  const defaultExecutionData = useMemo(() => {
    return {
      ...task.execution_data,
      template_source: "Default_Email_Task",
    };
  }, [task.execution_data]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setExecutionData = useCallback(
    (executionData: Partial<TaskEmailExecutionData>) => {
      setValue({
        execution_data: {
          ...defaultExecutionData,
          ...task.execution_data,
          ...executionData,
        },
      });
    },
    [defaultExecutionData, setValue, task.execution_data],
  );

  return (
    <>
      <Grid item xs={12}>
        <TextField
          fullWidth
          placeholder="email@address.com"
          value={
            task.execution_data?.to && task.execution_data?.to.length > 0
              ? task.execution_data?.to[0]
              : ""
          }
          label={"To"}
          InputLabelProps={{ shrink: true }}
          onChange={(event) => {
            setExecutionData({
              to: [event.target.value],
            });
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          value={task.execution_data?.email_data?.subject ?? ""}
          label={"Subject"}
          InputLabelProps={{ shrink: true }}
          onChange={(event) => {
            setExecutionData({
              email_data: {
                ...task.execution_data?.email_data,
                subject: event.target.value,
              },
            });
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          value={task.execution_data?.email_data?.header ?? ""}
          label={"Header"}
          InputLabelProps={{ shrink: true }}
          onChange={(event) => {
            setExecutionData({
              email_data: {
                ...task.execution_data?.email_data,
                header: event.target.value,
              },
            });
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <ReactQuillEditor
          id={task.id || ""}
          onChange={(value) => {
            setExecutionData({
              email_data: {
                ...task.execution_data?.email_data,
                body: value,
              },
            });
          }}
          value={task.execution_data?.email_data?.body || "<p><br></p>"}
          placeholder="Body"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          value={task.execution_data?.email_data?.button?.text ?? ""}
          label={"Button Label"}
          InputLabelProps={{ shrink: true }}
          onChange={(event) => {
            setExecutionData({
              email_data: {
                ...task.execution_data?.email_data,
                button: {
                  link: "",
                  ...task.execution_data?.email_data?.button,
                  text: event.target.value,
                },
              },
            });
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          placeholder="www.example.com"
          value={task.execution_data?.email_data?.button?.link ?? ""}
          label={"Button Link"}
          InputLabelProps={{ shrink: true }}
          onChange={(event) => {
            setExecutionData({
              email_data: {
                ...task.execution_data?.email_data,
                button: {
                  text: "",
                  ...task.execution_data?.email_data?.button,
                  link: event.target.value,
                },
              },
            });
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          placeholder="Identifier"
          value={task.execution_data?.attachments ?? ""}
          label={"Attachment"}
          InputLabelProps={{ shrink: true }}
          onChange={(event) => {
            setExecutionData({
              ...task.execution_data,
              attachments: event.target.value,
            });
          }}
        />
      </Grid>
    </>
  );
};

export default EmailSideSectionEditor;
