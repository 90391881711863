import {
  Typography,
  FormControlLabel,
  FormGroup,
  Checkbox,
  FormLabel,
} from "@mui/material";
import { MultiSelectionFormElement } from "../../../types";
import FormElementTaskDescription from "../FormElementTaskDescription";

export const MultiSelectionInlineDisplay = ({
  element,
}: {
  element: MultiSelectionFormElement;
}) => {
  return (
    <FormGroup>
      <FormLabel required={element.required}>{element.label}</FormLabel>
      <FormElementTaskDescription>
        {element.description}
      </FormElementTaskDescription>
      {element.options.map(({ id, name }, index) => {
        return (
          <FormControlLabel
            key={index}
            value={name}
            label={<Typography sx={{ fontSize: "14px" }}>{name}</Typography>}
            control={<Checkbox id={id} disabled />}
          />
        );
      })}
    </FormGroup>
  );
};
