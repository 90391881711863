import API from "./base";
import {
  AccountType,
  AccountTypeAffinityDto,
  AccountTypeRoleUserDtoResponse,
} from "../model/AccountType";
import FieldDefinition from "../model/FieldDefinition";

const get = (id: string): Promise<AccountType> => {
  return new Promise((resolve, reject) => {
    API.get(`/account-types/${id}`, { public: true }).then((result) => {
      resolve(result.data.payload as AccountType);
    }, reject);
  });
};

const create = (accountType: AccountType): Promise<AccountType> => {
  return new Promise((resolve, reject) => {
    API.post(`/account-types`, accountType).then((result) => {
      resolve(result.data.payload as AccountType);
    }, reject);
  });
};

const deleteAccountType = (accountTypeId: string): Promise<void> => {
  return API.delete(`/account-types/${accountTypeId}`);
};

const update = (accountType: AccountType): Promise<AccountType> => {
  return new Promise((resolve, reject) => {
    API.put(`/account-types/${accountType.id}`, accountType).then((result) => {
      resolve(result.data.payload as AccountType);
    }, reject);
  });
};

const byOrganizationId = (organizationId: string): Promise<AccountType[]> => {
  return new Promise((resolve, reject) => {
    API.get(`/account-types/byOrganizationId/${organizationId}`, {
      public: true,
    }).then((result) => {
      resolve(result.data.payload as AccountType[]);
    }, reject);
  });
};

const byKey = (
  organizationId: string,
  accountTypeKey: string,
): Promise<AccountType> => {
  return new Promise((resolve, reject) => {
    API.get(
      `/account-types/byOrganizationId/${organizationId}/byKey/${accountTypeKey}`,
      { public: true },
    ).then((result) => {
      resolve(result.data.payload as AccountType);
    }, reject);
  });
};

const getAffinity = (accountId: string): Promise<AccountTypeAffinityDto> => {
  return new Promise((resolve, reject) => {
    API.get(`/account-types/${accountId}/affinity`).then((result) => {
      resolve(result.data.payload as AccountTypeAffinityDto);
    }, reject);
  });
};

const listAccountTypeRoles = (
  accountTypeId: string,
): Promise<AccountTypeRoleUserDtoResponse[]> => {
  return new Promise((resolve, reject) => {
    API.get(`/account-types/listAccountTypeRoles/${accountTypeId}`).then(
      (result) => {
        resolve(result.data.payload as AccountTypeRoleUserDtoResponse[]);
      },
      reject,
    );
  });
};

const reassignAccountTypeRole = (
  accountTypeId: string,
  roleId: string,
  userId?: string,
): Promise<void> => {
  return new Promise((resolve, reject) => {
    API.post(`/account-types/reassignAccountTypeRole`, {
      accountTypeId,
      roleId,
      userId,
    }).then(() => resolve(), reject);
  });
};

const getFieldDefinitions = (
  accountTypeId: string,
): Promise<{
  selected: string[];
  available: { [key: string]: FieldDefinition };
}> => {
  return new Promise((resolve, reject) => {
    API.get(`/account-types/${accountTypeId}/fields`).then((result) => {
      resolve(
        result.data.payload as {
          selected: string[];
          available: { [key: string]: FieldDefinition };
        },
      );
    }),
      reject;
  });
};

const addSelectedFieldDefinition = (
  accountTypeId: string,
  fieldDefinitionId: string,
): Promise<void> => {
  return new Promise((resolve, reject) => {
    API.post(
      `/account-types/${accountTypeId}/fields/${fieldDefinitionId}`,
    ).then(() => resolve(), reject);
  });
};

const removeSelectedFieldDefinition = (
  accountTypeId: string,
  fieldDefinitionId: string,
): Promise<void> => {
  return new Promise((resolve, reject) => {
    API.delete(
      `/account-types/${accountTypeId}/fields/${fieldDefinitionId}`,
    ).then(() => resolve(), reject);
  });
};

const setSelectedFieldDefinitions = (
  accountTypeId: string,
  fieldDefinitionIds: string[],
): Promise<void> => {
  return new Promise((resolve, reject) => {
    API.put(`/account-types/${accountTypeId}/fields`, fieldDefinitionIds).then(
      () => resolve(),
      reject,
    );
  });
};

export default {
  create,
  update,
  delete: deleteAccountType,
  get,
  getAffinity,
  byKey,
  byOrganizationId,
  listAccountTypeRoles,
  reassignAccountTypeRole,
  getFieldDefinitions,
  addSelectedFieldDefinition,
  removeSelectedFieldDefinition,
  setSelectedFieldDefinitions,
};
