import { AuditEvent } from "../model/AuditEvent";
import API from "./base";

const getByProcessExecutionId = (
  organizationId: string,
  processExecutionId: string,
): Promise<Array<AuditEvent>> => {
  return new Promise((resolve, reject) => {
    API.get(
      `/audit-events/${organizationId}/processExecution/${processExecutionId}`,
    ).then(
      (result) => resolve(result.data.payload as Array<AuditEvent>),
      reject,
    );
  });
};

export default {
  getByProcessExecutionId: getByProcessExecutionId,
};
