import { ThemeOptions } from "@mui/material";
import companyIcon from "../assets/images/Transparent-192x192.png";

type Theme = ThemeOptions & {
  lang?: Record<string, string>;
  icons?: Record<string, string>;
};
type EnvironmentConfig = {
  theme?: Theme;
  organization?: {
    organizationId: string;
    key: string;
    name: string;
  };
  app: {
    name: string;
    logo: string;
    logoIcon: string;
    sesConfigId?: string;
  };
  features: Record<string, boolean>;
  hiddenProcessMetaIds: string[];
};
declare global {
  interface Window {
    ENV_CONFIG?: EnvironmentConfig;
  }
}
const ENV_DEFAULTS: EnvironmentConfig = {
  app: {
    name: "",
    logo: "/assets/images/Transparent-192x192.png",
    logoIcon: companyIcon,
  },
  features: { apiKeys: false, integrations: false },
  hiddenProcessMetaIds: [],
};
export default window.ENV_CONFIG
  ? { ...ENV_DEFAULTS, ...window.ENV_CONFIG }
  : ENV_DEFAULTS;
