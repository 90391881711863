import Stepper from "@mui/material/Stepper";
import {
  useState,
  useCallback,
  PropsWithChildren,
  useEffect,
  lazy,
  Suspense,
} from "react";
import MuiStep from "@mui/material/Step";
import StepLabel, { StepLabelProps } from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";

export type StepData = {
  id: string;
  content?: React.FC;
  icon?: string;
  completed?: boolean;
  title: string | React.FC;
  labelProps?: StepLabelProps;
  active?: boolean;
};
export type VerticalStepperProps = PropsWithChildren<{
  steps: Array<StepData>;
}>;

const expandSteps = (
  steps: Array<StepData>,
  currentExpansion: Record<string, boolean>,
) => {
  const existingState = Object.keys(currentExpansion).length > 0;
  return steps.reduce(
    (expanded, curStep) => {
      if (curStep.id && !(curStep.id in expanded)) {
        expanded[curStep.id] = existingState;
      }
      return expanded;
    },
    { ...currentExpansion },
  );
};

const DynamicIconComponent = lazy(
  () => import(/* webpackChunkName: "DynamicIcon" */ "../DynamicIcon"),
);
const VerticalStepper = ({ children, steps }: VerticalStepperProps) => {
  const [expandedSteps, setExpandedSteps] = useState(expandSteps(steps, {}));
  const toggleStepExpansion = useCallback(
    (id: string) => {
      setExpandedSteps((expandedStepState) => ({
        ...expandedStepState,
        [id]: !expandedStepState[id],
      }));
    },
    [setExpandedSteps],
  );
  useEffect(() => {
    setExpandedSteps((expandedStepState) =>
      expandSteps(steps, expandedStepState),
    );
  }, [steps]);

  return (
    <>
      <Suspense fallback={<></>}>
        <Stepper orientation="vertical" activeStep={0} connector={<></>}>
          {steps.map(
            ({ id, icon, completed, content, labelProps, title, active }) => {
              const optionalLabelProps: StepLabelProps = {};
              if (icon) {
                optionalLabelProps["icon"] = (
                  <DynamicIconComponent name={icon} />
                );
              }
              return (
                <MuiStep
                  key={id}
                  completed={completed}
                  active={expandedSteps[id ?? ""] || active}
                  sx={{
                    "& .MuiStepLabel-root .Mui-completed": {
                      color: "success.main",
                    },
                    "& .MuiStepLabel-label.Mui-completed": {
                      color: "text.primary",
                    },
                  }}
                >
                  <StepLabel
                    {...optionalLabelProps}
                    onClick={() => {
                      toggleStepExpansion(id ?? "");
                    }}
                    {...labelProps}
                    sx={{
                      cursor: "pointer",
                      "&.Mui-disabled": {
                        cursor: "pointer",
                      },
                      "& .MuiStepLabel-labelContainer": {
                        backgroundColor: (theme) =>
                          active
                            ? "primary.light"
                            : completed
                              ? "success.light"
                              : theme.palette.grey[300],
                        px: 3,
                        py: 1,
                        borderRadius: "4px",
                      },
                    }}
                  >
                    {typeof title === "function" ? title({}) : title}
                  </StepLabel>
                  {content && (
                    <StepContent sx={{ pr: 0 }}>{content({})}</StepContent>
                  )}
                </MuiStep>
              );
            },
          )}
          {children}
        </Stepper>
      </Suspense>
    </>
  );
};
export default VerticalStepper;
