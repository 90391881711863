import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { ProcessExecution } from "../../../model/ProcessExecution";

export enum ProcessProgressVariant {
  Count,
  Percent,
}

export type ProcessProgressProps = {
  processExecution: ProcessExecution;
  variant?: ProcessProgressVariant;
};

const ProcessProgress: FC<ProcessProgressProps> = ({
  processExecution,
  variant = ProcessProgressVariant.Percent,
}) => {
  if (processExecution.completed_tasks && processExecution.total_tasks) {
    const label = [
      processExecution.completed_tasks,
      processExecution.total_tasks,
    ].join("/");
    const progress: number =
      100 * (+processExecution.completed_tasks / +processExecution.total_tasks);
    const remaining: number = 100 - progress;

    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            background: (theme) =>
              `linear-gradient(to right, ${theme.palette.progress.progress} 0% ${progress}%, ${theme.palette.progress.remaining} ${progress}% ${remaining}%)`,
            width: "100%",
            textAlign: "center",
            borderRadius: "18px",
            flex: 1,
          }}
        >
          {variant === ProcessProgressVariant.Count && (
            <Typography
              fontWeight="bold"
              sx={{
                p: 0.25,
                fontSize: "14px",
                fontWeight: "bold",
                color: (theme) =>
                  theme.palette.getContrastText(
                    progress > 50
                      ? theme.palette.progress.progress
                      : theme.palette.progress.remaining,
                  ),
              }}
            >
              {label}
            </Typography>
          )}
        </Box>
        {variant === ProcessProgressVariant.Percent && (
          <Typography
            variant="body2"
            sx={{ minWidth: "40px", textAlign: "right" }}
          >
            {Math.round(progress)}%
          </Typography>
        )}
      </Box>
    );
  }
  return <></>;
};

export default ProcessProgress;
