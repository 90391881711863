import {
  Dispatch,
  FC,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import { PopoverOrigin } from "@mui/material";

type IconButtonDropdownMenuProps = {
  dataCy?: string;
  element: ReactNode;
  closed: boolean;
  setClosed: Dispatch<SetStateAction<boolean>>;
  size?: "small" | "medium" | "large";
  anchorOrigin?: PopoverOrigin;
};

const IconButtonDropdownMenu: FC<
  PropsWithChildren<IconButtonDropdownMenuProps>
> = (props) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
    event.stopPropagation();
    props.setClosed(false);
  };

  const handleMenuClose = useCallback(() => {
    setMenuAnchorEl(null);
    props.setClosed(true);
  }, [props]);

  useEffect(() => {
    if (props.closed) {
      handleMenuClose();
    }
  }, [props.closed, handleMenuClose]);

  return (
    <div>
      <IconButton
        data-cy={props.dataCy || null}
        size={props.size || "medium"}
        color="inherit"
        aria-controls={menuOpen ? "user-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? "true" : undefined}
        onClick={handleMenuOpen}
      >
        {props.element}
      </IconButton>
      <Menu
        anchorEl={menuAnchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={props.anchorOrigin}
      >
        {props.children}
      </Menu>
    </div>
  );
};

export default IconButtonDropdownMenu;
