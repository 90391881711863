import API from "./base";

const getAllMetricsForDashboard = async (
  dashboardId: string,
  startDate?: Date,
  endDate?: Date,
) => {
  const result = await API.get(`/metrics/${dashboardId}`, {
    params: {
      startDate: startDate ? startDate.toISOString() : undefined,
      endDate: endDate ? endDate.toISOString() : undefined,
    },
    public: true,
  });

  return result.data.payload;
};

export default {
  getAllMetricsForDashboard,
};
