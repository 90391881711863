import { CircularProgress, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import processes from "../../../../../api/processes";
import roles from "../../../../../api/roles";
import { UserSelectionByRole } from "../../../../../components/core/Selection";
import { Role } from "../../../../../model";
import { Process } from "../../../../../model/Process";
import { useTaskContext } from "../../../hooks";
import { TaskAssignment, TaskSideSectionEditorProps } from "../../../types";
import ProcessBuilderRoleSelect from "../../editor/ProcessBuilderRoleSelect";

const AssignmentSideSectionEditor = ({
  task,
}: TaskSideSectionEditorProps<TaskAssignment>) => {
  const taskContext = useTaskContext();
  const [process, setProcess] = useState<Process>();
  const [targetRole, setTargetRole] = useState<Role>();

  useEffect(() => {
    processes
      .get(task.process_id)
      .then((response) => {
        setProcess(response);
      })
      .catch(() => {
        setProcess(undefined);
      });
  }, [task.process_id]);

  useEffect(() => {
    if (task.data?.targetRoleId) {
      roles
        .get(task.data?.targetRoleId)
        .then((response) => {
          setTargetRole(response);
        })
        .catch((error) => {
          console.error(`Could not get role ${task.data?.targetRoleId}`, error);
        });
    }
    return () => setTargetRole(undefined);
  }, [task.data?.targetRoleId]);

  if (!process) {
    return <CircularProgress />;
  }

  return (
    <>
      <Grid item xs={12}>
        <ProcessBuilderRoleSelect
          label="Select a Target Role"
          onChange={(updatedRole) => {
            taskContext.setValue({
              data: {
                ...task.data,
                targetRoleId: updatedRole.id,
              },
            });
          }}
          value={targetRole}
        />
      </Grid>
      <Grid item xs={12}>
        <UserSelectionByRole
          values={task.execution_data?.assignees ?? []}
          label={"Pre-assign Users (Optional)"}
          targetRoleId={task.data?.targetRoleId ?? ""}
          disabled={!task.data?.targetRoleId}
          onChange={(users) => {
            taskContext.setValue({
              execution_data: {
                assignees: users.map((user) => user.id),
              },
            });
          }}
          onInvite={(invitation) => {
            taskContext.setValue({
              execution_data: {
                assignees: task.execution_data?.assignees
                  ? [
                      ...task.execution_data.assignees,
                      invitation.target_user_id,
                    ]
                  : [invitation.target_user_id],
              },
            });
          }}
        />
      </Grid>
    </>
  );
};

export default AssignmentSideSectionEditor;
