import { FC } from "react";
import { Box } from "@mui/material";

const EmailCell: FC<{ email: string }> = ({ email }) => {
  return (
    <Box
      component={"span"}
      sx={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        "&:hover": {
          overflow: "scroll",
          textOverflow: "clip",
        },
      }}
    >
      {email}
    </Box>
  );
};

export default EmailCell;
