import { GridFilterItem } from "@mui/x-data-grid";

export * from "./integrations";
export { default as TaskAPI } from "./tasks";
export { default as ProcessExecutionAPI } from "./process_executions";
export { default as ProcessAPI } from "./processes";
export { default as ProcessCategoryAPI } from "./processCategories";
export { default as RoleAPI } from "./roles";
export { default as InvitationAPI } from "./invitations";
export { default as FileAPI } from "./file";
export { default as ApiKeyAPI } from "./apikey";
export { default as UserAPI } from "./users";
export { default as OrganizationAPI } from "./organizations";
export { default as DashboardAPI } from "./dashboards";
export { default as AccountAPI } from "./accounts";
export { default as AccountTypeAPI } from "./accountTypes";
export { default as FieldDefinitionAPI } from "./fieldDefinitions";
export { default as TaskExecutionAPI } from "./taskExecutions";
export { default as ExecutorAPI } from "./executors";
export { default as CopilotAPI } from "./copilot";
export { default as CopilotChatAPI } from "./copilotChat";
export { default as ChatAPI } from "./chat";
export { default as MetricsAPI } from "./metric";
export { default as PublicProcessExecutionAPI } from "./public_executor";

export type Pagination = {
  offset?: number;
  total?: number;
  pageSize: number;
};

export type ResponseEnvelope<PayloadType> = {
  requestId: string;
  payload: PayloadType | null;
  pagination?: Pagination;
};
export type PagedResponse<T> = {
  total_count: number;
  data: Array<T>;
};

export type FilterOperator = "=" | "not in" | "!=";

export type Filter = {
  field: string;
  operator: FilterOperator;
  value?: string | string[];
};

const FILTER_OPERATOR_MAP = {
  equals: "=",
} as const;

type GridFilterOperator = keyof typeof FILTER_OPERATOR_MAP;

const toFilterOperator = (operator: GridFilterOperator) => {
  return FILTER_OPERATOR_MAP[operator] ?? operator;
};

export const toFilter = (gridFilter: GridFilterItem) => {
  return {
    field: gridFilter.field,
    operator: toFilterOperator(gridFilter.operator as GridFilterOperator),
    value: gridFilter.value,
  };
};
