import { useCallback } from "react";
import { Collapse, Grid } from "@mui/material";
import FormBuilderAddElement from "./FormBuilderAddElement";
import FormBuilderSideSectionContainer from "./FormBuilderSideSectionContainer";
import { useDisplayOptions } from "../../../../hooks/useDisplayOptions";
import { FormBuilderHeader } from "./FormBuilderHeader";
import { FormBuilderDraggableList } from "./FormBuilderDraggableList";
import { useFormBuilderContext } from "./FormBuilderProvider";
import { FormDefinitionType, FormExecutionType } from "../../types";
import Draggable from "../../../../components/core/Draggable/Draggable";
import FormBuilderChild from "./FormBuilderChild";

type FormElementUpdateType = {
  changeType: "update";
  newElement?: FormDefinitionType;
  newExecutionData?: FormExecutionType;
};

type FormElementAddType = {
  changeType: "add";
  newElement: FormDefinitionType;
  prevElementId?: string;
};

type FormElementRemoveType = {
  changeType: "remove";
  elementId: string;
};

type FormElementReorderType = {
  changeType: "reorder";
  activeIndex: number;
  overIndex: number;
};

export type FormElementChangeType =
  | FormElementUpdateType
  | FormElementAddType
  | FormElementRemoveType
  | FormElementReorderType;

const FormBuilder = () => {
  const {
    task,
    elements,
    executionData,
    selectedElementId,
    setSelectedElementId,
    hideChildren,
    toggleChildren,
  } = useFormBuilderContext();

  useDisplayOptions({ title: `Edit Form - ${task.name}` });

  const FormBuilderChildRenderer = useCallback(
    (element: FormDefinitionType) => {
      return (
        <FormBuilderChild
          element={element}
          executionData={executionData.find((ex) => {
            return ex.id == element.id;
          })}
          isSelected={element.id == selectedElementId}
          onClick={(element) => {
            setSelectedElementId(element.id);
          }}
          hideChildren={(hide) => {
            toggleChildren(element.id, hide);
          }}
        />
      );
    },
    [executionData, selectedElementId, setSelectedElementId, toggleChildren],
  );

  return (
    <Grid
      container
      flexDirection={"column"}
      flexWrap={"nowrap"}
      height={"100%"}
    >
      <Grid item>
        <FormBuilderHeader />
      </Grid>
      <Grid item flex={1} height={"100%"} overflow={"hidden"}>
        <Grid container height="100%">
          <Grid
            item
            flex={1}
            sx={{ p: 4, pt: 1, overflow: "auto", height: "100%" }}
          >
            <Grid container>
              <FormBuilderDraggableList>
                {elements.map((element) => {
                  return (
                    <Collapse
                      in={!hideChildren[element.id]}
                      key={element.id}
                      sx={{ width: "100%" }}
                    >
                      <Grid container direction={"column"} sx={{ mb: 1 }}>
                        {hideChildren[element.id] ? (
                          FormBuilderChildRenderer(element)
                        ) : (
                          <Draggable id={element.id}>
                            {FormBuilderChildRenderer(element)}
                          </Draggable>
                        )}
                      </Grid>
                    </Collapse>
                  );
                })}
              </FormBuilderDraggableList>
            </Grid>
            <Grid>
              <FormBuilderAddElement />
            </Grid>
          </Grid>
          <FormBuilderSideSectionContainer />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormBuilder;
