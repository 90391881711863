import { Snackbar, Alert, AlertColor, Box } from "@mui/material";
import {
  createContext,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

const DEFAULT_AUTO_HIDE_DURATION = 5000;

type Alert = {
  message: string;
  severity?: AlertColor;
  autoHideDuration?: number;
};

type AlertContent = {
  open: boolean;
  close: () => void;
  alertContext: Alert;
  setAlertContext: (alert: Alert) => void;
};

const AlertContext = createContext<AlertContent>({
  open: false,
  close: () => {
    return;
  },
  alertContext: { message: "" },
  setAlertContext: () => {
    return;
  },
});

export const AlertSnackbar: FC<unknown> = () => {
  const { open, close, alertContext } = useContext(AlertContext);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState<AlertColor>("info");
  const [autoHideDuration, setAutoHideDuration] = useState(
    DEFAULT_AUTO_HIDE_DURATION,
  );

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    close();
  };

  useEffect(() => {
    if (!alertContext.message) {
      return;
    }
    setMessage(alertContext.message);
    setAutoHideDuration(
      alertContext.autoHideDuration || DEFAULT_AUTO_HIDE_DURATION,
    );
    setSeverity(alertContext.severity || "info");
  }, [alertContext]);

  const severityCheck = () => {
    switch (severity) {
      case "success":
        return {
          bgcolor: "#2E7D32",
          color: "common.white",
          fontWeight: "bold",
          "& .MuiAlert-icon": {
            color: "common.white",
          },
        };
      case "error":
        return {
          bgcolor: "#C35631",
          color: "common.white",
          fontWeight: "bold",
          "& .MuiAlert-icon": {
            color: "common.white",
          },
        };
      case "info":
        return {
          bgcolor: "common.white",
          color: "#2E7D32",
          border: "3px solid #2E7D32",
          fontWeight: "bold",
          "& .MuiAlert-icon": {
            color: "#2E7D32",
          },
        };
    }
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert
        onClose={handleClose}
        severity={alertContext.severity}
        sx={severityCheck()}
      >
        <Box sx={{ position: "relative" }}>{message}</Box>
      </Alert>
    </Snackbar>
  );
};

export const useAlert = () => {
  const { setAlertContext } = useContext(AlertContext);

  const alertInSnackBar = useCallback(
    (err: unknown, alert: Alert) => {
      switch (alert.severity) {
        case "error":
          console.error(err);
          break;
        case "warning":
          console.warn(err);
          break;
        case "success":
          break;
        default:
          console.info(err);
      }
      setAlertContext(alert);
    },
    [setAlertContext],
  );

  const handleRejectionWithError = useCallback(
    (message: string, duration?: number) => {
      return (err?: unknown) => {
        alertInSnackBar(err, {
          message: message,
          autoHideDuration: duration,
          severity: "error",
        });
        return Promise.reject(err);
      };
    },
    [alertInSnackBar],
  );

  const handleRejectionWithWarning = useCallback(
    (message: string, duration?: number) => {
      return (err?: unknown) => {
        alertInSnackBar(err, {
          message: message,
          autoHideDuration: duration,
          severity: "warning",
        });
        return Promise.reject(err);
      };
    },
    [alertInSnackBar],
  );

  const error = useCallback(
    (message: string, duration?: number) => {
      alertInSnackBar(message, {
        message: message,
        autoHideDuration: duration,
        severity: "error",
      });
    },
    [alertInSnackBar],
  );

  const warning = useCallback(
    (message: string, duration?: number) => {
      alertInSnackBar(message, {
        message: message,
        autoHideDuration: duration,
        severity: "warning",
      });
    },
    [alertInSnackBar],
  );

  const info = useCallback(
    (message: string, duration?: number) => {
      alertInSnackBar(message, {
        message: message,
        autoHideDuration: duration,
        severity: "info",
      });
    },
    [alertInSnackBar],
  );

  const success = useCallback(
    (message: string, duration?: number) => {
      alertInSnackBar(message, {
        message: message,
        autoHideDuration: duration,
        severity: "success",
      });
    },
    [alertInSnackBar],
  );

  return {
    handleRejectionWithError,
    handleRejectionWithWarning,
    error,
    warning,
    info,
    success,
  };
};

export const DefaultAlertSnackbar: FC<PropsWithChildren> = (props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [alertContext, setAlertContext] = useState<Alert>({ message: "" });
  const openAndSetAlertContext = useCallback(
    (props: SetStateAction<Alert>) => {
      setOpen(true);
      return setAlertContext(props);
    },
    [setAlertContext, setOpen],
  );
  const close = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  return (
    <AlertContext.Provider
      value={{
        open,
        close,
        alertContext,
        setAlertContext: openAndSetAlertContext,
      }}
    >
      {props.children}
      <AlertSnackbar />
    </AlertContext.Provider>
  );
};
