import { DragEvent, FC, useEffect, useState } from "react";
import { FormGroup, FormLabel, Grid, Typography } from "@mui/material";
import { useAlert } from "../../../../../../lib/alert";
import { TaskExecutionState, UploadFormElement } from "../../../../types";
import { FileAPI } from "../../../../../../api";
import { UploadFileComponent } from "../../../../../../components/core/UploadFile";
import LightBox from "../../../../../../components/elements/LightBox";
import PdfViewer from "../../../../../../components/core/PdfViewer/PdfViewer";
import FormElementTaskDescription from "../../../form-builder/FormElementTaskDescription";

type TaskUploadFormType = {
  onValueChange: (
    value: Array<{ key: string; source: string; contentType?: string }>,
  ) => void;
  formId?: string;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  execution?: any;
} & UploadFormElement;

const TaskUploadFormComponent: FC<TaskUploadFormType> = ({
  formId,
  disabled,
  execution,
  taskExecutionState,
  ...props
}) => {
  const { error } = useAlert();

  // state that keeps track of the uploaded file keys
  const [uploadedFiles, setUploadedFiles] = useState<
    Array<{ key: string; source: string; contentType?: string }>
  >(() => {
    if (Array.isArray(execution?.value)) {
      return execution.value;
    }
    return [];
  });

  const handleDrop = (e: DragEvent) => {
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      uploadFile(e.dataTransfer.files);
    }
  };

  const uploadFile = (
    fileList: FileList,
    e?: React.ChangeEvent<HTMLInputElement>,
  ) => {
    FileAPI.uploadFile(fileList).then(
      (value) => {
        // replacing '/' in keys so they can be used to fetch files
        const keyedFiles = value.map((fileUploadResponse) => {
          const file = {
            key: encodeURIComponent(fileUploadResponse.key),
            source: `${
              process.env.REACT_APP_REST_API
            }/files/${encodeURIComponent(fileUploadResponse.key)}`,
            contentType: fileUploadResponse.contentType,
          };
          return file;
        });

        const updatedArray = uploadedFiles.concat(keyedFiles);
        setUploadedFiles(updatedArray);

        // reset the file input element value
        if (e) e.target.value = "";

        props.onValueChange(updatedArray);
      },
      (err) => {
        // upload failed
        console.error(`Error uploading file: ${err}`);
        if (err.response.status === 413) {
          error("File is too large to upload");
        } else {
          error("A maximum of five files are allowed per upload");
        }
      },
    );
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files || undefined;
    if (!files) {
      return;
    }
    uploadFile(files, e);
  };

  const deleteUploadedImage = (key: string) => {
    const keySource = key.match("([^/]+$)");
    if (!keySource) {
      console.error(`Error deleting uploaded file: Bad Key`);
      return;
    }
    FileAPI.deleteFile(keySource[0]).then(
      () => {
        const filteredArray = uploadedFiles.filter((file) => file.key !== key);
        setUploadedFiles(filteredArray);
        props.onValueChange(filteredArray);
      },
      (err) => {
        // error deleting the image from the s3 bucket
        console.error(`Error deleting uploaded file: ${err}`);
        error("Error when deleting file");
      },
    );
  };

  useEffect(() => {
    if (execution?.value) {
      props.onValueChange(uploadedFiles);
    }
  }, [execution?.value, props, uploadedFiles]);

  // Readonly view
  if (disabled && taskExecutionState === TaskExecutionState.Completed) {
    return (
      <Grid item container gap={1} alignItems={"center"}>
        <Typography variant="subtitle1">{props.label}:</Typography>
        <UploadFileComponent
          renderer={(file) => {
            return file.contentType?.includes("pdf") ? (
              <PdfViewer source={file.source} scale={0.25} />
            ) : (
              <LightBox
                src={
                  process.env.REACT_APP_REST_API &&
                  file.source?.startsWith(process.env.REACT_APP_REST_API)
                    ? file.source
                    : `${process.env.REACT_APP_REST_API}/files/${file.source}`
                }
                alt="Uploaded image"
              />
            );
          }}
          files={uploadedFiles}
          disabled={disabled}
        />
      </Grid>
    );
  }

  return (
    <Grid item sx={{ width: "100%" }}>
      <FormGroup id={formId}>
        <FormLabel required={props.required}>{props.label}</FormLabel>
        <FormElementTaskDescription>
          {props.description}
        </FormElementTaskDescription>
        <UploadFileComponent
          renderer={(file) => {
            return file.contentType?.includes("pdf") ? (
              <PdfViewer source={file.source} scale={0.25} />
            ) : (
              <LightBox
                src={
                  process.env.REACT_APP_REST_API &&
                  file.source?.startsWith(process.env.REACT_APP_REST_API)
                    ? file.source
                    : `${process.env.REACT_APP_REST_API}/files/${file.source}`
                }
                alt="Uploaded image"
              />
            );
          }}
          handleDrop={handleDrop}
          files={uploadedFiles}
          handleFileChange={handleFileChange}
          multiple={false}
          accept={"image/*,.pdf"}
          text={"Drag and drop files here"}
          uploadText={"Upload files"}
          onRemove={(file) => {
            deleteUploadedImage(file.key);
          }}
          disabled={disabled}
          size={"large"}
        />
      </FormGroup>
    </Grid>
  );
};

export default TaskUploadFormComponent;
