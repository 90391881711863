import { AddOutlined } from "@mui/icons-material";
import { useState } from "react";
import { IconButtonDropdownMenu } from "../../../components/elements";
import { MenuItem, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import PATHS from "../../../components/navigation/_paths";

const ManageOrganizationMenu = () => {
  const [menuClosed, setMenuClosed] = useState<boolean>(true);

  return (
    <IconButtonDropdownMenu
      dataCy="manage-org-button"
      closed={menuClosed}
      setClosed={setMenuClosed}
      element={
        <Tooltip
          title="Manage Organizations"
          placement="right"
          arrow
          data-cy="manage-org-button-tooltip"
        >
          <AddOutlined sx={{ fontSize: "2rem" }} />
        </Tooltip>
      }
      anchorOrigin={{ horizontal: "right", vertical: "center" }}
      size="large"
    >
      <MenuItem
        component={Link}
        to={PATHS.ORGANIZATION_CREATE.link}
        onClick={() => setMenuClosed(true)}
      >
        {PATHS.ORGANIZATION_CREATE.title}
      </MenuItem>
      <MenuItem
        component={Link}
        to={PATHS.USER_MANAGE_ORGANIZATIONS.link}
        onClick={() => setMenuClosed(true)}
      >
        {PATHS.USER_MANAGE_ORGANIZATIONS.title}
      </MenuItem>
    </IconButtonDropdownMenu>
  );
};

export default ManageOrganizationMenu;
