import { TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { UserSelection } from "../core/Selection";
import { User } from "../../model";
import { useGlobalUserContext } from "../../hooks/useGlobalUserContext";

export type AssignDialogProps = {
  open: boolean;
  onClose: () => void;
  orgId: string;
  onConfirm: (userId: string) => void;
  title?: string;
  question?: string;
};

const AssignDialog = ({
  open,
  onClose,
  orgId,
  onConfirm,
  title = "Assign Member",
}: AssignDialogProps) => {
  const [processing, setProcessing] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>();
  const [isCurrentUser, setIsCurrentUser] = useState(false);
  const { user: currentUser } = useGlobalUserContext();
  return (
    <Dialog open={open} onClose={onClose} maxWidth={"sm"}>
      <DialogTitle>
        <Typography variant="h4" fontWeight={700}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            justifyContent: "center",
            alignItems: "center",
            minWidth: "450px",
            pt: 1,
          }}
        >
          <UserSelection
            organizationId={orgId}
            AutoCompleteProps={{
              renderInput: (params) => (
                <TextField {...params} label={"Select Members"} />
              ),
              multiple: false,
              value: (users) => {
                if (isCurrentUser) {
                  const user =
                    users.find((user) => {
                      return user.id == currentUser.id;
                    }) ?? null;
                  setSelectedUser(user);
                  return user;
                }
                return selectedUser ?? null;
              },
              onChange: (_, user) => {
                setIsCurrentUser(false);
                setSelectedUser(user);
              },
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between", mb: 2 }}>
        <Button
          sx={{ ml: 2 }}
          variant="text"
          onClick={async () => {
            setProcessing(true);
            setIsCurrentUser(true);
            setProcessing(false);
          }}
        >
          {"Assign to Me"}
        </Button>
        <Box display={"flex"} gap={2} mr={2}>
          <Button
            data-cy={"assign-dialog-cancel-btn"}
            variant="outlined"
            onClick={onClose}
            disabled={processing}
          >
            {"Cancel"}
          </Button>
          <Button
            data-cy={"assign-dialog-confirm-btn"}
            variant="contained"
            onClick={async () => {
              setProcessing(true);
              if (selectedUser) {
                await onConfirm(selectedUser.id);
              }
              setProcessing(false);
              onClose();
            }}
            disabled={processing || !selectedUser}
          >
            {"Confirm"}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
export default AssignDialog;
