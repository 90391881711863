import { useNavigate, useParams } from "react-router-dom";
import { PageContainer } from "../../components/layout";
import { PageHeader } from "../../components/layout/page";
import { FC, useCallback, useEffect, useReducer, useState } from "react";
import { AccountDto } from "../../model/Account";
import { AccountAPI, ProcessAPI } from "../../api";
import pluralize from "pluralize";
import { Box, Button, IconButton, Tab, Tabs, Typography } from "@mui/material";
import {
  MapOutlined,
  Settings,
  ViewCompactAltOutlined,
} from "@mui/icons-material";
import { useIntl } from "react-intl";
import messages from "./messages";
import { Organization } from "../../model";
import { Process, ProcessStatus } from "../../model/Process";
import { AccountProgram } from "../../model/AccountProgram";
import React from "react";
import { useGlobalOrganizationContext } from "../../hooks/useGlobalOrganizationContext";
import { ACCOUNT_TYPE } from "../../model/EntityRef";
import accountPrograms from "../../api/accountPrograms";
import AccountProfileOverview from "./components/AccountProfileOverview";
import AccountProfileProgramViewer from "./components/program/AccountProgramViewer";
import ProgramEditor from "./components/program/ProgramEditor";
import { DndContext } from "@dnd-kit/core";
import { ProcessCategory } from "../../model/ProcessCategory";
import processCategories from "../../api/processCategories";
import { environment } from "../../util";
import PATHS from "../../components/navigation/_paths";
import EditAccountDialog from "./components/dialogs/EditAccountDialog";
import useFeature from "../../hooks/useFeature";

export enum AccountProfileSubpage {
  Overview = "overview",
  ProgramViewer = "programViewer",
  ProgramEditor = "programEditor",
}

export enum AccountProfileViewer {
  Admin = "admin",
  User = "user",
}

type AccountProfilePageProps = {
  view: AccountProfileSubpage;
  viewer: AccountProfileViewer;
};

type AccountPageContextType = {
  account: AccountDto;
  organization: Organization;
  accountTypeProcesses: Process[];
  programs: AccountProgram[];
  userType: AccountProfileViewer;
  categories?: ProcessCategory[];
  dispatch: (newState: Partial<AccountPageContextType>) => void;
};

export const AccountPageContext = React.createContext<AccountPageContextType>(
  {} as AccountPageContextType,
);

const reducer = (state: AccountPageContextType | undefined, action: object) => {
  return { ...state, ...action } as AccountPageContextType | undefined;
};

const AccountProfilePage: FC<AccountProfilePageProps> = ({ view, viewer }) => {
  const programsFF = useFeature("program");
  const [account, setAccount] = useState<AccountDto>();
  const [icon, setIcon] = useState<string>();
  const [accountPageContext, dispatch] = useReducer(reducer, undefined);
  const [hasProgram, setHasProgram] = useState<boolean>(false);

  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);

  const intl = useIntl();
  const navigate = useNavigate();
  const subjectId = useParams().subjectId;
  const { organization, setOrganization, accountListIsMemberOf, isMember } =
    useGlobalOrganizationContext();
  const isMemberOfOrg = isMember(organization?.id);

  const isEnterprise = environment.organization?.key;

  // stores the routes to all of the subpages
  const routes = new Map<AccountProfileSubpage, string>();
  routes.set(AccountProfileSubpage.Overview, "../overview");
  routes.set(AccountProfileSubpage.ProgramViewer, "../program");
  routes.set(AccountProfileSubpage.ProgramEditor, "../program/editor");

  const breadcrumbs =
    viewer === AccountProfileViewer.Admin && account
      ? [
          { title: "Accounts", link: PATHS.ACCOUNTS.link },
          {
            title: pluralize.plural(account?.account_type_name ?? ""),
            link: PATHS.ACCOUNTS_BY_TYPE.linkTo(account.account_type_key ?? ""),
          },
        ]
      : [];

  const headerAction =
    viewer == AccountProfileViewer.Admin ? (
      view == AccountProfileSubpage.ProgramEditor ? (
        <Button
          variant="contained"
          sx={{ backgroundColor: "primary.main" }}
          onClick={() => {
            navigate(routes.get(AccountProfileSubpage.ProgramViewer) ?? "");
          }}
        >
          View
        </Button>
      ) : view == AccountProfileSubpage.ProgramViewer ? (
        <Button
          variant="contained"
          sx={{ backgroundColor: "primary.main" }}
          onClick={() => {
            navigate(routes.get(AccountProfileSubpage.ProgramEditor) ?? "");
          }}
        >
          Edit
        </Button>
      ) : view == AccountProfileSubpage.Overview ? (
        <IconButton onClick={() => setEditDialogOpen(true)}>
          <Settings />
        </IconButton>
      ) : (
        <></>
      )
    ) : undefined;

  const load = useCallback(async () => {
    // loads all aspects of an account once for smooth page navigation, passes context to children
    const account =
      subjectId && ((await AccountAPI.get(subjectId)) as AccountDto);

    if (account && accountListIsMemberOf) {
      if (!organization) {
        const org =
          account.organization ??
          accountListIsMemberOf.find((a) => account.id === a.id)?.organization;
        setOrganization(org);
      } else {
        setAccount(account);
        dispatch({
          account: account,
          organization: organization,
          accountTypeProcesses: await ProcessAPI.byEntity(
            organization.id,
            {
              entity_type: ACCOUNT_TYPE,
              entity_id: account.account_type_id ?? "",
              counterparty_can_execute: isMemberOfOrg ? undefined : true,
            },
            account.is_test ? ProcessStatus.Draft : ProcessStatus.Published,
          ),
          programs: await accountPrograms.loadByAccountId(account.id),
          userType: viewer,
          categories:
            viewer == AccountProfileViewer.Admin && isMemberOfOrg
              ? await processCategories.byOrganization(organization.id)
              : undefined,
          dispatch: dispatch,
        });
      }
    }
  }, [
    accountListIsMemberOf,
    isMemberOfOrg,
    organization,
    setOrganization,
    subjectId,
    viewer,
  ]);

  // user can see tab if a program exists and at least one is visible
  useEffect(() => {
    if (accountPageContext) {
      const programs = accountPageContext.programs;
      if (programs.length > 0 && programs.find((program) => program.visible))
        setHasProgram(true);
    }
  }, [accountPageContext]);

  // removes the icon from the header when the editor is navigated away from
  useEffect(() => {
    setIcon(undefined);
  }, [view]);

  useEffect(() => {
    load();
  }, [load]);

  const getPage = () => {
    switch (view) {
      case AccountProfileSubpage.Overview:
        return <AccountProfileOverview viewer={viewer} />;
      case AccountProfileSubpage.ProgramViewer:
        return <AccountProfileProgramViewer />;
      case AccountProfileSubpage.ProgramEditor:
        return (
          <DndContext>
            <ProgramEditor updateIcon={(newIcon) => setIcon(newIcon)} />
          </DndContext>
        );
    }
  };

  return (
    <PageContainer variant="full" GridProps={{ overflow: "hidden" }}>
      {account && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflow: "hidden",
          }}
        >
          {(viewer === AccountProfileViewer.Admin || !isEnterprise) && (
            <>
              <PageHeader
                actions={viewer == AccountProfileViewer.Admin && headerAction}
                variant="primary"
                breadcrumbTitle={account.account_name}
                links={breadcrumbs}
                icon={icon}
                title={
                  viewer == AccountProfileViewer.Admin
                    ? account.account_name
                    : organization?.name
                }
              >
                <Typography variant="h1">
                  {viewer == AccountProfileViewer.Admin ? (
                    account.account_name
                  ) : organization?.theme?.longLogoUrl ? (
                    <img
                      src={organization?.theme?.longLogoUrl}
                      style={{
                        maxHeight: "64px",
                        marginTop: "-10px",
                        marginBottom: "-10px",
                      }}
                    />
                  ) : (
                    organization?.name
                  )}
                </Typography>
              </PageHeader>
              {editDialogOpen && (
                <EditAccountDialog
                  open={editDialogOpen}
                  accountDto={account}
                  onClose={() => {
                    setEditDialogOpen(false);
                  }}
                  onUpdate={() => {
                    load();
                  }}
                />
              )}
            </>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              overflow: "hidden",
            }}
          >
            <Tabs
              value={
                !programsFF
                  ? AccountProfileSubpage.Overview
                  : view !== AccountProfileSubpage.Overview
                    ? AccountProfileSubpage.ProgramViewer
                    : view
              }
              sx={{ ml: 2 }}
              onChange={(_, newPage) => navigate(routes.get(newPage) ?? "")}
            >
              <Tab
                label={
                  <Typography
                    variant="subtitle2"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <ViewCompactAltOutlined sx={{ mr: 1 }} />
                    Overview
                  </Typography>
                }
                value={AccountProfileSubpage.Overview}
              />
              {((hasProgram && viewer == AccountProfileViewer.User) ||
                viewer == AccountProfileViewer.Admin) &&
                programsFF && (
                  <Tab
                    label={
                      <Typography
                        variant="subtitle2"
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <MapOutlined sx={{ mr: 1 }} />
                        {intl.formatMessage(messages.account.program.props)}
                      </Typography>
                    }
                    value={AccountProfileSubpage.ProgramViewer}
                  />
                )}
            </Tabs>
            <Box sx={{ my: 2, flexGrow: 1, overflow: "auto" }}>
              {accountPageContext && (
                <AccountPageContext.Provider value={accountPageContext}>
                  {getPage()}
                </AccountPageContext.Provider>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </PageContainer>
  );
};

export default AccountProfilePage;
