import { Box, Button } from "@mui/material";

const ProcessStartInlineEditor = () => {
  return (
    <Box>
      <Button variant="contained" size="small" sx={{ m: 1 }} disabled={true}>
        Start Process
      </Button>
    </Box>
  );
};

export default ProcessStartInlineEditor;
