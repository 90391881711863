import { MoreVert } from "@mui/icons-material";
import {
  Box,
  DialogContentText,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  GridRowSelectionModel,
  GridColDef,
  GridRowId,
  DataGrid,
} from "@mui/x-data-grid";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { IconButtonDropdownMenu } from "../../components/elements";
import CommonDatagridWrapper from "../../components/elements/datagrid/CommonDatagridWrapper";
import { useDisplayOptions } from "../../hooks/useDisplayOptions";
import { useAlert } from "../../lib/alert";
import debounce from "lodash.debounce";
import { currentUserId } from "../../lib/auth";
import { ApiKeyAPI } from "../../api";
import { ApiKey } from "../../model/ApiKey";
import Dialog from "../../components/Dialog";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { PageContainer } from "../../components/layout";

const ApiKeysPage: FC<unknown> = () => {
  useDisplayOptions({});

  const { handleRejectionWithWarning, handleRejectionWithError, success } =
    useAlert();
  const [isLoaded, setIsLoaded] = useState(false);

  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(
    [],
  );
  const [apiKeys, setApiKeys] = useState<Array<ApiKey>>([]);
  const [dropdownClosed, setDropdownClosed] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [showDialogOpen, setShowDialogOpen] = useState(false);
  const [newApiKeyName, setNewApiKeyName] = useState<string>("");
  const [createdApiKey, setCreatedApiKey] = useState<string>("");

  const integrationsColumns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      sortable: true,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "valid",
      headerName: "Valid",
      sortable: true,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "key",
      headerName: "API Key",
      sortable: true,
      flex: 1,
      minWidth: 150,
      renderCell: () => {
        return <>*********************</>;
      },
    },
  ];

  const fetchApiKeys = useCallback(() => {
    return ApiKeyAPI.getAll(currentUserId()).then(
      (apiKeys) => {
        setApiKeys(apiKeys);
        return apiKeys;
      },
      (err) => {
        handleRejectionWithWarning(
          "Could not load integrations for organization",
        )(err);
        return [];
      },
    );
  }, [handleRejectionWithWarning]);

  const reload = useMemo(
    () =>
      debounce(
        () => {
          setIsLoaded(false);
          fetchApiKeys().then((result) => {
            // Cancel any queued calls now that we've finished loading
            reload.cancel();
            setApiKeys(result);
            setIsLoaded(true);
          });
        },
        5000,
        { leading: true },
      ),
    [fetchApiKeys],
  );

  const revokeSelectedApiKeys = () => {
    const promises = selectionModel.map((id: GridRowId) => {
      return ApiKeyAPI.revokeByName(id.toString());
    });

    Promise.all(promises).then(() => {
      setDropdownClosed(true);
      success("Keys revoked");
      reload();
    }, handleRejectionWithError("Failed to revoke all keys"));
  };

  useEffect(() => {
    reload();
  }, [reload]);

  const menu = (
    <IconButtonDropdownMenu
      element={<MoreVert />}
      closed={dropdownClosed}
      setClosed={setDropdownClosed}
    >
      <MenuItem
        onClick={() => {
          setAddDialogOpen(true);
          setDropdownClosed(true);
        }}
      >
        Create New Api Key
      </MenuItem>
      <MenuItem
        onClick={() => {
          revokeSelectedApiKeys();
          setDropdownClosed(true);
        }}
        disabled={!selectionModel.length}
      >
        Revoke Selected Api Keys
      </MenuItem>
    </IconButtonDropdownMenu>
  );

  const loaded = (
    <DataGrid
      checkboxSelection
      disableRowSelectionOnClick
      rows={apiKeys}
      pageSizeOptions={[10]}
      getRowHeight={() => "auto"}
      getRowId={(row) => row.name}
      columns={integrationsColumns}
      onRowSelectionModelChange={(newSelectionModel) => {
        setSelectionModel(newSelectionModel);
      }}
      rowSelectionModel={selectionModel}
    />
  );

  return (
    <PageContainer>
      <Grid item xs={12}>
        <CommonDatagridWrapper
          isLoaded={isLoaded}
          loaded={loaded}
          title="API Keys"
          menu={menu}
        />
        <Dialog
          open={addDialogOpen}
          title={"Create API Key"}
          confirmText={"Create"}
          cancelText={"Cancel"}
          handleConfirm={(): Promise<void> => {
            return ApiKeyAPI.create(newApiKeyName).then((key) => {
              setAddDialogOpen(false);
              setNewApiKeyName("");
              setShowDialogOpen(true);
              setCreatedApiKey(key);
              reload();
            }, handleRejectionWithError("Failed to create api key"));
          }}
          handleCancel={function (): void {
            setAddDialogOpen(false);
          }}
        >
          <DialogContentText>
            Enter the name of your new API Key
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="key name"
            label="API Key Name"
            type="text"
            fullWidth
            variant="standard"
            value={newApiKeyName}
            onChange={(e) => setNewApiKeyName(e.target.value as string)}
          />
        </Dialog>
        <Dialog
          open={showDialogOpen}
          title={"New API Key"}
          confirmText={"Close"}
          handleConfirm={(): Promise<void> => {
            setCreatedApiKey("");
            setShowDialogOpen(false);
            return Promise.resolve();
          }}
          handleCancel={function (): void {
            setCreatedApiKey("");
            setShowDialogOpen(false);
          }}
        >
          <DialogContentText>
            Your API Key has been generated. Copy this key, it will not be shown
            again.
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Box
                sx={{
                  overflowWrap: "anywhere",
                  whiteSpace: "wrap",
                  display: "flex",
                  flex: 1,
                  pt: 2,
                  pr: 2,
                }}
              >
                {createdApiKey}
              </Box>
              <Box sx={{ display: "flex", flex: 0 }}>
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(createdApiKey);
                  }}
                  aria-label="copy"
                  sx={{
                    borderRadius: 0,
                    "&:hover": {
                      color: "primary.main",
                    },
                  }}
                >
                  <Tooltip title="Copy" placement="right" arrow>
                    <ContentCopyIcon />
                  </Tooltip>
                </IconButton>
              </Box>
            </Box>
          </DialogContentText>
        </Dialog>
      </Grid>
    </PageContainer>
  );
};

export default ApiKeysPage;
