import {
  AddOutlined,
  Assignment,
  Business,
  ChecklistSharp,
  SettingsAccessibility,
  SpaceDashboard,
  SupervisedUserCircle,
  SupervisorAccount,
} from "@mui/icons-material";
import IntegrationInstructions from "@mui/icons-material/IntegrationInstructions";
import KeyIcon from "@mui/icons-material/Key";
import appMessages from "../../features/app/messages";
import messages from "../../features/process/messages";
import { getFeature } from "../../hooks/useFeature";

const PATHS = {
  HOME: {
    title: "Home",
    // titleIntl: messages.executions,
    link: "/user",
  },
  DASHBOARD: {
    title: "Dashboard",
    // titleIntl: messages.executions,
    link: "/user/organization/dashboards/admin",
    icon: <SpaceDashboard />,
    visible: true,
  },
  ORGANIZATION_EXECUTIONS: {
    title: "In Flight",
    titleIntl: messages.executions,
    link: "/user/organization/executions",
    icon: <ChecklistSharp />,
    visible: true,
  },
  ACCOUNTS: {
    title: "Accounts",
    link: "/user/organization/accounts",
    icon: <SupervisorAccount />,
    visible: true,
    requiresAdmin: true,
  },
  ACCOUNTS_BY_TYPE: {
    title: "Accounts",
    linkTo: (accountTypeKey: string) =>
      `/user/organization/accounts/byType/${accountTypeKey}`,
    visible: true,
    requiresAdmin: true,
  },
  ACCOUNTS_TYPE_PROCESSES: {
    title: "Account Processes",
    linkTo: (accountTypeKey: string) =>
      `/user/organization/accounts/byType/${accountTypeKey}/processes`,
    visible: true,
    requiresAdmin: true,
  },
  INTEGRATIONS: {
    title: "Integrations",
    link: "/user/integrations",
    icon: <IntegrationInstructions />,
    visible: getFeature("integrations"),
    location: "bottom",
    requiresAdmin: true,
  },
  API_KEYS: {
    title: "API Keys",
    link: "/user/apikeys",
    icon: <KeyIcon />,
    visible: getFeature("apiKeys"),
    location: "bottom",
    requiresAdmin: true,
  },
  ACCOUNT_EXECUTION: {
    linkTo: (processId: string, executionId: string) =>
      `/user/executions/${executionId}`,
  },
  EXECUTION: {
    linkTo: (processId: string, executionId: string) =>
      `/user/organization/executions/${processId}/${executionId}`,
  },
  EXECUTION_TASK_FORM: {
    linkTo: (processId: string, executionId: string, taskId: string) =>
      `/user/organization/executions/${processId}/${executionId}/tasks/${taskId}/form`,
  },
  PROCESS: {
    title: "Flight Plans",
    titleIntl: messages.process,
    link: "/user/organization/processes/{processId}",
    linkTo: (processId: string) => `/user/organization/processes/${processId}`,
    icon: <Assignment />,
    visible: true,
    requiresAdmin: true,
  },
  PROCESS_TASK: {
    title: "Form",
    link: "/user/organization/processes/{processId}/tasks/{taskId}",
    linkTo: (processId: string, taskId: string) =>
      `/user/organization/processes/${processId}/tasks/${taskId}`,
    visible: true,
    requiresAdmin: true,
  },
  PROCESS_TASK_ELEMENT: {
    title: "Task Element",
    link: "/user/organization/processes/{processId}/tasks/{taskId}/elements/{elementId}",
    linkTo: (processId: string, taskId: string, elementId: string) =>
      `/user/organization/processes/${processId}/tasks/${taskId}/elements/${elementId}`,
    visible: true,
    requiresAdmin: true,
  },
  PROCESS_TASK_ELEMENT_FORM: {
    title: "Task Element Form",
    link: "/user/organization/processes/{processId}/tasks/{taskId}/elements/{elementId}/keys/{key}",
    linkTo: (
      processId: string,
      taskId: string,
      elementId: string,
      key: string,
    ) =>
      `/user/organization/processes/${processId}/tasks/${taskId}/elements/${elementId}/keys/${key}`,
    visible: true,
    requiresAdmin: true,
  },
  PROCESSES: {
    name: "processes", // Used for Cypress testing
    title: "Flight Plans",
    titleIntl: messages.processes,
    link: "/user/organization/processes",
    icon: <Assignment />,
    visible: true,
    requiresAdmin: true,
  },
  ACCOUNT_PROFILE: {
    title: "Account",
    visible: true,
    requiresAdmin: false,
    linkTo: (accountId: string) => `/user/organization/accounts/${accountId}`,
  },
  ACCOUNT_PORTAL: {
    title: "Portal",
    titleIntl: appMessages.portal,
    visible: true,
    requiresAdmin: false,
    linkTo: (accountId: string) => `/user/organizations/accounts/${accountId}`,
  },
  ORGANIZATION_MANAGE: {
    name: "organization",
    title: "Manage Organization",
    link: "/user/organization/manage",
    visible: true,
    icon: <Business />,
    requiresAdmin: true,
  },
  ORGANIZATION_CREATE: {
    title: "Create Organization",
    link: "/user/organizations/create",
    icon: <AddOutlined />,
    visible: true,
    requiresAdmin: true,
  },
  USER_MANAGE_ORGANIZATIONS: {
    title: "Manage Organizations",
    link: "/user/dashboard",
    visible: true,
  },
  MEMBERS: {
    name: "members",
    title: "Members",
    link: "/user/organization/members",
    icon: <SupervisedUserCircle />,
    visible: true,
  },
  ROLES: {
    name: "roles",
    title: "Roles",
    link: "/user/organization/roles",
    icon: <SettingsAccessibility />,
    visible: true,
  },
  PROFILE: {
    name: "profile",
    title: "Profile",
    link: "/user/profile/preferences", //profile page opens preferences by default
    visible: false,
  },
  PROFILE_PREFERENCES: {
    name: "preferences",
    title: "Preferences",
    link: "/user/profile/preferences",
    icon: <ChecklistSharp />,
    visible: false,
  },
  PROFILE_ORGANIZATIONS: {
    name: "organizations",
    title: "Organizations",
    link: "/user/profile/organizations",
    icon: <ChecklistSharp />,
    visible: false,
  },
};
export default PATHS;
