import { createContext, useEffect, useMemo, useState } from "react";
import { Account, Organization } from "../../../model";
import { AccountType } from "../../../model/AccountType";
import { TaskView, TaskViews } from "../../task";
import useOrganizationDirectory from "../../task/hooks/useOrganizationDirectory";
import { getAccountName } from "../utils";
import AccountSelectExpanded from "./AccountSelect/AccountSelectExpanded";
import AccountSelectCondensed from "./AccountSelect/AccountSelectCondensed";
import CreateAccountDialog from "./dialogs/CreateAccountDialog";

export type AccountSelectTaskOption = {
  type: "account" | "organization";
  data: Account | Organization;
  label: string;
  icon?: string;
};

// AccountSelectTaskContext definition

type AccountSelectTaskContextType = {
  options: AccountSelectTaskOption[];
  query?: string;
  selected?: AccountSelectTaskOption;
  disabled?: boolean;
  setQuery: (newQuery: string) => void;
};

export const AccountSelectTaskContext =
  createContext<AccountSelectTaskContextType>({
    options: [],
    setQuery: () => {},
  });

const AccountSelectTaskWrapper = ({
  organization,
  accountType,
  taskView = TaskViews.CONDENSED,
  onSelect,
  onCreateAccount,
  value,
  disabled,
  hideOrganizations,
}: {
  organization: Organization;
  accountType?: AccountType;
  taskView?: TaskView;
  onSelect?: (option: AccountSelectTaskOption | undefined) => void;
  onCreateAccount?: (account: Account) => void;
  value?: Account;
  disabled?: boolean;
  hideOrganizations?: boolean;
}) => {
  const { accounts, organizations, query, setQuery, addAccounts, findAccount } =
    useOrganizationDirectory(organization, accountType, hideOrganizations);

  const [selected, setSelected] = useState<AccountSelectTaskOption | undefined>(
    value && (value.id || value.primary_email)
      ? { type: "account", data: value, label: getAccountName(value) }
      : undefined,
  );

  const [createAccountDialogOpen, setCreateAccountDialogOpen] =
    useState<boolean>(false);

  useEffect(() => {
    onSelect && onSelect(selected);
  }, [onSelect, selected]);

  const accountOptions = useMemo(() => {
    const accountOptions: AccountSelectTaskOption[] = [];
    accounts.forEach((account) =>
      accountOptions.push({
        type: "account",
        data: account,
        label: getAccountName(account),
      }),
    );
    return accountOptions;
  }, [accounts]);

  const organizationOptions = useMemo(() => {
    const organizationOptions: AccountSelectTaskOption[] = [];
    organizations.forEach((organization) =>
      organizationOptions.push({
        type: "organization",
        data: organization,
        label: organization.name,
      }),
    );
    return organizationOptions;
  }, [organizations]);

  const options = useMemo(() => {
    return [...accountOptions, ...organizationOptions];
  }, [accountOptions, organizationOptions]);

  const content = useMemo(() => {
    switch (taskView) {
      case TaskViews.EXPANDED:
        return (
          <AccountSelectExpanded
            setSelected={(option) => setSelected(option)}
            setDialogOpen={(open) => setCreateAccountDialogOpen(open)}
          />
        );
      default:
        return (
          <AccountSelectCondensed
            setSelected={(option) => setSelected(option)}
            setDialogOpen={(open) => setCreateAccountDialogOpen(open)}
          />
        );
    }
  }, [taskView]);

  return (
    <>
      <AccountSelectTaskContext.Provider
        value={{ options, selected, disabled, query, setQuery }}
      >
        {content}
      </AccountSelectTaskContext.Provider>
      {createAccountDialogOpen && ( // doesnt need to be conditional, but stops this from being rerendered when its not open
        <CreateAccountDialog
          open={createAccountDialogOpen}
          accountType={accountType}
          organizationId={organization.id}
          account={{
            account_name: query?.trim(),
            primary_email: query && query.indexOf("@") > 0 ? query?.trim() : "",
          }}
          onClose={() => setCreateAccountDialogOpen(false)}
          onCreate={(account) => {
            const existingAccount = findAccount(account);
            if (!existingAccount) {
              onCreateAccount && onCreateAccount(account);
              setQuery(getAccountName(account));
              addAccounts([account]);
            }
            setSelected({
              type: "account",
              data: account,
              label: getAccountName(account),
            });
            addAccounts([account]);
          }}
        />
      )}
    </>
  );
};

export default AccountSelectTaskWrapper;
