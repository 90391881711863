import { Box, Button } from "@mui/material";
import { useState } from "react";
import ConfirmationModal from "../../../../process/components/confirmation-modal/ConfirmationModal";
import {
  Confirmation,
  TaskExecutionComponentProps,
  TaskExecutionState,
  TaskShortCircuit,
  TaskShortCircuitExecution,
} from "../../../types";
import { SubmitButton } from "../../../../../components/elements";
import ProcessExecutionProgressComponent from "../process-monitor/ProcessExecutionProgressComponent";

const NO_OP = async () => {
  //
};
const ShortCircuitExecutionComponent = ({
  onValueChange,
  children,
  ...props
}: TaskExecutionComponentProps<
  TaskShortCircuit,
  TaskShortCircuitExecution
>) => {
  // states to manage halt confirmation modal
  const [halt, setHalt] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<Confirmation>({
    title: "",
    body: "",
  });

  const onExecute = async (halt: boolean) => {
    if (props.onExecute) {
      await props.onExecute({
        ...props.taskExecution,
        task_id: props.task.id,
        state: TaskExecutionState.Completed,
        data: {
          halt,
        },
      });
    }

    onValueChange &&
      onValueChange({
        ...props.task,
        data: {
          ...props.task.data,
          halt,
        },
      } as TaskShortCircuit);
  };

  const handleHalt = () => {
    //We always want confirm if the user wants to halt the process
    setHalt(true);
    setModalData({
      title: "Final Confirmation",
      body: "Are you sure you do not want to continue?",
      cancelLabelText: "No",
      continueLabelText: "Yes",
    });
    setOpenModal(true);
  };

  const handleContinue = async () => {
    const confirmation = props.task.data?.confirmation;
    if (confirmation) {
      setHalt(false);
      setModalData(confirmation);
      setOpenModal(true);
    } else {
      await onExecute(false);
    }
  };

  const modalProps = {
    openModal,
    handleCloseModal: () => setOpenModal(false),
    executeModal: () => onExecute(halt),
    confirmationData: modalData,
  };

  const combinedData = { ...props.task.data, ...props.taskExecution?.data };
  const haltSet = combinedData.halt !== undefined;
  const isDisabled = haltSet || props.isDisabled;

  const content = (
    <>
      <Box>
        <SubmitButton
          size="small"
          sx={{ m: 1, textTransform: "none", pr: "20px", pl: "20px" }}
          disabled={isDisabled && combinedData?.halt}
          onSubmit={isDisabled ? NO_OP : handleContinue}
        >
          {(props.task as TaskShortCircuit).data?.continueLabel}
        </SubmitButton>
        <Button
          variant="outlined"
          color="error"
          size="small"
          sx={{ m: 1, textTransform: "none", pr: "20px", pl: "20px" }}
          disabled={isDisabled && !combinedData?.halt}
          onClick={isDisabled ? NO_OP : handleHalt}
        >
          {(props.task as TaskShortCircuit).data?.haltLabel}
        </Button>
        {openModal && <ConfirmationModal {...modalProps} />}
      </Box>
      {props.taskExecution?.data?.haltProcessExecutionId && (
        <ProcessExecutionProgressComponent
          processExecutionId={props.taskExecution.data.haltProcessExecutionId}
        />
      )}
    </>
  );
  if (children) {
    return children({ content });
  }
  return content;
};

export default ShortCircuitExecutionComponent;
