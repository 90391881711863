import { Box, Tooltip, TooltipProps } from "@mui/material";
import { PropsWithChildren, useState } from "react";

const OverflowTooltip = (props: PropsWithChildren<TooltipProps>) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  return (
    <Tooltip {...props} open={showTooltip}>
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        onMouseEnter={(event) => {
          // only shows a tooltip if the content is overflowing
          const element = event.currentTarget;
          setShowTooltip(element.clientWidth < element.scrollWidth);
        }}
        onMouseLeave={() => {
          setShowTooltip(false);
        }}
      >
        {props.children}
      </Box>
    </Tooltip>
  );
};

export default OverflowTooltip;
