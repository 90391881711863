import { Divider, ListItemIcon, Menu, MenuItem, SvgIcon } from "@mui/material";
import ToolbarButton from "./ToolbarButton";
import { ComponentType, PropsWithChildren, ReactNode, useState } from "react";
import { ArrowDropDown, SvgIconComponent } from "@mui/icons-material";

type CustomMenuItem = {
  component: ComponentType<{
    onClick: () => void;
  }>;
  separator?: boolean;
};

type ToolbarMenuItem =
  | {
      onClick: () => void;
      label: string;
      icon?: SvgIconComponent;
      separator?: boolean;
      id?: string;
    }
  | CustomMenuItem;

export type DatagridToolbarMenuButtonProps = PropsWithChildren<{
  label?: string;
  items?: Array<ToolbarMenuItem>;
}>;

const DatagridToolbarMenuButton = ({
  label = "More",
  items = [],
  children,
}: DatagridToolbarMenuButtonProps) => {
  const [menuExpanded, setMenuExpanded] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  return (
    <>
      <ToolbarButton
        variant="text"
        onClick={(event) => {
          setMenuAnchorEl(event.currentTarget);
          setMenuExpanded(true);
        }}
        color="inherit"
        endIcon={<ArrowDropDown />}
        textContent={label}
      />
      <Menu
        anchorEl={menuAnchorEl}
        open={menuExpanded}
        onClose={() => {
          setMenuExpanded(false);
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {children}
        {items.reduce<ReactNode[]>(
          (items, { separator, ...menuItem }, index) => {
            if ("component" in menuItem) {
              const Component = menuItem.component;
              items.push(
                <Component
                  key={index}
                  onClick={() => setMenuExpanded(false)}
                />,
              );
            } else {
              const { onClick, label, icon: Icon, id } = menuItem;
              items.push(
                <MenuItem
                  key={index}
                  onClick={() => {
                    setMenuExpanded(false);
                    onClick();
                  }}
                  data-cy={id}
                >
                  <ListItemIcon>
                    {Icon && <Icon fontSize="small" />}
                    {!Icon && <SvgIcon fontSize="small" />}
                  </ListItemIcon>
                  {label}
                </MenuItem>,
              );
            }
            if (separator) {
              items.push(<Divider key={`${index}-separator`} />);
            }
            return items;
          },
          [],
        )}
      </Menu>
    </>
  );
};

export default DatagridToolbarMenuButton;
