import Organizations from "edo-platform-frontend/src/api/organizations";
import Invitations from "edo-platform-frontend/src/api/invitations";
import Roles from "edo-platform-frontend/src/api/roles";
import { UserProvider, InvitationProvider } from ".";

const userProvider = {
  fetch: (organizationId: string) => {
    if (!organizationId) {
      return Promise.resolve([]);
    }
    return Organizations.getMembers(organizationId).then((result) => {
      return result;
    });
  },
  removeFromOrganization: (organizationId: string, userId: string) => {
    return Organizations.removeMember(organizationId, userId);
  },
  assignRole: (roleId: string, userId: string) => {
    return Roles.assignRole(roleId, userId);
  },
  unassignRole: (roleId: string, userId: string) => {
    return Roles.unassignRole(roleId, userId);
  },
} as UserProvider;

const invitationProvider = {
  fetch: (organizationId: string) => {
    if (!organizationId) {
      return Promise.resolve([]);
    }
    return Invitations.membersByOrganization(organizationId).then((result) => {
      return result;
    });
  },
  delete: (id: string) => {
    return Invitations.delete(id).then((result) => {
      return result;
    });
  },
  assignRole: (roleId: string, userId: string) => {
    return Invitations.assignRole(roleId, userId);
  },
  unassignRole: (roleId: string, invitationId: string) => {
    return Invitations.unassignRole(roleId, invitationId);
  },
} as InvitationProvider;

export { userProvider, invitationProvider };
