import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Card,
  Grid,
  Container,
} from "@mui/material";
import { FC, useState, useEffect, useCallback } from "react";
import PublicExecutor from "../../api/public_executor";
import { useAlert } from "../../lib/alert";
import {
  Task,
  TaskCreateAccountExecution,
  TaskExecution,
  TaskExecutionState,
  TaskType,
} from "../task";
import { useNavigate, useParams } from "react-router-dom";
import SingleTaskExecutionCard from "../process/components/SingleTaskExecutionCard";
import { Process } from "../../model/Process";
import { createTaskExecution } from "../task/components/task-types/getTaskByType";
import { useGlobalOrganizationContext } from "../../hooks/useGlobalOrganizationContext";
import { CardActionBar, CardTitle } from "../../components/elements";
import { AccountRegistrationType, AccountType } from "../../model/AccountType";
import {
  loadTasksByPublicExecutorId as loadTasksByPublicExecutorIdHelper,
  loadTaskExecutionsByPublicExecutorId as loadTaskExecutionsByPublicExecutorIdHelper,
} from "./index";
import { useGlobalRoleContext } from "../../hooks/useGlobalRoleContext";
import { Role } from "../../model";
import NotFound from "../app/NotFound";
import { ProcessExecutionScreen } from "../process/components/execution";
import { ProcessExecutionProvider } from "../process/hooks";
import useProcessExecution from "../process/hooks/useProcessExecution";

type PublicExecutionRendererProps = {
  publicExecutorId: string;
  processExecutionId?: string;
  onSubmit?: (processExecutionId?: string) => void;
};

const NewProcessExecutionScreenContainer = ({
  executionId,
  publicExecutorId,
}: {
  executionId: string;
  publicExecutorId: string;
}) => {
  const processExecutionValues = useProcessExecution(executionId, {
    publicExecutorId,
  });

  return (
    <ProcessExecutionProvider value={processExecutionValues}>
      <ProcessExecutionScreen />
    </ProcessExecutionProvider>
  );
};

const PublicExecutionRenderer: FC<PublicExecutionRendererProps> = (props) => {
  const { orgKey } = useParams<{ orgKey: string }>();
  const { organization } = useGlobalOrganizationContext();
  const navigate = useNavigate();
  const { handleRejectionWithError, error } = useAlert();

  const [process, setProcess] = useState<Process | undefined>();
  const [tasks, setTasks] = useState<Array<Task>>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [taskExecutions, setTaskExecutions] = useState<Array<any>>([]);
  const [submitted, setSubmitted] = useState<boolean>();
  const [accountType, setAccountType] = useState<AccountType | undefined>();
  const [executionId, setExecutionId] = useState<string | undefined>(
    props.processExecutionId,
  );
  const [loading, setLoading] = useState<boolean>();
  const [taskIndex, setTaskIndex] = useState(0);
  const { setRoles } = useGlobalRoleContext();
  const [show404, setShow404] = useState(false);

  const loadTasksByPublicExecutorId = useCallback(async () => {
    if (!organization) {
      return;
    }

    const result = await loadTasksByPublicExecutorIdHelper(
      props.publicExecutorId,
      organization.id,
    ).catch(handleRejectionWithError("Error loading tasks"));

    if (!result) {
      setShow404(true);
      return;
    }

    const { process, tasks, accountType } = result;

    setProcess(process);
    setTasks(tasks);
    setAccountType(accountType);

    if (process.org_id !== organization?.id) {
      setShow404(true);
      return;
    }

    if (accountType?.role_id) {
      // Add the account type role to the list of roles that a user can act as
      const accountTypeRole: Role = {
        id: accountType.role_id,
        name: accountType.name,
        org_id: accountType.organization_id,
      };
      setRoles((roles) =>
        roles.some((r) => r.id === accountTypeRole.id)
          ? roles
          : [...roles, accountTypeRole],
      );
    }

    return process;
  }, [
    props.publicExecutorId,
    setRoles,
    organization,
    handleRejectionWithError,
  ]);

  const loadTaskExecutionsByPublicExecutorId = useCallback(
    async (tasks: Array<Task>) => {
      if (!organization) {
        return;
      }
      const result = await loadTaskExecutionsByPublicExecutorIdHelper(
        props.publicExecutorId,
        tasks,
        organization.id,
        executionId,
      ).catch(handleRejectionWithError("Error loading task executions"));

      if (!result) {
        setShow404(true);
        return;
      }

      setTaskExecutions(result);
      return result;
    },
    [
      executionId,
      props.publicExecutorId,
      organization,
      handleRejectionWithError,
    ],
  );

  useEffect(() => {
    setLoading(true);
    loadTasksByPublicExecutorId()
      .then(async (p) => {
        if (!p || !p.tasks) {
          return [p, []];
        }
        return [p, await loadTaskExecutionsByPublicExecutorId(p.tasks)];
      })
      .then(([p, taskExecutions]) => {
        let mostRecentCompletedTaskIndex = 0;
        const process = p as Process | undefined;

        if (!process) {
          return;
        }

        const processTasks = process.tasks as Task[];
        while (mostRecentCompletedTaskIndex < processTasks.length) {
          const currentTaskExecution = (
            (taskExecutions ?? []) as TaskExecution[]
          ).find(
            (taskExecution) =>
              taskExecution.task_id ==
              processTasks[mostRecentCompletedTaskIndex].id,
          );
          if (currentTaskExecution?.state !== TaskExecutionState.Completed) {
            break;
          }
          mostRecentCompletedTaskIndex++;
        }
        if (
          mostRecentCompletedTaskIndex > 0 &&
          mostRecentCompletedTaskIndex < processTasks.length
        ) {
          setTaskIndex(mostRecentCompletedTaskIndex);
        }
      }, handleRejectionWithError("Unknown error encountered when loading data."))
      .finally(() => {
        setLoading(false);
      });
  }, [
    handleRejectionWithError,
    loadTaskExecutionsByPublicExecutorId,
    loadTasksByPublicExecutorId,
  ]);

  const handleSubmitPublicExecution = useCallback(
    (taskExecutions: Array<TaskExecution>) => {
      if (!organization) {
        return;
      }

      try {
        return PublicExecutor.execute(
          props.publicExecutorId,
          taskExecutions,
          organization.id,
          undefined,
          executionId,
        ).then((execution) => {
          if (!executionId) {
            navigate("executions/" + execution.id, {
              replace: true,
            });
          }

          // Set the execution id
          setExecutionId(execution.id);

          // If this is the last task, then set the execution submitted
          // so that the user can see the thank you page
          if (taskIndex === tasks.length - 1) {
            if (props.onSubmit) {
              props.onSubmit(execution.id);
            } else {
              setSubmitted(true);
            }
          }
        }, handleRejectionWithError("Error encountered during submission"));
        // Error should be of type any so that it can be used directly in the error
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        error(err.toString());
      }
    },
    [
      organization,
      props,
      executionId,
      handleRejectionWithError,
      taskIndex,
      tasks.length,
      navigate,
      error,
    ],
  );

  const taskExecution =
    tasks.length > taskIndex
      ? taskExecutions?.find(
          (taskExecution) => taskExecution.task_id == tasks[taskIndex].id,
        )
      : undefined;
  const currentTask = tasks[taskIndex];
  const hasNext = tasks.length > taskIndex + 1;
  const hideBackButton = taskIndex === 0;

  const content = loading ? (
    <Box sx={{ mt: 4, textAlign: "center", width: "100%" }}>
      <CircularProgress />
    </Box>
  ) : (
    <>
      {currentTask && (
        <SingleTaskExecutionCard
          process={process}
          task={currentTask}
          taskExecution={taskExecution}
          loaded={!!tasks[0]}
          hasNext={hasNext}
          icon={process?.icon}
          onExecute={async () => {
            // Store task execution
            const taskExecution = createTaskExecution(tasks[taskIndex]);
            let newTaskExecutions = taskExecutions;
            if (!taskExecutions || taskExecutions.length === 0) {
              newTaskExecutions = [taskExecution];
            }
            const existingExecution = taskExecutions.find(
              (execution) => execution.task_id === currentTask.id,
            );
            if (existingExecution) {
              newTaskExecutions = taskExecutions.map((execution) => {
                if (execution.task_id === currentTask.id) {
                  return taskExecution;
                }
                return execution;
              });
            } else {
              newTaskExecutions = [...taskExecutions, taskExecution];
            }

            // Submit only the latest task execution
            await handleSubmitPublicExecution([newTaskExecutions[taskIndex]]);

            // Load the updated task executions which may have undergone
            // templating from the backend
            await loadTaskExecutionsByPublicExecutorId(tasks);
          }}
          onBack={
            hideBackButton
              ? undefined
              : () => {
                  if (taskIndex > 0) {
                    setTaskIndex((taskIndex) => taskIndex - 1);
                  } else if (taskIndex === 0) {
                    navigate(`/${orgKey}/`);
                  }
                }
          }
          onValueChange={(task) => {
            tasks[taskIndex] = task;
          }}
          onNext={() => {
            setTaskIndex(taskIndex + 1);
          }}
        />
      )}
    </>
  );

  if (executionId) {
    return (
      <Container>
        <Card
          elevation={2}
          sx={{
            position: "relative",
            bgcolor: (theme) => theme.palette.grey[100],
            pr: 2,
          }}
        >
          <Grid
            container
            sx={{
              height: "100%",
              position: "relative",
              justifyContent: "center",
              alignContent: "flex-start",
              p: 2,
              flex: 1,
            }}
            spacing={2}
          >
            <NewProcessExecutionScreenContainer
              executionId={executionId}
              publicExecutorId={props.publicExecutorId!}
            />
          </Grid>
        </Card>
      </Container>
    );
  }

  return show404 ? (
    <NotFound />
  ) : (
    <Grid
      container
      sx={{
        height: "100%",
        position: "relative",
        justifyContent: "center",
        alignContent: "flex-start",
        p: 2,
        flex: 1,
      }}
      spacing={2}
    >
      <Grid
        item
        xs={12}
        sm={10}
        md={8}
        lg={6}
        xl={4}
        sx={{ "> div": { mb: 4 } }}
      >
        {submitted ? (
          <Card elevation={1}>
            <Box sx={{ px: 3, py: 2 }}>
              <CardTitle>Thank You! Your request has been submitted.</CardTitle>
              <Typography>
                We have received your information and will handle your request
                shortly. Updates will be sent to your inbox.
              </Typography>
              <br />
              {accountType &&
                accountType.registration_type ===
                  AccountRegistrationType.SelfRegistration && (
                  <Typography fontWeight="bold">
                    Continue creating an account to track progress of the
                    services you requested.
                  </Typography>
                )}
            </Box>
            <CardActionBar align="space-between" sx={{ px: 3, py: 2 }}>
              <Button
                variant="text"
                onClick={() =>
                  navigate(
                    `/${orgKey}/works/${props.publicExecutorId}/executions/${executionId}`,
                  )
                }
              >
                View your submission
              </Button>
              {accountType &&
                accountType.registration_type ===
                  AccountRegistrationType.SelfRegistration && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      const selectAccountExecution = taskExecutions.find(
                        (execution) => execution.type == TaskType.CreateAccount,
                      ) as TaskCreateAccountExecution;
                      const emailAddress =
                        selectAccountExecution.data?.account?.primary_email;

                      navigate(
                        `/register?email=${emailAddress}&orgId=${organization?.id}&accountType=${
                          process?.entity_id ?? ""
                        }`,
                      );
                    }}
                  >
                    Create Account
                  </Button>
                )}
            </CardActionBar>
          </Card>
        ) : (
          content
        )}
      </Grid>
    </Grid>
  );
};

export default PublicExecutionRenderer;
