import { Grid, TextField } from "@mui/material";
import { Confirmation } from "../../types";

type TaskConfirmationFieldsProps = {
  confirmation: Confirmation;
  onUpdate: (confirmation: Confirmation) => void;
};

export const TaskConfirmationFields = ({
  confirmation,
  onUpdate,
}: TaskConfirmationFieldsProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          onChange={(event) => {
            onUpdate({
              ...confirmation,
              title: event.target.value,
            });
          }}
          name="confirmationTitle"
          value={confirmation.title}
          label={"Confirmation Title"}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          onChange={(event) => {
            onUpdate({
              ...confirmation,
              body: event.target.value,
            });
          }}
          name="confirmationBody"
          value={confirmation.body}
          label={"Confirmation Body"}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          onChange={(event) => {
            onUpdate({
              ...confirmation,
              cancelLabelText: event.target.value.length
                ? event.target.value
                : undefined,
            });
          }}
          name="confirmationCancel"
          value={confirmation.cancelLabelText ?? ""}
          label={"Cancel Button Label"}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          onChange={(event) => {
            onUpdate({
              ...confirmation,
              continueLabelText: event.target.value.length
                ? event.target.value
                : undefined,
            });
          }}
          name="confirmationContinue"
          value={confirmation.continueLabelText ?? ""}
          label={"Continue Button Label"}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    </Grid>
  );
};
