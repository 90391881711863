import { Button, SxProps, Typography } from "@mui/material";

const RetryTaskButton = ({
  onClick,
  sx,
}: {
  onClick?: () => void;
  sx?: SxProps;
}) => {
  return (
    <Button
      variant="contained"
      size="small"
      sx={{
        backgroundColor: "white",
        color: "black",
        border: "solid black 1px",
        textTransform: "none",
        ":hover": {
          background: "white",
        },
        ...sx,
      }}
      onClick={(evt) => {
        evt.stopPropagation();
        onClick && onClick();
      }}
    >
      <Typography variant="caption">Retry</Typography>
    </Button>
  );
};

export default RetryTaskButton;
