import Role from "./Role";

export enum InvitationType {
  Account = "ACCOUNT",
  Member = "MEMBER",
}

export type InvitationDisplayData = {
  creator_name: string;
  organization_name: string;
  description?: string;
  organizationShortLogoURL?: string;
};

export type InvitationInstruction = {
  roles?: string[];
};
export default class Invitation {
  public id = "";
  public target_user_email = "";
  public target_user_id = "";
  public creator_user_id = "";
  public organization_id = "";
  public type: InvitationType = InvitationType.Member;
  public account_id?: string;
  public instruction?: InvitationInstruction;
  public roles?: Array<Role>;
  public display?: InvitationDisplayData;
  public origin?: string; // used in sending invite emails
}
