import { FC } from "react";
import OrganizationScopedPage from "../../components/OrganizationScopedPage";
import { useDisplayOptions } from "../../hooks/useDisplayOptions";
import { OrganizationMembersProvider } from "../../providers";
import { UserOrganizationRelationshipType } from "../../types";
import IntegrationsGrid from "./IntegrationsGrid";
import { useGlobalOrganizationContext } from "../../hooks/useGlobalOrganizationContext";

const IntegrationsPage: FC<unknown> = () => {
  useDisplayOptions({});

  const { organization } = useGlobalOrganizationContext();

  return (
    <OrganizationScopedPage
      category="integrations"
      displayOrganizationActionMenu={false}
    >
      {organization && (
        <IntegrationsGrid
          organizationId={organization.id}
          users={OrganizationMembersProvider}
          userOrganizationRelationshipType={
            UserOrganizationRelationshipType.Member
          }
        />
      )}
    </OrganizationScopedPage>
  );
};

export default IntegrationsPage;
