import { atom } from "jotai";
import { Process } from "../../../model/Process";

type ProcessFilterHandler = (process: Partial<Process>) => void;

const onProcessFilter = atom<{ fn: ProcessFilterHandler }>({
  fn: () => {
    console.log("Filter not handled.");
  },
});
export default atom<ProcessFilterHandler, [ProcessFilterHandler], void>(
  (get) => get(onProcessFilter).fn,
  (_get, set, newOnProcessFilter) =>
    set(onProcessFilter, { fn: newOnProcessFilter }),
);
