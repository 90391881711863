import { Button } from "@mui/material";
import {
  Task,
  TaskExecution,
  TaskExecutionComponentProps,
  TaskExecutionState,
} from "../../../types";

export enum UnstructuredTypes {
  CreateOrganization = "CREATE_ORGANIZATION",
  Register = "REGISTER",
  Complete = "COMPLETE",
  StripeCheckout = "STRIPE_CHECKOUT",
}

export const CompleteUnstructuredExecutionComponent = (
  props: TaskExecutionComponentProps<Task, TaskExecution>,
) => {
  return (
    <Button
      onClick={() => {
        if (!props.onExecute) {
          return;
        }

        const execution = {
          ...props.taskExecution,
          task_id: props.task.id,
          state: TaskExecutionState.Completed,
        };

        props.onExecute(execution as TaskExecution);
      }}
    >
      Complete
    </Button>
  );
};

export const CompleteUnstructuredInlineEditor = () => {
  return <Button disabled={true}>Complete</Button>;
};
