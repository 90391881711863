import { FC, useState, useEffect, useMemo } from "react";
import debounce from "lodash.debounce";
import { useAlert } from "../../../../lib/alert";
// import { AccountProvider } from "../../../../providers";
import { AccountProvider } from "../../../../providers";
import { AuditEvent } from "../../../../model/AuditEvent";
import { filterAuditEvents } from "../../utils";
import { DataGrid, GridRowSelectionModel } from "@mui/x-data-grid";
import activityListColumns, { visibilityColumns } from "./activityListColumns";
import { CommonDatagridWrapper } from "../../../../components/elements";
import messages from "../../../app/messages";
import { useIntl } from "react-intl";

type CommonActivityLogProps = {
  audits: AccountProvider;
  organizationId?: string;
  subjectId: string;
  onDataLoaded?: (processes: AuditEvent[]) => void;
};

const CommonActivityLogGrid: FC<CommonActivityLogProps> = ({
  audits,
  organizationId,
  subjectId,
  onDataLoaded,
}) => {
  const { handleRejectionWithError } = useAlert();
  const intl = useIntl();

  //useStates
  const [activitySelectionModel, setActivitySelectionModel] =
    useState<GridRowSelectionModel>();
  const [isLoaded, setIsLoaded] = useState(false);
  const [activityList, setActivityList] = useState<Array<AuditEvent>>([]);

  const reload = useMemo(
    () =>
      debounce(
        () => {
          setIsLoaded(false);
          audits.getAuditEvents(subjectId).then((response) => {
            // Cancel any queued calls now that we've finished loading
            reload.cancel();

            //filter events
            const filtered = filterAuditEvents(response);
            setActivityList(filtered);
            setIsLoaded(true);
            if (onDataLoaded) {
              onDataLoaded(response);
            }
          }, handleRejectionWithError("Failed to load audit events for organization"));
        },
        5000,
        { leading: true },
      ),
    [audits, handleRejectionWithError, subjectId, onDataLoaded],
  );

  useEffect(() => {
    reload();
  }, [reload, organizationId]);

  const activityDataGrid = (
    <DataGrid
      disableColumnSelector={true}
      rows={activityList}
      columns={activityListColumns}
      columnVisibilityModel={visibilityColumns}
      pageSizeOptions={[10]}
      getRowHeight={() => "auto"}
      onRowSelectionModelChange={(newSelectionModel) => {
        setActivitySelectionModel(newSelectionModel);
      }}
      rowSelectionModel={activitySelectionModel}
      autoHeight
    />
  );

  return (
    <CommonDatagridWrapper
      isLoaded={isLoaded}
      loaded={activityDataGrid}
      title={intl.formatMessage(messages.activityLog.props)}
      variant="card"
    />
  );
};

export default CommonActivityLogGrid;
