import { FC } from "react";
import { AuditEvent } from "../../../../../model/AuditEvent";
import { getAuditLogEventInfo } from "../../../utils";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";

type ActivityCellProps = {
  auditEvent: AuditEvent;
};

const ActivityCell: FC<ActivityCellProps> = ({ auditEvent }) => {
  const structure = getAuditLogEventInfo(auditEvent);
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1, my: 2 }}>
      {structure.icon}
      <Typography variant="body1">{structure.title}</Typography>
    </Box>
  );
};

export default ActivityCell;
