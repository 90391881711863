import { Breadcrumbs as MuiBreadcrumbs, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export type BreadcrumbLink = {
  title: React.ReactNode;
  titleIntl?: JSX.Element;
  link: string;
};

export type BreadcrumbsProps = {
  links: Array<BreadcrumbLink>;
  page: React.ReactNode;
};
const Breadcrumbs = ({ links, page }: BreadcrumbsProps) => {
  return (
    <MuiBreadcrumbs separator=">">
      {links.map((link, index) => {
        return (
          <Link
            data-cy={"breadcrumbs-link"}
            underline="hover"
            key={index}
            component={RouterLink}
            to={link.link}
            sx={{ color: (theme) => theme.palette.text.secondary }}
          >
            <Typography variant="caption">
              {link.titleIntl ?? link.title}
            </Typography>
          </Link>
        );
      })}
      <Typography variant="caption" fontWeight="bold">
        {page}
      </Typography>
    </MuiBreadcrumbs>
  );
};
export default Breadcrumbs;
