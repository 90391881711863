import { Grid, Tooltip, Typography } from "@mui/material";
import { DashboardElementHeader } from "../../../model/Dashboard";
import { Info } from "@mui/icons-material";
import { DashboardElementRenderer } from "./DashboardElementRenderer";
import React from "react";

type DashboardElementHeaderProps = {
  element: DashboardElementHeader;
};

export const Header = ({ element }: DashboardElementHeaderProps) => {
  return (
    <>
      <Grid container alignItems={"center"} gap={1}>
        <Typography
          variant={element.variant == "body" ? "body1" : "h4"}
          fontWeight={element.variant == "body" ? undefined : "bold"}
          sx={element.variant == "body" ? {} : { pt: 2 }}
        >
          {element.label}
        </Typography>
        {element.tooltip && (
          <Tooltip arrow placement="right" title={element.tooltip}>
            <Info
              color={"disabled"}
              fontSize={element.variant == "body" ? "small" : undefined}
            />
          </Tooltip>
        )}
      </Grid>
      {element.elements && (
        <Grid container flexDirection="row" gap={2}>
          {element.elements?.map((e, index) => {
            try {
              return (
                <Grid key={index} item flex={1}>
                  <DashboardElementRenderer
                    {...e}
                    data={element.data?.[index]}
                  />
                </Grid>
              );
            } catch (e) {
              console.error(e);
              return <React.Fragment key={index}></React.Fragment>;
            }
          })}
        </Grid>
      )}
    </>
  );
};
