import { useCallback, useState } from "react";
import ReactCodeMirror, { ReactCodeMirrorProps } from "@uiw/react-codemirror";

export const CodeMirror = (props: ReactCodeMirrorProps) => {
  const [maxWidth, setMaxWidth] = useState<string>();
  const container = useCallback<(node: HTMLDivElement) => void>(
    (node) => {
      if (node !== null) {
        setMaxWidth(node.getBoundingClientRect().width + "px");
      } else {
        setMaxWidth("100%");
      }
    },
    [setMaxWidth],
  );
  return (
    <div ref={container}>
      <ReactCodeMirror {...props} maxWidth={props.maxWidth ?? maxWidth} />
    </div>
  );
};
