import OrganizationScopedPage from "../../components/OrganizationScopedPage";
import { useGlobalOrganizationContext } from "../../hooks/useGlobalOrganizationContext";
import { FC } from "react";
import AccountTypeList from "./components/AccountTypeList";

const AccountListPage: FC<unknown> = () => {
  const { organization } = useGlobalOrganizationContext();

  return (
    <OrganizationScopedPage
      category="accounts"
      displayOrganizationActionMenu={false}
    >
      {organization && <AccountTypeList organizationId={organization.id} />}
    </OrganizationScopedPage>
  );
};

export default AccountListPage;
