import { Box, Paper, TextField } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useEffect, useMemo, useState } from "react";
import ProcessesApi from "../../../../api/processes";
import { Process } from "../../../../model/Process";

const OPTIONS_LIMIT = 10;
const filterOptions = createFilterOptions<ProcessSelectValueType>({
  limit: OPTIONS_LIMIT,
  ignoreCase: true,
  ignoreAccents: false,
});

type ProcessSelectValueType = {
  process_key: string;
  name: string;
};

type ProcessSelectionType = {
  organizationId: string;
  onChange: (process?: Process) => void;
  value?: { process_key: string; meta?: string };
  label?: string;
  helperText?: string;
};

const ProcessSelect = ({
  organizationId,
  onChange,
  value,
  label = "Select a Process",
  helperText,
}: ProcessSelectionType) => {
  const [processes, setProcesses] = useState<Array<Process>>([]);
  const [selectedValue, setSelectedValue] = useState<Process>();
  const [inputValue, setInputValue] = useState("");

  const options = useMemo(() => {
    return processes.reduce<Array<ProcessSelectValueType>>((arr, curr) => {
      arr.push({ process_key: curr.process_key, name: curr.name });
      return arr;
    }, []);
  }, [processes]);

  useEffect(() => {
    ProcessesApi.byOrganization(organizationId).then(setProcesses);
  }, [organizationId]);

  useEffect(() => {
    setSelectedValue(
      processes.find((p) => p.process_key == value?.process_key),
    );
  }, [processes, value]);

  return (
    <Autocomplete
      sx={{ flex: 2 }}
      filterOptions={filterOptions}
      value={selectedValue ?? null}
      isOptionEqualToValue={(option, value) =>
        option.process_key === value.process_key
      }
      onChange={(event, newValue) => {
        // Find the Process corresponding to the process name that was selected
        const selectedProcess = processes.find((process) => {
          return process.process_key === newValue?.process_key;
        });
        onChange(selectedProcess);
        setSelectedValue(selectedProcess);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      id="process-selection"
      options={options}
      getOptionLabel={(option) => option.name}
      PaperComponent={(props: object) => {
        return (
          <Paper sx={{ backgroundColor: "common.white" }}>
            <Box {...props} />
          </Paper>
        );
      }}
      noOptionsText={`No ${inputValue} process found`}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            style: {
              paddingLeft: 0,
            },
          }}
          label={label}
          helperText={helperText}
        />
      )}
    />
  );
};

export default ProcessSelect;
