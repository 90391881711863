import Delete from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, CircularProgress, Grid } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { useCallback } from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Processes from "../../../../api/processes";
import { IconButtonDropDown } from "../../../../components/core/IconButtonDropDown";
import VerticalStepper from "../../../../components/core/VerticalStepper";
import {
  Process,
  ProcessStatus,
  ProcessStructure,
} from "../../../../model/Process";
import { Step } from "../../../../model/step";
import { TaskSideSectionContainer, useTaskContext } from "../../../task";
import { ProcessRoleProvider, useProcessRoleContext } from "../../hooks";
import ProcessTasksEditor from "../ProcessTasksEditor";
import AddStepButton from "./AddStepButton";
import DeleteStepDialog from "./DeleteStepDialog";
import EditStepDialog from "./EditStepDialog";
import VerticalSteppedProcessEditorHeader from "./VerticalStepperProcessEditorHeader";
import { ProcessDto } from "../../../../types";
import { ValidationError } from "class-validator";
import { useAlert } from "../../../../lib/alert";
import PATHS from "../../../../components/navigation/_paths";
import { scrollbarStyles } from "../../../../util/customStyles";

type VerticalSteppedProcessEditorProps = {
  processId: string;
};

const VerticalSteppedProcessEditor = ({
  processId,
}: VerticalSteppedProcessEditorProps) => {
  const navigate = useNavigate();
  const { handleRejectionWithError } = useAlert();
  const [process, setProcess] = useState<Process>();
  const [draftProcessId, setDraftProcessId] = useState<string>(processId);

  const fetchProcess = useCallback(async () => {
    // Fetches latest regardless of whether the process id is the process meta or latest draft id
    const draftProcess = await Processes.fetchLatest(
      processId,
      ProcessStatus.Draft,
    );
    setDraftProcessId(draftProcess.id);
    setProcess(await Processes.getForEdit(draftProcess.id));
  }, [processId]);

  const updateProcess = useCallback(
    async (changes: Partial<Process>) => {
      if (!process?.id) {
        return;
      }
      const response = await Processes.update(changes as ProcessDto);
      setProcess(response);
    },
    [process?.id],
  );

  const publishProcess = useCallback(async () => {
    if (!process?.id) {
      return;
    }
    try {
      const response = await Processes.publish(draftProcessId);
      setProcess(response);
      navigate(PATHS.PROCESSES.link);
    } catch (e) {
      handleRejectionWithError("Unable to publish process")(e);
      throw e;
    }
  }, [navigate, process?.id, draftProcessId, handleRejectionWithError]);

  useEffect(() => {
    fetchProcess();
  }, [fetchProcess, processId]);

  if (!process?.id) {
    return <CircularProgress />;
  }

  return (
    <ProcessRoleProvider>
      <VerticalSteppedProcessEditorWithProcess
        process={process}
        refresh={fetchProcess}
        update={updateProcess}
        publish={publishProcess}
      />
    </ProcessRoleProvider>
  );
};

type VerticalSteppedProcessEditorWithProcessProps = {
  process: Process;
  refresh: () => Promise<void>;
  update: (process: Partial<Process>) => Promise<void>;
  publish: () => Promise<void>;
};

const VerticalSteppedProcessEditorWithProcess = ({
  process,
  refresh,
  update,
  publish,
}: VerticalSteppedProcessEditorWithProcessProps) => {
  const taskContext = useTaskContext();
  const { fetchRoles } = useProcessRoleContext();
  const [validationFailures, setValidationFailures] = useState<
    ValidationError[] | undefined
  >();
  const [steps, setSteps] = useState<Array<Step>>([]);
  const [currentStep, setCurrentStep] = useState<Step>({
    name: "",
    org_id: "",
    previous_step_id: null,
  });
  const [editStepOpen, setEditStepOpen] = useState(false);
  const [deleteStepOpen, setDeleteStepOpen] = useState(false);

  const fetchSteps = useCallback(async () => {
    if (!process.id || !process.structure) {
      return;
    }
    const response = await Processes.getSteps(process.id);
    setSteps(response);
  }, [process.id, process.structure]);

  useEffect(() => {
    fetchSteps();
  }, [fetchSteps]);

  useEffect(() => {
    fetchRoles(process.org_id);
  }, [fetchRoles, process.org_id]);

  return (
    <Grid item flex={1} mb={"10px"}>
      <Grid
        container
        flexDirection={"column"}
        flexWrap={"nowrap"}
        height={"100%"}
      >
        <Grid item>
          <VerticalSteppedProcessEditorHeader
            process={process}
            handleUpdateProcess={update}
            handlePublishProcess={async () => {
              try {
                return await publish();
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              } catch (e: any) {
                setValidationFailures(
                  e.response.data.payload.validationFailures,
                );
                throw e;
              }
            }}
          />
        </Grid>
        <Grid item flex={1} overflow={"auto"} height={"100%"}>
          <Grid container flexDirection={"row"} height={"100%"}>
            <Grid
              item
              height={"100%"}
              xs={8}
              overflow={"auto"}
              sx={(theme) => {
                return {
                  p: theme.spacing(0, 2),
                  ...scrollbarStyles,
                };
              }}
            >
              {(process?.structure === ProcessStructure.Flat ||
                steps.length === 0) && (
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                <ProcessTasksEditor
                  stepOrProcessId={process.id}
                  process={process}
                  validationFailures={validationFailures}
                />
              )}
              <VerticalStepper
                steps={steps.map((step, index) => ({
                  id: index.toString(),
                  ...step,
                  title: () => (
                    <Box sx={{ display: "flex", alignContent: "center" }}>
                      <Box sx={{ flex: 1 }}>
                        <Typography
                          variant="h4"
                          component="h2"
                          sx={{ lineHeight: "34px" }}
                        >
                          {step.name ?? ""}
                        </Typography>
                      </Box>
                      <IconButtonDropDown
                        IconButtonProps={{ size: "small" }}
                        onOpen={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                        onClose={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                      >
                        <MenuItem
                          onClick={async () => {
                            setCurrentStep(step);
                            setEditStepOpen(true);
                          }}
                        >
                          <ListItemIcon>
                            <EditIcon fontSize="small" />
                          </ListItemIcon>
                          <Typography variant="inherit">Edit</Typography>
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setCurrentStep(step);
                            setDeleteStepOpen(true);
                          }}
                        >
                          <ListItemIcon>
                            <Delete fontSize="small" />
                          </ListItemIcon>
                          <Typography variant="inherit">Delete</Typography>
                        </MenuItem>
                      </IconButtonDropDown>
                    </Box>
                  ),
                  content: () => {
                    return (
                      <>
                        {step.id && (
                          <ProcessTasksEditor
                            stepOrProcessId={step.id}
                            process={process}
                            validationFailures={validationFailures}
                          />
                        )}
                      </>
                    );
                  },
                }))}
              />

              <AddStepButton
                onAddStep={async (stepTitle) => {
                  await Processes.createStep(process?.id ?? "", {
                    org_id: process?.org_id ?? "",
                    name: stepTitle,
                    previous_step_id:
                      steps.length === 0
                        ? null
                        : steps[steps.length - 1].id ?? null,
                  });
                  if (process?.structure != ProcessStructure.Stepped) {
                    await refresh();
                  } else {
                    await fetchSteps();
                  }
                }}
              />
            </Grid>
            <Grid
              item
              height={"100%"}
              xs={4}
              sx={{
                pl: 1,
                pr: 1,
              }}
            >
              <Box
                sx={{
                  backgroundColor: "background.drawer",
                  height: "100%",
                  overflow: "auto",
                  p: 4,
                  borderRadius: 3,
                  ...scrollbarStyles,
                }}
              >
                {taskContext.selectedTask && (
                  <TaskSideSectionContainer task={taskContext.selectedTask} />
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {editStepOpen && (
        <EditStepDialog
          open={editStepOpen}
          step={currentStep}
          onClose={() => {
            setEditStepOpen(false);
          }}
          onSave={async (step) => {
            await Processes.updateStep(step);
            await fetchSteps();
          }}
        />
      )}
      {deleteStepOpen && (
        <DeleteStepDialog
          open={deleteStepOpen}
          step={currentStep}
          onClose={() => {
            setDeleteStepOpen(false);
          }}
          onConfirm={async (step) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            await Processes.deleteStep(process.id!, step.id!);
            await fetchSteps();
          }}
        />
      )}
    </Grid>
  );
};
export default VerticalSteppedProcessEditor;
