import { Button, Card, CardHeader, Chip, Grid } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TaskExecutionAPI } from "../../../api";
import { useGlobalOrganizationContext } from "../../../hooks/useGlobalOrganizationContext";
import { TaskExecution, TaskType } from "../../task";
import { Wysiwyg } from "@mui/icons-material";
import PATHS from "../../../components/navigation/_paths";

type ProcessExecutionSharedTaskExecutionsProps = {
  processId: string;
  processExecutionId: string;
  attachmentReloadTrigger?: number;
  variant?: "chip" | "card";
};

const ProcessExecutionSharedTaskExecutions: FC<
  ProcessExecutionSharedTaskExecutionsProps
> = ({ variant = "card", ...props }) => {
  const [taskExecutions, setTaskExecutions] = useState<Array<TaskExecution>>(
    [],
  );
  const navigate = useNavigate();
  const { organization } = useGlobalOrganizationContext();

  const reload = useCallback(async () => {
    if (!props.processExecutionId) {
      return;
    }

    const taskExecutions =
      await TaskExecutionAPI.listSharedByProcessExecutionId(
        props.processExecutionId,
      );
    setTaskExecutions(taskExecutions);
  }, [props.processExecutionId]);

  useEffect(() => {
    reload();
  }, [props.attachmentReloadTrigger, reload]);

  const openAttachment = useCallback(
    (taskExecution: TaskExecution) => {
      if (organization?.id) {
        navigate(
          PATHS.EXECUTION_TASK_FORM.linkTo(
            props.processId,
            props.processExecutionId,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            taskExecution.task_id!,
          ),
        );
      } else {
        navigate(`/share/${taskExecution.id}/form`);
      }
    },
    [navigate, organization?.id, props.processExecutionId, props.processId],
  );

  return (
    <Grid container>
      {taskExecutions
        .filter((taskExecution) => taskExecution.type == TaskType.Form)
        .map((taskExecution) => {
          return (
            <Grid key={taskExecution.id} item xs={12} sx={{ py: 2 }}>
              {variant === "chip" && (
                <Chip
                  icon={<Wysiwyg fontSize="small" />}
                  label={taskExecution.name}
                  clickable
                  onClick={() => {
                    openAttachment(taskExecution);
                  }}
                />
              )}
              {variant === "card" && (
                <Card>
                  <CardHeader
                    avatar={<Wysiwyg fontSize="large" />}
                    action={
                      <Button
                        onClick={() => {
                          openAttachment(taskExecution);
                        }}
                      >
                        View
                      </Button>
                    }
                    title={`Form: ${taskExecution.name}`}
                  />
                </Card>
              )}
            </Grid>
          );
        })}
    </Grid>
  );
};

export default ProcessExecutionSharedTaskExecutions;
