import { Box, Grid, SxProps, Theme, Typography } from "@mui/material";
import { useDisplayOptions } from "../../../hooks/useDisplayOptions";
import Breadcrumbs, {
  BreadcrumbLink,
} from "../../core/Breadcrumbs/Breadcrumbs";
import { lazy } from "react";

const DynamicIcon = lazy(() => import("../../core/DynamicIcon"));
export type PageHeaderProps = React.PropsWithChildren<{
  title?: string;
  breadcrumbTitle?: string;
  links?: Array<BreadcrumbLink>;
  actions?: React.ReactNode;
  variant?: "default" | "primary" | "raised";
  color?: string;
  setPageTitle?: boolean;
  showAccountMenu?: boolean;
  icon?: string;
}>;
const PageHeader = ({
  title,
  breadcrumbTitle,
  links,
  children,
  actions,
  variant = "default",
  color,
  icon,
  setPageTitle = true,
}: PageHeaderProps) => {
  useDisplayOptions(setPageTitle ? { title: title } : {});
  const hasLinks = links && links.length > 0;
  const variantProps: SxProps<Theme> =
    variant === "primary"
      ? {
          borderBottom: "8px solid",
          borderColor: color ?? "primary.main",
        }
      : {};

  return (
    <Grid item sx={{ width: "100%" }} xs={12} flex={0} flexBasis={0}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          px: 2,
          py: 1.25,
          width: "100%",
          paddingBottom: 0,
          ...variantProps,
        }}
      >
        <Box sx={{ flex: 1 }}>
          {!!title && !children && (
            <Typography
              variant="h1"
              sx={{ display: "flex", alignItems: "center" }}
            >
              {title}
              {icon && <DynamicIcon name={icon} sx={{ ml: 1 }} />}
            </Typography>
          )}
          {children}
          {hasLinks && (
            <Breadcrumbs links={links} page={breadcrumbTitle ?? title} />
          )}
        </Box>
        {actions && <Box sx={{ flex: 0, mt: 0.5, mr: 6 }}>{actions}</Box>}
      </Box>
    </Grid>
  );
};
export default PageHeader;
