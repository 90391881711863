import { Typography } from "@mui/material";
import { Task, TaskInlineEditorProps } from "../../../types";

const EmailInlineEditor = ({ task }: TaskInlineEditorProps<Task>) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const emails = (task.data as any)?.to ?? ["<unknown>"];
  return (
    <Typography variant="caption" sx={{ color: "text.disabled" }}>
      {`Automated email will be sent to ${emails.join(", ")}`}
    </Typography>
  );
};

export default EmailInlineEditor;
