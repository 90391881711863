import React from "react";
import Editor, { Quill, ReactQuillProps } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

export type ReactQuillRefType = Editor;

// Add MUI attachment icon for quill editor
const icons = Quill.import("ui/icons");
icons["attachment"] =
  '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none" /><path class="ql-stroke ql-thin ql-fill" d="M16.5 6v11.5c0 2.21-1.79 4-4 4s-4-1.79-4-4V5c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5v10.5c0 .55-.45 1-1 1s-1-.45-1-1V6H10v9.5c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5V5c0-2.21-1.79-4-4-4S7 2.79 7 5v12.5c0 3.04 2.46 5.5 5.5 5.5s5.5-2.46 5.5-5.5V6h-1.5z"/></svg>';

const ReactQuill = React.forwardRef<Editor, ReactQuillProps>((props, ref) => {
  return (
    <Editor theme={!props.readOnly ? "snow" : "bubble"} {...props} ref={ref} />
  );
});
ReactQuill.displayName = "ReactQuill";

export default ReactQuill;
