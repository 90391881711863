import { styled } from "@mui/material";
import {
  DateCalendar,
  DateCalendarProps,
  PickersDay,
  PickersDayProps,
} from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { useMemo } from "react";

export type DatePickerProps = Omit<
  DateCalendarProps<Dayjs>,
  "value" | "onChange"
> & {
  multiday?: boolean;
  disableWeekend?: boolean;
  values?: string[] | undefined;
  onChange?: (values: string[] | undefined) => void;
};
interface DayProps extends PickersDayProps<Dayjs> {
  values?: Array<Dayjs> | undefined;
}
interface SelectedDateRenderProps extends PickersDayProps<Dayjs> {
  isSelected: boolean;
}

const SelectedDateRender = styled(
  PickersDay,
  {},
)<SelectedDateRenderProps>(({ theme, isSelected }) => ({
  ...(isSelected
    ? {
        borderTopRightRadius: "50%",
        borderBottomRightRadius: "50%",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
        "&:hover, &:focus": {
          backgroundColor: theme.palette.primary.main,
        },
        "&.Mui-disabled:not(.Mui-selected)": {
          color: theme.palette.getContrastText(theme.palette.primary.main),
        },
      }
    : {
        "&:hover, &:focus": {
          backgroundColor: "unset",
          color: "unset",
        },
        ":not(:focus)": {
          backgroundColor: "unset",
          color: "unset",
        },
        "&.Mui-selected:hover": {
          backgroundColor: "unset",
          color: "unset",
        },
        "&:focus.Mui-selected": {
          backgroundColor: "unset",
          color: "unset",
        },
      }),
})) as React.ComponentType<SelectedDateRenderProps>;

const Day = ({ values, day, ...props }: DayProps) => {
  if (!values) {
    return <PickersDay day={day} {...props} />;
  }
  const isSelected = values.some((selectedDate) =>
    day.isSame(selectedDate, "day"),
  );
  return <SelectedDateRender day={day} isSelected={isSelected} {...props} />;
};

const DatePicker = ({
  multiday = false,
  disableWeekend = false,
  onChange,
  values,
  disabled,
  ...props
}: DatePickerProps) => {
  const dateValues = useMemo(() => {
    return values ? values.map((s) => dayjs(s)) : undefined;
  }, [values]);
  const renderProps: DateCalendarProps<Dayjs> = useMemo(() => {
    if (multiday) {
      return {
        slots: { day: Day },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        slotProps: { day: { values: dateValues } } as any,
      };
    } else {
      return {
        value: dateValues?.[0] ?? dayjs(new Date().toDateString()),
      };
    }
  }, [dateValues, multiday]);
  return (
    <DateCalendar
      data-cy={"date-calendar"}
      onChange={(selectedDate) => {
        const s = selectedDate
          ? (selectedDate as unknown as Dayjs).toISOString()
          : undefined;
        if (multiday) {
          const alreadySelected = !dateValues
            ? -1
            : dateValues?.findIndex((d) => d.isSame(selectedDate, "day"));
          if (alreadySelected >= 0) {
            onChange &&
              onChange(values?.filter((v, i) => i !== alreadySelected));
          } else {
            onChange && onChange([...(values ?? []), s as string]);
          }
        } else {
          onChange && onChange(s ? [s] : undefined);
        }
      }}
      disabled={disabled}
      shouldDisableDate={
        disableWeekend
          ? (date) => {
              const dayOfWeek = date.day();
              return dayOfWeek === 0 || dayOfWeek === 6;
            }
          : undefined
      }
      {...renderProps}
      {...props}
    ></DateCalendar>
  );
};

export default DatePicker;
