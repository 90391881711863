import Invitation from "../model/Invitation";
import API from "./base";

const membersByOrganization = (
  organizationId: string,
  filter?: string,
): Promise<Array<Invitation>> => {
  return new Promise((resolve, reject) => {
    API.get("/invitations/byOrganizationId/" + organizationId + "/members", {
      params: { filter: filter ? `${filter}` : undefined },
    }).then((result) => {
      resolve(result.data.payload as Array<Invitation>);
    }, reject);
  });
};

const accountsByOrganization = (
  organizationId: string,
): Promise<Array<Invitation>> => {
  return new Promise((resolve, reject) => {
    API.get(
      "/invitations/byOrganizationId/" + organizationId + "/accounts",
    ).then((result) => {
      resolve(result.data.payload as Array<Invitation>);
    }, reject);
  });
};

const create = (invitation: Invitation): Promise<Invitation> => {
  return new Promise((resolve, reject) => {
    API.post("/invitations/", { ...invitation, origin: location.origin }).then(
      (result) => {
        resolve(result.data.payload as Invitation);
      },
      reject,
    );
  });
};

const resend = (invitationId: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    return API.post("/invitations/" + invitationId + "/resend", {
      origin: location.origin,
    }).then(() => {
      resolve();
    }, reject);
  });
};

const byTargetUserId = (userId: string): Promise<Array<Invitation>> => {
  return new Promise((resolve, reject) => {
    API.get("/invitations/byTargetUserId/" + userId).then((result) => {
      resolve(result.data.payload as Array<Invitation>);
    }, reject);
  });
};

const get = (invitationId: string): Promise<Invitation> => {
  return new Promise((resolve, reject) => {
    API.get("/invitations/" + invitationId).then((result) => {
      resolve(result.data.payload as Invitation);
    }, reject);
  });
};

const accept = (invitationId: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    API.post("/invitations/" + invitationId + "/accept").then(() => {
      resolve();
    }, reject);
  });
};

const remove = (invitationId: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    API.delete("/invitations/" + invitationId).then(() => {
      resolve();
    }, reject);
  });
};

const assignRole = (roleId: string, invitationId: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    API.post("/invitations/" + invitationId + "/assign/" + roleId).then(() => {
      resolve();
    }, reject);
  });
};

const unassignRole = (roleId: string, invitationId: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    API.post("/invitations/" + invitationId + "/unassign/" + roleId).then(
      () => {
        resolve();
      },
      reject,
    );
  });
};

export default {
  create: create,
  membersByOrganization: membersByOrganization,
  accountsByOrganization: accountsByOrganization,
  resend: resend,
  byTargetUserId: byTargetUserId,
  get: get,
  accept: accept,
  delete: remove,
  assignRole: assignRole,
  unassignRole: unassignRole,
};
