// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapCognitoError = (err: any) => {
  console.error(err);
  console.error(JSON.stringify(err));

  switch (err.code) {
    case "NotAuthorizedException":
      return "Incorrect email address or password.";
    case "InvalidParameterException":
      return "Invalid data in form submission.";
    case "UsernameExistsException":
      return "An account with the given email already exists.";
    case "InvalidPasswordException":
      return err.toString().replace("InvalidPasswordException: ", "");
    case "CodeMismatchException":
      return "Invalid verification code provided, please try again.";
    default:
      return "Unknown authentication error occured.";
  }
};
