import { FC } from "react";
import { User } from "../../../../../model";
import { Avatar, Box, Typography, styled } from "@mui/material";
import { getUserFullName } from "../../../utils";

type CompletedByProps = {
  user: User;
};

const UserAvatar = styled(Avatar, {
  shouldForwardProp: (prop) => prop !== "user",
})<CompletedByProps>(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  width: 32,
  height: 32,
  backgroundColor: theme.palette.primary.main,
}));

const CompletedByCell: FC<CompletedByProps> = ({ user }) => {
  if (!user) return <></>;

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <UserAvatar alt={getUserFullName(user)} user={user}>
        {user.first_name ? user.first_name[0] : user.email[0]}
      </UserAvatar>
      <Typography variant="body1">{getUserFullName(user)}</Typography>
    </Box>
  );
};

export default CompletedByCell;
