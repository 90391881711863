import { atom } from "jotai";

const testModeAtom = atom<boolean>(
  localStorage.getItem("runway.testtoggle") === "test",
);

export default atom<boolean, [boolean], void>(
  (get) => get(testModeAtom),
  (get, set, newVal) => {
    set(testModeAtom, newVal);
    localStorage.setItem("runway.testtoggle", newVal ? "test" : "live");
  },
);
