import { Organization, User } from "../../model";
import { InvitationDisplayData } from "../../model/Invitation";

export const getInvitationDisplayData = (
  user: User,
  organization: Organization,
): InvitationDisplayData => {
  return {
    creator_name: user.first_name + " " + user.last_name,
    organization_name: organization.name,
    description: organization.description,
    organizationShortLogoURL: organization.theme?.shortLogoUrl,
  };
};
