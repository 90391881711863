import Role from "./Role";
import User from "./User";

export enum AccountRegistrationType {
  InviteOnly = "INVITE_ONLY",
  SelfRegistration = "SELF_REGISTRATION",
}

export type AccountType = {
  id: string;
  organization_id: string;
  key: string;
  name: string;
  description?: string;
  registration_type?: AccountRegistrationType;
  role_id?: string;
  org_role_id?: string;
};

export type AccountTypeRoleAffinity = Role & {
  valid_users: User[];
};

export type AccountTypeAffinityDto = {
  id: string;
  organization_id: string;
  name: string;
  description?: string;
  roles: AccountTypeRoleAffinity[];
};

export type AccountTypeRoleUserDtoResponse = {
  user: User;
  role: Role;
};
