import { DashboardAPI } from "../../../../api";
import { useAlert } from "../../../../lib/alert";
import { useState } from "react";
import Dashboard from "../../../../model/Dashboard";
import { DashboardDialog } from "./DashboardDialog";

type UpdateDashboardDialogProps = {
  open: boolean;
  handleConfirm: (newDashboard: Dashboard) => void | Promise<void>;
  handleCancel: () => void | Promise<void>;
  dashboard: Dashboard;
};

export const UpdateDashboardDialog = ({
  open,
  handleConfirm,
  handleCancel,
  dashboard: originalDashboard,
}: UpdateDashboardDialogProps) => {
  const { success, error } = useAlert();
  const [dashboard, setDashboard] = useState<Dashboard>({
    ...originalDashboard,
  });

  return (
    <DashboardDialog
      open={open}
      title={"Update Dashboard"}
      confirmText={"Update"}
      dashboard={dashboard}
      updateDashboard={(updates) => {
        setDashboard((prev) => ({ ...prev, ...updates }));
      }}
      handleConfirm={async () => {
        try {
          const newDashboard = await DashboardAPI.update(dashboard);
          success(
            `${newDashboard?.config?.name ?? "Dashboard"} updated successfully`,
          );
          handleConfirm(newDashboard);
        } catch {
          error(`Error updating dashboard`);
        }
      }}
      handleCancel={handleCancel}
    />
  );
};
