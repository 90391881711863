import { Container, Paper } from "@mui/material";
import { Organization } from "../../../../../../model";
import CreateOrganizationComponent from "../../../../../organization/components/CreateOrganizationComponent";
import {
  Task,
  TaskExecution,
  TaskExecutionComponentProps,
  TaskExecutionState,
} from "../../../../types";

const CreateOrganizationExecutionComponent = (
  props: TaskExecutionComponentProps<Task, TaskExecution>,
) => {
  return (
    <Container maxWidth="lg">
      <Paper elevation={2} sx={{ p: 4, borderRadius: "10px" }}>
        <CreateOrganizationComponent
          hideStepper={
            !props.isDisabled ||
            props.taskExecution?.state !== TaskExecutionState.Completed
          }
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          organization={(props.taskExecution?.data as any)?.organization}
          disabled={props.taskExecution?.state === TaskExecutionState.Completed}
          buttonAlignment="right"
          buttonText="Continue"
          emailDisabled={true}
          onCreate={(organization: Organization) => {
            const executionData = {
              data: {
                organization: organization,
              },
            };

            const execution = {
              ...props.taskExecution,
              task_id: props.task.id,
              state: TaskExecutionState.Completed,
              ...executionData,
            };

            props.onExecute?.(execution as TaskExecution);
            props.onValueChange?.({ ...props.task, ...executionData } as Task);
          }}
        />
      </Paper>
    </Container>
  );
};

export default CreateOrganizationExecutionComponent;
