import { TaskExecution } from "../features/task";
import { Process } from "../model/Process";
import { ProcessExecution } from "../model/ProcessExecution";
import { unauthenticatedAPI as API } from "./base";

const getTasksByPublicExecutorId = (
  publicExecutorId: string,
  organizationId: string,
): Promise<Process> => {
  return new Promise((resolve, reject) => {
    API.get("/executors/public/" + publicExecutorId + "/tasks", {
      params: {
        organizationId,
      },
    }).then((result) => {
      resolve(result.data.payload as Process);
    }, reject);
  });
};

const execute = (
  publicExecutorId: string,
  taskSubmission: Array<TaskExecution>,
  organizationId: string,
  subjectId?: string,
  executionId?: string,
): Promise<ProcessExecution> => {
  return new Promise((resolve, reject) => {
    API.post(
      "/executors/public/" + publicExecutorId + "/execute",
      taskSubmission,
      {
        params: {
          subjectId,
          executionId,
          organizationId,
        },
      },
    ).then((result) => {
      resolve(result.data.payload as ProcessExecution);
    }, reject);
  });
};

const getProcessExecutionByPublicExecutorIdAndProcessExecutionId = (
  publicExecutorId: string,
  organizationId: string,
  processExecutionId: string,
): Promise<ProcessExecution> => {
  return new Promise((resolve, reject) => {
    API.get(
      "/executors/public/" +
        publicExecutorId +
        "/executions/" +
        processExecutionId,
      {
        params: {
          organizationId,
        },
      },
    ).then((result) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      resolve(result.data.payload as ProcessExecution);
    }, reject);
  });
};

const getProcessExecutionTasksByPublicExecutorIdAndProcessExecutionId = (
  publicExecutorId: string,
  organizationId: string,
  processExecutionId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<Array<TaskExecution>> => {
  return new Promise((resolve, reject) => {
    API.get(
      "/executors/public/" +
        publicExecutorId +
        "/executions/" +
        processExecutionId +
        "/tasks",
      {
        params: {
          organizationId,
        },
      },
    ).then((result) => {
      resolve(result.data.payload as Array<TaskExecution>);
    }, reject);
  });
};

export default {
  getTasksByPublicExecutorId: getTasksByPublicExecutorId,
  getProcessExecutionByPublicExecutorIdAndProcessExecutionId:
    getProcessExecutionByPublicExecutorIdAndProcessExecutionId,
  execute: execute,
  getProcessExecutionTasksByPublicExecutorIdAndProcessExecutionId:
    getProcessExecutionTasksByPublicExecutorIdAndProcessExecutionId,
};
