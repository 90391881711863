export type Interval = {
  years?: number;
  months?: number;
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
  milliseconds?: number;
};
//gets the value of an interval in the selected format
export const intervalString = (
  intervalValue: number, // the interval's value in seconds
  valueFormat:
    | "milliseconds"
    | "seconds"
    | "minutes"
    | "hours"
    | "days"
    | "months"
    | "years"
    | "highest",
): string => {
  // NOTE: months have 30 days and years have 365 days

  // find the highest unit the interval can have
  if (valueFormat == "highest") {
    valueFormat =
      intervalValue >= 60 * 60 * 24 * 365
        ? "years"
        : intervalValue >= 60 * 60 * 24 * 30
          ? "months"
          : intervalValue >= 60 * 60 * 24
            ? "days"
            : intervalValue >= 60 * 60
              ? "hours"
              : intervalValue >= 60
                ? "minutes"
                : intervalValue >= 1
                  ? "seconds"
                  : "milliseconds";
  }

  // get the value of the interval in the selected unit
  let number: number;
  switch (valueFormat) {
    case "milliseconds":
      number = intervalValue * 1000;
      break;
    case "seconds":
      number = intervalValue;
      break;
    case "minutes":
      number = intervalValue / 60;
      break;
    case "hours":
      number = intervalValue / (60 * 60);
      break;
    case "days":
      number = intervalValue / (60 * 60 * 24);
      break;
    case "months":
      number = intervalValue / (60 * 60 * 24 * 30);
      break;
    case "years":
      number = intervalValue / (60 * 60 * 24 * 365);
      break;
  }

  // round to 2 decimal places
  number = Math.round(number * 100) / 100;

  return `${number} ${
    number == 1 ? valueFormat.substring(0, valueFormat.length - 2) : valueFormat
  }`;
};

// Dashboard
export type executionsPerStep = {
  process_name: string;
  steps: Array<{
    name: string;
    countCumulative: number;
    countTimeRange?: number;
  }>;
};

export type accountsPerPhase = {
  processName: string;
  phaseName: string;
  countCumulative: number;
  countTimeRange?: number;
};
