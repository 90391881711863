import React, { FC, useState } from "react";
import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import successCheckMark from "../../assets/images/Success_Check_Mark.svg";
import {
  getEmailAndCodeForPasswordReset,
  login,
  resetUserPassword,
} from "../../lib/auth";
import { mapCognitoError } from "../../lib/cognito";
import useAppNavigation from "../../hooks/useAppNavigation";

const ForgotPasswordResetPage: FC<unknown> = () => {
  const { navigateHome } = useAppNavigation();
  const [authenticating, setAuthenticating] = useState<boolean>(false);
  const [error, setError] = useState("");

  const signInLink = "/sign-in";

  const resetPassword = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const newPassword = data.get("newPassword");
    if (!newPassword) {
      setError("Password is required");
      return;
    }

    const { emailForPasswordReset, codeForPasswordReset } =
      getEmailAndCodeForPasswordReset();

    setAuthenticating(true);
    resetUserPassword(codeForPasswordReset, newPassword.toString())
      .then((result) => {
        if (result.success) {
          login(emailForPasswordReset, newPassword.toString()).then(
            (result) => {
              if (result.success) {
                navigateHome();
                return;
              }

              setAuthenticating(false);
              setError("Unable to complete login, please try again");
            },
            (err) => {
              setAuthenticating(false);
              setError(mapCognitoError(err));
            },
          );
        }
      })
      .catch((err) => {
        setAuthenticating(false);
        setError(mapCognitoError(err));
      });
  };

  return (
    <>
      <Container component="main" maxWidth="xs" sx={{ mt: { xs: 3, md: 18 } }}>
        <Paper elevation={9}>
          {authenticating ? (
            <Box
              sx={{
                width: "100%",
                height: "300px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box sx={{ px: 4, py: 4 }}>
              {error && (
                <Alert sx={{ width: "100%" }} severity="error">
                  {error}
                </Alert>
              )}
              {/* Header */}
              <Box component="header" sx={{ mb: 3 }}>
                <Avatar
                  alt="Password reset email successfully sent"
                  src={successCheckMark}
                  sx={{
                    width: 48,
                    height: 48,
                  }}
                />
              </Box>

              {/* Body */}
              <Box>
                <Typography component="h1" variant="h5">
                  Reset Your Password
                </Typography>
              </Box>

              <Box component="form" onSubmit={resetPassword} sx={{ mt: 3 }}>
                <TextField
                  required
                  fullWidth
                  type="password"
                  id="newPassword"
                  label="New Password"
                  name="newPassword"
                  autoComplete="newPassword"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Change Password
                </Button>
              </Box>

              {/* Footer */}
              <Box sx={{ mt: 4 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Link href={signInLink} underline="hover">
                      Back to Login
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default ForgotPasswordResetPage;
