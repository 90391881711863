import { ChevronRight, ExpandMore } from "@mui/icons-material";
import {
  Autocomplete,
  IconButton,
  ListItem,
  ListSubheader,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { DataModelReference } from "../../task";

type ModelFieldReference = DataModelReference & {
  label?: string;
};

const STANDARD_OBJECT_FIELDS: Array<ModelFieldReference> = [
  { path: "account_name", label: "name", objectType: "Account" },
  { path: "primary_email", objectType: "Account" },
  { path: "message", objectType: "Thread" },
  { path: "subject", objectType: "Thread" },
];

// MUI Autocomplete component for selecting a data model field with a tree structure containing model objects and child fields.
const DataModelAutocomplete = ({
  modelRefs,
  onChange,
}: {
  modelRefs?: DataModelReference[];
  onChange: (modelFields: DataModelReference[]) => void;
}) => {
  const [openGroups, setOpenGroups] = useState<Record<string, boolean>>({
    Account: true,
    Thread: true,
  });
  const [value, setValue] = useState<Array<ModelFieldReference>>(
    modelRefs ?? [],
  );
  const [inputValue, setInputValue] = useState("");

  const handleToggleObjectOpen = (objectType: string) => {
    setOpenGroups((prev) => ({ ...prev, [objectType]: !prev[objectType] }));
  };

  return (
    <Autocomplete
      fullWidth
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
        onChange(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={STANDARD_OBJECT_FIELDS} // Shows all when not filtered to prevent no options
      groupBy={(option) => option.objectType}
      getOptionLabel={(modelField) =>
        `${modelField.objectType}.${modelField.label ?? modelField.path}`
      }
      renderOption={(props, modelField) => (
        <ListItem {...props}>{modelField.label ?? modelField.path}</ListItem>
      )}
      multiple
      renderGroup={(params) => (
        <>
          <ListSubheader
            key={params.key}
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <IconButton
              size="small"
              onClick={() => handleToggleObjectOpen(params.group)}
            >
              {openGroups[params.group] ? <ExpandMore /> : <ChevronRight />}
            </IconButton>
            {params.group}
          </ListSubheader>
          {openGroups[params.group] && params.children}
        </>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Store Field Value"
          variant="outlined"
          fullWidth
        />
      )}
      style={{ width: 300 }}
    />
  );
};
export default DataModelAutocomplete;
