import { Alert } from "@mui/material";
import { useAtom } from "jotai";
import { FC } from "react";
import { testModeAtom } from "../../../../features/process/state";

type TestModeToggleProps = {
  objectTitle?: string;
};

const TestModeStatus: FC<TestModeToggleProps> = ({ objectTitle }) => {
  const [testMode] = useAtom(testModeAtom);

  if (!testMode) {
    return <></>;
  }
  return (
    <Alert sx={{ py: 0, mr: 1 }} severity="warning">
      {objectTitle ? `Displaying Test ${objectTitle}` : "Displaying Tests"}
    </Alert>
  );
};

export default TestModeStatus;
