import { Typography } from "@mui/material";
import { Task, TaskInlineEditorProps } from "../../../../types";

const CalendlyEventInlineEditor = ({ task }: TaskInlineEditorProps<Task>) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data: any = task.data;
  const email = data?.email ?? "<unknown>";
  const name = data?.name ?? "<unknown>";
  return (
    <Typography variant="caption" sx={{ color: "text.disabled" }}>
      {`Automated email will be sent to ${name} at ${email}`}
    </Typography>
  );
};

export default CalendlyEventInlineEditor;
