export type Display = {
  fillColor?: string;
  borderColor?: string;
};
export default class Role {
  public id = "";
  public name = "";
  public org_id = "";
  public description?: string;
  public display?: Display;
}
