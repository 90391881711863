import { Typography, TypographyProps } from "@mui/material";

// A standardized Typopgraphy for task descriptions, although there is flexibility to override it
const FormElementTaskDescription = (props: TypographyProps) => {
  return (
    <Typography variant="body2" sx={{ my: 1 }} {...props}>
      {props.children}
    </Typography>
  );
};
export default FormElementTaskDescription;
