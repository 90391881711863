import { v4 as uuid } from "uuid";
import { AddCircle } from "@mui/icons-material";
import { Typography, IconButton } from "@mui/material";
import { FormElementType, FormDefinitionType } from "../../types";
import FormElementMenu from "./FormElementMenu";
import { useCallback, useRef, useState } from "react";
import { useFormBuilderContext } from "./FormBuilderProvider";
import FieldDefinition, {
  FieldDefinitionTypes,
} from "../../../../model/FieldDefinition";
import { FieldDefinitionAPI } from "../../../../api";

export const getNewFormElement: (
  type: FormElementType,
) => FormDefinitionType = (type) => {
  switch (type) {
    case FormElementType.Text:
      return {
        type: FormElementType.Text,
        id: uuid(),
        name: "",
        label: "Untitled Text Element",
      };
    case FormElementType.MultiSelection:
      return {
        type: FormElementType.MultiSelection,
        id: uuid(),
        name: "",
        label: "Untitled Multi Select Element",
        options: [{ id: "default", name: "Option 1" }],
      };
    case FormElementType.Radio:
      return {
        type: FormElementType.Radio,
        id: uuid(),
        name: "",
        label: "Untitled Radio Element",
        options: [
          { id: "yes", name: "Yes" },
          { id: "no", name: "No" },
        ],
      };
    case FormElementType.Selection:
      return {
        type: FormElementType.Selection,
        id: uuid(),
        name: "",
        label: "Untitled Select Element",
        options: [],
      };
    case FormElementType.Upload:
      return {
        type: FormElementType.Upload,
        id: uuid(),
        name: "",
        label: "Untitled Upload Element",
      };
    case FormElementType.NestedForm:
      return {
        type: FormElementType.NestedForm,
        id: uuid(),
        name: "",
        label: "Untitled Nested Form Element",
      };
    case FormElementType.Heading:
      return {
        type: FormElementType.Heading,
        id: uuid(),
        name: "",
        label: "Untitled",
      };
    case FormElementType.Checkbox:
      return {
        type: FormElementType.Checkbox,
        id: uuid(),
        name: "",
        label: "Untitled",
      };
    case FormElementType.Date:
      return {
        type: FormElementType.Date,
        id: uuid(),
        name: "date",
        label: "Date",
      };
    case FormElementType.Autocomplete:
      return {
        type: FormElementType.Autocomplete,
        id: uuid(),
        name: "",
        label: "Untitled",
        query: "",
      };
    case FormElementType.FieldDefinition:
      return {
        type: FormElementType.FieldDefinition,
        id: uuid(),
        name: "",
        label: "Field Definition",
        field_definition_id: "",
      };
  }
};

export const FormBuilderAddButton = ({
  open,
  setOpen,
  onClick,
  type,
  disabledTypes,
}: {
  open: boolean;
  setOpen: (value: boolean) => void;
  onClick: (newElement: FormDefinitionType) => void;
  type?: FormElementType;
  disabledTypes?: FormElementType[];
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <IconButton
        ref={buttonRef}
        size="small"
        disableRipple
        onClick={() => {
          if (type) {
            onClick(getNewFormElement(type));
          } else {
            setOpen(true);
          }
        }}
      >
        <AddCircle />
        <Typography sx={{ ml: 1 }} variant={"subtitle2"}>
          {"Add Form Element"}
        </Typography>
      </IconButton>
      <FormElementMenu
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onSelect={(
          type: FormElementType,
          fieldDefOptions?: {
            fieldDef?: FieldDefinition;
            entity?: { id: string; type: FieldDefinitionTypes };
          },
        ) => {
          if (type == FormElementType.FieldDefinition && fieldDefOptions) {
            if (fieldDefOptions.entity) {
              FieldDefinitionAPI.create({
                id: "",
                meta: "",
                label: "Field Definition",
                entity_type: fieldDefOptions.entity.type,
                entity_id: fieldDefOptions.entity.id,
                data: { elements: [] },
              }).then((newFieldDef) =>
                onClick({
                  type: FormElementType.FieldDefinition,
                  id: uuid(),
                  name: "",
                  label: newFieldDef.label,
                  field_definition_id: newFieldDef.id,
                }),
              );
            } else if (fieldDefOptions.fieldDef) {
              onClick({
                type: FormElementType.FieldDefinition,
                id: uuid(),
                name: "",
                label: fieldDefOptions.fieldDef.label,
                field_definition_id: fieldDefOptions.fieldDef.id,
              });
            }
          } else {
            onClick(getNewFormElement(type));
          }
        }}
        anchorEl={buttonRef.current}
        disabledTypes={disabledTypes}
      />
    </>
  );
};

const FormBuilderAddElement = ({ type }: { type?: FormElementType }) => {
  const { elements, onFormElementChange } = useFormBuilderContext();

  const [open, setOpen] = useState(false);

  const onClick = useCallback(
    (newElement: FormDefinitionType) => {
      if (newElement) {
        onFormElementChange({
          changeType: "add",
          newElement,
          prevElementId:
            elements.length > 0 ? elements[elements.length - 1].id : undefined,
        });
      }
    },
    [elements, onFormElementChange],
  );

  return (
    <FormBuilderAddButton
      open={open}
      setOpen={setOpen}
      onClick={onClick}
      type={type}
    />
  );
};

export default FormBuilderAddElement;
