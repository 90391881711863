import { ThemeOptions } from "@mui/material/styles";
import grey from "@mui/material/colors/grey";

const darkTheme: ThemeOptions = {
  palette: {
    mode: "dark",
    background: {
      drawer: grey[900],
    },
  },
};

export default darkTheme;
