/**
 * This global context is used to store / retrieve what elements in the layout are displayed on a per-page-basis
 */

import { createContext, Dispatch, SetStateAction, useContext } from "react";

export type Display = {
  drawer?: boolean;
  topBarBorder?: boolean;
  topBarBorderColor?: string;
};

export type GlobalPageContentType = {
  display: Display;
  setDisplay: Dispatch<SetStateAction<Display>>;
};

export const GlobalPageContext = createContext<GlobalPageContentType>({
  display: {
    drawer: undefined,
    topBarBorder: undefined,
    topBarBorderColor: undefined,
  },
  setDisplay: () => {
    return;
  },
});

export const useGlobalPageContext = () => useContext(GlobalPageContext);
