import { Box } from "@mui/material";
import { ProcessRoute } from "../../../types";
import { Navigate, useParams } from "react-router-dom";
import VerticalSteppedProcessEditor from "../components/VerticalSteppedProcessEditor";
import { TaskProvider } from "../../task";

const ProcessViewPage = () => {
  const { processId } = useParams<ProcessRoute>();
  if (!processId) {
    return <Navigate to={"/user/processes"}></Navigate>;
  }
  return (
    <Box
      component="section"
      sx={{
        display: "flex",
        height: "100%",
        overflow: "auto",
        backgroundColor: (theme) => theme.palette.background.default,
      }}
    >
      <TaskProvider>
        <VerticalSteppedProcessEditor processId={processId} />
      </TaskProvider>
    </Box>
  );
};

export default ProcessViewPage;
