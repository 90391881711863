import { User } from "../model";
import { Account, AccountDto } from "../model/Account";
import { FieldValueObject } from "../model/FieldDefinition";
import { AuditEvent } from "../model/AuditEvent";
import { AssignedRoleUserDto } from "../types";
import API from "./base";
import { AxiosRequestConfig } from "axios";

const create = (account: Account): Promise<Account> => {
  return new Promise((resolve, reject) => {
    API.post("/accounts", account).then(
      (result) => resolve(result.data.payload as Account),
      reject,
    );
  });
};

const get = (accountId: string): Promise<Partial<AccountDto>> => {
  return new Promise((resolve, reject) => {
    API.get("/accounts/" + accountId).then((result) => {
      resolve(result.data.payload as Partial<AccountDto>);
    }, reject);
  });
};

const update = (account: Account): Promise<Account> => {
  return new Promise((resolve, reject) => {
    API.put("/accounts/" + account.id, account).then((result) => {
      resolve(result.data.payload as Account);
    }, reject);
  });
};

// Delete not allowed as variable name
const remove = (accountId: string): Promise<void> => {
  return API.delete("/accounts/" + accountId);
};

const activeByOrganizationId = (
  organizationId: string,
  options: AxiosRequestConfig = {},
): Promise<Array<AccountDto> | Blob> => {
  return new Promise((resolve, reject) => {
    API.get(
      `/accounts/byOrganizationId/${organizationId}/active`,
      options,
    ).then((result) => {
      if (options.format) {
        resolve(result.data);
      } else {
        resolve(result.data.payload as Array<AccountDto>);
      }
    }, reject);
  });
};

const byOrganizationId = (
  organizationId: string,
  options: AxiosRequestConfig = {},
): Promise<Array<AccountDto>> => {
  return new Promise((resolve, reject) => {
    API.get(`/accounts/byOrganizationId/${organizationId}`, options).then(
      (result) => {
        // TODO - move this to interceptors
        if (options.format) {
          resolve(result.data);
        } else {
          resolve(result.data.payload as Array<AccountDto>);
        }
      },
      reject,
    );
  });
};

const byOrganizationAndAccountType = (
  organizationId: string,
  accountTypeId: string,
  options: AxiosRequestConfig = {},
): Promise<Array<AccountDto>> => {
  return new Promise((resolve, reject) => {
    API.get(
      `/accounts/byOrganizationId/${organizationId}/byAccountType/${accountTypeId}`,
      options,
    ).then((result) => {
      if (options.format) {
        resolve(result.data);
      } else {
        resolve(result.data.payload as Array<AccountDto>);
      }
    }, reject);
  });
};

const listAccountsByMember = (userId: string): Promise<Array<AccountDto>> => {
  return new Promise((resolver, reject) => {
    API.get("/accounts/byMember/" + userId).then((result) => {
      resolver(result.data.payload as Array<AccountDto>);
    }, reject);
  });
};

const auditEventsBySubjectId = (
  subjectId: string,
): Promise<Array<AuditEvent>> => {
  return new Promise((resolve, reject) => {
    API.get("/accounts/" + subjectId + "/audit-events").then((result) => {
      resolve(result.data.payload as Array<AuditEvent>);
    }, reject);
  });
};

const listUsersByAccountId = (accountId: string): Promise<Array<User>> => {
  return new Promise((resolve, reject) => {
    API.get("/accounts/" + accountId + "/users").then((result) => {
      resolve(result.data.payload as Array<User>);
    }, reject);
  });
};

const listTeamMembersByAccountId = (
  accountId: string,
): Promise<Array<AssignedRoleUserDto>> => {
  return new Promise((resolve, reject) => {
    API.get("/accounts/" + accountId + "/teamMembers").then((result) => {
      resolve(result.data.payload as Array<AssignedRoleUserDto>);
    }, reject);
  });
};

const assignAccountRole = (
  accountId: string,
  userId: string,
  roleId: string,
): Promise<void> => {
  return API.post("/accounts/assignAccountRole", {
    userId,
    roleId,
    accountId,
  });
};

const unassignAccountRole = (
  accountId: string,
  userId: string,
  roleId: string,
): Promise<void> => {
  return API.post("/accounts/unAssignAccountRole", {
    userId,
    roleId,
    accountId,
  });
};

const removeUser = (accountId: string, userId: string): Promise<void> => {
  return API.delete(`/accounts/${accountId}/users/${userId}`);
};

const getShareKey = (accountId: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    API.get(`/accounts/${accountId}/shareKey`).then((result) => {
      resolve(result.data.payload as string);
    }, reject);
  });
};

const createShareKey = (accountId: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    API.post(`/accounts/${accountId}/shareKey`).then((result) => {
      resolve(result.data.payload as string);
    }, reject);
  });
};

const setFieldValue = (
  accountId: string,
  fieldDefinitionId: string,
  value: FieldValueObject,
): Promise<void> => {
  return API.post(`/accounts/${accountId}/fields/${fieldDefinitionId}`, value);
};

const deleteFieldValue = (
  accountId: string,
  fieldDefinitionId: string,
): Promise<void> => {
  return API.delete(`/accounts/${accountId}/fields/${fieldDefinitionId}`);
};

const getFieldValue = (
  accountId: string,
  fieldDefinitionId: string,
): Promise<{ value: FieldValueObject; updated_at?: number }> => {
  return new Promise((resolve, reject) => {
    API.get(`/accounts/${accountId}/fields/${fieldDefinitionId}`).then(
      (result) =>
        resolve(
          result.data.payload as {
            value: FieldValueObject;
            updated_at?: number;
          },
        ),
      reject,
    );
  });
};

export default {
  create,
  get,
  update,
  delete: remove,
  byOrganizationId,
  byOrganizationAndAccountType,
  listAccountsByMember,
  activeByOrganizationId,
  auditEventsBySubjectId,
  listUsersByAccountId,
  listTeamMembersByAccountId,
  assignAccountRole,
  unassignAccountRole,
  removeUser,
  getShareKey,
  createShareKey,
  setFieldValue,
  deleteFieldValue,
  getFieldValue,
};
