import { Role } from "../model";
import API from "./base";

const create = (role: Role): Promise<Role> => {
  return new Promise((resolve, reject) => {
    API.post("/roles", role).then(
      (result) => resolve(result.data.payload as Role),
      reject,
    );
  });
};

const get = (roleId: string): Promise<Role> => {
  return new Promise((resolve, reject) => {
    API.get("/roles/" + roleId).then((result) => {
      resolve(result.data.payload as Role);
    }, reject);
  });
};

// Won't currently be used because we're only creating and assigning roles to Tasks/Users
const update = (role: Role): Promise<Role> => {
  return new Promise((resolve, reject) => {
    API.put("/roles/" + role.id, role).then((result) => {
      resolve(result.data.payload as Role);
    }, reject);
  });
};

// Won't be used currently because we aren't deleting any Roles in the mockup
// We're just canceling Role creation
const remove = (roleId: string): Promise<void> => {
  return API.delete("/roles/" + roleId);
};

// For auto-complete feature
const byOrganization = (orgId: string): Promise<Array<Role>> => {
  return new Promise((resolve, reject) => {
    API.get("/roles/byOrganization/" + orgId).then((result) => {
      resolve(result.data.payload as Array<Role>);
    }, reject);
  });
};

const byUserIdAndOrganizationId = (
  userId: string,
  organizationId: string,
): Promise<Array<Role>> => {
  return new Promise((resolve, reject) => {
    API.post("/roles/byUserIdAndOrganizationId/", {
      userId: userId,
      organizationId: organizationId,
    }).then((result) => {
      resolve(result.data.payload as Array<Role>);
    }, reject);
  });
};

const assignRole = (roleId: string, userId: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    API.post("/roles/" + roleId + "/assign/" + userId).then(() => {
      resolve();
    }, reject);
  });
};

const unassignRole = (roleId: string, userId: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    API.post("/roles/" + roleId + "/unassign/" + userId).then(() => {
      resolve();
    }, reject);
  });
};

export default {
  create: create,
  get: get,
  update: update,
  remove: remove,
  byOrganization: byOrganization,
  byUserIdAndOrganizationId: byUserIdAndOrganizationId,
  assignRole: assignRole,
  unassignRole: unassignRole,
};
