import { PropsWithChildren } from "react";
import { Box } from "@mui/material";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

type DraggableProps = {
  id: string;
};

const Draggable = (props: PropsWithChildren<DraggableProps>) => {
  const { attributes, listeners, setNodeRef, transform, transition, active } =
    useSortable({ id: props.id });
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    opacity: active?.id === props.id ? 0 : 100,
  };

  return (
    <Box
      data-cy={"draggable-box"}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      style={style}
    >
      {props.children}
    </Box>
  );
};

export default Draggable;
