import { useDroppable } from "@dnd-kit/core";
import { AccountProgram } from "../../../../../model/AccountProgram";
import { Process } from "../../../../../model/Process";
import { ProcessCategory } from "../../../../../model/ProcessCategory";
import { Box, Typography } from "@mui/material";
import ProcessCard from "./ProcessCard";

const BuilderPanel = ({
  cards,
  programs,
  categories,
  toggleVisibility,
}: {
  cards: Process[];
  programs: Map<string, AccountProgram>;
  categories: Map<string, ProcessCategory>;
  toggleVisibility: (process: Process, visible: boolean) => void;
}) => {
  const { setNodeRef } = useDroppable({ id: "builder" });

  return (
    <Box ref={setNodeRef} sx={{ height: "100%", pt: 2, ml: "8%" }}>
      {cards.length > 0 ? (
        <Box>
          {cards.map((card) => {
            return (
              <ProcessCard
                card={card}
                program={programs.get(card.meta ?? card.id)}
                currentPanel="builder"
                category={categories.get(card.category_id ?? "")}
                toggleVisibility={toggleVisibility}
                key={card.id}
              />
            );
          })}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "30vw",
            height: "20%",
            border: "5px dashed lightgrey",
            borderRadius: "10px",
          }}
        >
          <Typography
            sx={{
              color: "grey",
              textAlign: "center",
              mx: 8,
            }}
          >
            Drag processes from the Services List on the left to begin building
            this plan.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default BuilderPanel;
