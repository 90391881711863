import { json } from "@codemirror/lang-json";
import Dialog from "../../../../components/Dialog";
import { TextField, Typography } from "@mui/material";
import { CodeMirror } from "../../../../components/core/CodeMirror/CodeMirror";
import Dashboard from "../../../../model/Dashboard";

type DashboardDialogProps = {
  open: boolean;
  title: string;
  confirmText: string;
  dashboard: Dashboard;
  updateDashboard: (updates: Partial<Dashboard>) => void;
  handleConfirm?: () => void | Promise<void>;
  handleCancel?: () => void | Promise<void>;
};

export const DashboardDialog = ({
  open,
  title,
  confirmText,
  dashboard,
  updateDashboard,
  handleConfirm,
  handleCancel,
}: DashboardDialogProps) => {
  return (
    <Dialog
      open={open}
      title={title}
      fullWidth={true}
      confirmText={confirmText}
      cancelText="Cancel"
      handleConfirm={async () => handleConfirm && (await handleConfirm())}
      handleCancel={handleCancel}
    >
      <TextField
        autoFocus
        margin="dense"
        label="Dashboard Key"
        fullWidth
        value={dashboard?.key ?? null}
        disabled
        onChange={(e) => {
          updateDashboard({ key: e.target.value });
        }}
      />
      <Typography variant="h4" sx={{ mt: 2 }}>
        {"Config"}
      </Typography>
      <CodeMirror
        value={JSON.stringify(dashboard.config, null, "  ") ?? ""}
        height="400px"
        extensions={[json()]}
        onChange={(value) => {
          try {
            updateDashboard({ config: JSON.parse(value) });
          } catch (e) {
            console.error("invalid JSON!", e);
          }
        }}
      />
    </Dialog>
  );
};
