/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { AxiosError } from "axios";
import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ProcessExecutionAPI } from "../../api";
import { useAlert } from "../../lib/alert";
import { ExecutionState } from "../../model/Process";
import { ProcessExecution } from "../../model/ProcessExecution";
import {
  TaskExecution,
  TaskExecutionRenderProps,
  TaskExecutionState,
} from "../task";
import SingleTaskExecutionCard from "./components/SingleTaskExecutionCard";
import PATHS from "../../components/navigation/_paths";
import ProcessExecutionSharedTaskExecutions from "./components/ProcessExecutionSharedTaskExecutions";
import { useIntl } from "react-intl";
import messages from "../app/messages";
import NotFound from "../app/NotFound";

const ACCOUNT_PORTAL = "ACCOUNT_PORTAL";
const ORGANIZATION_PORTAL = "ORGANIZATION_PORTAL";

export type AccountTaskExecutionPageProps = {
  returnTo?: typeof ORGANIZATION_PORTAL | typeof ACCOUNT_PORTAL | undefined;
  showAttachments?: boolean;
};

const findLatestTaskExecution = (taskExecutions: TaskExecution[]) => {
  const lastTask = taskExecutions.find(
    (taskEx) => taskEx.task?.next_task_id === null,
  );
  if (lastTask) {
    return lastTask;
  }
  let mostRecentTaskExecution = undefined;
  for (const taskEx of taskExecutions) {
    if (
      mostRecentTaskExecution === undefined ||
      (taskEx.completed_at &&
        mostRecentTaskExecution.completed_at &&
        taskEx.completed_at > mostRecentTaskExecution.completed_at)
    ) {
      mostRecentTaskExecution = taskEx;
    }
  }
  return mostRecentTaskExecution;
};

const getFirstTaskCompletion = (
  taskExecutions: TaskExecution[],
  startingAt: Date,
) => {
  let earliestTaskExecution = undefined;
  for (const taskEx of taskExecutions) {
    if (
      taskEx.completed_at &&
      (taskEx.completed_at < startingAt ||
        (earliestTaskExecution?.completed_at &&
          taskEx.completed_at < earliestTaskExecution.completed_at))
    ) {
      earliestTaskExecution = taskEx;
    }
  }
  return earliestTaskExecution;
};

const findFirstTaskExecution = (
  processExecution: ProcessExecution | undefined,
  taskExecutions: TaskExecution[],
) => {
  if (!taskExecutions) {
    return undefined;
  }
  // 1. Find the earliest completed
  const earliestTaskExecution = getFirstTaskCompletion(
    taskExecutions,
    new Date(0),
  );
  if (earliestTaskExecution) {
    return earliestTaskExecution;
  }
  // 1. If none completed, look for next process execution task
};

const findNextTaskExecution = (
  processExecution: ProcessExecution | undefined,
  taskExecution: TaskExecution,
  taskExecutions: TaskExecution[],
) => {
  const nextTask = taskExecutions?.find(
    (tEx) => tEx.task?.id === taskExecution.task?.next_task_id,
  );
  if (nextTask) {
    return nextTask;
  }
  // Find the task that is completed after this task
  if (taskExecution.completed_at) {
    const nextCompleted = getFirstTaskCompletion(
      taskExecutions ?? [],
      taskExecution.completed_at,
    );
    if (nextCompleted) {
      return nextCompleted;
    }
  }
  // Find the next task in the process execution
  return taskExecutions?.find(
    (tEx) => tEx.task?.id === processExecution?.next_task_id,
  );
};

const renderLandingPageContent = (
  processExecution: ProcessExecution | undefined,
  taskExecution: TaskExecution | undefined,
  waitingForOthers: boolean,
  showCompletionMessage: boolean,
  loadingTask: boolean,
): (TaskExecutionRenderProps & { icon?: string }) | undefined => {
  if (loadingTask) {
    // TODO - Display skeleton
    return undefined;
  }
  if (waitingForOthers) {
    // TODO - get submission message from previous task
    return {
      title: "Thank You! Your request has been submitted.",
      icon: "CheckCircle",
      content: (
        <Typography>
          We have received your information and will handle your request
          shortly. Updates will be sent to your inbox.
        </Typography>
      ),
    };
  }
  if (
    processExecution?.state === ExecutionState.Completed &&
    showCompletionMessage
  ) {
    // Display completed page
    return {
      title: taskExecution?.task?.data?.submission?.title ?? "All Set",
      icon: "CheckCircle",
      content: (
        <Typography>
          {taskExecution?.task?.data?.submission?.message ??
            "There is nothing left to do. Enjoy your accomplishment!"}
        </Typography>
      ),
    };
  }
  if (taskExecution) {
    return undefined; // just render the task execution
  }
  return {
    title: "Thank You! Your request has been submitted.",
    icon: "CheckCircle",
    content: (
      <Typography>
        We have received your information and will handle your request shortly.
        Updates will be sent to your inbox.
      </Typography>
    ),
  };
};

const AccountTaskExecutionPage: FC<AccountTaskExecutionPageProps> = ({
  returnTo,
  showAttachments = true,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { processExecutionId } = useParams();
  const [loaded, setLoaded] = useState(true);
  const [showWaitingForOthers, setShowWaitingForOthers] = useState(false);
  const [showCompletionMessage, setShowCompletionMessage] = useState(false);
  const { error } = useAlert();

  const [taskExecution, setTaskExecution] = useState<TaskExecution>();
  const [taskExecutions, setTaskExecutions] = useState<Array<TaskExecution>>();
  const [processExecution, setProcessExecution] = useState<ProcessExecution>();
  const [loadingTask, setLoadingTask] = useState(true);
  const loadProcessExecution = useCallback(async () => {
    if (processExecutionId) {
      const processExecution = await ProcessExecutionAPI.getDto(
        processExecutionId!,
      );
      const taskExecutions =
        await ProcessExecutionAPI.getTaskExecutions(processExecutionId);
      setProcessExecution(processExecution);
      setTaskExecution((taskExecution) => {
        const updatedTaskExecution = taskExecutions.find(
          (tEx) => tEx?.task?.id === taskExecution?.task?.id,
        );
        return updatedTaskExecution ?? taskExecution;
      });
      return [processExecution, taskExecutions];
    }
    return undefined;
  }, [processExecutionId]);

  const submitTask = useCallback(
    async (s: TaskExecution) => {
      if (!s.task_id) {
        error(`Failed to submit task: missing task_id for task ${s.id}`);
        return;
      }
      setLoaded(false);
      return ProcessExecutionAPI.submitTask(processExecutionId!, s.task_id, s)
        .then(() => {
          if (!s.task_id) {
            error(`Failed to complete task: missing task_id for task ${s.id}`);
            return;
          }
          return ProcessExecutionAPI.completeTask(
            processExecutionId!,
            s.task_id,
          );
        })
        .catch((e) => {
          // error occurred when submitting and/or completing the task
          console.error(e);
          error("Failed to complete task");
        })
        .finally(() => {
          setLoaded(true);
        });
    },
    [error, processExecutionId],
  );
  const setWaitingForOthers = useCallback(
    (taskExecutions: Array<TaskExecution>) => {
      setShowWaitingForOthers(true);
      if (!taskExecution) {
        const completedExecutions = taskExecutions.filter(
          (taskEx) => taskEx.state === TaskExecutionState.Completed,
        );
        const previouslyCompletedTaskExecution =
          completedExecutions[completedExecutions.length - 1];
        setTaskExecution(previouslyCompletedTaskExecution);
      }
    },
    [taskExecution],
  );

  const displayNextTaskOrProcessCompletion = useCallback(
    async (
      processExecution: ProcessExecution,
      taskExecutions: Array<TaskExecution>,
    ) => {
      setTaskExecutions(taskExecutions);
      if (processExecution.state === ExecutionState.Completed) {
        // FUTURE - Show CTAs based on account/organization in portal
        setShowCompletionMessage(true);
        setTaskExecution(
          (taskExecution) =>
            taskExecution ?? findLatestTaskExecution(taskExecutions),
        );
        setLoadingTask(false);
      } else if (processExecution.next_task_id) {
        return ProcessExecutionAPI.getTaskExecution(
          processExecutionId!,
          processExecution.next_task_id,
        ).then(
          (nextTaskExecution) => {
            // Check permission to execute task
            ProcessExecutionAPI.submitTask(
              processExecutionId!,
              processExecution.next_task_id,
              nextTaskExecution,
            ).then(
              () => {
                // Access to submit - render next task
                setTaskExecution(nextTaskExecution);
                setLoadingTask(false);
              },
              (error: AxiosError) => {
                if (error.response?.status === 403) {
                  // No permissions show waiting for organization card
                  setWaitingForOthers(taskExecutions);
                  setLoadingTask(false);
                  // How do we know who we are waiting for?
                  // - what is the current user's involvement in the process execution?
                  //   - Authorized member of an account -> check subject ID on process execution and check account membership
                  //   - Authorized member of the organization ->
                }
              },
            );
          },
          () => {
            // No access - show waiting for organization card
            setWaitingForOthers(taskExecutions);
            setLoadingTask(false);
          },
        );
      }
    },
    [processExecutionId, setWaitingForOthers],
  );

  const load = useCallback(async () => {
    if (!processExecution && processExecutionId) {
      setLoadingTask(true);
      const currentProcessExecution = await loadProcessExecution();
      if (currentProcessExecution) {
        const [pe, taskExecutions] = currentProcessExecution;
        await displayNextTaskOrProcessCompletion(
          (pe as ProcessExecution)!,
          (taskExecutions as TaskExecution[])!,
        );
      }
    }
  }, [
    displayNextTaskOrProcessCompletion,
    loadProcessExecution,
    processExecution,
    processExecutionId,
  ]);

  const submitTaskAndLoadNextTask = useCallback(
    async (taskEx: TaskExecution) => {
      //taskExecution!.state = TaskExecutionState.Completed;
      setLoadingTask(true);
      await submitTask({
        ...taskEx,
        state: TaskExecutionState.Completed,
      });
      const currentProcessExecution = await loadProcessExecution(); // reload process execution state after task submission
      if (currentProcessExecution) {
        const [pe, currentTaskExecutions] = currentProcessExecution;
        await displayNextTaskOrProcessCompletion(
          pe as ProcessExecution,
          currentTaskExecutions as TaskExecution[],
        );
      }
    },
    [displayNextTaskOrProcessCompletion, loadProcessExecution, submitTask],
  );

  useEffect(() => {
    load();
  }, [load]);

  if (!processExecutionId) {
    return <NotFound />;
  }
  let backTitle = undefined;
  let onBack = undefined;
  if (showCompletionMessage) {
    backTitle = `View Completed`;
    onBack = () => setShowCompletionMessage(false);
  } else if (showWaitingForOthers) {
    backTitle = `< Back`;
    onBack = () => setShowWaitingForOthers(false);
  } else if (
    !taskExecution?.task?.previous_task_id ||
    !taskExecutions?.some(
      (tEx) => tEx.task?.id === taskExecution?.task?.previous_task_id,
    )
  ) {
    // No previous task
    if (returnTo === ACCOUNT_PORTAL) {
      backTitle = `Back to ${intl.formatMessage(messages.portal.props)}`;
      onBack = () =>
        navigate(PATHS.ACCOUNT_PORTAL.linkTo(processExecution!.subject_id!));
    }
  } else {
    // Previous task exists
    onBack = () => {
      const prev = taskExecutions?.find(
        (tEx) => tEx.task?.id === taskExecution.task?.previous_task_id,
      );
      if (prev) {
        setTaskExecution(prev);
      }
    };
  }

  // Display submission confirmation or completion landing pages
  const content = renderLandingPageContent(
    processExecution,
    taskExecution,
    showWaitingForOthers,
    showCompletionMessage,
    loadingTask,
  );
  const showNextButton =
    !!taskExecution?.task?.next_task_id && !showWaitingForOthers;
  const onNext = showNextButton
    ? () =>
        setTaskExecution((taskExecution) => {
          const nextTaskExecution = taskExecution
            ? findNextTaskExecution(
                processExecution,
                taskExecution,
                taskExecutions ?? [],
              )
            : findFirstTaskExecution(processExecution, taskExecutions ?? []);
          if (!nextTaskExecution) {
            setShowWaitingForOthers(true);
            return taskExecution;
          }
          return nextTaskExecution;
        })
    : undefined;
  return (
    <>
      <Grid
        container
        sx={{
          height: "100%",
          position: "relative",
          justifyContent: "center",
          alignContent: "flex-start",
          backgroundColor: "background.drawer",
          p: 2,
          flex: 1,
        }}
        spacing={2}
      >
        <Grid
          xs={12}
          md={8}
          lg={6}
          lgOffset={showAttachments ? 2 : 0}
          sx={{ maxHeight: 1, minHeight: "100%" }}
        >
          {(taskExecution || content) && (
            <SingleTaskExecutionCard
              process={undefined}
              taskExecution={taskExecution}
              content={content}
              onExecute={submitTaskAndLoadNextTask}
              icon={content?.icon ?? processExecution!.process_icon}
              loaded={loaded}
              backTitle={backTitle}
              onBack={onBack}
              onNext={onNext}
              hasNext={showNextButton}
            />
          )}
        </Grid>
        {showAttachments && (
          <Grid xs={12} md={8} lg={2} sx={{ flex: 1 }}>
            {processExecution && (
              <ProcessExecutionSharedTaskExecutions
                processId={processExecution.process_id}
                processExecutionId={processExecution.id}
                attachmentReloadTrigger={1}
                variant="chip"
              />
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default AccountTaskExecutionPage;
