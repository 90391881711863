/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useState } from "react";
import { ProcessAPI } from "../../../api";
import { useGlobalOrganizationContext } from "../../../hooks/useGlobalOrganizationContext";
import { useAlert } from "../../../lib/alert";
import { AccountType } from "../../../model/AccountType";
import { ACCOUNT_TYPE } from "../../../model/EntityRef";
import { Process } from "../../../model/Process";
import OrganizationPublicProcessCards from "./OrganizationPublicProcessCards";
type OrganizationAccountTypePublicPageProps = {
  accountType: AccountType;
};
const OrganizationAccountTypePublicProcessCards = ({
  accountType,
}: OrganizationAccountTypePublicPageProps) => {
  const { organization } = useGlobalOrganizationContext();
  const [processes, setProcesses] = useState<Process[] | undefined>();
  const { handleRejectionWithError } = useAlert();
  useEffect(() => {
    const loadPublicProcesses = () => {
      ProcessAPI.byEntity(organization!.id, {
        entity_type: ACCOUNT_TYPE,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        entity_id: accountType.id,
      }).then((processes) => {
        setProcesses(processes);
      }, handleRejectionWithError);
    };
    loadPublicProcesses();
  }, [accountType, handleRejectionWithError, organization]);
  if (!processes) {
    return <></>;
  }
  return <OrganizationPublicProcessCards processes={processes} />;
};
export default OrganizationAccountTypePublicProcessCards;
