import React, { useRef, useState, useEffect } from "react";

interface ContentEditableProps {
  value: string;
  onChange: (newValue: string) => Promise<boolean>;
}

const ContentEditable: React.FC<ContentEditableProps> = ({
  value,
  onChange,
}) => {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [localValue, setLocalValue] = useState<string>(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const handleBlur = async () => {
    if (contentRef.current) {
      const newValue = contentRef.current.innerHTML;
      if (newValue !== localValue) {
        const shouldUpdate = await onChange(newValue);
        if (!shouldUpdate) {
          contentRef.current.innerHTML = localValue;
        }
      }
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    // Prevent the click event from propagating further
    e.stopPropagation();

    // Select everything inside the content-editable div
    const selection = window.getSelection();
    const range = document.createRange();
    if (contentRef.current) {
      range.selectNodeContents(contentRef.current);
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevents the addition of a new line
      handleBlur();
      contentRef.current?.blur();
    }
    e.stopPropagation();
  };

  return (
    <div
      ref={contentRef}
      contentEditable={true}
      onBlur={handleBlur}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      dangerouslySetInnerHTML={{ __html: localValue }}
    />
  );
};

export default ContentEditable;
