import { useCallback, useMemo, useState } from "react";
import { DragEndEvent } from "@dnd-kit/core";
import DraggableList from "../../../../components/core/Draggable/DraggableList";
import FormBuilderChild from "./FormBuilderChild";
import { useFormBuilderContext } from "./FormBuilderProvider";

export const FormBuilderDraggableList = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const { elements, selectedElementId, onFormElementChange } =
    useFormBuilderContext();

  const [activeId, setActiveId] = useState("");

  const overlayComponent = useMemo(() => {
    const activeElement = elements.find((el) => el.id === activeId);
    if (!activeElement) {
      return <></>;
    }
    return (
      <FormBuilderChild
        element={activeElement}
        isSelected={activeId == selectedElementId}
      />
    );
  }, [activeId, elements, selectedElementId]);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (over?.id && active.id !== over?.id) {
        const activeIndex = elements.findIndex((el) => el.id === active.id);
        const overIndex = elements.findIndex((el) => el.id === over.id);
        onFormElementChange({
          changeType: "reorder",
          activeIndex,
          overIndex,
        });
      }
    },
    [elements, onFormElementChange],
  );

  return (
    <DraggableList
      listItemIds={elements.map((el) => el.id)}
      handleDragEnd={handleDragEnd}
      handleDragStart={(event) => setActiveId(event.active.id.toString())}
      overlayComponent={overlayComponent}
    >
      {children}
    </DraggableList>
  );
};
