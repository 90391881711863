import { Box, Tabs, Tab } from "@mui/material";
import { FC } from "react";
import { Process } from "../../../model/Process";

import { Section } from "./section-components/sections";

export enum KanbanColumnType {
  TASK = "task",
  STEP = "step",
}

type KanbanBoardControlsProps = {
  process: Process;
  sectionList?: Section[];
  currentSectionIndex: number;
  handleTabChange: (index: number) => void;
};

const KanbanBoardControls: FC<KanbanBoardControlsProps> = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box sx={{ maxWidth: "50%" }}>
        <Tabs
          value={props.currentSectionIndex}
          variant="scrollable"
          scrollButtons="auto"
          onChange={(e, value) => {
            props.handleTabChange(parseInt(value));
          }}
        >
          {props.sectionList &&
            props.sectionList.length > 0 &&
            props.sectionList.map((section, index) => {
              if (section.step) {
                return (
                  <Tab
                    label={section.step.name}
                    sx={{ borderBottom: "1px solid rgb(148 148 148)" }}
                    value={index}
                    key={index}
                  ></Tab>
                );
              }
            })}
        </Tabs>
      </Box>
    </Box>
  );
};

export default KanbanBoardControls;
