import API from "./base";
import FieldDefinition from "../model/FieldDefinition";
import { FormDefinitionType } from "../features/task";

const get = (id: string): Promise<FieldDefinition> => {
  return new Promise((resolve, reject) => {
    API.get(`/field-definitions/${id}`, { public: true }).then((result) => {
      resolve(result.data.payload as FieldDefinition);
    }, reject);
  });
};

const getWithoutParentElements = (id: string): Promise<FieldDefinition> => {
  return new Promise((resolve, reject) => {
    API.get(`/field-definitions/${id}`, {
      public: true,
      params: {
        includeParentElements: false,
      },
    }).then((result) => {
      resolve(result.data.payload as FieldDefinition);
    }, reject);
  });
};

const create = (fieldDefinition: FieldDefinition): Promise<FieldDefinition> => {
  return new Promise((resolve, reject) => {
    API.post(`/field-definitions`, fieldDefinition).then((result) => {
      resolve(result.data.payload as FieldDefinition);
    }, reject);
  });
};

const update = (fieldDefinition: FieldDefinition): Promise<FieldDefinition> => {
  return new Promise((resolve, reject) => {
    API.put(`/field-definitions/${fieldDefinition.id}`, fieldDefinition).then(
      (result) => {
        resolve(result.data.payload as FieldDefinition);
      },
      reject,
    );
  });
};

const updateElements = async (
  fieldDefinitionId: string,
  elements: FormDefinitionType[],
): Promise<FieldDefinition> => {
  const fieldDefinition = await get(fieldDefinitionId);
  return await update({ ...fieldDefinition, data: { elements } });
};

const deleteFieldDefinition = (fieldDefinitionId: string): Promise<void> => {
  return API.delete(`/field-definitions/${fieldDefinitionId}`);
};

export default {
  get,
  getWithoutParentElements,
  create,
  update,
  updateElements,
  delete: deleteFieldDefinition,
};
