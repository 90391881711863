import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { ProcessExecution } from "../../../model/ProcessExecution";
import { Task } from "../../task";
import KanbanBoardCard from "./KanbanBoardCard";
import { KanbanColumnType } from "./KanbanBoardControls";
import KanbanBoardCategory from "./section-components/KanbanBoardCategory";
import KanbanBoardSectionHeader from "./section-components/KanbanBoardSectionHeader";
import { Section, FlatCategory } from "./section-components/sections";
import React from "react";

type KanbanBoardSectionProps = {
  section: Section;
  functions: {
    reload: (fromTask: Task) => Promise<void>;
    submit: (execution: ProcessExecution, taskId: string) => Promise<boolean>;
  };
  complete?: boolean;
};

const KanbanBoardSection = React.forwardRef<
  HTMLElement,
  KanbanBoardSectionProps
>(({ section, functions, complete }, ref) => {
  const DEFAULT_COL_WIDTH = 20; // default size of a column in a section, measured in vw

  const [view, setView] = useState<KanbanColumnType>(KanbanColumnType.STEP);
  const [columns, setColumns] = useState<FlatCategory[]>(
    section.columns.get(KanbanColumnType.STEP) ?? [],
  );

  const getCards = (executions: ProcessExecution[]) => {
    return executions.map((execution) => {
      return (
        <KanbanBoardCard
          execution={execution}
          key={execution.id}
          task={execution.next_task_instance as Task}
          view={view}
          submit={functions.submit}
          reload={() => {
            return new Promise<void>((resolve) => {
              resolve();
            });
          }}
          draggable={true}
        />
      );
    });
  };

  useEffect(() => {
    setColumns(section.columns.get(view) ?? []);
  }, [section.columns, view]);

  return (
    <Box
      sx={{
        display: "flex",
        flexBasis: `calc(${
          DEFAULT_COL_WIDTH * columns.length + (columns.length - 1) * 0.5
        }vw + ${columns.length > 2 ? columns.length - 2 : 0}vw)`, // dynamically sets the width based on how many columns are in a section
        flexShrink: 0,
        flexDirection: "column",
        mr: "5%",
        transition: "flexBasis, .5s",
        "&:last-of-type": {
          mr: 0,
        },
      }}
      ref={ref}
    >
      <KanbanBoardSectionHeader
        step={section.step}
        view={view}
        setView={setView}
        complete={complete}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexGrow: 1,
          mt: "1vh",
          borderRadius: "10px",
        }}
      >
        {columns.map(({ token, executions }, index) => {
          return (
            <KanbanBoardCategory
              token={token}
              view={view}
              submit={functions.submit}
              reload={functions.reload}
              key={index}
            >
              {getCards(executions)}
            </KanbanBoardCategory>
          );
        })}
      </Box>
    </Box>
  );
});

KanbanBoardSection.displayName = "KanbanBoardSection";
export default KanbanBoardSection;
