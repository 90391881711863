import { useState } from "react";
import { AutocompleteFormElement } from "../../../types";
import TaskAutocompleteFormComponent from "../../task-types/form/task-form-elements/TaskAutocompleteFormComponent";

const AutocompleteInlineDisplay = ({
  element,
}: {
  element: AutocompleteFormElement;
}) => {
  const [value, setValue] = useState(element.value ?? "");
  return (
    <TaskAutocompleteFormComponent
      execution={{
        ...element,
        value,
      }}
      {...element}
      onValueChange={(value) => {
        setValue(value);
      }}
    />
  );
};

export default AutocompleteInlineDisplay;
