import { FC, PropsWithChildren } from "react";
import { ProcessExecution } from "../../../../model/ProcessExecution";

import { Box, Paper, Tooltip, Typography } from "@mui/material";
import { Task } from "../../../task";
import { ColumnToken } from "./sections";
import { KanbanColumnType } from "../KanbanBoardControls";

type KanbanCategoryProps = {
  token: ColumnToken;
  view: KanbanColumnType;
  submit: (execution: ProcessExecution, taskId: string) => Promise<boolean>;
  reload: (fromTask: Task) => Promise<void>;
};

const KanbanBoardCategory: FC<PropsWithChildren<KanbanCategoryProps>> = (
  props,
) => {
  return (
    <Paper
      elevation={2}
      sx={{
        flexBasis: "20vw",
        justifyContent: "center",
        bgcolor: (theme) => theme.palette.grey[100],
        borderRadius: "10px",
        mx: ".5vw",
        mb: ".5vh",
        "&:first-of-type": {
          ml: 0,
          flexShrink: 0,
        },
        "&:last-of-type": {
          mr: 0,
        },
      }}
    >
      {props.view == KanbanColumnType.TASK && (
        <Tooltip title={props.token.name} placement="bottom-start">
          <Typography
            sx={{
              mx: "2vw",
              lineHeight: "1.2em",
              my: "1vh",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              overflow: "hidden",
            }}
          >
            {props.token.name}
          </Typography>
        </Tooltip>
      )}
      <Box>{props.children}</Box>
    </Paper>
  );
};

export default KanbanBoardCategory;
