import { useContext, useMemo } from "react";
import {
  AccountSelectTaskContext,
  AccountSelectTaskOption,
} from "../AccountSelectWrapper";
import {
  Box,
  Button,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Business,
  Close,
  SearchOutlined,
  SupervisorAccountOutlined,
} from "@mui/icons-material";
import { Account, Organization } from "../../../../model";

const AccountSelectExpanded = ({
  setSelected,
  setDialogOpen,
}: {
  setSelected: (option?: AccountSelectTaskOption) => void;
  setDialogOpen: (open: boolean) => void;
}) => {
  const { options, disabled, query, setQuery } = useContext(
    AccountSelectTaskContext,
  );

  return (
    <Container disableGutters>
      <TextField
        InputProps={{
          startAdornment: <SearchOutlined sx={{ mr: 1 }} />,
          endAdornment: query && (
            <Tooltip title="Clear Search">
              <IconButton onClick={() => setQuery("")}>
                <Close />
              </IconButton>
            </Tooltip>
          ),
        }}
        placeholder="Search Directory"
        fullWidth
        onChange={(event) => setQuery(event.target.value)}
        sx={{ mb: "1rem" }}
        value={query ?? ""}
        disabled={disabled}
      />
      <OptionSelectedCard setDialogOpen={setDialogOpen} />
      {options.length > 0 && <OptionsList onSelect={setSelected} />}
    </Container>
  );
};

// Displays the selected option as a card or a button to create a new account
const OptionSelectedCard = ({
  setDialogOpen,
}: {
  setDialogOpen: (open: boolean) => void;
}) => {
  const { options, selected, disabled } = useContext(AccountSelectTaskContext);

  const defaultContent = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        border: "1px dashed lightgrey",
        borderRadius: ".25rem",
        p: "1rem",
      }}
    >
      <Typography color="grey">
        {"Can't find the account you're looking for?"}
      </Typography>
      <Button
        variant="contained"
        size="small"
        color={options.length > 0 ? "inherit" : "primary"}
        sx={{ mt: 1 }}
        onClick={() => setDialogOpen(true)}
        disabled={disabled}
      >
        Create Account
      </Button>
    </Box>
  );

  const getOrganizationContent = () => {
    const organization = selected!.data as Organization;
    return (
      <>
        <img
          src={organization.theme?.shortLogoUrl}
          style={{ width: "5rem", marginRight: "1rem" }}
        />
        <Box>
          <Typography variant="inherit">{selected!.label}</Typography>
          {/* commented out since we don't have an organization's address available
          
          <Typography
            variant="subtitle2"
            sx={{ color: (theme) => theme.palette.grey[600] }}
          >
            Hancock, MI
          </Typography> */}
        </Box>
      </>
    );
  };

  const getAccountContent = () => {
    const account = selected!.data as Account;
    const hasEmail = account.primary_email && account.primary_email.length > 0;
    return (
      <Box sx={{ ml: "1rem" }}>
        <Typography variant="inherit">{selected!.label}</Typography>
        <Typography
          variant="subtitle2"
          sx={{
            color: (theme) => theme.palette.grey[600],
          }}
        >
          {hasEmail ? account.primary_email : "No email provided"}
        </Typography>
      </Box>
    );
  };

  const content = selected && (
    <Paper sx={{ border: "1px solid black", height: "6rem", p: "1rem" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
        }}
      >
        {selected.type === "organization"
          ? getOrganizationContent()
          : getAccountContent()}
      </Box>
    </Paper>
  );

  return (
    <Container disableGutters sx={{ mb: "1rem", height: "6rem" }}>
      {selected ? content : defaultContent}
    </Container>
  );
};

const OptionsList = ({
  onSelect,
}: {
  onSelect: (option?: AccountSelectTaskOption) => void;
}) => {
  const { selected, options, disabled } = useContext(AccountSelectTaskContext);

  const getOptionIcon = (option: AccountSelectTaskOption) => {
    if (option.type === "organization") {
      return (
        <Tooltip title="Organization">
          <Business />
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title="Account">
          <SupervisorAccountOutlined />
        </Tooltip>
      );
    }
  };
  const content = useMemo(() => {
    return options.map((option, index) => {
      const lastElement = index === options.length - 1;

      if (option.data.id != selected?.data.id) {
        return (
          <ListItem
            divider={!lastElement}
            disableGutters
            disablePadding
            key={option.data.id}
          >
            <ListItemButton
              onClick={() => onSelect(option)}
              disabled={disabled}
              key={option.data.id}
            >
              <ListItemIcon>{getOptionIcon(option)}</ListItemIcon>
              <ListItemText>{option.label}</ListItemText>
            </ListItemButton>
          </ListItem>
        );
      } else {
        return (
          <ListItem
            sx={{
              bgcolor: (theme) => theme.palette.background.paper,
              mt: "-1px",
              borderTop: "1px solid black",
              borderBottom: !lastElement ? "1px solid black" : 0,
            }}
            secondaryAction={
              <IconButton
                onClick={() => onSelect(undefined)}
                size="small"
                disabled={disabled}
              >
                <Close />
              </IconButton>
            }
            key={selected.data.id}
          >
            <ListItemIcon>{getOptionIcon(selected)}</ListItemIcon>
            <ListItemText>{selected.label}</ListItemText>
          </ListItem>
        );
      }
    });
  }, [disabled, onSelect, options, selected]);

  return (
    <List
      sx={{
        bgcolor: "background.menu",
        border: "1px solid black",
        borderRadius: ".25rem",
        py: 0,
        maxHeight: "40vh",
        overflowY: "scroll",
      }}
    >
      {content}
    </List>
  );
};

export default AccountSelectExpanded;
