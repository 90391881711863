import { TextField } from "@mui/material";
import { AutocompleteFormElement } from "../../../types";
import { FormElementChangeType } from "../FormBuilder";

const AutocompleteSideSectionDisplay = ({
  element,
  onChange,
}: {
  element: AutocompleteFormElement;
  onChange: (props: FormElementChangeType) => void;
}) => {
  return (
    <>
      <TextField
        fullWidth
        label={"Query"}
        value={element?.query ?? ""}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) => {
          onChange({
            changeType: "update",
            newElement: {
              ...element,
              query: e.target.value,
            },
          });
        }}
      />
      <TextField
        fullWidth
        label={"Error"}
        value={element?.error ?? ""}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) => {
          onChange({
            changeType: "update",
            newElement: {
              ...element,
              error: e.target.value,
            },
          });
        }}
      />
    </>
  );
};

export default AutocompleteSideSectionDisplay;
