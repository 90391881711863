import { Button, ButtonProps, CircularProgress } from "@mui/material";
import React from "react";
import { useState } from "react";

export type SubmitButtonProps = ButtonProps & {
  onSubmit: () => Promise<void>;
  forwardedRef?: React.Ref<HTMLButtonElement>;
};
const SubmitButton = ({
  disabled,
  forwardedRef,
  ...props
}: SubmitButtonProps) => {
  const [loading, setLoading] = useState(false);
  return (
    <Button
      data-cy={"submit-button"}
      disabled={loading || disabled}
      onClick={() => {
        setLoading(true);
        props.onSubmit().finally(() => {
          setLoading(false);
        });
      }}
      variant="contained"
      ref={forwardedRef}
      {...props}
    >
      {props.children}
      {loading && (
        <CircularProgress
          data-cy={"circular-progress"}
          size={24}
          sx={{ position: "absolute" }}
        />
      )}
    </Button>
  );
};
export default React.forwardRef<HTMLButtonElement, SubmitButtonProps>(
  (props, ref) => <SubmitButton {...props} forwardedRef={ref} />,
);
