import {
  AddCircleOutline,
  ExpandLess,
  ExpandMore,
  SubdirectoryArrowRight,
} from "@mui/icons-material";
import { Grid, Button, Typography, Divider } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { v4 } from "uuid";
import {
  FormExecutionType,
  NestedFormElement,
  NestedFormElementExecution,
  TaskExecutionState,
} from "../../../../types";
import FormExecutionComponent, {
  FormExecutionComponentProps,
} from "../FormExecutionComponent";
import FormElementTaskDescription from "../../../form-builder/FormElementTaskDescription";

type TaskNestedFormComponentProps = NestedFormElement &
  FormExecutionComponentProps & {
    disabled?: boolean;
    onButtonClick: (elements: Array<Array<FormExecutionType>>) => void;
    execution?: NestedFormElementExecution;
  };

const TaskNestedFormComponent: FC<TaskNestedFormComponentProps> = ({
  label,
  name,
  description,
  taskExecutionState,
  ...props
}) => {
  const [nestedFormElements, setNestedFormElements] = useState<
    Array<Array<FormExecutionType>>
  >([]);
  const [nestedFormElementKeys, setNestedFormElementKeys] = useState<
    Array<string>
  >([]);
  const { execution, onButtonClick } = props;
  const [expanded, setExpanded] = useState(true);

  useEffect(() => {
    if (execution?.value) {
      setNestedFormElements(execution?.value);
      setNestedFormElementKeys(execution?.value.map(() => v4()));
      onButtonClick(execution?.value);
    }
  }, [onButtonClick, execution?.value]);

  const renderChildren = useCallback(() => {
    const toRender = [];
    for (let i = 0; i < nestedFormElements.length; i++) {
      const isLastItem = i === nestedFormElements.length - 1;
      toRender.push(
        <Grid item key={nestedFormElementKeys[i]}>
          <FormExecutionComponent
            {...props}
            isDisabled={props.disabled}
            elements={nestedFormElements[i]}
            executionElements={
              props.execution?.value ? props.execution?.value[i] : []
            }
            removeNestedForm={
              props.taskExecution?.state == TaskExecutionState.InProgress
                ? () => {
                    setNestedFormElements((prev) => {
                      prev.splice(i, 1);
                      setNestedFormElementKeys((prevKey) => {
                        prevKey.splice(i, 1);
                        return prevKey;
                      });
                      props.onButtonClick(prev);
                      return [...prev];
                    });
                  }
                : undefined
            }
            hideCompleteButton
            hideEditButton
          />
          {props.disabled && !isLastItem && (
            <Divider variant="middle" sx={{ mt: 1 }} />
          )}
        </Grid>,
      );
    }
    return toRender;
  }, [nestedFormElementKeys, nestedFormElements, props]);

  const ExpandIcon = expanded ? ExpandLess : ExpandMore;
  const allowExpand =
    props.taskExecution?.state == TaskExecutionState.Completed;

  // Readonly view
  if (props.disabled && taskExecutionState === TaskExecutionState.Completed) {
    return (
      <>
        <Grid item gap={1} alignItems={"center"}>
          <Typography variant="subtitle1">{label}:</Typography>
        </Grid>
        {renderChildren()}
      </>
    );
  }

  return (
    <Grid item>
      <Grid container gap={3} flexDirection={"column"}>
        <Grid
          item
          onClick={() => {
            if (!allowExpand) return;
            setExpanded((prev) => !prev);
          }}
          sx={(theme) => ({
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "5px",
            cursor: "pointer",
            px: 2,
            background: theme.palette.grey[800],
            color: theme.palette.getContrastText(theme.palette.grey[800]),
          })}
        >
          <Typography sx={{ padding: 1 }} variant={"h4"} id={name}>
            {label}
          </Typography>

          {nestedFormElements.length > 0 && allowExpand && (
            <ExpandIcon
              sx={(theme) => ({
                color: theme.palette.getContrastText(theme.palette.grey[800]),
              })}
            ></ExpandIcon>
          )}
        </Grid>
        <FormElementTaskDescription>{description}</FormElementTaskDescription>
        {nestedFormElements.length > 0 &&
          (expanded ? (
            renderChildren()
          ) : (
            <Grid item sx={{ display: "flex", alignItems: "center", ml: 3 }}>
              {
                <SubdirectoryArrowRight
                  sx={(theme) => ({
                    mr: 1,
                    color: theme.typography.subtitle1.color,
                  })}
                />
              }
              <Typography variant={"subtitle1"}>
                {`${nestedFormElements.length} "${label}" hidden`}
              </Typography>
            </Grid>
          ))}
        {props.taskExecution?.state !== TaskExecutionState.Error &&
          !props.disabled && (
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => {
                  setNestedFormElements((prev) => {
                    prev.push(
                      (props.elements ?? []).map((e) => ({
                        ...e,
                      })),
                    );
                    setNestedFormElementKeys((prevKey) => {
                      prevKey.push(v4());
                      return prevKey;
                    });
                    props.onButtonClick(prev);
                    setExpanded(true);
                    return [...prev];
                  });
                }}
              >
                <AddCircleOutline sx={{ mr: 1 }} />
                {`Add ${label}`}
              </Button>
            </Grid>
          )}
      </Grid>
    </Grid>
  );
};

export default TaskNestedFormComponent;
