import { PaymentElement } from "@stripe/react-stripe-js";
import {
  TaskExecutionComponentProps,
  TaskExecution,
  Task,
} from "../../../../types";
import StripeContainer from "./StripeContainer";
import StripeSubmitButton from "./StripeSubmitButton";
import { useEffect, useState } from "react";
import { CheckCircle } from "@mui/icons-material";
import { Box, Container, Paper, Typography } from "@mui/material";
import { createTaskExecution } from "../../getTaskByType";
import poweredByStripe from "../../../../../../assets/images/powered_by_stripe.svg";
import { useAlert } from "../../../../../../lib/alert";

const StripeCheckoutExecutionComponent = (
  props: TaskExecutionComponentProps<Task, TaskExecution>,
) => {
  const { handleRejectionWithError } = useAlert();
  const [clientSecret, setClientSecret] = useState<string | undefined>();

  const [paid, setPaid] = useState<boolean>(false);

  useEffect(() => {
    if (props.isDisabled) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const customerId = (props.taskExecution?.data as any)?.customerId;

    if (!customerId) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const webhookUrl = (props.task.data as any)?.url;
    if (!webhookUrl) {
      return;
    }

    fetch(webhookUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: customerId,
      }),
    })
      .then((response) => {
        // Only proceed if the response is a 2xx
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        setClientSecret(data.payload);
      })
      .catch(handleRejectionWithError("Failed to create payment intent"));
  }, [
    props.task.data,
    props.taskExecution?.data,
    props.isDisabled,
    handleRejectionWithError,
  ]);

  return (
    <Container maxWidth="sm">
      <Paper elevation={2} sx={{ p: 4, borderRadius: "10px" }}>
        {clientSecret ? (
          paid ? (
            <Box sx={{ textAlign: "center", width: "100%" }}>
              <CheckCircle fontSize="large" sx={{ margin: "auto" }} />
            </Box>
          ) : (
            <>
              <Typography variant="h1">Payment Details</Typography>
              <Typography variant="body1" sx={{ pb: 2 }}>
                After the 7 day trial period, your card will be billed monthly.
              </Typography>

              <StripeContainer clientSecret={clientSecret}>
                <PaymentElement />
                <StripeSubmitButton
                  clientSecret={clientSecret}
                  onSubmit={() => {
                    setPaid(true);
                    props.onExecute?.(
                      props.taskExecution ?? createTaskExecution(props.task),
                    );
                  }}
                  disabled={props.isDisabled}
                />
              </StripeContainer>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <img src={poweredByStripe} style={{ maxHeight: "34px" }} />
              </Box>
            </>
          )
        ) : (
          <></>
        )}
      </Paper>
    </Container>
  );
};

export default StripeCheckoutExecutionComponent;
