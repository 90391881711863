import { FC, useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import {
  authenticated,
  authenticateUserWithAuthCodeGrant,
  createNewPasswordChallenge,
  federatedAuthroizationEndpoint,
  getChangePasswordState,
  IdentityProvider,
  IdentityProviderFlow,
  login,
} from "../../lib/auth";
import { Navigate, useSearchParams } from "react-router-dom";
import { mapCognitoError } from "../../lib/cognito";
import GoogleIcon from "@mui/icons-material/Google";
import useAppNavigation from "../../hooks/useAppNavigation";

const UserChangePasswordPage: FC<unknown> = () => {
  const { navigateHome } = useAppNavigation();
  const [searchParams] = useSearchParams();
  const [updatingPassword, setUpdatingPassword] = useState<boolean>();
  const [error, setError] = useState("");
  const [googleAuthentication, setGoogleAuthentication] =
    useState<boolean>(false);

  useEffect(() => {
    const authCodeGrant = searchParams.get("code");
    const stateValue: { authValue?: string } = searchParams.get("state")
      ? JSON.parse(atob(searchParams.get("state")!))
      : {};

    // If user is redirected from /oath2/authorize endpoint extract search params from url
    if (authCodeGrant && stateValue.authValue) {
      // deleting the search params stop re-renders from trying to use the old auth code grant
      searchParams.delete("code");
      searchParams.delete("state");
      setGoogleAuthentication(true);
      authenticateUserWithAuthCodeGrant(
        authCodeGrant,
        stateValue.authValue,
        IdentityProviderFlow.AcceptInvitation,
      ).then(
        () => {
          navigateHome();
        },
        () => {
          setError("Error authentication with Google");
          setGoogleAuthentication(false);
        },
      );
    }
  }, [navigateHome, searchParams]);

  // Otherwise try to authenticate them with an existing login session
  useEffect(() => {
    setUpdatingPassword(true);
    login().then(
      () => {
        navigateHome();
      },
      () => {
        setUpdatingPassword(false);
        // Do nothing, this indicates that the user does not already have a session
        // which is not a problem
      },
    );
  }, [navigateHome]);

  const changePasswordState = getChangePasswordState();

  if (!process.env.REACT_APP_COGNITO_USER_POOL_ID) {
    console.error(
      "UserChangePasswordPage: cognito user pool id was not defined",
    );
    return <Navigate to={"/"} />;
  }

  if (!process.env.REACT_APP_COGNITO_CLIENT_ID) {
    console.error("UserChangePasswordPage: clientId was not defined");
    return <Navigate to={"/"} />;
  }

  if (!changePasswordState) {
    console.error(
      "VerificationPage: state was not defined from registration page",
    );
    return <Navigate to={"/sign-in"} />;
  }

  // If the user is already authenticated let them through to the dashboard
  if (authenticated()) {
    navigateHome();
    return <></>;
  }

  // Added checks for the firstName and lastName input fields, but no further functionality changes have been added yet.
  // This is not in scope for this PR, but will come back later to finish it.
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const firstNameValue = data.get("firstname");
    if (firstNameValue === null) {
      setError("First name is required.");
      return;
    }

    const lastNameValue = data.get("lastname");
    if (lastNameValue === null) {
      setError("Last name is required.");
      return;
    }

    const passwordValue = data.get("password");
    if (passwordValue === null) {
      setError("Password is required.");
      return;
    }

    setUpdatingPassword(true);

    createNewPasswordChallenge(
      changePasswordState.cognitoUser,
      firstNameValue.toString(),
      lastNameValue.toString(),
      passwordValue.toString(),
    )
      .then((result) => {
        if (result.success) {
          navigateHome();
        }
      })
      .catch((err) => {
        setUpdatingPassword(false);
        setError(mapCognitoError(err));
      });
  };

  const handleGoogleAuth = () => {
    // routes the user to the external providers login UI
    // in our case the user is routed to Google's sign-in page
    window.location.href = federatedAuthroizationEndpoint(
      IdentityProvider.Google,
      IdentityProviderFlow.AcceptInvitation,
    );
  };

  return (
    <>
      <Container
        component="main"
        maxWidth="sm" // Originally was "xs" but forced the header to be on two lines, may want to change later or discuss changing the header?
        sx={{ mt: { xs: 3, md: 18 } }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          maxWidth="xs"
          sx={{
            mt: 8,
          }}
        >
          <Container maxWidth="xs">
            <Paper elevation={9} sx={{ px: 4, py: 6 }}>
              {updatingPassword || googleAuthentication ? (
                <Box
                  sx={{
                    width: "100%",
                    height: "200px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <div>
                  {error && (
                    <Alert sx={{ width: "100%" }} severity="error">
                      {error}
                    </Alert>
                  )}
                  <Box component="header">
                    <Typography
                      component="h1"
                      variant="h4"
                      sx={{ whiteSpace: "nowrap" }}
                    >
                      Complete Registration
                    </Typography>
                  </Box>
                  <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmit}
                    sx={{ mt: 3 }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          required
                          fullWidth
                          id="firstname"
                          label="First Name"
                          name="firstname"
                          autoComplete="firstname"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          required
                          fullWidth
                          id="lastname"
                          label="Last Name"
                          name="lastname"
                          autoComplete="lastname"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          name="password"
                          label="New Password"
                          type="password"
                          id="password"
                          autoComplete="new-password"
                        />
                      </Grid>
                    </Grid>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Register Account
                    </Button>

                    <Divider>
                      <Typography component="p" variant="caption">
                        OR
                      </Typography>
                    </Divider>

                    <Button
                      onClick={handleGoogleAuth}
                      fullWidth
                      variant={"outlined"}
                      sx={{
                        mt: 3,
                        mb: 2,
                        py: 1.5,
                        color: "text.secondary",
                        borderColor: "text.secondary",
                        "&:hover": {
                          color: "primary.main",
                          borderColor: "primary.main",
                        },
                      }}
                      startIcon={<GoogleIcon />}
                    >
                      Create Account with Google
                    </Button>
                  </Box>
                </div>
              )}
            </Paper>
          </Container>
        </Grid>
      </Container>
    </>
  );
};

export default UserChangePasswordPage;
