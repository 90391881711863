import { Box } from "@mui/material";
import { FC } from "react";
import { RoleIcon } from "../../../../components/elements";
import { Role, User } from "../../../../model";
import { AssignedRoleUserDto, CommonRole } from "../../../../types";
import { getUserFullName } from "../../../activity-log";

type ProcessExecutionRolesComponentProps = {
  assignedRoles: Array<AssignedRoleUserDto>;
};

type ProcessExecutionRoleAndUsersReduction = {
  role: Role;
  users: Array<User>;
};

const ProcessExecutionRolesComponent: FC<
  ProcessExecutionRolesComponentProps
> = ({ assignedRoles }) => {
  const reduction: ProcessExecutionRoleAndUsersReduction[] = [];

  for (let i = 0; i < assignedRoles.length; i++) {
    const current = assignedRoles[i];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((Object as any).values(CommonRole).includes(current.role.id)) {
      continue;
    }

    const found = reduction.find((roleAndUsers) => {
      return roleAndUsers.role.id == current.role.id;
    });

    if (found) {
      if (!found.users.find((u) => u.id == current.user.id)) {
        found.users.push(current.user);
      }
    } else {
      reduction.push({
        role: current.role,
        users: [current.user],
      });
    }
  }

  return (
    <Box sx={{ p: 1 }}>
      {reduction.map((roleAndUsers) => {
        return (
          <Box
            key={roleAndUsers.role.id}
            sx={{
              py: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              lineHeight: 1,
            }}
          >
            <Box sx={{ flex: 0, mr: 1 }}>
              <RoleIcon role={roleAndUsers.role} />
            </Box>
            <Box sx={{ flex: 1 }}>{roleAndUsers.role.name}</Box>
            <Box sx={{ flex: 1 }}>
              {roleAndUsers.users.map((u) => getUserFullName(u)).join(", ")}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default ProcessExecutionRolesComponent;
