import { styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

export const TEST_ROW_CLASS_NAME = "test-row";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  borderRadius: "0px",
  borderLeft: "0px",
  borderRight: "0px",
  "& .MuiDataGrid-footerContainer": {
    justifyContent: "unset",
  },
  "& .MuiTablePagination-toolbar": {
    flexDirection: "row-reverse",
    paddingLeft: 8,
  },
  "& .MuiTablePagination-actions": {
    marginLeft: 0,
    marginRight: 16,
  },
  [`& .${TEST_ROW_CLASS_NAME}`]: {
    backgroundColor: theme.palette.testRow.default,
    "&:hover": {
      backgroundColor: theme.palette.testRow.hover,
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.testRow.selected,
      "&:hover": {
        backgroundColor: theme.palette.testRow.selectedHover,
      },
    },
  },
}));

export default StyledDataGrid;
