import { useNavigate } from "react-router-dom";
import { useGlobalOrganizationContext } from "../../hooks/useGlobalOrganizationContext";
import { useCallback, useEffect } from "react";
import PATHS from "./_paths";
import { environment } from "../../util";

const NavigateHome = () => {
  const {
    organization,
    organizations,
    organizationsLoaded,
    accountsLoaded,
    accountListIsMemberOf,
  } = useGlobalOrganizationContext();
  const navigate = useNavigate();
  const redirect = useCallback(() => {
    if (environment.organization?.key) {
      const isAdmin = organizations.find(
        (org) => org.key === environment.organization?.key,
      );
      if (isAdmin) {
        // Enterprise pages don't support /user/main so navigate to the organization execution page instead
        navigate(PATHS.ORGANIZATION_EXECUTIONS.link, { replace: true });
      } else {
        const account = accountListIsMemberOf.find(
          (account) => account.org_id === organization?.id,
        );
        if (account?.id) {
          navigate(PATHS.ACCOUNT_PORTAL.linkTo(account?.id), { replace: true });
        } else {
          // Account user has been invited, but has not accepted invitation yet. Show invitation management page.
          navigate(PATHS.USER_MANAGE_ORGANIZATIONS.link, { replace: true });
        }
      }
    } else {
      if (organizations.length > 0) {
        // If user is a member of an organization redirect to the organizations page
        navigate(PATHS.ORGANIZATION_EXECUTIONS.link, { replace: true });
      } else if (accountListIsMemberOf.length > 0) {
        navigate(PATHS.ACCOUNT_PORTAL.linkTo(accountListIsMemberOf[0].id), {
          replace: true,
        });
      } else {
        navigate(PATHS.USER_MANAGE_ORGANIZATIONS.link, { replace: true });
      }
    }
  }, [accountListIsMemberOf, navigate, organization?.id, organizations]);
  useEffect(() => {
    const redirectWhenOrganizationsLoad = () => {
      if (organizationsLoaded && accountsLoaded) {
        redirect();
      } else {
        setTimeout(redirectWhenOrganizationsLoad, 200);
      }
    };
    redirectWhenOrganizationsLoad();
  }, [accountsLoaded, organizationsLoaded, redirect]);
  return <></>;
};

export default NavigateHome;
