import messages from "../messages";
import { useIntl } from "react-intl";
import Dialog from "../../../components/Dialog";
import { DragEvent, useCallback, useState } from "react";
import processes from "../../../api/processes";
import { useGlobalOrganizationContext } from "../../../hooks/useGlobalOrganizationContext";
import { UploadFileComponent } from "../../../components/core/UploadFile";
import { FilePresent } from "@mui/icons-material";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useAlert } from "../../../lib/alert";
const ImportProcessDialog = ({
  open,
  handleConfirm,
  handleCancel,
}: {
  open: boolean;
  handleConfirm: () => Promise<void>;
  handleCancel: () => void;
}) => {
  const { success } = useAlert();
  const { organization, setOrganization } = useGlobalOrganizationContext();
  const { formatMessage } = useIntl();
  const [files, setFiles] = useState<File[]>([]);
  const [overwrite, setOverwrite] = useState(false);
  const addFiles = useCallback(
    (newFiles: FileList) => {
      const allFiles = [...files];
      for (let i = 0; i < newFiles.length; i++) {
        allFiles.push(newFiles[i]);
      }
      setFiles(allFiles);
    },
    [files],
  );
  const handleDrop = useCallback(
    (e: DragEvent) => {
      if (e.dataTransfer?.files && e.dataTransfer.files[0]) {
        addFiles(e.dataTransfer?.files);
      }
    },
    [addFiles],
  );
  const handleFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newFiles = e.target.files || undefined;
      if (!newFiles) {
        return;
      }
      addFiles(newFiles);
    },
    [addFiles],
  );

  return (
    <Dialog
      open={open}
      title={`Import ${formatMessage(messages.process.props)}`}
      fullWidth={true}
      confirmText="Import"
      cancelText="Cancel"
      handleConfirm={async () => {
        if (!organization) {
          return;
        }

        await processes.import(organization.id, files, overwrite);
        handleConfirm();
        success(
          files.length === 1
            ? `${formatMessage(
                messages.process.props,
              )} imported successfully in DRAFT status`
            : `${files.length} ${formatMessage(
                messages.processes.props,
              )} imported successfully in DRAFT status`,
        );
        setOrganization({ ...organization });
      }}
      maxWidth="sm"
      handleCancel={() => {
        handleCancel();
      }}
    >
      <UploadFileComponent
        renderer={(file) => {
          return (
            <>
              <FilePresent fontSize="large" />
              <Typography>{file.name}</Typography>
            </>
          );
        }}
        handleDrop={handleDrop}
        files={files}
        handleFileChange={handleFileChange}
        accept=".json"
        multiple={true}
        text="Drag and drop files here"
        uploadText="Upload"
        disabled={!organization}
        onRemove={(file, index) => {
          const newFiles = [...files];
          newFiles.splice(index, 1);
          setFiles(newFiles);
        }}
      />

      <FormControl sx={{ my: 1 }}>
        <FormControlLabel
          control={
            <Checkbox
              data-cy="matching-name-checkbox"
              onChange={(e) => {
                e.stopPropagation();
                setOverwrite(e.target.checked);
              }}
            />
          }
          label="Update process with matching name if one exists"
          value={overwrite}
        />
      </FormControl>
    </Dialog>
  );
};
export default ImportProcessDialog;
