import { ChatMessage, Thread } from "../features/messaging/model";
import API from "./base";

const getThreadMessages = (threadId: string): Promise<ChatMessage[]> => {
  return new Promise((resolve, reject) => {
    API.get(`/chats/${threadId}/messages`).then((result) => {
      resolve(result.data.payload as ChatMessage[]);
    }, reject);
  });
};

const sendMessage = (
  threadId: string,
  message: ChatMessage,
): Promise<ChatMessage> => {
  return new Promise((resolve, reject) => {
    API.post(`/chats/${threadId}/messages`, message).then((result) => {
      resolve(result.data.payload as ChatMessage);
    }, reject);
  });
};

const getChatsForSubject = (subjectId: string): Promise<Thread[]> => {
  return new Promise((resolve, reject) => {
    API.get(`/chats/byProcessExecutionId/${subjectId}`).then((result) => {
      resolve(result.data.payload as Thread[]);
    }, reject);
  });
};

export default {
  getThreadMessages,
  getChatsForSubject,
  sendMessage: sendMessage,
};
