import { TextFormDataType } from "../features/task";

// Code inspired from: https://stackoverflow.com/a/13622393
export const formatTextFormElementData = (
  value: string | undefined,
  dataType: TextFormDataType | undefined,
): string | undefined => {
  if (!value || typeof value !== "string") {
    return;
  }

  // TODO - restore with a search and replace in the future
  // This was disabled since it prevented the form builder from adding templated values to form elements
  // if future tasks require a direct previous task for templating then we don't want to display the templating string
  // this allows us to template within the process without displaying our own template strings to users
  /*if (value.startsWith("${") && value.endsWith("}")) {
    return "";
  }*/

  switch (dataType) {
    case TextFormDataType.Date: {
      const date = new Date(value);
      // If the original TextFormElement's value is not a valid date, then return whatever the user had previously entered
      if (!(date instanceof Date) || isNaN(date.valueOf())) {
        return value;
      }
      const timeZoneOffset = new Date().getTimezoneOffset();
      date.setMinutes(date.getMinutes() + timeZoneOffset);
      return date.toLocaleDateString();
    }
    default: {
      // TODO: When adding new TextForm data types make sure to catch them here and do any formatting needed
      return value;
    }
  }
};
