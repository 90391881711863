import { AppBar, Toolbar, Box, Typography } from "@mui/material";
import { FC } from "react";
import { Organization } from "../../../model";
import { environment } from "../../../util";

type NavbarProps = {
  title?: string;
  organization: Organization;
};

const PublicOrganizationNavbar: FC<NavbarProps> = ({ title, organization }) => {
  return (
    <AppBar sx={{ bgcolor: "white" }} position="fixed">
      <Toolbar>
        <img
          src={organization.theme?.longLogoUrl ?? environment.app.logo}
          alt="light"
          height="50px"
        />
        {title && (
          <Box sx={{ flex: 1, textAlign: "center" }}>
            <Typography
              variant="h6"
              sx={{ color: "black", fontSize: "typography.h1" }}
            >
              {title}
            </Typography>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};
export default PublicOrganizationNavbar;
