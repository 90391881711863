import {
  Box,
  Button,
  CircularProgress,
  Dialog as MuiDialog,
  DialogProps,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { PropsWithChildren, useState } from "react";

type ConfirmDialogProps = {
  open: boolean;
  maxWidth?: DialogProps["maxWidth"];
  fullWidth?: DialogProps["fullWidth"];
  title?: string | React.ReactElement;
  disableConfirm?: boolean;
  confirmText: string;
  cancelText?: string;
  handleConfirm: () => Promise<void>;
  handleCancel?: () => void;
};

const Dialog = ({
  open,
  maxWidth,
  title,
  confirmText,
  disableConfirm = false,
  cancelText,
  fullWidth,
  handleConfirm,
  handleCancel,
  children,
}: PropsWithChildren<ConfirmDialogProps>) => {
  const [processing, setProcessing] = useState<boolean>(false);

  const confirm = () => {
    setProcessing(true);
    handleConfirm().then(
      () => {
        setProcessing(false);
      },
      () => {
        setProcessing(false);
      },
    );
  };

  return (
    <MuiDialog
      data-cy={"dialog"}
      open={open}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      onClose={handleCancel}
      sx={{ pb: 2 }}
    >
      <DialogTitle>{title || "Confirm"}</DialogTitle>
      <DialogContent sx={{ pb: 1 }}>
        {processing ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress data-cy={"circular-progress"} />
          </Box>
        ) : (
          children
        )}
      </DialogContent>
      <DialogActions sx={{ pb: 3, pr: 3 }}>
        {cancelText && (
          <Button
            data-cy={"dialog-cancel-btn"}
            variant={"outlined"}
            onClick={handleCancel}
            disabled={processing}
          >
            {cancelText || "Cancel"}
          </Button>
        )}
        <Button
          data-cy={"dialog-confirm-btn"}
          variant={"contained"}
          onClick={confirm}
          disabled={processing || disableConfirm}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </MuiDialog>
  );
};

export default Dialog;
