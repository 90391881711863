import React, { useState, useMemo, useRef } from "react";
import { Card, Chip, Grid, Tooltip } from "@mui/material";
import { Task } from "../../types";
import { useTaskContext } from "../../hooks";
import { AddTaskMenuButton, TaskContainer } from "..";
import { TaskBody } from "../task-types";
import { ValidationError } from "class-validator";

import { Process } from "../../../../model/Process";
import { ButtonType } from "./AddTaskMenuButton";

const TaskInlineEditorContainer = ({
  task,
  validationFailures,
  stepOrProcessId,
  process,
}: {
  task: Task;
  validationFailures?: ValidationError[];
  stepOrProcessId: string;
  process: Process;
}) => {
  const taskContext = useTaskContext();
  const tasks = taskContext.getTasks(stepOrProcessId);
  const [taskMenuOpen, setTaskMenuOpen] = useState(false);
  const [raised, setRaised] = useState(false);
  const [addTaskButtonAbove, setAddTaskButtonAbove] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  // Get a list of variables as they appear in the task name
  const matchOrderedVars = useMemo(() => {
    // eslint-disable-next-line no-useless-escape
    const findVariables = task.name.matchAll(/\$\{[^\}]*\}/g);
    const orderedVars = [];
    let match = findVariables.next();
    while (!match.done) {
      const varWithWrapper = match.value[0];
      const varWithoutWrapper = varWithWrapper.substring(
        2, // Remove: ${
        varWithWrapper.length - 1, // Remove: }
      );
      // If it is a used variable add it to our array
      if (task.used_variables?.includes(varWithoutWrapper)) {
        orderedVars.push(match.value);
      }
      match = findVariables.next();
    }
    return orderedVars;
  }, [task.name, task.used_variables]);

  const newLabel = useMemo(() => {
    const newLabel = [];
    let lastIndex = 0;

    matchOrderedVars.forEach((match) => {
      const matchIndex = match.index ?? 0;
      const matchValue = match[0];

      // Get the section before the variable
      newLabel.push(
        <React.Fragment key={lastIndex}>
          {task.name.substring(lastIndex, matchIndex)}
        </React.Fragment>,
      );
      lastIndex += matchIndex;

      const seperator = ".data.";
      const seperatorIndex = matchValue.indexOf(seperator);
      // If there is no seperator continue
      if (seperatorIndex < 0) {
        return;
      }

      // Try to find a task with the given identifier
      const refTask = taskContext.getTasks(task.process_id).find((t) => {
        return (
          t.task_identifier_name == matchValue.substring(2, seperatorIndex)
        );
      });

      // If there is no task ref continue
      if (!refTask) {
        return;
      }

      // Add the variable as a chip
      newLabel.push(
        <Tooltip
          title={refTask?.name ?? "Unknown Task Reference"}
          key={lastIndex}
        >
          <Chip
            size="small"
            label={matchValue.substring(
              seperatorIndex + seperator.length,
              matchValue.length - 1,
            )}
          />
        </Tooltip>,
      );
      lastIndex += matchValue.length;
    });

    // If the were no matches use the task name otherwise append the remaining text
    if (matchOrderedVars.length == 0) {
      newLabel.push(
        <React.Fragment key={lastIndex}>{task.name}</React.Fragment>,
      );
    } else {
      newLabel.push(
        <React.Fragment key={lastIndex}>
          {task.name.substring(lastIndex)}
        </React.Fragment>,
      );
    }
    return newLabel;
  }, [matchOrderedVars, task.name, task.process_id, taskContext]);

  return (
    <Grid
      container
      flexDirection={"column"}
      position={"relative"}
      zIndex={0}
      onMouseEnter={() => {
        setRaised(true);
      }}
      onMouseLeave={() => {
        setRaised(false);
        setTaskMenuOpen(false);
      }}
      onMouseMove={(e) => {
        if (!containerRef.current || taskMenuOpen) {
          return;
        }
        const bounds = containerRef.current.getBoundingClientRect();
        const y = e.clientY - bounds.top;
        const height = containerRef.current.clientHeight;
        setAddTaskButtonAbove(y < height / 2);
      }}
      ref={containerRef}
    >
      <Grid item>
        <Card
          sx={(theme) => {
            return {
              cursor: "pointer",
              mb: 2,
              zIndex: raised ? 1 : 10,
              outline:
                taskContext.selectedTask?.id == task.id
                  ? "1px solid " + theme.palette.action.active
                  : "",
            };
          }}
          elevation={raised || taskContext.selectedTask?.id == task.id ? 3 : 0}
          onClick={() => {
            taskContext.setSelectedTask(task);
          }}
        >
          <TaskContainer
            label={<>{newLabel}</>}
            description={task.description}
            type={task.type}
            role={task.role}
            isComplete={false}
            showChildren={true}
            onClick={() => {
              // Do nothing
            }}
            validationFailures={validationFailures?.filter(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (failure) => (failure?.target as any)?.id == task.id,
            )}
          >
            <TaskBody variant="inline" task={task} />
          </TaskContainer>
        </Card>
      </Grid>

      {raised && (
        <Grid
          item
          alignSelf={"center"}
          position={"absolute"}
          top={addTaskButtonAbove ? -10 : "unset"}
          bottom={addTaskButtonAbove ? "unset" : 5}
          zIndex={40}
        >
          <AddTaskMenuButton
            open={taskMenuOpen}
            setOpen={(value) => {
              setTaskMenuOpen(value);
              if (value === false) setRaised(false);
            }}
            variant={ButtonType.CARD}
            previousTask={
              addTaskButtonAbove
                ? tasks.find((item) => item.id === task.previous_task_id)
                : task
            }
            process={process}
            task={task}
            stepOrProcessId={stepOrProcessId}
            insertAbove={addTaskButtonAbove}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default TaskInlineEditorContainer;
