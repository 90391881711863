import { Box, InputAdornment, Paper, TextField } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useState } from "react";
import { RoleIcon } from "../../components/elements";
import { Role } from "../../model";

type RoleOption = {
  id: string;
  name?: string;
};

const OPTIONS_LIMIT = 10;
const filterOptions = createFilterOptions<RoleOption>({
  limit: OPTIONS_LIMIT,
});

type RoleSelectionType = {
  roles: Role[];
  value?: Role;
  label?: string;
  paperComponent?: React.ReactNode;
  onChange: (role: Role) => void;
  onInputChange?: (event: React.SyntheticEvent, newInputValue: string) => void;
};

export const RoleSelect = ({
  roles,
  value,
  label = "Select a Role",
  paperComponent,
  onChange,
  onInputChange,
}: RoleSelectionType) => {
  const [inputValue, setInputValue] = useState("");

  const handleOnChange = (
    event: React.SyntheticEvent,
    newValue: RoleOption | null,
  ) => {
    const selectedRole = roles.find((role) => role.id === newValue?.id);
    if (!selectedRole) {
      return;
    }
    onChange(selectedRole);
  };

  const CustomPaper = (props: object) => {
    return (
      <Paper sx={{ backgroundColor: "common.white" }}>
        <Box {...props} />
        {paperComponent}
      </Paper>
    );
  };

  return (
    <>
      <Autocomplete
        sx={{ flex: 2 }}
        filterOptions={filterOptions}
        value={value ?? null}
        onChange={handleOnChange}
        inputValue={inputValue ?? null}
        onInputChange={(event, newInputValue) => {
          onInputChange && onInputChange(event, newInputValue);
          setInputValue(newInputValue);
        }}
        id="role-selection"
        options={roles}
        PaperComponent={CustomPaper}
        noOptionsText={`No ${inputValue} role found`}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  {value?.id && (
                    <RoleIcon role={value} toolTipPosition="left" />
                  )}
                </InputAdornment>
              ),
            }}
            inputProps={{
              ...params.inputProps,
              style: {
                paddingLeft: 0,
              },
            }}
            label={label}
          />
        )}
        getOptionLabel={(option) => option.name ?? option.id}
        renderOption={(props, option) => (
          <li {...props}>
            <RoleIcon
              sx={{ mr: 1 }}
              role={roles.find((role) => role.id === option.id)}
              toolTipPosition="left"
            />
            {option.name ?? option.id}
          </li>
        )}
        isOptionEqualToValue={(option, value) => option.id == value.id}
      />
    </>
  );
};

export default RoleSelect;
