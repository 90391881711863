import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { CodeMirror } from "../../../../components/core/CodeMirror/CodeMirror";
import { Task, TaskSideSectionEditorProps } from "../../types";
import { useTaskContext } from "../../hooks";
import { useEffect, useState } from "react";
import { json } from "@codemirror/lang-json";
import { Info } from "@mui/icons-material";

const DefaultSideSectionEditor = ({
  task,
}: TaskSideSectionEditorProps<Task>) => {
  const taskContext = useTaskContext();
  const [executionData, setExecutionData] = useState<string>(
    JSON.stringify(task.execution_data, null, "  "),
  );
  const [taskId, setTaskId] = useState<string | undefined>(task?.id);
  const [error, setError] = useState("");

  useEffect(() => {
    setTaskId(task?.id);
  }, [task.id]);
  useEffect(() => {
    if (!executionData || task.id !== taskId) {
      setExecutionData(JSON.stringify(task.execution_data, null, "  "));
    }
  }, [task.execution_data, executionData, task.id, taskId]);
  return (
    <Grid item xs={12}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pb={1}
      >
        <Typography variant="body1">Execution Data</Typography>
        <Tooltip
          title={
            "This is the raw execution data JSON for the task, edit with caution."
          }
          placement={"bottom"}
          arrow
        >
          <Info />
        </Tooltip>
      </Box>
      <CodeMirror
        value={executionData}
        height="200px"
        extensions={[json()]}
        onChange={(value: string) => {
          try {
            setError("");
            const parsed = JSON.parse(value);
            taskContext.setValue({
              execution_data: parsed,
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            setError(error.toString());
          } finally {
            setExecutionData(value);
          }
        }}
      />
      <Typography variant="body1">{error}</Typography>
    </Grid>
  );
};

export default DefaultSideSectionEditor;
