import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useState } from "react";
import { Step } from "../../../../model/step";

export type DeleteStepDialogProps = {
  step: Step;
  open: boolean;
  onClose: () => void;
  onConfirm: (step: Step) => Promise<void>;
};

const DeleteStepDialog = ({
  open,
  onClose,
  onConfirm,
  step,
}: DeleteStepDialogProps) => {
  const [updatedStep, setUpdatedStep] = useState<Step>({ ...step });
  const [processing, setProcessing] = useState(false);
  useEffect(() => {
    setUpdatedStep(step);
  }, [step]);
  return (
    <Dialog open={open} onClose={onClose} maxWidth={"xs"}>
      <DialogTitle>
        <Typography variant="h4" fontWeight={700}>
          {"Delete Step"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {processing && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {!processing &&
          `Are you sure you want to delete ${
            step.name ? `step - ${step.name}?` : "this step?"
          }`}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} disabled={processing}>
          {"Cancel"}
        </Button>
        <Button
          variant="contained"
          onClick={async () => {
            setProcessing(true);
            await onConfirm(updatedStep);
            setProcessing(false);
            onClose();
          }}
          disabled={processing}
        >
          {"Delete"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DeleteStepDialog;
