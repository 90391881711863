import { useMemo } from "react";
import { Task, TaskExecution, TaskExecutionState } from "../types";
import { useGlobalRoleContext } from "../../../hooks/useGlobalRoleContext";
import { atom, useAtom } from "jotai";
import { accountTypesAtom } from "../../organization/state/accountTypesAtom";
import { useGlobalOrganizationContext } from "../../../hooks/useGlobalOrganizationContext";
import { useProcessExecutionContext } from "../../process/hooks";

export const taskEditingAtom = atom<boolean | undefined>(undefined);

export const useTaskExecutionEditingState = (
  taskExecution?: TaskExecution,
  task?: Task,
) => {
  const { roles, accountRoles } = useGlobalRoleContext();
  const [accountTypes] = useAtom(accountTypesAtom);
  const { organizations } = useGlobalOrganizationContext();
  const { process } = useProcessExecutionContext();
  const editable = useMemo(() => {
    if (!task) {
      return false;
    }

    const isAdmin = organizations.some((org) => org.id === process?.org_id);

    // If the role is public, anyone can edit
    if (task.execution_role_id == "public") {
      return true;
    }

    // If the user has admin rights for this process, they can edit
    if (isAdmin) {
      return true;
    }

    // In this case, nobody has the correct roles,
    // so we just don't allow editing.
    if (!task.execution_role_id) {
      return false;
    }

    // If the task is completed, exclude account roles from editing
    if (taskExecution?.state == TaskExecutionState.Completed || !accountRoles) {
      return roles.map((r) => r.id).includes(task.execution_role_id);
    }

    // Otherwise, as long as the user has the correct roles, they can edit
    const hasRole = [...roles, ...accountRoles]
      .map((r) => r.id)
      .includes(task.execution_role_id);
    if (hasRole) {
      return true;
    }
    // If the current role is an account role, the user can edit/complete.
    // TODO - process execution should have organization id and that should be checked against user's orgs
    return accountTypes?.some(
      (accountType) => accountType.role_id === task.execution_role_id,
    );
  }, [
    accountRoles,
    accountTypes,
    organizations,
    process?.org_id,
    roles,
    task,
    taskExecution?.state,
  ]);
  return { editable };
};
