import { DashboardElement } from "../../../model/Dashboard";
import { BarChartCard, BarChartCardSkeleton } from "./card-types/BarChartCard";
import ClientLocation from "./card-types/client-location/ClientLocation";
import { Header } from "./Header";
import { PieChartCard, PieChartCardSkeleton } from "./card-types/PieChartCard";
import { NumberCard, NumberCardSkeleton } from "./card-types/NumberCard";
import {
  ProcessCountCard,
  ProcessCountCardSkeleton,
} from "./card-types/ProcessCountCard";
import { Box } from "@mui/material";

export const DashboardElementRenderer = (element: DashboardElement) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const error = ((element.data ?? {}) as any).error;
  if (error && element.type != "header") {
    console.error(error);
    switch (element.type) {
      case "number":
        return <NumberCardSkeleton />;
      case "barChart":
        return <BarChartCardSkeleton />;
      case "pieChart":
        return <PieChartCardSkeleton />;
      case "processCount":
        return <ProcessCountCardSkeleton />;
      default:
        return <Box />;
    }
  }
  switch (element.type) {
    case "header":
      return <Header element={element} />;
    case "number":
      return <NumberCard element={element} />;
    case "barChart":
      return <BarChartCard element={element} />;
    case "pieChart":
      return <PieChartCard element={element} />;
    case "location":
      return <ClientLocation element={element} />;
    case "processCount":
      return <ProcessCountCard element={element} />;
    case "spacer":
      return <Box />;
  }
};
