import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";

export type DeleteDialogProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => Promise<unknown>;
  title?: string;
  question?: string;
};

const DeleteDialog = ({
  open,
  onClose,
  onConfirm,
  title = "Delete",
  question = "Are you sure you want to permanently delete this?",
}: DeleteDialogProps) => {
  const [processing, setProcessing] = useState(false);
  return (
    <Dialog
      data-cy={"delete-dialog"}
      open={open}
      onClose={onClose}
      maxWidth={"xs"}
    >
      <DialogTitle>
        <Typography variant="h4" fontWeight={700}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {processing && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress data-cy={"circular-progress"} />
          </Box>
        )}
        {!processing && question}
      </DialogContent>
      <DialogActions>
        <Button
          data-cy={"delete-dialog-cancel-btn"}
          variant="outlined"
          onClick={onClose}
          disabled={processing}
        >
          {"Cancel"}
        </Button>
        <Button
          data-cy={"delete-dialog-delete-btn"}
          variant="contained"
          onClick={async () => {
            setProcessing(true);
            await onConfirm();
            setProcessing(false);
            onClose();
          }}
          disabled={processing}
        >
          {"Delete"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DeleteDialog;
