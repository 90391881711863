import { FC, PropsWithChildren } from "react";
import {
  Box,
  darken,
  SxProps,
  getContrastRatio,
  lighten,
  styled,
  Typography,
  Theme,
  TooltipProps,
} from "@mui/material";
import { Circle } from "@mui/icons-material";
import { Role, User } from "../../model";
import getUserFullName from "../../features/activity-log/utils/getUserFullName";
import UserIcon from "./UserIcon";

type RoleIconType = {
  assignedUser?: User;
  sx?: SxProps;
  role?: Role;
  toolTipPosition?: TooltipProps["placement"];
  fillColor?: string;
  borderColor?: string;
};

export const CircleIcon = styled(Circle, {
  shouldForwardProp: (prop) => prop !== "role",
})(({ theme }) => ({
  borderRadius: "50%",
  position: "relative",
  zIndex: 1,
  backgroundColor: theme.palette.primary.dark,
  fill: theme.palette.primary.light,
}));

const RoleIcon: FC<PropsWithChildren<RoleIconType>> = ({
  sx,
  assignedUser,
  role,
  toolTipPosition = "bottom",
}) => {
  const getBackgroundColor = (theme: Theme) =>
    role?.display?.fillColor ?? theme.palette.primary.dark;

  const defaultRoleIcon = (
    <Typography
      sx={(theme) => {
        const defaultStyles: SxProps = {
          border: "solid",
          borderWidth: 2,
          borderRadius: "100%",
          height: theme.spacing(3),
          width: theme.spacing(3),
          lineHeight: `calc(${theme.spacing(3)} - 4px)`, //subtract the 4px border height to vertically center letter
          textAlign: "center",
          fontWeight: 600,
          fontSize: "0.9rem",
          backgroundColor: getBackgroundColor(theme),
          borderColor:
            getContrastRatio(getBackgroundColor(theme), "#fff") > 3
              ? lighten(getBackgroundColor(theme), 0.5)
              : darken(getBackgroundColor(theme), 0.5),
          color:
            getContrastRatio(getBackgroundColor(theme), "#fff") > 3
              ? "#fff"
              : "#111",
        };

        return { ...defaultStyles, ...sx };
      }}
    >
      {role ? role.name.at(0)?.toUpperCase() : ""}
    </Typography>
  );

  const tooltipText = assignedUser ? (
    <Box sx={{ textAlign: "center" }}>
      <Typography sx={{ fontSize: (theme) => theme.spacing(1.25) }}>
        {`${role?.name}`}
      </Typography>
      <Typography sx={{ fontSize: (theme) => theme.spacing(1.25) }}>
        {getUserFullName(assignedUser)}
      </Typography>
    </Box>
  ) : (
    <Typography sx={{ fontSize: (theme) => theme.spacing(1.25) }}>
      {`${role?.name}`}
    </Typography>
  );

  return (
    <UserIcon
      toolTipPosition={toolTipPosition}
      title={tooltipText}
      user={assignedUser}
      sx={sx}
      defaultIcon={defaultRoleIcon}
    />
  );
};

export default RoleIcon;
