import { Grid, TextField } from "@mui/material";
import { Submission } from "../../types";

type TaskSubmissionFieldsProps = {
  submission: Submission;
  onUpdate: (submission: Submission) => void;
};

export const TaskSubmissionFields = ({
  submission,
  onUpdate,
}: TaskSubmissionFieldsProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          onChange={(event) => {
            onUpdate({
              ...submission,
              title: event.target.value,
            });
          }}
          name="submissionTitle"
          value={submission.title}
          label={"Submission Title"}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          onChange={(event) => {
            onUpdate({
              ...submission,
              message: event.target.value,
            });
          }}
          name="submissionMessage"
          value={submission.message}
          label={"Submission Message"}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    </Grid>
  );
};
