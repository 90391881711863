import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { SubmitButton } from "../../../../components/elements";
import { Task } from "../../types";
import { useCallback, useState } from "react";
import ProcessSelect from "./ProcessSelect";
import { useGlobalOrganizationContext } from "../../../../hooks/useGlobalOrganizationContext";
import { Process } from "../../../../model/Process";
import { TaskAPI } from "../../../../api";
import { useAlert } from "../../../../lib/alert";

type MoveTaskDialogProps = {
  open: boolean;
  onClose: () => void;
  onMove: () => void;
  task: Task;
};

const MoveTaskDialog = ({
  open,
  onClose,
  onMove,
  task,
}: MoveTaskDialogProps) => {
  const { organization } = useGlobalOrganizationContext();
  const { error, info } = useAlert();
  const [targetProcess, setTargetProcess] = useState<Process | undefined>();
  const moveTask = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return TaskAPI.move(task, targetProcess!.id!)
      .then(
        () => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          info(`Moved task to process ${targetProcess?.name}`);
          onMove && onMove();
        },
        () => {
          error(`Could not move task to process ${targetProcess?.name}`);
        },
      )
      .finally(() => {
        onClose();
      });
  }, [error, info, onClose, onMove, targetProcess, task]);
  return (
    <Dialog open={open} fullWidth={true} maxWidth="sm">
      <DialogTitle>Move Task</DialogTitle>
      <DialogContent sx={{ p: 3 }}>
        <Typography sx={{ py: 2 }}>
          Select a different process to move the task &quot;{task.name}&quot;
          to.
        </Typography>
        <ProcessSelect
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          organizationId={organization!.id}
          onChange={(process) => setTargetProcess(process)}
          value={targetProcess}
        ></ProcessSelect>
      </DialogContent>
      <DialogActions sx={{ pb: 3, pr: 3 }}>
        <Button variant={"outlined"} onClick={onClose}>
          Cancel
        </Button>
        <SubmitButton onSubmit={moveTask} disabled={!targetProcess}>
          Move
        </SubmitButton>
      </DialogActions>
    </Dialog>
  );
};
export default MoveTaskDialog;
