import { DateFormElement, TaskExecutionState } from "../../../../types";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import { FormLabel, Grid, Typography } from "@mui/material";
import { DatePicker } from "../../../../../../components";

export type TaskDateFormProps = {
  formId?: string;
  disabled: boolean;
  onValueChange: (values: string[] | undefined) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  execution: any;
} & DateFormElement;

const TaskDateFormComponent = ({
  onValueChange,
  disabled,
  execution,
  value,
  label,
  required,
  description,
  multiday = false,
  disablePast = false,
  disableWeekend = false,
  taskExecutionState,
}: TaskDateFormProps) => {
  const [dateValues, setDateValues] = useState<string[] | undefined>(
    execution?.value ?? value,
  );

  useEffect(() => {
    setDateValues(execution?.value ?? value);
  }, [execution?.value, value]);

  // Readonly view
  if (disabled && taskExecutionState === TaskExecutionState.Completed) {
    return (
      <Grid item container gap={1} alignItems={"center"}>
        <Typography variant="subtitle1">{label}:</Typography>
        {dateValues?.map((date, index) => {
          const isLastItem = index === dateValues.length - 1;
          return (
            <Typography key={date}>
              {new Date(date).toLocaleDateString("en-us", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
              })}
              {isLastItem ? "" : ","}
            </Typography>
          );
        })}
      </Grid>
    );
  }
  return (
    <Grid item>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {label && <FormLabel required={required}>{label}</FormLabel>}
        {description && (
          <Typography sx={{ pt: 1, pb: 2, fontSize: "14px" }}>
            {description}
          </Typography>
        )}
        <DatePicker
          multiday={multiday}
          disablePast={disablePast}
          disableWeekend={disableWeekend}
          onChange={(values) => {
            setDateValues(values);
            onValueChange(values);
          }}
          values={dateValues}
          disabled={disabled}
        />
      </LocalizationProvider>
    </Grid>
  );
};

export default TaskDateFormComponent;
