import { ChatMessage, EmailChatMessage, HashedEmailLine } from "./model";

const trimLine = (line: string) => {
  let i = 0;
  for (; i < line.length; i++) {
    const chr = line.charAt(i);
    if (chr !== ">" && chr !== " ") {
      break;
    }
  }
  if (i === 0) {
    return line.trim();
  }
  return line.substring(i).trim();
};
const hashLine = (line: string) => {
  let hash = 0;
  for (let i = 0; i < line.length; i++) {
    hash += line.charCodeAt(i);
  }
  return hash;
};

const findDuplicateOrQuotedLines = (
  lines: string[],
  previousLines: Map<number, Map<number, Array<HashedEmailLine>>>,
) => {
  const hashedLines = lines.map((line) => {
    // 1. trim each line to remove quoted ">"
    const trimmedLine = trimLine(line);

    // 2. hash each line
    const hash = hashLine(trimmedLine);
    const quoted = line.length > 0 && line.charAt(0) === ">";
    const hashedLinesByLength = previousLines.get(trimmedLine.length);
    // 3. lookup hashtable by length of line to see if line exists
    if (hashedLinesByLength) {
      const matchingLines = hashedLinesByLength.get(hash);
      // 4. if match, compare full text of line
      if (
        matchingLines &&
        matchingLines.some((line) => line.trimmedLine === trimmedLine)
      ) {
        return { hash, trimmedLine, line, seen: true, quoted };
      }
    }
    return { hash, line, trimmedLine, seen: false, quoted };
  });
  // 5. return hashed lines for future comparisons
  return hashedLines;
};

export default (messages: ChatMessage[]): EmailChatMessage[] => {
  const scrubbedMessages = [];
  const previousLines = new Map<
    /* length */ number,
    Map</* hash */ number, Array<HashedEmailLine>>
  >();
  for (let i = 0; i < messages.length; i++) {
    const msg = messages[i];
    if (msg.data?.text) {
      const hashedLines = findDuplicateOrQuotedLines(
        msg.data.text.split("\n"),
        previousLines,
      );
      scrubbedMessages.push({ ...msg, linesOfText: hashedLines });
      hashedLines.forEach((hashedLine) => {
        if (hashedLine.trimmedLine.length === 0) {
          return;
        }
        let hashMap = previousLines.get(hashedLine.trimmedLine.length);
        if (!hashMap) {
          hashMap = new Map<number, Array<HashedEmailLine>>();
          previousLines.set(hashedLine.trimmedLine.length, hashMap);
        }
        let previouslyHashedLinesOfLength = hashMap.get(hashedLine.hash);
        if (!previouslyHashedLinesOfLength) {
          previouslyHashedLinesOfLength = [];
          hashMap.set(hashedLine.hash, previouslyHashedLinesOfLength);
        }
        previouslyHashedLinesOfLength.push(hashedLine);
      });
    } else {
      scrubbedMessages.push(msg);
    }
  }
  return scrubbedMessages;
};
