/**
 * This global context is used to store / retrieve current user data
 */

import { createContext, Dispatch, SetStateAction, useContext } from "react";
import { User } from "../model";

export type GlobalUserContentType = {
  user: User;
  setUser: Dispatch<SetStateAction<User>>;
};

export const GlobalUserContext = createContext<GlobalUserContentType>({
  user: {
    id: "",
    email: "",
    first_name: "",
    last_name: "",
    profile_picture: "",
  },
  setUser: () => {
    return;
  },
});

export const useGlobalUserContext = () => useContext(GlobalUserContext);
