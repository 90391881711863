import Chat from "../components/chat/Chat";
import Message from "../components/chat/Message";
import API from "./base";

const createChat = (organizationId: string): Promise<Chat> => {
  return new Promise((resolve, reject) => {
    API.post("/copilot-chats/", undefined, {
      params: {
        organizationId,
      },
    }).then((result) => {
      resolve(result.data.payload as Chat);
    }, reject);
  });
};

const sendMessage = (
  organizationId: string,
  chatId: string,
  message: Message,
): Promise<Message> => {
  return new Promise((resolve, reject) => {
    API.post("/copilot-chats/" + chatId + "/messages", message, {
      params: {
        organizationId,
      },
    }).then((result) => {
      resolve(result.data.payload as Message);
    }, reject);
  });
};

const getChat = (chatId: string): Promise<Chat> => {
  return new Promise((resolve, reject) => {
    API.get("/copilot-chats/" + chatId).then((result) => {
      resolve(result.data.payload as Chat);
    }, reject);
  });
};

export default {
  createChat: createChat,
  getChat: getChat,
  sendMessage: sendMessage,
};
