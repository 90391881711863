import { Process } from "../../../../model/Process";
import { ProcessExecution } from "../../../../model/ProcessExecution";
import { Step } from "../../../../model/step/Step";

import Processes from "../../../../api/processes";
import ProcessExecutions from "../../../../api/process_executions";
import { KanbanColumnType } from "../KanbanBoardControls";
import Role from "../../../../model/Role";

// "FlatCategory" is a task with all of the executions currently in it
export interface FlatCategory {
  token: ColumnToken;
  executions: ProcessExecution[];
}

export interface ColumnToken {
  id: string;
  name: string;
  role?: Role;
}

// Generates all flat categories for a given process (or step given as it's a partial process)
export const getFlatCategories = async (
  process: Process,
  type: KanbanColumnType,
  complete?: boolean,
) => {
  const categories: FlatCategory[] = [];

  if (process.id && process.org_id) {
    const pagedResponse = await ProcessExecutions.byOrganizationId(
      process.org_id,
    );
    const executions = pagedResponse.data;
    if (!complete) {
      switch (type) {
        case KanbanColumnType.TASK:
          // column type == task

          return (await Processes.getTasks(process.id)).map((task) => {
            const matchingExecutions: ProcessExecution[] = executions.filter(
              (execution) => execution.next_task_id == task.id,
            );

            // returns an individual FlatCategory based on task
            return {
              token: {
                id: task.id ?? "",
                name: task.name,
                role: task.role,
              },
              executions: matchingExecutions,
            };
          });

        case KanbanColumnType.STEP:
          categories.push({
            token: {
              id: process.id ?? "",
              name: process.name ?? "",
            },
            executions: executions.filter(
              (execution) => execution.next_step_id == process.id,
            ),
          });
      }
    } else {
      const matchingExecutions: ProcessExecution[] = executions.filter(
        (execution) =>
          execution.total_tasks &&
          parseInt(execution.total_tasks) > 0 &&
          execution.process_id == process.id &&
          execution.total_tasks == execution.completed_tasks,
      );
      categories.push({
        token: {
          id: "complete",
          name: "Completed",
        },
        executions: matchingExecutions,
      });
    }
  }
  return categories;
};

// "Sections" represent groupings within the board which *may* be represented by a step
// Flat Processes have 1 section to represent it with no step
// Stepped Processes can have multiple sections which are each represented by a step of the process
// Sections that are stepped processes but the column type is set to step are not represened by a step, but have categories of step -> executions
export interface Section {
  step: Step;
  columns: Map<KanbanColumnType, FlatCategory[]>;
}

// Generates all sections for a given process
export const getSections = async (process: Process) => {
  const sections: Section[] = [];

  if (process.id) {
    const steps: Step[] = await Processes.getSteps(process.id);
    // generates step columns
    if (steps.length == 0) {
      const columnMap = new Map<KanbanColumnType, FlatCategory[]>();
      columnMap.set(
        KanbanColumnType.STEP,
        await getFlatCategories(process, KanbanColumnType.STEP),
      );
      columnMap.set(
        KanbanColumnType.TASK,
        await getFlatCategories(process, KanbanColumnType.TASK),
      );

      sections.push({ step: process as Step, columns: columnMap });
    } else {
      for (const step of steps) {
        const columnMap = new Map<KanbanColumnType, FlatCategory[]>();
        columnMap.set(
          KanbanColumnType.STEP,
          await getFlatCategories(step as Process, KanbanColumnType.STEP),
        );
        columnMap.set(
          KanbanColumnType.TASK,
          await getFlatCategories(step as Process, KanbanColumnType.TASK),
        );
        sections.push({ step: step, columns: columnMap });
      }
    }
  }

  sections.push(await getCompleteColumn(process));

  return sections;
};

export const getCompleteColumn = async (process: Process) => {
  const columnMap = new Map<KanbanColumnType, FlatCategory[]>();
  columnMap.set(
    KanbanColumnType.STEP,
    await getFlatCategories(process, KanbanColumnType.STEP, true),
  );

  return {
    step: { id: "complete", name: "Completed" } as Step,
    columns: columnMap,
  };
};
