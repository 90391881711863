import { ProcessExecution } from "../../../model/ProcessExecution";
import { Task } from "./Task";
import { TaskExecution } from "./TaskExecution";

export type TaskExecutionRenderProps = {
  title?: React.ReactNode;
  actions?: React.ReactNode;
  content?: React.ReactNode | Array<React.ReactNode>;
};

export const TaskViews = {
  EXPANDED: "expanded",
  CONDENSED: "condensed",
};
export type TaskView = typeof TaskViews.EXPANDED | typeof TaskViews.CONDENSED;

export type TaskExecutionComponentProps<
  TASK extends Task,
  TASK_EXECUTION extends TaskExecution,
> = {
  task: TASK;
  taskExecution?: TASK_EXECUTION;
  processExecution?: ProcessExecution;
  isDisabled?: boolean;
  onExecute?: (
    submission: TASK_EXECUTION,
    completeTask?: boolean,
  ) => Promise<void>;
  // Used in Form
  onValueChange?: (value: TASK) => void;
  isShortTaskForm?: boolean;
  hideCompleteButton?: boolean;
  // Used in Square
  reload?: () => void;
  children?: (renderProps: TaskExecutionRenderProps) => React.ReactElement;
  view?: TaskView;
};

export type TaskInlineEditorProps<TASK extends Task> = {
  task: TASK;
};

export type TaskSideSectionEditorProps<TASK extends Task> = {
  task: TASK;
};
