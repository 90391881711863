import { Checkbox, FormControlLabel } from "@mui/material";
import { DateFormElement } from "../../../types";
import { FormElementChangeType } from "../FormBuilder";

export const DateSideSectionDisplay = ({
  // eslint Allow unused vars for future options
  // eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
  element,
  // eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
  onChange,
}: {
  element: DateFormElement;
  onChange: (props: FormElementChangeType) => void;
}) => {
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={!!element.multiday}
            onChange={(e) => {
              onChange({
                changeType: "update",
                newElement: {
                  ...element,
                  multiday: e.target.checked,
                },
              });
            }}
          />
        }
        label={"Multi-day"}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={!!element.disablePast}
            onChange={(e) => {
              onChange({
                changeType: "update",
                newElement: {
                  ...element,
                  disablePast: e.target.checked,
                },
              });
            }}
          />
        }
        label={"Disable Days in the Past"}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={!!element.disableWeekend}
            onChange={(e) => {
              onChange({
                changeType: "update",
                newElement: {
                  ...element,
                  disableWeekend: e.target.checked,
                },
              });
            }}
          />
        }
        label={"Disable Weekend Days"}
      />
    </>
  );
};
