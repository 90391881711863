import { Box, Button, Card, Container, Grid, Typography } from "@mui/material";
import { AccountType } from "../../../model/AccountType";
import { CardActionBar, CardTitle } from "../../../components/elements";
import { useNavigate } from "react-router-dom";
import { CARD_HEIGHT } from "./constants";

export type OrganizationAccountTypeListPublicPageProps = {
  accountTypes: AccountType[];
};
const OrganizationAccountTypeListPublicPage = ({
  accountTypes,
}: OrganizationAccountTypeListPublicPageProps) => {
  const navigate = useNavigate();
  const navigateToAccountType = (accountType: AccountType) => {
    navigate(`for/${accountType.key}`);
  };
  return (
    <Container maxWidth="lg">
      <Grid
        container
        sx={{
          alignContent: "center",
          alignItems: "center",
          justifyContent: "flex-start",
          p: 3,
        }}
        spacing={3}
      >
        {accountTypes.map((accountType) => (
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            xl={6}
            key={accountType.id}
            sx={{
              minWidth: "224px",
            }}
          >
            <Card
              elevation={1}
              sx={{
                minHeight: CARD_HEIGHT,
                maxHeight: CARD_HEIGHT,
                position: "relative",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box sx={{ p: 2, pb: 0, flex: 0 }}>
                <CardTitle variant="h2" component="h1">
                  {accountType.name}
                </CardTitle>
              </Box>
              <Box sx={{ px: 2, flex: 1, overflow: "scroll" }}>
                <Typography>{accountType.description}</Typography>
              </Box>
              <CardActionBar>
                <Button
                  variant="contained"
                  onClick={() => navigateToAccountType(accountType)}
                >
                  Continue
                </Button>
              </CardActionBar>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
export default OrganizationAccountTypeListPublicPage;
