import { FC } from "react";
import { ProcessExecution } from "../../../model/ProcessExecution";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Divider,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";

import { Task } from "../../task";
import { RoleIcon } from "../../../components/elements";
import { FormattedDate } from "react-intl";
import { KanbanColumnType } from "./KanbanBoardControls";
import CardProgressBar from "./card-components/CardProgressBar";
import { useNavigate } from "react-router-dom";

type KanbanBoardCardProps = {
  execution: ProcessExecution;
  task: Task;
  view: KanbanColumnType;
  submit: (execution: ProcessExecution, taskId: string) => Promise<boolean>;
  reload: () => Promise<void>;
  draggable: boolean;
};

const KanbanBoardCard: FC<KanbanBoardCardProps> = (props) => {
  const navigate = useNavigate();

  const headerExpanded =
    props.task != null && props.view == KanbanColumnType.STEP ? true : false;

  const handleCardClick = () => {
    navigate(`../${props.execution.id}`);
  };

  const cardContent = (
    <Box sx={{ py: "1vh" }}>
      <Grid>
        <Accordion
          sx={{
            px: "1vw",
            width: "100%",
            boxShadow: 0,
          }}
          expanded={headerExpanded}
          disableGutters
        >
          <AccordionSummary
            sx={{
              display: "flex",
              justifyContent: "space-between",
              p: 0,
            }}
          >
            <Tooltip title={props.execution.name}>
              <Typography
                variant="body1"
                sx={{
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  overflow: "hidden",
                  lineHeight: "1.25em",
                  maxHeight: "2.5em",
                  fontWeight: "bold",
                  flexGrow: 1,
                }}
              >
                {props.execution.name}
              </Typography>
            </Tooltip>
            {props.task && <RoleIcon role={props.task.role} />}
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <CardProgressBar execution={props.execution} />
            <Typography
              sx={{
                lineHeight: "1.1em",
                fontWeight: "bold",
                my: "1vh",
              }}
              variant="subtitle2"
            >
              Next Task
              <Tooltip
                title={props.execution.next_task_instance?.name}
                placement="bottom-start"
              >
                <Typography
                  variant="body2"
                  sx={{
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                    overflow: "hidden",
                  }}
                >
                  {props.execution.next_task_instance?.name}
                </Typography>
              </Tooltip>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Divider />
      <Box sx={{ px: "1vw", pt: "1vh" }}>
        <Typography
          sx={{
            lineHeight: "1.1em",
            fontWeight: "bold",
            color: (theme) => theme.palette.grey[500],
          }}
          variant="subtitle2"
        >
          Date Started:
          <Typography variant="body2">
            <FormattedDate value={props.execution.started_at} />
          </Typography>
        </Typography>
      </Box>
    </Box>
  );

  return (
    <Card
      sx={{
        m: "2vh 2vw",
        borderRadius: "1vh",
        "&:hover": {
          cursor: "pointer",
        },
      }}
      onClick={handleCardClick}
    >
      {cardContent}
    </Card>
  );
};

export default KanbanBoardCard;
