import { Grid, TextField, Typography } from "@mui/material";
import { useTaskContext } from "../../../hooks";
import { TaskShortCircuit, TaskSideSectionEditorProps } from "../../../types";
import { useState } from "react";
import { json } from "@codemirror/lang-json";
import ProcessSelect from "../../editor/ProcessSelect";
import { Process } from "../../../../../model/Process";
import { useGlobalOrganizationContext } from "../../../../../hooks/useGlobalOrganizationContext";
import { CodeMirror } from "../../../../../components/core/CodeMirror/CodeMirror";

const ShortCircuitSideSectionEditor = ({
  task,
}: TaskSideSectionEditorProps<TaskShortCircuit>) => {
  const { organization } = useGlobalOrganizationContext();
  const taskContext = useTaskContext();

  const parameters = (taskContext.selectedTask as TaskShortCircuit | undefined)
    ?.execution_data?.haltProcessParameters;

  const [unformattedParameters, setUnformattedParameters] = useState<
    string | undefined
  >(parameters ? JSON.stringify(parameters, null, "  ") : "");
  const [parametersError, setParametersError] = useState<string>("");

  return (
    <>
      <Grid item xs={12}>
        <TextField
          helperText={`The label for the "Halt" action button.`}
          name={"haltLabel"}
          fullWidth
          value={task.data?.haltLabel ?? "Halt"}
          onChange={(event) => {
            taskContext.setValue({
              data: {
                ...task.data,
                haltLabel: event.target.value ?? "Halt",
              },
            });
          }}
          label={"Halt Label"}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          helperText={`The label for the "Continue" action button.`}
          name={"continueLabel"}
          fullWidth
          value={task.data?.continueLabel ?? "Continue"}
          onChange={(event) => {
            taskContext.setValue({
              data: {
                ...task.data,
                continueLabel: event.target.value ?? "Continue",
              },
            });
          }}
          label={"Continue Label"}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <ProcessSelect
          label={"Halt Process"}
          helperText={`The process to start when halt is selected`}
          organizationId={organization?.id ?? ""}
          value={{ process_key: task.data?.haltProcessKey ?? "" }}
          onChange={(process?: Process) => {
            taskContext.setValue({
              data: {
                ...task.data,
                haltProcessKey: process?.process_key,
              },
            });
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          helperText={`What to name the process execution that is started on halt`}
          name={"haltProcessExecutionName"}
          fullWidth
          value={task.execution_data?.haltTargetProcessExecutionName}
          onChange={(event) => {
            taskContext.setValue({
              execution_data: {
                ...task.execution_data,
                haltTargetProcessExecutionName: event.target.value,
              },
            });
          }}
          label={"Halt Process Execution Name"}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">Halt Process Parameters</Typography>
        <CodeMirror
          value={unformattedParameters}
          height="200px"
          extensions={[json()]}
          onChange={(value) => {
            try {
              let parsed = undefined;
              if (value) {
                parsed = JSON.parse(value);
              }
              setUnformattedParameters(value);
              taskContext.setValue({
                execution_data: {
                  ...task.execution_data,
                  haltProcessParameters: parsed,
                },
              });
              setParametersError("");
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (err: any) {
              setParametersError(err.toString());
            } finally {
              setUnformattedParameters(value);
            }
          }}
        />
        <Typography variant="body1">{parametersError}</Typography>
      </Grid>
    </>
  );
};

export default ShortCircuitSideSectionEditor;
