import { ArrowBack, ArrowForward, CheckCircle } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { FC, lazy, useState } from "react";
import { Step } from "../../../../model/step";
import { KanbanColumnType } from "../KanbanBoardControls";

const DynamicIcon = lazy(
  () => import("../../../../components/core/DynamicIcon"),
);

type KanbanBoardSectionHeaderProps = {
  step: Step;
  view: KanbanColumnType;
  setView: (newView: KanbanColumnType) => void;
  complete?: boolean;
};

const KanbanBoardSectionHeader: FC<KanbanBoardSectionHeaderProps> = ({
  step,
  view,
  setView,
  complete,
}) => {
  const [extended, setExtended] = useState<boolean>(
    view == KanbanColumnType.TASK ? true : false,
  );

  const toggleExtended = () => {
    setExtended(!extended);
    if (extended) {
      setView(KanbanColumnType.STEP);
    } else {
      setView(KanbanColumnType.TASK);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        height: "6.5vh",
        p: "1.5vh 1vw",
        bgcolor: (theme) => theme.palette.grey[300],
        borderRadius: "10px",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {step.icon ? (
          <DynamicIcon name={step.icon} />
        ) : complete ? (
          <CheckCircle />
        ) : (
          <></>
        )}
        <Typography sx={{ fontSize: "1.1em", fontWeight: "bold", ml: "1vw" }}>
          {step.name}
        </Typography>
      </Box>
      {!complete && (
        <Button
          onClick={() => toggleExtended()}
          sx={{ color: "black" }}
          disableRipple
        >
          {view == KanbanColumnType.STEP ? <ArrowForward /> : <ArrowBack />}
        </Button>
      )}
    </Box>
  );
};

KanbanBoardSectionHeader.displayName = "KanbanBoardSectionHeader";
export default KanbanBoardSectionHeader;
