import { Box } from "@mui/material";
import { useParams, Navigate } from "react-router-dom";
import PublicExecutionRenderer from "./PublicExecutionRenderer";

export const PublicProcessExecutionPage = () => {
  const { publicExecutorId, processExecutionId } = useParams<{
    publicExecutorId: string;
    processExecutionId: string;
  }>();

  if (!publicExecutorId) {
    return <Navigate to={"/"}></Navigate>;
  }

  return (
    <>
      <Box
        sx={{
          bgcolor: "primary.main",
          height: "250px",
          position: "absolute",
          width: "100%",
          zIndex: "0",
        }}
      />
      <Box sx={{ mt: 7, width: "100%" }}>
        <PublicExecutionRenderer
          publicExecutorId={publicExecutorId}
          processExecutionId={processExecutionId}
        />
      </Box>
    </>
  );
};
