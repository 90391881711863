import { TaskExecution } from "../../../task";
import RoleContainer from "../../../task/components/RoleContainer";
import { useProcessExecutionContext } from "../../hooks";

const TaskRoleAssignmentIcon = ({
  taskExecution,
}: {
  taskExecution: TaskExecution;
}) => {
  const { getAssignedUser } = useProcessExecutionContext();
  // TODO - check automation
  return (
    <RoleContainer
      execution={taskExecution}
      processExecutionId={taskExecution.process_execution_id}
      assignedUser={getAssignedUser(taskExecution)}
      role={taskExecution.task?.role}
      hide={false}
    />
  );
};

export default TaskRoleAssignmentIcon;
