import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useAlert } from "../../../../../lib/alert";
import {
  Task,
  TaskExecution,
  TaskExecutionComponentProps,
} from "../../../types";
import { TaskOAuthLinkExecution } from "../../../types/task-types/TaskOAuthLink";

const OAuthLinkExecutionComponent = (
  props: TaskExecutionComponentProps<Task, TaskExecution> & {
    reload?: () => void;
  },
) => {
  const { error } = useAlert();
  const [execution, setExecution] = useState<
    TaskOAuthLinkExecution | undefined
  >(props.taskExecution as TaskOAuthLinkExecution | undefined);

  useEffect(() => {
    setExecution(props.taskExecution as TaskOAuthLinkExecution | undefined);
  }, [props.taskExecution]);

  const handleClick = () => {
    if (execution?.data?.link) {
      window.open(execution?.data.link, "_self");
    } else {
      error("No OAuth link available");
    }
  };

  return (
    <Box>
      <Button
        variant="contained"
        color={!execution?.data?.authenticated ? "primary" : "error"}
        size="small"
        sx={{ m: 1 }}
        onClick={handleClick}
        disabled={props.isDisabled}
      >
        Sign in with OAuth
      </Button>
      <Button
        variant="contained"
        color={execution?.data?.authenticated ? "primary" : "error"}
        size="small"
        sx={{ m: 1 }}
        onClick={() => {
          props.onExecute && execution && props.onExecute(execution);
        }}
        disabled={props.isDisabled}
      >
        Mark Complete
      </Button>
    </Box>
  );
};

export default OAuthLinkExecutionComponent;
