/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import { AuditEvent } from "../../../../model/AuditEvent";
import { getAuditLogEventInfo } from "../../utils";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

export const AuditLogEventItem: FC<AuditEvent> = (auditEvent: AuditEvent) => {
  const structure = getAuditLogEventInfo(auditEvent);

  const [errorOpen, setErrorOpen] = useState(false);

  const handleClick = () => {
    setErrorOpen(!errorOpen);
  };

  return (
    <Box key={auditEvent.id} sx={{ display: "flex" }}>
      <Box sx={{ flex: 0, mr: 1 }}>{structure.icon}</Box>
      <Box sx={{ flex: 1 }}>
        <Box>{structure.title}</Box>
        <Box sx={{ display: "flex", flexWrap: "wrap", rowGap: 1, mt: 1 }}>
          <Typography
            sx={{
              flex: 1,
              color: "text.disabled",
              textAlign: "left",
            }}
          >
            {structure.timestampPart}
          </Typography>
          {structure.userPart && (
            <Typography
              sx={{
                color: "text.disabled",
                overflowWrap: "anywhere",
                whiteSpace: "normal",
              }}
            >
              {structure.userPart}
            </Typography>
          )}
          {structure.error && (
            <div style={{ width: "100%" }}>
              <List>
                <ListItemButton onClick={handleClick}>
                  <ListItemText primary="Error" />
                  {errorOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={errorOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem>
                      <pre style={{ whiteSpace: "pre-wrap" }}>
                        {structure.error}
                      </pre>
                    </ListItem>
                  </List>
                </Collapse>
              </List>
            </div>
          )}
        </Box>
      </Box>
    </Box>
  );
};
