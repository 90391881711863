import { useEffect, useMemo, useState } from "react";
import {
  Tooltip,
  SvgIcon,
  Typography,
  TextField,
  AutocompleteProps,
  AutocompleteValue,
} from "@mui/material";
import { PendingOutlined } from "@mui/icons-material";
import invitations from "../../../api/invitations";
import organizations from "../../../api/organizations";
import Invitation from "../../../model/Invitation";
import { User } from "../../../model";
import Selection, { SelectionProps } from "./Selection";
import UserSelectionInviteButton from "./UserSelectionInviteButton";
import getUserFullName from "../../../features/activity-log/utils/getUserFullName";

export type UserOrInvitedUser = User & {
  invitationId?: string;
};

const createUserFromInvitation = (invitation: Invitation) => {
  const newUser: UserOrInvitedUser = new User();
  newUser.id = invitation.target_user_id;
  newUser.email = invitation.target_user_email;
  newUser.roles = invitation.roles;
  newUser.invitationId = invitation.id;
  return newUser;
};

export const fetchUsers = async (organizationId?: string, filter?: string) => {
  if (!organizationId) {
    return [];
  }
  const [users, invites] = await Promise.all([
    organizations.getMembers(organizationId, filter),
    invitations.membersByOrganization(organizationId, filter),
  ]);
  return [
    ...users,
    ...invites.map((invitation) => {
      return createUserFromInvitation(invitation);
    }),
  ];
};

const defaultSort = (user1: UserOrInvitedUser, user2: UserOrInvitedUser) => {
  if (!user1.invitationId) return -1;
  if (!user2.invitationId) return 1;
  return 0;
};

type UserSelectionProps<Multiple extends boolean | undefined> = Partial<
  Omit<SelectionProps<User, Multiple>, "AutoCompleteProps">
> & {
  AutoCompleteProps: Omit<
    AutocompleteProps<User, Multiple, undefined, undefined>,
    "options" | "inputValue" | "renderInput" | "value" | "freeSolo"
  > & {
    value?: (
      options: Array<User>,
    ) => AutocompleteValue<User, Multiple, undefined, undefined>;
    renderInput?: AutocompleteProps<
      User,
      Multiple,
      undefined,
      undefined
    >["renderInput"];
  };
} & {
  organizationId?: string;
  onInvite?: (invitation: Invitation) => void;
  sort?: (o1: UserOrInvitedUser, o2: UserOrInvitedUser) => number;
};

const UserSelection = <Multiple extends boolean | undefined = undefined>({
  organizationId,
  onInvite,
  sort = defaultSort,
  ...props
}: UserSelectionProps<Multiple>) => {
  const [users, setUsers] = useState<User[]>([]);
  const [inputValue, setInputValue] = useState<string>();
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);

  const { dynamicFilter, ...SelectionProps } = props ?? {};
  const {
    value,
    renderInput,
    onInputChange,
    filterOptions,
    ...AutoCompleteProps
  } = SelectionProps.AutoCompleteProps ?? {};

  const newUserInvitation = useMemo(() => {
    const invitation = new Invitation();
    invitation.organization_id = organizationId ?? "";
    invitation.target_user_email = inputValue ?? "";
    return invitation;
  }, [organizationId, inputValue]);

  useEffect(() => {
    fetchUsers(organizationId).then((res) => {
      setUsers(res);
      setFilteredUsers(res);
    });
  }, [organizationId, setUsers, setFilteredUsers]);

  const selectedValue = useMemo(() => {
    return value?.(users);
  }, [value, users]);

  useEffect(() => {
    if (SelectionProps.AutoCompleteProps.multiple === false) {
      setInputValue(getUserFullName(selectedValue as User, true));
    }
  }, [selectedValue, SelectionProps.AutoCompleteProps.multiple]);

  return (
    <Selection
      dynamicFilter={async (filter) => {
        setFilteredUsers(await fetchUsers(organizationId, filter));
        dynamicFilter?.(filter);
      }}
      {...SelectionProps}
      AutoCompleteProps={{
        id: "user-selection",
        options: users.sort(sort),
        value: selectedValue,
        inputValue: inputValue,
        renderInput:
          renderInput ??
          ((params) => <TextField {...params} label={"Select Users"} />),
        renderOption: (props, user: UserOrInvitedUser) => (
          <li {...props} style={{ paddingLeft: "8px" }}>
            {user.invitationId ? (
              <Tooltip title={"Pending User"}>
                <PendingOutlined sx={{ color: "#888", marginRight: "6px" }} />
              </Tooltip>
            ) : (
              <SvgIcon sx={{ marginRight: "6px" }} />
            )}
            <Typography>{getUserFullName(user, true)}</Typography>
          </li>
        ),
        getOptionLabel: (user) => {
          return getUserFullName(user, true);
        },
        isOptionEqualToValue: (option, value) => option.id === value.id,
        noOptionsText: !onInvite ? (
          "No users found"
        ) : (
          <UserSelectionInviteButton
            invitation={newUserInvitation}
            onInvite={(invitation) => {
              fetchUsers(organizationId).then((response) => {
                setUsers(response);
                onInvite(invitation);
                setInputValue("");
              });
            }}
          />
        ),
        onInputChange: (event, value, reason) => {
          setInputValue(value);
          onInputChange?.(event, value, reason);
        },
        filterOptions: (options, state) => {
          if (options.length == 0) {
            // options are empty if we are loading
            return options;
          }
          if (filterOptions) {
            return filterOptions(filteredUsers, state).sort(sort);
          }
          return filteredUsers.sort(sort);
        },
        ...AutoCompleteProps,
      }}
    />
  );
};

export default UserSelection;
