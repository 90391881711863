import { Box, SxProps, Theme } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Processes from "../../../../api/processes";
import ProcessExecutions from "../../../../api/process_executions";
import { useAlert } from "../../../../lib/alert";
import { Process } from "../../../../model/Process";
import { ProcessExecution } from "../../../../model/ProcessExecution";
import KanbanBoardView from "../../../kanban-board/components/KanbanBoardView";
import SingleProcessHeader from "./SingleProcessHeader";
import SingleProcessTablePage from "./SingleProcessTablePage";
import PATHS from "../../../../components/navigation/_paths";

export enum SingleProcessViewType {
  TABLE = "table",
  BOARD = "board",
}

type SingleProcessWrapperProps = {
  sx?: SxProps<Theme>;
  view: SingleProcessViewType;
};

const SingleProcessWrapper: FC<SingleProcessWrapperProps> = (props) => {
  // table is used as the "default tab" to prevent warnings, but is overwritten when effect is called
  const [process, setProcess] = useState<Process>();
  const [executions, setExecutions] = useState<ProcessExecution[]>();

  const { processId } = useParams();
  const { error, handleRejectionWithWarning } = useAlert();
  const navigate = useNavigate();

  const getPage = () => {
    if (process && executions) {
      switch (props.view) {
        case SingleProcessViewType.TABLE:
          return (
            <SingleProcessTablePage process={process} executions={executions} />
          );
        case SingleProcessViewType.BOARD:
          return <KanbanBoardView process={process} />;
      }
    }
  };

  useEffect(() => {
    if (!processId) return;
    Processes.get(processId)
      .then((fetchedProcess) => {
        setProcess(fetchedProcess);
      })
      .catch((e) => {
        error("An error occured while loading the process");
        console.error(e);
      });
  }, [error, processId]);

  // fetches the executions if the process is valid
  useEffect(() => {
    if (!process) return;

    ProcessExecutions.byOrganizationId(process.org_id).then((pagedResponse) => {
      const fetchedExecutions = pagedResponse.data;
      setExecutions(
        fetchedExecutions.filter((execution) => {
          if (execution.process_id === processId) return execution;
        }),
      );
    });
  }, [process, processId]);

  const handleExecuteProcessRequest = (metaOrId: string, name: string) => {
    const configuration = { name: name };
    ProcessExecutions.start(metaOrId, configuration as ProcessExecution).then(
      (processExecution: ProcessExecution) => {
        navigate(
          PATHS.EXECUTION.linkTo(
            processExecution.process_id,
            processExecution.id,
          ),
        );
      },
      (err) => {
        handleRejectionWithWarning(
          `Could not start process execution for process ${metaOrId}`,
        )(err);
      },
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        flexDirection: "column",
        py: "1vh",
      }}
    >
      {process && (
        <SingleProcessHeader
          process={process}
          executeProcess={handleExecuteProcessRequest}
          view={props.view}
        />
      )}
      <Box sx={{ flexGrow: 1, px: 2 }}>{getPage()}</Box>
    </Box>
  );
};

export default SingleProcessWrapper;
