import { Alert, Box, CircularProgress, Link, Paper } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Navigate } from "react-router-dom";
import { CognitoUserPool, CognitoUser } from "amazon-cognito-identity-js";
import { useState } from "react";
import CheckCircle from "@mui/icons-material/CheckCircle";
import { login } from "../../../lib/auth";
import Users from "../../../api/users";
import { mapCognitoError } from "../../../lib/cognito";

type VerificationComponentProps = {
  userId: string;
  password: string;
  onComplete: () => void;
};

export default function VerificationComponent(
  props: VerificationComponentProps,
) {
  const [verifying, setVerifying] = useState<boolean>();
  const [verified, setVerified] = useState<boolean>(false);
  const [verificationCodeSent, setVerificationCodeSent] = useState(<></>);
  const [error, setError] = useState("");

  if (!process.env.REACT_APP_COGNITO_CLIENT_ID) {
    console.error("VerificationPage: clientId not defined");
    return <Navigate to={"/"} />;
  }

  if (!process.env.REACT_APP_COGNITO_USER_POOL_ID) {
    console.error("VerificationPage: cognito user pool id not defined");
    return <Navigate to={"/"} />;
  }

  const poolData = {
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  };

  const userPool = new CognitoUserPool(poolData);
  const userData = {
    Username: props.userId,
    Pool: userPool,
  };

  const cognitoUser = new CognitoUser(userData);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const verificationCode = data.get("code");

    if (!verificationCode) {
      setError("Code is required.");
      return;
    }

    setVerifying(true);
    cognitoUser.confirmRegistration(
      verificationCode.toString(),
      true,
      function (err) {
        if (err) {
          setError(mapCognitoError(err));
          setVerifying(false);
          return;
        }

        login(props.userId, props.password).then(
          () => {
            Users.register().then(() => {
              props.onComplete();
              setVerifying(false);
              setVerified(true);
            });
          },
          (err) => {
            setError(mapCognitoError(err));
            setVerifying(false);
            return;
          },
        );
      },
    );
  };

  const resendVerificationCode = (event: React.MouseEvent) => {
    event.preventDefault();

    cognitoUser.resendConfirmationCode(function (err) {
      if (err) {
        setError(mapCognitoError(err));
        return;
      }

      setVerificationCodeSent(<CheckCircle fontSize="small" />);
    });
  };

  return (
    <>
      {verifying ? (
        <CircularProgress />
      ) : (
        !verified && (
          <Container maxWidth="xs">
            <Paper elevation={2} sx={{ px: 4, py: 6 }}>
              <div>
                <Box component="header">
                  <Typography component="h1" variant="h4">
                    Verify
                  </Typography>
                  <Typography component="h2" variant="body2">
                    Check your email for a 6 digit verification code and enter
                    it in the box below.
                  </Typography>
                </Box>
                <Box
                  component="form"
                  noValidate
                  onSubmit={handleSubmit}
                  sx={{ mt: 3 }}
                >
                  {error && (
                    <Alert sx={{ width: "100%", mb: "16px" }} severity="error">
                      {error}
                    </Alert>
                  )}
                  <TextField
                    required
                    fullWidth
                    id="code"
                    label="Verification Code"
                    name="code"
                    autoComplete="code"
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Verify
                  </Button>
                  <Grid container>
                    <Grid item xs={6}>
                      <Link
                        onClick={resendVerificationCode}
                        sx={{ cursor: "pointer" }}
                        variant="body2"
                      >
                        {"Resend verification code"}
                      </Link>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "right" }}>
                      {verificationCodeSent}
                    </Grid>
                  </Grid>
                </Box>
              </div>
            </Paper>
          </Container>
        )
      )}
    </>
  );
}
