import { PropsWithChildren } from "react";
import { Box, SxProps, Theme } from "@mui/material";

export type SideDetailsContainerProps = PropsWithChildren & {
  maxWidth?: number;
  minWidth?: number;
  scrollable?: boolean;
  sx?: SxProps<Theme>;
};

const SideDetailsContainer = ({
  maxWidth = 600,
  minWidth = 216,
  scrollable,
  children,
  sx,
}: SideDetailsContainerProps) => (
  <Box
    component={"section"}
    sx={{
      flex: 1,
      maxWidth: maxWidth,
      minWidth: minWidth,
      backgroundColor: "background.drawer",
      overflow: scrollable ? "auto" : undefined,
      boxShadow: 3,
      "@media print": {
        overflow: "visible", // Make all content visible for printing
      },
      ...sx,
    }}
  >
    {children}
  </Box>
);

export default SideDetailsContainer;
