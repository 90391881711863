import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Box, Collapse, IconButton, Paper } from "@mui/material";
import { atom, useAtom } from "jotai";
import { RightPanel } from "../../components/layout/dashboard/RightPanel";

const CONTENT_Z_INDEX = 10;

const organizationDrawerMenuOpen = atom<boolean>(true);

export const useOrganizationDrawerAtom = () => {
  return useAtom(organizationDrawerMenuOpen);
};

const CollapseIcon = () => {
  const [open, setOpen] = useOrganizationDrawerAtom();

  const Icon = open ? ChevronLeft : ChevronRight;
  return (
    <Box
      width={0}
      position={"relative"}
      height={"100%"}
      display={"flex"}
      alignItems={"center"}
      sx={{
        displayPrint: "none",
      }}
    >
      <IconButton
        sx={{
          height: "80px",
          background: (theme) => theme.palette.grey[100],
          border: (theme) => `2px solid ${theme.palette.grey[400]}`,
          color: (theme) => theme.palette.grey[500],
          position: "absolute",
          cursor: "pointer",
          borderRadius: 0,
          p: 0,
          ...(open
            ? {
                right: "-4px",
                borderTopLeftRadius: "16px",
                borderBottomLeftRadius: "16px",
                zIndex: CONTENT_Z_INDEX - 1,
              }
            : {
                left: "-4px",
                borderTopRightRadius: "16px",
                borderBottomRightRadius: "16px",
                zIndex: CONTENT_Z_INDEX + 1,
              }),
          "&:hover": {
            background: (theme) => theme.palette.grey[300],
          },
        }}
        onClick={() => {
          setOpen((prev) => !prev);
        }}
      >
        <Icon sx={{ zIndex: 20 }} />
      </IconButton>
    </Box>
  );
};

const InternalLayout = ({
  menu: InternalMenu,
  children,
}: React.PropsWithChildren<{ menu?: () => React.JSX.Element }>) => {
  const [open] = useOrganizationDrawerAtom();
  return (
    <Box
      id="page-outlet"
      sx={{
        display: "flex",
        flexDirection: "row",
        height: "100%",
        backgroundColor: (theme) => theme.palette.background.drawer,
      }}
    >
      <Box sx={{ display: "flex" }}>
        {InternalMenu && (
          <>
            <Collapse in={open} orientation={"horizontal"}>
              <InternalMenu />
            </Collapse>
            <CollapseIcon />
            <Collapse in={!open} orientation={"horizontal"}>
              <Box sx={{ ml: 2 }} />
            </Collapse>
          </>
        )}
      </Box>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "row",
          flex: 1,
          m: InternalMenu ? 2 : 0,
          ml: 0,
          borderRadius: 2,
          overflow: "auto",
          "@media print": {
            overflow: "visible",
            border: "none",
            boxShadow: "unset",
          },
          zIndex: CONTENT_Z_INDEX,
        }}
        elevation={open ? 3 : 6}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            width: "100%",
          }}
        >
          {children}
        </Box>
        <RightPanel />
      </Paper>
    </Box>
  );
};

export default InternalLayout;
