import API from "./base";
import { PreferenceContainer } from "../model/Preference";

const get = (userId: string): Promise<PreferenceContainer> => {
  return new Promise((resolve, reject) => {
    API.get(`/preferences/${userId}`).then(
      (result) => resolve(result.data.payload as PreferenceContainer),
      reject,
    );
  });
};

const set = (
  userId: string,
  preferences: PreferenceContainer,
): Promise<void> => {
  return new Promise((resolve, reject) => {
    API.put(`/preferences/${userId}`, preferences).then(
      () => resolve(),
      reject,
    );
  });
};

export default {
  get,
  set,
};
