import { FC, useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { sendPasswordResetEmail } from "../../lib/auth";
import { mapCognitoError } from "../../lib/cognito";
import { ClientMetadata } from "amazon-cognito-identity-js";

export type ForgotPasswordPageProps = {
  clientMetadata?: ClientMetadata;
};

const ForgotPasswordPage: FC<ForgotPasswordPageProps> = (props) => {
  const navigate = useNavigate();
  const [authenticating, setAuthenticating] = useState<boolean>(false);
  const [error, setError] = useState("");

  const forgotPasswordEmailSentLink = "/forgot-password-email-sent";

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const emailValue = data.get("email");

    if (!emailValue) {
      setError("Email is required.");
      return;
    }

    setAuthenticating(true);

    sendPasswordResetEmail(emailValue.toString(), props.clientMetadata)
      .then((result) => {
        if (result.success) {
          navigate(forgotPasswordEmailSentLink);
          return;
        }
      })
      .catch((err) => {
        setAuthenticating(false);
        setError(mapCognitoError(err));
      });
  };

  return (
    <>
      <Container component="main" maxWidth="xs" sx={{ mt: { xs: 3, md: 18 } }}>
        <Paper elevation={9}>
          {authenticating ? (
            <Box
              sx={{
                width: "100%",
                height: "300px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box sx={{ px: 4, py: 6 }}>
              {error && (
                <Alert sx={{ width: "100%" }} severity="error">
                  {error}
                </Alert>
              )}
              {/* Header */}
              <Box component="header">
                <Typography component="h1" variant="h4">
                  Password Reset
                </Typography>
                <Typography component="h2" variant="body2" sx={{ mt: 1 }}>
                  We need your email to reset your password
                </Typography>
              </Box>

              {/* Body */}
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, py: 1.5 }}
                >
                  Get Recovery Link
                </Button>
              </Box>
            </Box>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default ForgotPasswordPage;
