import { FormattedMessage } from "react-intl";

/**
 *  All common messages for the process feature.
 *
 *  @see {@link ../../../docs/intl.md | Internationalization Code Style Guidelines}
 */
export default {
  process: (
    <FormattedMessage
      id="process.process"
      defaultMessage="Flight Plan"
      description="Common term for the repeatable series of steps and tasks to achieve a desired goal."
    />
  ),
  processes: (
    <FormattedMessage
      id="process.process.plural"
      defaultMessage="Flight Plans"
      description="A process collection."
    />
  ),
  execution: (
    <FormattedMessage
      id="process.execution"
      defaultMessage="Flight"
      description="Term for running a process."
    />
  ),
  executions: (
    <FormattedMessage
      id="process.execution.plural"
      defaultMessage="Flights"
      description="An execution collection."
    />
  ),
  inProgressExecutions: (
    <FormattedMessage
      id="process.execution.inProgress"
      defaultMessage="In Flight"
      description="A collection of executions that are in progress."
    />
  ),
  completedExecutions: (
    <FormattedMessage
      id="process.execution.complete"
      defaultMessage="Landed"
      description="A collection of executions that are complete."
    />
  ),
};
