import { TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import roles from "../../../api/roles";
import { Role, User } from "../../../model";
import Invitation from "../../../model/Invitation";
import UserSelection, { UserOrInvitedUser } from "./UserSelection";

const doesUserHaveRole = (user: User, targetRole?: Role) => {
  return !!user.roles?.filter((role) => role.id === targetRole?.id).length;
};

type UserSelectionByRoleProps = {
  values?: string[];
  targetRoleId: string;
  disabled?: boolean;
  label?: string;
  onChange?: (users: UserOrInvitedUser[]) => void;
  onInvite?: (invitation: Invitation) => void;
  multiple?: boolean;
};

const UserSelectionByRole = ({
  values,
  targetRoleId,
  disabled,
  label,
  onChange,
  onInvite,
  multiple,
}: UserSelectionByRoleProps) => {
  const [targetRole, setTargetRole] = useState<Role>();

  useEffect(() => {
    roles
      .get(targetRoleId)
      .then((role) => {
        setTargetRole(role);
      })
      .catch((error) => {
        console.error(`Could not get role ${targetRoleId}`, error);
      });
  }, [targetRoleId]);

  const determineValue = useCallback(
    (users: User[]) => {
      const v = (values ?? [])
        .map((userId) => users.find((user) => user.id == userId))
        .filter((u): u is User => !!u);
      if (multiple !== false) {
        return v;
      }
      return v.length ? v[0] : null;
    },
    [values, multiple],
  );

  return (
    <UserSelection
      organizationId={targetRole?.org_id}
      sort={(user1: UserOrInvitedUser, user2: UserOrInvitedUser) => {
        const user1HasTargetRole = doesUserHaveRole(user1, targetRole);
        const user2HasTargetRole = doesUserHaveRole(user2, targetRole);
        // If matching roles sort by invite
        if (user1HasTargetRole === user2HasTargetRole) {
          if (!user1.invitationId) return -1;
          if (!user2.invitationId) return 1;
          return 0;
        }
        // Sort by target role
        if (user1HasTargetRole) return -1;
        if (user2HasTargetRole) return 1;
        return 0;
      }}
      onInvite={onInvite}
      AutoCompleteProps={{
        multiple: multiple !== false ? true : false,
        disabled: disabled,
        value: determineValue,
        renderInput: (params) => (
          <TextField
            {...params}
            label={
              label
                ? label
                : targetRole
                  ? `Select ${targetRole.name} Users`
                  : `Select Users`
            }
          />
        ),
        onChange: (_, users) => {
          if (Array.isArray(users)) {
            onChange?.(users);
          } else if (!users) {
            // This is to detect if null was selected
            onChange?.([]);
          } else {
            // We know this cast is safe because of the
            // runtime array type check that happens above
            onChange?.([users as User]);
          }
        },
        groupBy: (user) => {
          return targetRole && doesUserHaveRole(user, targetRole)
            ? `${targetRole.name}`
            : `Other`;
        },
        filterOptions: (users, state) => {
          return users.filter((user) => {
            if (state.inputValue) {
              return true;
            }
            if (!user.roles) {
              return false;
            }
            return doesUserHaveRole(user, targetRole);
          });
        },
      }}
    />
  );
};

export default UserSelectionByRole;
