import { FC, useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import { CheckboxFormElement, TaskExecutionState } from "../../../../types";
import FormExecutionComponent from "../FormExecutionComponent";
import FormElementTaskDescription from "../../../form-builder/FormElementTaskDescription";

type TaskCheckboxFormType = {
  disabled: boolean;
  onValueChange: (value: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  execution: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formExecutionComponentProps: any;
} & CheckboxFormElement;

const TaskCheckboxFormComponent: FC<TaskCheckboxFormType> = ({
  required,
  label,
  formExecutionComponentProps,
  elements,
  onValueChange,
  execution,
  taskExecutionState,
  ...props
}) => {
  const [checked, setChecked] = useState<boolean>(false);
  useEffect(() => {
    if (execution?.value != undefined) {
      setChecked(execution?.value);
      onValueChange(execution?.value);
    }
  }, [onValueChange, execution]);

  // Readonly view
  if (props.disabled && taskExecutionState === TaskExecutionState.Completed) {
    return (
      <>
        <Grid item container gap={1} alignItems={"center"}>
          <Typography variant="subtitle1">{label}:</Typography>
          <Checkbox disabled checked={checked} size="small" sx={{ p: 0 }} />
        </Grid>
        {checked && elements && (
          <Box pt={1}>
            <FormExecutionComponent
              {...formExecutionComponentProps}
              elements={elements}
              executionElements={execution?.elements ?? []}
              hideCompleteButton
              hideEditButton
              isShortTaskForm
            />
          </Box>
        )}
      </>
    );
  }
  return (
    <>
      <Grid item>
        <FormGroup
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setChecked(e.target.checked);
            onValueChange(e.target.checked);
          }}
        >
          <FormControlLabel
            label={
              <>
                {label}
                {required && (
                  <Typography sx={{ display: "inline", color: "red" }}>
                    {" *"}
                  </Typography>
                )}
              </>
            }
            control={<Checkbox disabled={props.disabled} checked={checked} />}
          />
          <FormElementTaskDescription>
            {props.description}
          </FormElementTaskDescription>
        </FormGroup>
      </Grid>
      {checked && elements && (
        <Box>
          <FormExecutionComponent
            {...formExecutionComponentProps}
            elements={elements}
            executionElements={execution?.elements ?? []}
            hideCompleteButton
            hideEditButton
            isShortTaskForm
          />
        </Box>
      )}
    </>
  );
};

export default TaskCheckboxFormComponent;
