import {
  AuthenticationDetails,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  ClientMetadata,
} from "amazon-cognito-identity-js";
import * as AWS from "aws-sdk/global";
import { ChangePasswordState, record } from "../types";
import { decodeJwt } from "jose";
import Users from "../api/users";
import superagent from "superagent";

// add any future identity providers here (Facebook, etc.)
export enum IdentityProvider {
  Google = "Google",
}

export enum IdentityProviderFlow {
  SignIn = "SIGN_IN",
  Register = "REGISTER",
  AcceptInvitation = "ACCEPT_INVITATION",
}

type AuthenticationCodeGrantType = {
  id_token: string;
  access_token: string;
  refresh_token: string;
  expires_in: string;
  token_type: string;
};

// We will want to either store this email temporarily in local storage,
// or just have the user re-type their email in again. Not a great way to
// handle this longterm currently -- but I'm no longer storing the email
// in a cookie.
let _emailForPasswordReset = "";
let _codeForPasswordReset = "";

const getEmailAndCodeForPasswordReset = () => {
  return {
    emailForPasswordReset: _emailForPasswordReset,
    codeForPasswordReset: _codeForPasswordReset,
  };
};

const setEmailAndCodeForPasswordReset = (email: string, code: string) => {
  _emailForPasswordReset = email;
  _codeForPasswordReset = code;
};

interface Tokens {
  accessToken: string | null;
  refreshToken: string | null;
}

const tokens: Tokens = {
  accessToken: null,
  refreshToken: null,
};

interface AuthenticationResult {
  success: boolean;
  changePasswordState?: ChangePasswordState;
}

const federatedAuthroizationEndpoint = (
  identityProvider: IdentityProvider,
  originOverride?: string,
) => {
  const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID;
  const region = process.env.REACT_APP_AWS_REGION;
  const cognitoDomain = process.env.REACT_APP_COGNITO_DOMAIN;
  const redirectUri = process.env.REACT_APP_REDIRECT_GOOGLE_AUTH_URI;
  const stateValue = {
    authValue: process.env.REACT_APP_STATE_VALUE,
    origin: originOverride,
  };

  if (!clientId) {
    throw new Error("federated login: clientId not defined");
  }

  if (!stateValue) {
    throw new Error("federated login: state value not defined");
  }

  if (!cognitoDomain) {
    throw new Error("federated login: cognito domain not defined");
  }

  if (!redirectUri) {
    throw new Error("federated login: google auth redirect uri not defined");
  }

  const authDomain =
    process.env.REACT_APP_COGNITO_AUTH_DOMAIN ??
    `${cognitoDomain}.auth.${region}.amazoncognito.com`;

  // this state is used to protect against CSRF attacks
  const encodedStateValue = btoa(JSON.stringify(stateValue));
  return `https://${authDomain}/oauth2/authorize?identity_provider=${identityProvider}&redirect_uri=${redirectUri}&response_type=code&client_id=${clientId}&scope=email openid profile aws.cognito.signin.user.admin&state=${encodedStateValue}`;
};

const federatedLogoutEndpoint = (redirectOrigin: string) => {
  const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID;
  const region = process.env.REACT_APP_AWS_REGION;
  const cognitoDomain = process.env.REACT_APP_COGNITO_DOMAIN;
  const redirectUri = `${redirectOrigin}/sign-in`;

  if (!clientId) {
    throw new Error("federated logout: clientId not defined");
  }

  if (!cognitoDomain) {
    throw new Error("federated logout: cognito domain not defined");
  }

  if (!redirectUri) {
    throw new Error("federated logout: redirect uri not defined");
  }

  return `https://${cognitoDomain}.auth.${region}.amazoncognito.com/logout?client_id=${clientId}&logout_uri=${redirectUri}`;
};

let _changePasswordState: ChangePasswordState | undefined;

const getChangePasswordState = () => {
  return _changePasswordState;
};

const clearChangePasswordState = () => {
  _changePasswordState = undefined;
};

const authenticated = () => {
  if (tokens.accessToken && tokens.refreshToken) {
    return true;
  }

  // Loop through all the keys in local storage and try to find one
  // that starts with CognitoIdentityServiceProvider and ends with
  // .accessToken or .refreshToken.  If we find one then
  // set the access and refresh tokens accordingly
  for (const key in localStorage) {
    if (
      key.startsWith("CognitoIdentityServiceProvider") &&
      (key.endsWith(".accessToken") || key.endsWith(".refreshToken"))
    ) {
      const value = localStorage.getItem(key);
      if (value) {
        if (key.endsWith(".accessToken")) {
          tokens.accessToken = value;
          const jwt = decodeJwt(tokens.accessToken);
          _currentUserId = jwt.sub;
          _isUser = jwt.type !== "SHARE_KEY";
        } else if (key.endsWith(".refreshToken")) {
          tokens.refreshToken = value;
        }
      }
    }
  }

  return tokens.accessToken && tokens.refreshToken;
};

const isUser = () => {
  return _isUser;
};

let _currentUserId: string | undefined;
let _isUser = false;
const currentUserId = (): string => {
  // Typescript not smart enough to figure out that
  // if authenticated is true then the access token is defined
  // so we need the additional check for the other if statement's
  // types to work out
  if (!authenticated() || !tokens.accessToken) {
    throw new Error("No current user id available in this context");
  }

  if (!_currentUserId) {
    _currentUserId = decodeJwt(tokens.accessToken).sub;
  }

  if (!_currentUserId) {
    throw new Error("Unable to decode current user id from jwt");
  }

  return _currentUserId;
};

const login = (
  username?: string,
  password?: string,
): Promise<AuthenticationResult> => {
  if (!process.env.REACT_APP_COGNITO_CLIENT_ID) {
    throw new Error("login: clientId not defined");
  }

  if (!process.env.REACT_APP_COGNITO_USER_POOL_ID) {
    throw new Error("login: cognito user pool id not defined");
  }

  if (!process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID) {
    throw new Error("login: cognito identity pool not defined");
  }

  if (!process.env.REACT_APP_AWS_REGION) {
    throw new Error("login: region not defined");
  }

  const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID;
  const cognitoUserPoolId = process.env.REACT_APP_COGNITO_USER_POOL_ID;
  const cognitoIdentityPoolId = process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID;
  const region = process.env.REACT_APP_AWS_REGION;

  AWS.config.region = region;

  return new Promise<AuthenticationResult>((res, reject) => {
    const poolData = {
      UserPoolId: cognitoUserPoolId,
      ClientId: clientId,
    };
    const userPool = new CognitoUserPool(poolData);

    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser != null) {
      refreshLogin().then(() => {
        res({ success: true });
      }, reject);
      return;
    }

    if (username != null && password != null) {
      const authenticationData = {
        Username: username,
        Password: password,
      };
      const authenticationDetails = new AuthenticationDetails(
        authenticationData,
      );
      const poolData = {
        UserPoolId: cognitoUserPoolId,
        ClientId: clientId,
      };
      const userPool = new CognitoUserPool(poolData);
      const userData = {
        Username: username,
        Pool: userPool,
      };
      const cognitoUser = new CognitoUser(userData);

      AWS.config.region = process.env.REACT_APP_AWS_REGION;

      cognitoUser.authenticateUser(authenticationDetails, {
        newPasswordRequired: function (userAttributes) {
          delete userAttributes.email_verified;
          delete userAttributes.email;
          _changePasswordState = {
            userAttributes: userAttributes,
            cognitoUser: cognitoUser,
          };

          res({
            success: false,
            changePasswordState: _changePasswordState,
          });
        },

        onSuccess: function (result) {
          const credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: cognitoIdentityPoolId,
            Logins: {
              ...record(
                `cognito-idp.${region}.amazonaws.com/${cognitoUserPoolId}`,
                result.getIdToken().getJwtToken(),
              ),
            },
          });

          AWS.config.credentials = credentials;

          //refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
          credentials.refresh((error) => {
            if (error) {
              console.error(error);
              reject(error);
            } else {
              tokens.accessToken = result.getAccessToken().getJwtToken();
              tokens.refreshToken = result.getRefreshToken().getToken();
              _isUser = true;

              Users.get(currentUserId()).then(
                () => {
                  res({
                    success: true,
                  });
                },
                (err) => {
                  console.error(err);
                  Users.register().then(() => {
                    res({
                      success: true,
                    });
                  }, reject);
                },
              );
            }
          });
        },

        onFailure: function (err) {
          console.error(err);
          reject(err);
        },
      });

      return;
    }

    reject(new Error("login: unable to authenticate"));
  });
};

const refreshLogin = (): Promise<void> => {
  if (!process.env.REACT_APP_COGNITO_CLIENT_ID) {
    throw new Error("login: clientId not defined");
  }

  if (!process.env.REACT_APP_COGNITO_USER_POOL_ID) {
    throw new Error("login: cognito user pool id not defined");
  }

  if (!process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID) {
    throw new Error("login: cognito identity pool not defined");
  }

  if (!process.env.REACT_APP_AWS_REGION) {
    throw new Error("login: region not defined");
  }

  const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID;
  const cognitoUserPoolId = process.env.REACT_APP_COGNITO_USER_POOL_ID;
  const cognitoIdentityPoolId = process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID;
  const region = process.env.REACT_APP_AWS_REGION;

  AWS.config.region = region;

  return new Promise((res, reject) => {
    const poolData = {
      UserPoolId: cognitoUserPoolId,
      ClientId: clientId,
    };
    const userPool = new CognitoUserPool(poolData);

    const cognitoUser = userPool.getCurrentUser();

    if (!cognitoUser) {
      reject(new Error("No user session was initialized"));
      return;
    }

    cognitoUser.getSession(function (
      err: Error | null,
      result: CognitoUserSession | null,
    ) {
      if (err) {
        console.error(err);
        reject(err);
      }

      if (result) {
        const credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: cognitoIdentityPoolId,
          Logins: {
            ...record(
              `cognito-idp.${region}.amazonaws.com/${cognitoUserPoolId}`,
              result.getIdToken().getJwtToken(),
            ),
          },
        });
        AWS.config.credentials = credentials;

        //call refresh method in order to authenticate user and get new temp credentials
        credentials.refresh((error) => {
          if (error) {
            console.error(error);
            reject(new Error("login: Unable to refresh token", error));
          }

          tokens.accessToken = result.getAccessToken().getJwtToken();
          tokens.refreshToken = result.getRefreshToken().getToken();
          _isUser = true;

          Users.register().then(() => {
            res();
          }, reject);
        });
      }
    });
  });
};

const logout = (): Promise<void> => {
  return new Promise<void>((res) => {
    if (!process.env.REACT_APP_COGNITO_USER_POOL_ID) {
      throw new Error("logout: cognito user pool id not defined");
    }

    if (!process.env.REACT_APP_COGNITO_CLIENT_ID) {
      throw new Error("logout: clientId not defined");
    }

    const poolData = {
      UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    };
    const userPool = new CognitoUserPool(poolData);

    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser != null) {
      cognitoUser.signOut();
    }

    // The rest of the logout process should be performed even if there
    // was no user logged in through the sdk

    tokens.accessToken = null;
    tokens.refreshToken = null;
    _isUser = false;

    // Cache Feature Flags before clearing local storage
    const runwayFeatureFlages: Array<[key: string, value: string | null]> =
      Object.keys({
        ...localStorage,
      })
        .filter((key) => key.startsWith("runway."))
        .map((key) => [key, localStorage.getItem(key)]);

    // AWS Cognito SDK does not automatically clear out the JWT
    // tokens that are stored in local storage when signout is
    // called.  We manually clear all local storage to avoid issues
    // where a user logs out and then a new user logs in with a
    // different JWT while the old one is still cached.
    localStorage.clear();

    // Add feature flags
    runwayFeatureFlages.forEach(([key, value]) => {
      localStorage.setItem(key, value ?? "");
    });

    // This needs to be reset so other users can log in
    _currentUserId = undefined;

    res();
  });
};

const createNewPasswordChallenge = (
  user: CognitoUser,
  given_name: string,
  family_name: string,
  password: string,
): Promise<AuthenticationResult> => {
  return new Promise<AuthenticationResult>((res, reject) => {
    // This allows the user to enter their first and last name when registering
    const userAttributes = {
      given_name: given_name,
      family_name: family_name,
    };

    user.completeNewPasswordChallenge(password, userAttributes, {
      onSuccess: () => {
        clearChangePasswordState();
        Users.register().then(() => {
          res({ success: true });
        });
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });
};

// This method is used in both the 'ForgotPasswordPage' and 'ForgotPasswordEmailSentPage'
// If used in the email sent page then this is used to resend the password reset email, meaning there isn't an email being passed to this directly
const sendPasswordResetEmail = (
  email = "",
  clientMetadata?: ClientMetadata,
): Promise<AuthenticationResult> => {
  if (!process.env.REACT_APP_COGNITO_CLIENT_ID) {
    throw new Error("login: clientId not defined");
  }

  if (!process.env.REACT_APP_COGNITO_USER_POOL_ID) {
    throw new Error("login: cognito user pool id not defined");
  }

  if (!process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID) {
    throw new Error("login: cognito identity pool not defined");
  }

  if (!process.env.REACT_APP_AWS_REGION) {
    throw new Error("login: region not defined");
  }

  const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID;
  const cognitoUserPoolId = process.env.REACT_APP_COGNITO_USER_POOL_ID;

  return new Promise<AuthenticationResult>((res, reject) => {
    const poolData = {
      UserPoolId: cognitoUserPoolId,
      ClientId: clientId,
    };

    const userPool = new CognitoUserPool(poolData);

    let cognitoUser: CognitoUser;

    // Not checking if 'cognitoUser' is null or not here because 'cognitoUser' is not nullable in this case
    // This is because I'm not using 'getCurrentUser()' which returns a nullable CognitoUser
    if (email !== "") {
      // This will be the case when calling this method from the 'ForgotPasswordPage'
      cognitoUser = new CognitoUser({
        Username: email,
        Pool: userPool,
      });

      cognitoUser.forgotPassword(
        {
          onSuccess: () => {
            _emailForPasswordReset = email;
            res({ success: true });
          },
          onFailure: (err) => {
            reject(err);
          },
        },
        clientMetadata,
      );
    } else {
      // When resending the email, will not take in the user's email as a parameter
      cognitoUser = new CognitoUser({
        Username: _emailForPasswordReset,
        Pool: userPool,
      });

      cognitoUser.forgotPassword(
        {
          onSuccess: () => {
            res({ success: true });
          },
          onFailure: (err) => {
            reject(err);
          },
        },
        clientMetadata,
      );
    }
  });
};

const resetUserPassword = (
  code: string,
  newPassword: string,
): Promise<AuthenticationResult> => {
  if (!process.env.REACT_APP_COGNITO_CLIENT_ID) {
    throw new Error("login: clientId not defined");
  }

  if (!process.env.REACT_APP_COGNITO_USER_POOL_ID) {
    throw new Error("login: cognito user pool id not defined");
  }

  if (!process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID) {
    throw new Error("login: cognito identity pool not defined");
  }

  if (!process.env.REACT_APP_AWS_REGION) {
    throw new Error("login: region not defined");
  }

  const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID;
  const cognitoUserPoolId = process.env.REACT_APP_COGNITO_USER_POOL_ID;

  return new Promise<AuthenticationResult>((res, reject) => {
    const poolData = {
      UserPoolId: cognitoUserPoolId,
      ClientId: clientId,
    };

    const userPool = new CognitoUserPool(poolData);

    // Grabbing the stored email, want to store this in local storage probably tho, or have the user re-type their email in
    const cognitoUser = new CognitoUser({
      Username: _emailForPasswordReset,
      Pool: userPool,
    });

    cognitoUser.confirmPassword(code, newPassword, {
      onSuccess() {
        _emailForPasswordReset = "";
        _codeForPasswordReset = "";
        res({ success: true });
      },
      onFailure(err) {
        reject(err);
      },
    });
  });
};

const authenticateUserWithAuthCodeGrant = async (
  authCodeGrant: string,
  authStateValue: string,
  authenticationFlowType: IdentityProviderFlow,
) => {
  authenticationFlowType;
  const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID;
  const cognitoUserPoolId = process.env.REACT_APP_COGNITO_USER_POOL_ID;
  const cognitoIdentityPoolId = process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID;
  const region = process.env.REACT_APP_AWS_REGION;
  const cognitoDomain = process.env.REACT_APP_COGNITO_DOMAIN;
  const redirectUri = process.env.REACT_APP_REDIRECT_GOOGLE_AUTH_URI;
  const targetAuthStateValue = process.env.REACT_APP_STATE_VALUE;

  if (!clientId) {
    throw new Error("IdP login: clientId not defined");
  }

  if (!cognitoUserPoolId) {
    throw new Error("IdP login: cognito user pool id not defined");
  }

  if (!cognitoIdentityPoolId) {
    throw new Error("IdP login: cognito identity pool id not defined");
  }

  if (!targetAuthStateValue) {
    throw new Error("federated login: state value not defined");
  }

  if (!cognitoDomain) {
    throw new Error("federated login: cognito domain not defined");
  }

  if (!redirectUri) {
    throw new Error("federated login: google auth redirect uri not defined");
  }

  AWS.config.region = region;

  // helps prevent CSRF attacks
  if (authStateValue !== targetAuthStateValue) {
    return;
  }

  // hit '/oauth2/token' endpoint to exchange auth code grant for id/access/refresh tokens
  const result = await superagent
    .post(
      `https://${cognitoDomain}.auth.us-east-2.amazoncognito.com/oauth2/token`,
    )
    .set("Content-Type", "application/x-www-form-urlencoded")
    .send({
      grant_type: "authorization_code",
      client_id: clientId,
      code: authCodeGrant,
      redirect_uri: redirectUri,
    });

  const response = result.body as AuthenticationCodeGrantType;

  return new Promise((resolve, reject) => {
    // create the user session if authentication was successful
    const poolData = {
      UserPoolId: cognitoUserPoolId,
      ClientId: clientId,
    };
    const userPool = new CognitoUserPool(poolData);

    const accessToken = new CognitoAccessToken({
      AccessToken: response.access_token,
    });

    const idToken = new CognitoIdToken({
      IdToken: response.id_token,
    });
    const idTokenPayload = idToken.decodePayload();

    const refreshToken = new CognitoRefreshToken({
      RefreshToken: response.refresh_token,
    });

    const sessionData = {
      IdToken: idToken,
      AccessToken: accessToken,
      RefreshToken: refreshToken,
    };
    const userSession = new CognitoUserSession(sessionData);

    const userData = {
      Username: idTokenPayload.email as string,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.setSignInUserSession(userSession);

    tokens.accessToken = response.access_token;
    tokens.refreshToken = response.refresh_token;
    _isUser = true;

    Users.get(currentUserId()).then(
      () => {
        resolve({
          success: true,
        });
      },
      (err) => {
        console.error(err);
        Users.register().then(() => {
          resolve({
            success: true,
          });
        }, reject);
      },
    );
  });
};

// Note: Possibly extract the 'process.env' checks and assignments into it's own function to reduce code smells?

// Overrides the access token in the case of a share token
// or api key being used to access the frontend
const setAccessToken = (accessToken: string) => {
  tokens.accessToken = accessToken;
  tokens.refreshToken = accessToken;
  const jwt = decodeJwt(tokens.accessToken);
  _currentUserId = jwt.sub;
  _isUser = jwt.type !== "SHARE_KEY";
};

export {
  tokens,
  authenticated,
  isUser,
  authenticateUserWithAuthCodeGrant,
  currentUserId,
  federatedAuthroizationEndpoint,
  federatedLogoutEndpoint,
  login,
  refreshLogin,
  logout,
  getChangePasswordState,
  clearChangePasswordState,
  createNewPasswordChallenge,
  sendPasswordResetEmail,
  setEmailAndCodeForPasswordReset,
  getEmailAndCodeForPasswordReset,
  resetUserPassword,
  setAccessToken,
};
