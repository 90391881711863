import {
  Autocomplete,
  Box,
  Button,
  Paper,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import Dialog from "../../../components/Dialog";
import { ProcessCategory } from "../../../model/ProcessCategory";
import { FC, useEffect, useState } from "react";
import { common } from "@mui/material/colors";
import { Add } from "@mui/icons-material";
import { useAlert } from "../../../lib/alert";

type CategoryOption = {
  id: string;
  label?: string;
};

const OPTIONS_LIMIT = 10;
const filterOptions = createFilterOptions<CategoryOption>({
  limit: OPTIONS_LIMIT,
});

type ProcessCategorySelectType = {
  categories: ProcessCategory[];
  defaultCategory?: ProcessCategory;
  onChange: (category: ProcessCategory) => void;
  onInputChange?: (event: React.SyntheticEvent, newValue: string) => void;
  createNew?: (
    categoryLabel: string,
    parentCategoryId?: string,
  ) => Promise<boolean | undefined>;
  autocompleteLabel?: string;
};

export const ProcessCategorySelect: FC<ProcessCategorySelectType> = ({
  categories,
  defaultCategory,
  onChange,
  onInputChange,
  createNew,
  autocompleteLabel,
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [options, setOptions] = useState<CategoryOption[]>([]);
  const [currentOption, setCurrentOption] = useState<CategoryOption | null>(
    null,
  );
  const [creationDialogOpen, setCreationDialogOpen] = useState(false);
  const [createdProcessName, setCreatedProcessName] = useState("");
  const [createdProcessParent, setCreatedProcessParent] =
    useState<ProcessCategory>();
  const { error } = useAlert();

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: CategoryOption | null,
  ) => {
    const selectedCategory = categories.find(
      (category) => category.label === newValue?.label,
    );
    if (selectedCategory) onChange(selectedCategory);
  };

  useEffect(() => {
    setInputValue(defaultCategory?.label ?? "");
    setCurrentOption((defaultCategory as CategoryOption) ?? null);
  }, [defaultCategory]);

  useEffect(() => {
    setOptions(categories);
  }, [categories]);

  return (
    <>
      <Autocomplete
        options={options}
        filterOptions={filterOptions}
        renderInput={(params) => (
          <TextField
            placeholder={"Start typing..."}
            {...params}
            label={autocompleteLabel ?? "Category"}
            variant="standard"
          />
        )}
        value={currentOption}
        onInputChange={(event, value) => {
          onInputChange && onInputChange(event, value);
          setInputValue(value);
        }}
        onChange={handleChange}
        getOptionLabel={(option) => option.label ?? option.id}
        PaperComponent={(props) => (
          <Paper sx={{ bgcolor: common.white }}>
            <Box {...props} />
            <Box sx={{ display: "flex" }}>
              {inputValue && createNew && (
                <Button
                  variant="outlined"
                  fullWidth
                  startIcon={<Add />}
                  sx={{
                    m: 1,
                    textTransform: "unset",
                  }}
                  onMouseDown={() => {
                    if (inputValue) {
                      setCreatedProcessName(inputValue);
                      setCreationDialogOpen(true);
                    }
                  }}
                >
                  <Typography variant="body2">
                    {`Create ${inputValue} Category`}
                  </Typography>
                </Button>
              )}
            </Box>
          </Paper>
        )}
        isOptionEqualToValue={(option, value) => option.label === value.label}
      />
      <Dialog
        open={creationDialogOpen}
        title={"Create New Process Category"}
        fullWidth={true}
        confirmText={"Create"}
        cancelText={"Cancel"}
        handleConfirm={async () => {
          createNew!(createdProcessName, createdProcessParent?.id).then(
            (successful) => {
              if (!successful) {
                error(`Category "${createdProcessName}" could not be created`);
              } else {
                setCreationDialogOpen(false);
              }
            },
          );
        }}
        handleCancel={() => setCreationDialogOpen(false)}
        maxWidth="xs"
      >
        <Box display="flex" flexDirection="column" sx={{ gap: "16px" }}>
          <TextField
            autoFocus
            margin="dense"
            id="category-name"
            label={`Category Name`}
            type="text"
            fullWidth
            variant="standard"
            value={createdProcessName}
            onChange={(e) => setCreatedProcessName(e.target.value as string)}
          />
          <ProcessCategorySelect
            categories={categories}
            defaultCategory={createdProcessParent}
            onChange={(category) => setCreatedProcessParent(category)}
            autocompleteLabel={"Parent Category"}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default ProcessCategorySelect;
