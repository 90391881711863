import { Box, IconButton, SxProps } from "@mui/material";
import { Send } from "@mui/icons-material";
import { useCallback, useEffect, useState } from "react";
import { useGlobalUserContext } from "../../hooks/useGlobalUserContext";
import Message from "./Message";
import { ChatDto, EmailChatMessage } from "./model";
import { v4 as uuid } from "uuid";
import { ChatAPI, FileAPI } from "../../api";
import scrubTextEmails from "./scrubTextEmails";
import ReactQuillEditor from "../../components/core/ReactQuill/ReactQuillEditor";
import Dropzone from "../../components/core/Dropzone/Dropzone";
import { useAtom } from "jotai";
import onDropAtom from "../../components/core/atoms/onDropAtom";
import {
  FileUploadResponse,
  MAX_SIZE_MB,
} from "../../transfer/FileUploadResponse";
import DownloadableAttachment from "./DownloadableAttachment";
import { UploadFileComponent } from "../../components/core/UploadFile";
import PdfViewer from "../../components/core/PdfViewer/PdfViewer";
import { useAlert } from "../../lib/alert";
import ProcessChip from "../process/components/ProcessChip";
import { useNavigate } from "react-router";
import PATHS from "../../components/navigation/_paths";

export type ChatProps = {
  threadId: string;
  chat?: ChatDto;
  sx?: SxProps;
  showExecutions?: boolean;
};

const Chat = ({ threadId, sx, chat, showExecutions = false }: ChatProps) => {
  const navigate = useNavigate();
  const { error } = useAlert();
  const [onDrop] = useAtom(onDropAtom);
  const { user } = useGlobalUserContext();
  const [messages, setMessages] = useState<EmailChatMessage[]>([]);
  const [message, setMessage] = useState("");
  const [attachments, setAttachments] = useState<FileUploadResponse[]>([]);

  // When we send a chat message, we need to reset not just the message
  // that we are passing into the react quill component, but the entire
  // component including all internal state that may be managed
  // by the component.  Therefore we need to use a reset key to
  // force this re-render.
  const [resetKey, setResetKey] = useState(0);

  useEffect(() => {
    ChatAPI.getThreadMessages(threadId).then(
      (threadMessages) => {
        const scrubbedThreadMessages = scrubTextEmails(threadMessages);
        setMessages(scrubbedThreadMessages);
      },
      () => {
        // TODO - display warning in chat
      },
    );
  }, [threadId]);

  const sendMessage = useCallback(() => {
    const messageToSend = {
      id: uuid(),
      user_id: user.id,
      data: {
        text: message,
        attachments: attachments,
      },
    };
    setMessages((messages) => [...messages, messageToSend]);
    setMessage("");
    setResetKey((resetKey) => resetKey + 1);
    setAttachments([]);
    ChatAPI.sendMessage(threadId, messageToSend).then(
      () => {},
      () => {
        // Display warning when message can't be sent
        // Remove sent message from chat
        setMessages((messages) =>
          messages.filter((m) => m.id !== messageToSend.id),
        );
        setMessage(messageToSend.data.text);
        setAttachments(messageToSend.data.attachments);
      },
    );
  }, [message, threadId, user.id, attachments]);
  return (
    <Dropzone
      id="chat"
      display="flex"
      flexDirection="column"
      height="100%"
      padding={2}
      margin={0}
      variant="subtle"
      onDrop={(e) => {
        onDrop && onDrop(e);
      }}
    >
      <Box flex={1} overflow="auto">
        <Box>
          {messages.map((msg, index) => (
            <Message
              key={msg.id}
              message={msg}
              messageOnly={
                index > 0 && messages[index - 1].user_id === msg.user_id
              }
            />
          ))}
        </Box>
      </Box>
      {showExecutions && !!chat?.process_id && !!chat?.process_name && (
        <Box flex={0} py={1}>
          <ProcessChip
            icon={chat.process_icon}
            name={chat.process_name}
            onClick={() => {
              navigate(
                PATHS.EXECUTION.linkTo(
                  chat.process_id ?? "missing",
                  chat.process_execution_id ?? "missing",
                ),
              );
            }}
          />
        </Box>
      )}
      <Box flex={0} position={"relative"} sx={sx}>
        <ReactQuillEditor
          id="chat-message-field"
          borderRadius={8}
          value={message}
          onChange={(message) => {
            setMessage(message);
          }}
          key={resetKey}
          onAttachment={async (files) => {
            if (!files) {
              return;
            }

            const fileSizeMb = files[0].size / 1024 ** 2;
            if (fileSizeMb > MAX_SIZE_MB) {
              error("File size must be less than 8MB");
              return;
            }

            try {
              const value = await FileAPI.uploadFile(files);
              const keyedFiles = value.map((fileUploadResponse) => {
                setAttachments((attachments) => [
                  ...attachments,
                  fileUploadResponse,
                ]);
              });

              return keyedFiles[0];
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (err: any) {
              console.error(`Error uploading file: ${err}`);
              error("Error uploading file");
            }
          }}
        ></ReactQuillEditor>
        <IconButton
          color="primary"
          sx={{ position: "absolute", right: 0, bottom: 0 }}
          onClick={sendMessage}
        >
          <Send />
        </IconButton>
      </Box>
      <Box flex={0} mt={1}>
        {attachments.length > 0 && (
          <UploadFileComponent
            renderer={(file) => {
              return file.contentType?.includes("pdf") ? (
                <PdfViewer
                  source={`${
                    process.env.REACT_APP_REST_API
                  }/files/${encodeURIComponent(file.key)}`}
                  scale={0.25}
                />
              ) : (
                <DownloadableAttachment file={file} />
              );
            }}
            onRemove={(file) => {
              const index = attachments.findIndex((f) => f.key === file.key);
              if (index > -1) {
                setAttachments((attachments) => {
                  const newAttachments = [...attachments];
                  newAttachments.splice(index, 1);
                  return newAttachments;
                });
              }
            }}
            disabled={false}
            files={attachments}
          />
        )}
      </Box>
    </Dropzone>
  );
};
export default Chat;
