import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { TaskAPI } from "../../../api";
import { useDisplayOptions } from "../../../hooks/useDisplayOptions";
import { Task, TaskType, TaskForm } from "../types";
import FormBuilder from "./form-builder/FormBuilder";
import { FormBuilderProvider } from "./form-builder/FormBuilderProvider";

type TaskPageParams = {
  processId: string;
  taskId: string;
  elementId?: string;
  key?: string;
};

const TaskPage = () => {
  useDisplayOptions({ showDrawer: false });

  const { processId, taskId, elementId, key } = useParams<TaskPageParams>();
  const [task, setTask] = useState<Task>();
  const [error, setError] = useState(false);

  useEffect(() => {
    if (processId && taskId) {
      TaskAPI.get(processId, taskId)
        .then((task) => setTask(task))
        .catch(() => {
          setError(true);
          setTask(undefined);
        });
    } else {
      setError(true);
    }
  }, [processId, taskId, elementId]);

  if (error) {
    return <Navigate to={"/"}></Navigate>;
  }
  if (!task) {
    return <CircularProgress />;
  }
  if (task.type != TaskType.Form && task.type != TaskType.Check) {
    <Navigate to={"/"}></Navigate>;
  }

  return (
    <Box
      component={"section"}
      sx={{
        display: "flex",
        height: "100%",
        overflow: "auto",
        backgroundColor: (theme) => theme.palette.background.default,
      }}
    >
      <FormBuilderProvider
        task={task as TaskForm}
        elementId={elementId}
        elementKey={key}
      >
        <FormBuilder />
      </FormBuilderProvider>
    </Box>
  );
};

export default TaskPage;
