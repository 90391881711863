import Role from "./Role";

export default class User {
  public id = "";
  public email = "";
  public first_name = "";
  public last_name = "";
  public profile_picture = "";
  public roles?: Array<Role>;
}
