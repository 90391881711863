import { useEffect, useMemo, useState } from "react";
import {
  Autocomplete,
  AutocompleteProps,
  CircularProgress,
  Grid,
} from "@mui/material";
import debounce from "lodash.debounce";

export type SelectionProps<
  OptionType,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> = {
  dynamicFilter?: (filter?: string) => Promise<void>;
  AutoCompleteProps: AutocompleteProps<
    OptionType,
    Multiple,
    DisableClearable,
    FreeSolo
  >;
};

export const Selection = <
  OptionType,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  dynamicFilter,
  AutoCompleteProps,
}: SelectionProps<OptionType, Multiple, DisableClearable, FreeSolo>) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");

  const dynamicFilterOptions = (input: string) => {
    if (dynamicFilter) {
      setLoading(true);
      const filterDebounce = debounce(async () => {
        return await dynamicFilter(input).finally(() => setLoading(false));
      }, 500);
      filterDebounce();
    }
  };

  useEffect(() => {
    setInputValue(AutoCompleteProps.inputValue ?? "");
  }, [AutoCompleteProps.inputValue]);

  const forwardedProps = useMemo(() => {
    if (AutoCompleteProps.multiple === false) {
      delete AutoCompleteProps.multiple;
    }
    return AutoCompleteProps;
  }, [AutoCompleteProps]);

  const autocompleteOptions = useMemo(() => {
    if (loading) {
      return [];
    }
    return forwardedProps.options;
  }, [loading, forwardedProps.options]);

  const autocompleteLoading = useMemo(() => {
    if (loading) {
      return true;
    }
    return forwardedProps.loading;
  }, [loading, forwardedProps.loading]);

  const loadingText = useMemo(() => {
    if (AutoCompleteProps.loadingText) {
      return AutoCompleteProps.loadingText;
    }
    return (
      <Grid container sx={{ justifyContent: "space-between" }}>
        {"Loading..."}
        <CircularProgress size={"20px"} />
      </Grid>
    );
  }, [AutoCompleteProps.loadingText]);

  return (
    <Autocomplete
      {...forwardedProps}
      options={autocompleteOptions}
      loading={autocompleteLoading}
      loadingText={loadingText}
      inputValue={inputValue}
      onInputChange={(event, value, reason) => {
        if (!event) return;
        setInputValue(value);
        AutoCompleteProps.onInputChange &&
          AutoCompleteProps.onInputChange(event, value, reason);

        dynamicFilterOptions(value);
      }}
    />
  );
};

export default Selection;
