import { Grid, TextField } from "@mui/material";
import { Task, TaskSideSectionEditorProps } from "../../../../types";
import { useTaskContext } from "../../../../hooks";

const StripeCheckoutSideSectionEditor = ({
  task,
}: TaskSideSectionEditorProps<Task>) => {
  const taskContext = useTaskContext();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data = task.data as any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const executionData = task.execution_data as any;

  return (
    <>
      <Grid item xs={12}>
        <TextField
          fullWidth
          placeholder="Webhook URL"
          value={data?.url ?? ""}
          label={"Webhook URL"}
          InputLabelProps={{ shrink: true }}
          onChange={(event) => {
            taskContext.setValue({
              data: {
                ...data,
                url: event.target.value,
              },
            });
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          placeholder="Customer ID"
          value={executionData?.customerId ?? ""}
          label={"Customer ID"}
          InputLabelProps={{ shrink: true }}
          onChange={(event) => {
            taskContext.setValue({
              execution_data: {
                ...executionData,
                customerId: event.target.value,
              },
            });
          }}
        />
      </Grid>
    </>
  );
};

export default StripeCheckoutSideSectionEditor;
