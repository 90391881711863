import { Box, Grid, Button, Typography, Chip } from "@mui/material";
import { useState, useEffect } from "react";
import roles from "../../../api/roles";
import invitations from "../../../api/invitations";
import { useAlert } from "../../../lib/alert";
import { Role, User } from "../../../model";
import { UserSelectionByRole } from "../Selection";
import { UserOrInvitedUser } from "../Selection/UserSelection";
import Dialog from "../../Dialog";
import getUserFullName from "../../../features/activity-log/utils/getUserFullName";

export type UserAssignmentStruct = {
  targetRoleId: string;
  assignees: string[];
};

export type UserAssignmentProps = {
  targetRoleId: string;
  isDisabled?: boolean;
  onExecute: (userAssignment: UserAssignmentStruct) => void;
  selectedUsers?: Array<
    Partial<UserOrInvitedUser> & { id: string; hasTargetRoleId: boolean }
  >;
  assignees?: string[];
};

export const doesUserHaveTargetRole = (user: User, targetRoleId: string) => {
  return !!user.roles?.filter((role) => role.id === targetRoleId).length;
};

const UserAssignment = ({
  targetRoleId,
  isDisabled,
  onExecute,
  ...props
}: UserAssignmentProps) => {
  const { error } = useAlert();

  const [openRoleConfirmationDialog, setOpenRoleConfirmationDialog] =
    useState(false);
  const [assignees, setAssignees] = useState<string[]>();

  const [selectedUsers, setSelectedUsers] = useState<
    Array<Partial<UserOrInvitedUser> & { id: string; hasTargetRoleId: boolean }>
  >([]);

  const [targetRole, setTargetRole] = useState<Role>();

  const assignUsers = async () => {
    // Invite all users to have role
    await Promise.all(
      selectedUsers
        .filter((user) => !user.hasTargetRoleId)
        .map((user) => {
          if (user.invitationId) {
            return invitations.assignRole(targetRoleId, user.invitationId);
          } else if (user.id) {
            return roles.assignRole(targetRoleId, user.id);
          }
        }),
    );

    // create execution
    try {
      onExecute?.({
        targetRoleId,
        assignees: selectedUsers.map((user) => user.id),
      });

      // Error should be of type any so that it can be used directly in the error
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      error(err.toString());
    }
  };

  useEffect(() => {
    roles
      .get(targetRoleId)
      .then((role) => {
        setTargetRole(role);
      })
      .catch((error) => {
        console.error(`Could not get role ${targetRoleId}`, error);
      });
  }, [targetRoleId]);

  useEffect(() => {
    setSelectedUsers(props.selectedUsers ?? []);
  }, [props.selectedUsers]);

  useEffect(() => {
    setAssignees(props.assignees ?? []);
  }, [props.assignees]);

  return (
    <>
      <Box>
        <Grid item xs={8}>
          <UserSelectionByRole
            values={assignees ?? []}
            targetRoleId={targetRoleId}
            disabled={isDisabled}
            onChange={(users) => {
              setAssignees(users.map((user) => user.id));
              setSelectedUsers(
                users.map((user) => ({
                  ...user,
                  invitationId: user.invitationId,
                  hasTargetRoleId: doesUserHaveTargetRole(user, targetRoleId),
                })),
              );
            }}
            onInvite={(invitation) => {
              setAssignees((prev) => [
                ...(prev ?? []),
                invitation.target_user_id,
              ]);
              setSelectedUsers((prev) => [
                ...(prev ?? []),
                {
                  id: invitation.target_user_id,
                  email: invitation.target_user_email,
                  invitationId: invitation.id,
                  hasTargetRoleId:
                    (invitation.roles &&
                      invitation.roles.some(
                        (role) => targetRoleId == role.id,
                      )) ??
                    false,
                },
              ]);
            }}
          />
        </Grid>
        <Box sx={{ padding: 1 }}>
          {!isDisabled && (
            <Button
              variant="contained"
              size="small"
              sx={{ textTransform: "unset" }}
              onClick={() => {
                if (
                  selectedUsers.filter((user) => !user.hasTargetRoleId).length >
                  0
                ) {
                  setOpenRoleConfirmationDialog(true);
                } else {
                  assignUsers();
                }
              }}
            >
              Submit
            </Button>
          )}
        </Box>
      </Box>
      <Dialog
        maxWidth={"xs"}
        open={openRoleConfirmationDialog}
        title={
          <Typography fontWeight={700} variant="h3">
            {"Assign New Role"}
          </Typography>
        }
        confirmText={"Assign"}
        cancelText={"Cancel"}
        handleConfirm={assignUsers}
        handleCancel={() => {
          setOpenRoleConfirmationDialog(false);
        }}
      >
        {`The following users do not have ${
          targetRole?.name ?? "the target"
        } role. `}

        {/* <Typography fontWeight={700}> */}
        <strong>{`Are you sure you want to assign ${
          targetRole?.name ?? "the"
        } role to these users?`}</strong>
        {/* </Typography> */}

        <Grid container spacing={1} sx={{ mt: 0.5 }}>
          {selectedUsers
            .filter((user) => !user.hasTargetRoleId)
            .map((user) => {
              return (
                <Grid key={user.id} item>
                  <Chip label={getUserFullName(user as User, true)} />
                </Grid>
              );
            })}
        </Grid>
      </Dialog>
    </>
  );
};

export default UserAssignment;
