import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SxProps,
  Theme,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import PATHS from "../../components/navigation/_paths";

type NavLinkProps = React.PropsWithChildren<{
  dataCy?: string;
  title: string;
  titleIntl?: JSX.Element;
  link?: string;
  onClick?: () => void;
  icon?: JSX.Element;
  sx?: SxProps<Theme>;
  selectedOn?: LinkSelectedOn;
}>;

enum LinkSelectedOn {
  MATCH,
  START,
}

const NavLink = ({
  dataCy,
  title,
  titleIntl,
  link,
  icon,
  children,
  sx,
  onClick,
  selectedOn = LinkSelectedOn.START,
}: NavLinkProps) => {
  const { pathname } = useLocation();
  const linkProps = link
    ? {
        to: link,
        component: Link,
        selected:
          selectedOn === LinkSelectedOn.START
            ? pathname.startsWith(link)
            : pathname === link,
      }
    : {};
  return (
    <ListItemButton
      data-cy={dataCy}
      key={title}
      component={Link}
      onClick={onClick}
      sx={sx}
      {...linkProps}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText
        primary={titleIntl ?? title}
        sx={{
          "& .MuiTypography-root": { fontWeight: 600, lineHeight: "24px" },
        }}
      />
      {children}
    </ListItemButton>
  );
};

const ProfileDrawerMenu = () => {
  return (
    <Box
      data-cy={"side-drawer"}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "240px",
        height: "100%",
        displayPrint: "none",
        overflowY: "auto",
        px: 2,
        py: 1,
      }}
    >
      <h1 style={{ marginLeft: "16px" }}>Profile</h1>
      <List
        component="nav"
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          gap: 0.5,
          "& .MuiListItemButton-root": {
            borderRadius: 2,
          },
        }}
      >
        <NavLink
          dataCy="profile-preferences-btn"
          sx={{ flex: 0 }}
          {...PATHS.PROFILE_PREFERENCES}
        />
        <NavLink
          dataCy="profile-organizations-btn"
          sx={{ flex: 0 }}
          {...PATHS.PROFILE_ORGANIZATIONS}
          selectedOn={LinkSelectedOn.MATCH}
        />
        {/*Possibly add a Profile Settings button that allows for
           easy editing of name and profile picture*/}
      </List>
    </Box>
  );
};
export default ProfileDrawerMenu;
