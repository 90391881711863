import { Typography } from "@mui/material";

const CalendlyEventSideSectionEditor = () => {
  return (
    <>
      <Typography>Task Calendly Event Placeholder</Typography>
    </>
  );
};

export default CalendlyEventSideSectionEditor;
