import { Box, Step, StepLabel, Stepper } from "@mui/material";
import { SideDetailsContainer } from "../process/components/side-details";
import { FC } from "react";
import OrganizationLogo from "../organization/components/OrganizationLogo";
import { useGlobalOrganizationContext } from "../../hooks/useGlobalOrganizationContext";

const OnboardingSidebar: FC<{
  activeStep: number;
}> = ({ activeStep }) => {
  const { organization } = useGlobalOrganizationContext();
  return (
    <SideDetailsContainer
      sx={{ height: "100%", bgcolor: "white", maxWidth: "auto" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Box sx={{ mt: "10%" }}>
          <OrganizationLogo organization={organization} height={100} />
        </Box>
        <Stepper
          orientation="vertical"
          sx={{
            width: "calc(50%/1.15)",
            mt: "20%",
            scale: "1.15",
          }}
          activeStep={activeStep}
        >
          <Step>
            <StepLabel>Create Account/Login</StepLabel>
          </Step>
          <Step>
            <StepLabel>Accept Invitation</StepLabel>
          </Step>
          <Step>
            <StepLabel>Finish</StepLabel>
          </Step>
        </Stepper>
      </Box>
    </SideDetailsContainer>
  );
};

export default OnboardingSidebar;
