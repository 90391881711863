import { FC, lazy, Suspense } from "react";
import { Link, Button, Typography, Box, SvgIcon } from "@mui/material";
import { grey } from "@mui/material/colors";
import messages from "./messages";
import { environment } from "../../util";
import PATHS from "../../components/navigation/_paths";

const DynamicIcon = lazy(() => import("../../components/core/DynamicIcon"));
const NotFound: FC<unknown> = () => {
  const icon =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    environment.theme?.icons && "pageNotFound" in environment.theme!.icons
      ? environment.theme?.icons["pageNotFound"]
      : "Flight";
  return (
    <Box sx={{ display: "flex", flexDirection: "row", mt: 18 }}>
      <Box
        sx={{
          mx: 4,
          flex: 1,
          textAlign: "right",
          fontSize: "216px",
          fontWeight: 600,
        }}
      >
        404
      </Box>
      <Box sx={{ mx: 4, flex: 1 }}>
        <Box sx={{ maxWidth: "300px" }}>
          <Suspense fallback={<SvgIcon sx={{ fontSize: "32px" }} />}>
            <DynamicIcon
              name={icon}
              sx={{ fontSize: "32px", transform: "rotate(90deg)" }}
            />
          </Suspense>
          <Typography
            variant="h1"
            component="h1"
            sx={{ color: "primary.main", fontWeight: 600 }}
          >
            {messages.pageNotFoundTitle}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              my: 2,
              fontWeight: "600",
              fontSize: "16px",
            }}
          >
            {messages.pageNotFoundDescription}
          </Typography>
          <Box
            sx={{
              my: 4,
              border: "1px",
              borderColor: grey[200],
              borderRadius: 1,
              height: "3px",
              width: "80px",
              backgroundColor: grey[200],
            }}
          />
          <Link
            href={PATHS.ORGANIZATION_EXECUTIONS.link}
            underline="none"
            sx={{ color: "common.white" }}
          >
            <Button variant="contained">Back Home</Button>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default NotFound;
