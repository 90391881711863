import { FormattedMessage } from "react-intl";

/**
 *  All common messages for the accounts feature.
 *
 *  @see {@link ../../../docs/intl.md | Internationalization Code Style Guidelines}
 */
export default {
  account: {
    program: (
      <FormattedMessage
        id="account.program"
        defaultMessage="Program"
        description="Label for the program tab."
      />
    ),
  },
};
