import { FC, useCallback, useEffect, useState } from "react";
import { Button, CircularProgress, Grid } from "@mui/material";
import { PageContainer, PageHeader } from "../../components/layout/page";
import DashboardOrganizationCard from "./DashboardOrganizationCard";
import { Organization } from "../../model";
import Invitation from "../../model/Invitation";
import {
  InvitationAPI,
  OrganizationAPI,
  ProcessExecutionAPI,
  UserAPI,
} from "../../api";
import { useAlert } from "../../lib/alert";
import {
  TaskAcceptInvitationExecution,
  TaskExecutionState,
  TaskType,
} from "../task";
import { ProcessExecution } from "../../model/Process";
import { currentUserId } from "../../lib/auth";
import { useGlobalOrganizationContext } from "../../hooks/useGlobalOrganizationContext";
import DeleteOrganizationDialog from "../../components/dialogs/DeleteOrganizationDialog";
import { Delete } from "@mui/icons-material";

const OrganizationInvitationsPage: FC = () => {
  const { refreshOrganizations } = useGlobalOrganizationContext();

  const [userOrganizations, setUserOrganizations] = useState<Organization[]>(
    [],
  );
  const [userInvitations, setUserInvitations] = useState<Invitation[]>([]);
  const [deleteOrganizationDialogOpen, setDeleteOrganizationDialogOpen] =
    useState<boolean>(false);
  const [organizationToDelete, setOrganizationToDelete] =
    useState<Organization>();

  const { success, error } = useAlert();

  useEffect(() => {
    Promise.all([
      OrganizationAPI.byMemberOrAccountUser(currentUserId()),
      InvitationAPI.byTargetUserId(currentUserId()),
    ]).then(([organizations, invitations]) => {
      setUserOrganizations(organizations);
      setUserInvitations(invitations);
    });
  }, [error]);

  const getTaskExecution = (
    execution: ProcessExecution,
    invitationId: string,
  ): TaskAcceptInvitationExecution => {
    return {
      ...execution,
      task_id: execution.next_task_id,
      state: TaskExecutionState.Completed,
      data: {
        accept: true,
        invitationId: invitationId,
      },
    };
  };

  const acceptInvitationHandle = async (invitation: Invitation) => {
    // Accepts the invitation
    await InvitationAPI.accept(invitation.id).catch(() => {
      error("An error occured trying to accept this invitation");
      return;
    });

    // Since the user is now a member of the organization, they can query the organization
    const organization = await OrganizationAPI.get(invitation.organization_id);

    // if an execution exists with a task where the user must accept
    // an invitation, complete that task
    const pagedResponse = await ProcessExecutionAPI.byOrganizationId(
      organization.id,
    );
    const executions = pagedResponse.data;

    if (executions) {
      const executionToAdvance = executions.find((execution) => {
        const nextTask = execution.next_task_instance;
        if (nextTask && nextTask?.type === TaskType.AcceptInvitation) {
          return execution;
        }
      });

      // if an execution within this organization was found, complete the next task
      if (executionToAdvance) {
        ProcessExecutionAPI.submitTask(
          executionToAdvance.id,
          executionToAdvance.next_task_id,
          getTaskExecution(executionToAdvance, invitation.id),
        ).then(() => {
          ProcessExecutionAPI.completeTask(
            executionToAdvance.id,
            executionToAdvance.next_task_id,
          );
        });
      }

      success(`You have successfully joined ${organization.name}.`);
      refreshOrganizations();

      // remove the invitation from the invite list and to the org list
      const newInvitations = userInvitations.slice();
      const invitationLocation = newInvitations.indexOf(invitation);
      if (invitationLocation != -1) {
        newInvitations.splice(invitationLocation, 1);
      }

      const newOrganizations = userOrganizations.slice();
      newOrganizations.push(organization);

      setUserInvitations(newInvitations);
      setUserOrganizations(newOrganizations);
    }
  };

  const leaveOrganizationHandle = useCallback(
    (organization: Organization) => {
      UserAPI.leaveOrganization(currentUserId(), organization.id)
        .then(() => {
          success(`You have successfully left ${organization.name}.`);

          const newOrganizations = userOrganizations.slice();
          const organizationLocation = newOrganizations.indexOf(organization);
          if (organizationLocation != -1) {
            newOrganizations.splice(organizationLocation, 1);
          }

          setUserOrganizations(newOrganizations);
          refreshOrganizations();
        })
        .catch(() => {
          error("An error occured while trying to leave this organization.");
        });
    },
    [error, refreshOrganizations, success, userOrganizations],
  );

  return (
    <PageContainer variant="full">
      <PageHeader title="Organizations" />
      <Grid item flex={1} overflow="auto">
        <Grid container spacing={3} rowSpacing={3} px={3} mb={3}>
          {userOrganizations && userInvitations ? (
            <>
              {userInvitations.map((invitation) => {
                return (
                  <Grid item key={invitation.id} xs={12} md={6} lg={4} xl={3}>
                    <DashboardOrganizationCard
                      title={invitation.display?.organization_name ?? ""}
                      description={invitation.display?.description}
                      shortLogoURL={
                        invitation.display?.organizationShortLogoURL
                      }
                      actions={[
                        <Button
                          variant="contained"
                          sx={{ textTransform: "none", fontWeight: "bold" }}
                          key={invitation.id + "_invite_button"}
                          onClick={() => acceptInvitationHandle(invitation)}
                        >
                          Accept Invitation
                        </Button>,
                      ]}
                    />
                  </Grid>
                );
              })}
              {userOrganizations.map((organization) => {
                const actions =
                  organization.owner_id !== currentUserId()
                    ? [
                        <Button
                          variant="contained"
                          sx={{
                            textTransform: "none",
                            fontWeight: "bold",
                            "&:hover": {
                              bgcolor: (theme) => theme.palette.grey[400],
                            },
                          }}
                          color="inherit"
                          key={organization.id + "_leave_button"}
                          onClick={() => leaveOrganizationHandle(organization)}
                        >
                          Leave
                        </Button>,
                      ]
                    : [
                        <Button
                          variant="outlined"
                          key={organization.id + "_delete_button"}
                          onClick={() => {
                            setOrganizationToDelete(organization);
                            setDeleteOrganizationDialogOpen(true);
                          }}
                          sx={{ color: (theme) => theme.palette.grey[700] }}
                        >
                          <Delete sx={{ marginRight: 1 }} />
                          Delete
                        </Button>,
                      ];

                return (
                  <Grid item key={organization.id} xs={12} md={6} lg={4} xl={3}>
                    <DashboardOrganizationCard
                      title={organization.name}
                      description={organization.description}
                      shortLogoURL={organization.theme?.shortLogoUrl}
                      actions={actions}
                    />
                  </Grid>
                );
              })}
            </>
          ) : (
            <CircularProgress sx={{ mt: "20%", alignSelf: "center" }} />
          )}
          {organizationToDelete && (
            <DeleteOrganizationDialog
              organization={organizationToDelete}
              open={deleteOrganizationDialogOpen}
              setDialogOpen={setDeleteOrganizationDialogOpen}
              onOrganizationDeleted={() => {
                return success(
                  `${organizationToDelete.name} has been deleted.`,
                );
              }}
            />
          )}
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default OrganizationInvitationsPage;
