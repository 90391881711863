import {
  Checkbox,
  DialogContentText,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from "@mui/material";
import Dialog from "../../../../components/Dialog";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useAlert } from "../../../../lib/alert";
import { Account } from "../../../../model";
import { AccountAPI, InvitationAPI } from "../../../../api";
import { AccountType } from "../../../../model/AccountType";
import { InvitationType } from "../../../../model/Invitation";
import { v4 as uuid } from "uuid";
import { useGlobalUserContext } from "../../../../hooks/useGlobalUserContext";
import { currentUserId } from "../../../../lib/auth";
import { getInvitationDisplayData } from "../../../invitations/InvitationDisplay";
import { useGlobalOrganizationContext } from "../../../../hooks/useGlobalOrganizationContext";
import AccountTypeSelect from "../AccountTypeSelect";

type CreateAccountDialogProps = {
  open: boolean;
  organizationId: string;
  accountType?: AccountType;
  account?: Partial<Account>;
  onClose?: () => void;
  onCreate?: (account: Account) => void;
};
const CreateAccountDialog: FC<CreateAccountDialogProps> = ({
  accountType,
  account,
  ...props
}) => {
  const { handleRejectionWithError } = useAlert();
  const { user } = useGlobalUserContext();
  const { organization } = useGlobalOrganizationContext();

  const [selectedAccountType, setSelectedAccountType] = useState(accountType);
  const [name, setName] = useState(account?.account_name ?? "");
  const [emailAddress, setEmailAddress] = useState<string | undefined>(
    account?.primary_email,
  );
  const [isTest, setIsTest] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string | undefined>(undefined);

  const [sendInvitation, setSendInvitation] = useState<boolean>(false);

  const title = useMemo(() => {
    return accountType ? `Create ${accountType.name}` : "Create Account";
  }, [accountType]);

  useEffect(() => {
    if (account?.primary_email) {
      setEmailAddress((emailAddress) => emailAddress ?? account?.primary_email);
    }
    if (account?.account_name) {
      setName((name) => name ?? account?.account_name);
    }
  }, [account?.account_name, account?.primary_email]);

  useEffect(() => {
    setSelectedAccountType(accountType);
  }, [accountType]);

  const createAccount = useCallback(async () => {
    if (!props.organizationId) {
      return;
    }

    if (!name) {
      const errorText = "Account name must be specified";
      setErrorText(errorText);
      throw new Error(errorText);
    }

    if (!selectedAccountType) {
      const errorText = "Account type must be specified";
      setErrorText(errorText);
      throw new Error(errorText);
    }

    let account: Account;
    try {
      account = await AccountAPI.create({
        id: "",
        org_id: props.organizationId,
        account_type_id: selectedAccountType?.id ?? uuid(),
        account_type_key: selectedAccountType?.key,
        account_type: selectedAccountType.name,
        account_name: name,
        primary_email: emailAddress,
        is_test: isTest,
      });
    } catch (e) {
      handleRejectionWithError("Failed to create account");
      throw e;
    }

    try {
      if (!emailAddress || !organization || !sendInvitation) {
        return;
      }

      await InvitationAPI.create({
        id: "",
        target_user_id: "",
        creator_user_id: currentUserId(),
        organization_id: props.organizationId,
        target_user_email: emailAddress,
        type: InvitationType.Account,
        account_id: account.id,
        display: getInvitationDisplayData(user, organization),
      });
      return;
    } catch (e) {
      handleRejectionWithError(
        `Failed to invite user ${emailAddress} to account "${name}"`,
      );
      throw e;
    } finally {
      setName("");
      setEmailAddress("");
      setErrorText(undefined);
      props.onClose?.();
      props.onCreate?.(account);
    }
  }, [
    props,
    name,
    selectedAccountType,
    emailAddress,
    isTest,
    handleRejectionWithError,
    organization,
    sendInvitation,
    user,
  ]);

  return (
    <Dialog
      open={props.open}
      title={title}
      confirmText={"Create"}
      cancelText={"Cancel"}
      handleConfirm={createAccount}
      handleCancel={function (): void {
        props.onClose && props.onClose();
        setName("");
        setEmailAddress("");
        setErrorText(undefined);
      }}
    >
      <DialogContentText>
        Enter the name for the account and the email of the primary contact:
        {errorText && (
          <Typography variant="body1" sx={{ color: "red" }}>
            {errorText}
          </Typography>
        )}
      </DialogContentText>
      <TextField
        autoFocus
        margin="dense"
        id="name"
        label="Account Name"
        type="text"
        fullWidth
        variant="standard"
        value={name}
        onChange={(e) => setName(e.target.value as string)}
      />
      <TextField
        autoFocus
        margin="dense"
        id="email"
        label="Email Address"
        type="text"
        fullWidth
        variant="standard"
        value={emailAddress}
        onChange={(e) => setEmailAddress(e.target.value as string)}
      />
      {accountType && (
        <TextField
          autoFocus
          margin="dense"
          id="accountType"
          label="Account Type"
          type="text"
          fullWidth
          variant="standard"
          value={selectedAccountType?.name}
          InputProps={{
            readOnly: !!accountType,
          }}
        />
      )}
      {!accountType && (
        <AccountTypeSelect
          organizationId={organization?.id ?? ""}
          value={selectedAccountType?.key}
          onChange={(accountType) => setSelectedAccountType(accountType)}
        />
      )}
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={sendInvitation}
              onChange={(_, checked) => setSendInvitation(checked)}
            />
          }
          label="Send Invitation"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isTest}
              onChange={(_, checked) => setIsTest(checked)}
            />
          }
          label="Create as test account"
        />
      </FormGroup>
    </Dialog>
  );
};

export default CreateAccountDialog;
