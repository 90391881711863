import { HeadingFormElement } from "../../../types";
import FormBuilderStep from "../FormBuilderStep";

export const HeadingInlineDisplay = ({
  element,
}: {
  element: HeadingFormElement;
}) => {
  return <FormBuilderStep key={element.id} element={element} />;
};
