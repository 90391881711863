import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAlert } from "../../../../../../lib/alert";
import {
  Task,
  TaskExecution,
  TaskExecutionComponentProps,
  TaskSquareLinkExecution,
} from "../../../../types";

const SquareLinkExecutionComponent = (
  props: TaskExecutionComponentProps<Task, TaskExecution> & {
    reload?: () => void;
  },
) => {
  const { error } = useAlert();
  // state to manage whether step is greyed out
  // a step is disabled if processExecution.next_step_id != task.id
  const [execution, setExecution] = useState<
    TaskSquareLinkExecution | undefined
  >(props.taskExecution as TaskSquareLinkExecution | undefined);

  // controls whether the user can use the payment link or attempt to validate again
  // these should be disabled if a payment has already been made and validated
  const [paymentDisabled, setPaymentDisabled] = useState(false);

  useEffect(() => {
    setExecution(props.taskExecution as TaskSquareLinkExecution | undefined);
    if (
      (props.taskExecution as TaskSquareLinkExecution | undefined)?.data
        ?.amountPayment
    ) {
      setPaymentDisabled(true);
    }
  }, [props.taskExecution]);

  const handleClick = () => {
    if (execution?.data?.paymentLink) {
      window.open(execution?.data.paymentLink, "_blank");
    } else {
      error("No Square link available");
    }
  };

  return (
    <Box>
      <Typography>
        {execution?.data?.amountPayment
          ? `Payment of ${execution?.data?.amountPayment} completed.`
          : `Payment incomplete or not yet validated.`}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        size="small"
        sx={{ m: 1 }}
        onClick={handleClick}
        disabled={props.isDisabled ? props.isDisabled : paymentDisabled}
      >
        View payment submission page
      </Button>
      <Button
        variant="contained"
        color="primary"
        size="small"
        sx={{ m: 1 }}
        onClick={() => {
          props.reload && props.reload();
        }}
        disabled={props.isDisabled ? props.isDisabled : paymentDisabled}
      >
        Validate payment
      </Button>
      <Button
        variant="contained"
        color={execution?.data?.amountPayment ? "primary" : "error"}
        size="small"
        sx={{ m: 1 }}
        onClick={() => {
          props.onExecute && execution && props.onExecute(execution);
        }}
        disabled={props.isDisabled}
      >
        Mark Complete
      </Button>
    </Box>
  );
};

export default SquareLinkExecutionComponent;
