import { FC } from "react";
import { IntlProvider } from "react-intl";
import environment from "../../util/environment";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "../../routes";
import RunwayThemeProvider from "../../providers/theme/RunwayThemeProvider";

const Router = BrowserRouter; // far future -> may need to use MemoryRouter for embedded components

const messages = { ...(environment.theme ? environment.theme.lang : {}) };

const AppShell: FC<unknown> = () => {
  return (
    <IntlProvider messages={messages} locale="en" defaultLocale="en">
      <RunwayThemeProvider>
        <Router>
          <AppRoutes />
        </Router>
      </RunwayThemeProvider>
    </IntlProvider>
  );
};

export default AppShell;
