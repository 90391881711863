import { useRef } from "react";
import { Task, TaskTypeOption } from "../../types";
import { Button, Grid, Paper, Tooltip } from "@mui/material";
import { Add } from "@mui/icons-material";
import TaskTypeMenu from "./TaskTypeMenu";
import { v4 as uuid } from "uuid";
import {
  CommonRole,
  ResourceModificationOperationType,
} from "../../../../types";
import { createTask } from "../../../task/components/task-types/getTaskByType";
import Tasks from "../../../../api/tasks";
import Processes from "../../../../api/processes";
import { Process } from "../../../../model/Process";
import { useProcessRoleContext } from "../../../process/hooks";
import { useTaskContext } from "../../hooks";

export enum ButtonType {
  MENU = "MENU",
  CARD = "CARD",
}

type AddTaskMenuButtonProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  stepOrProcessId: string;
  process: Process;
  task?: Task;
  previousTask?: Task;
  insertAbove?: boolean;
  variant?: ButtonType;
};

const AddTaskMenuButton = ({
  open,
  setOpen,
  variant = ButtonType.MENU,
  previousTask,
  process,
  task,
  stepOrProcessId,
  insertAbove = false,
}: AddTaskMenuButtonProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { roles } = useProcessRoleContext();
  const taskContext = useTaskContext();

  const tasks = taskContext.getTasks(stepOrProcessId);

  const onSelectTaskType = async (taskType: TaskTypeOption) => {
    const defaultTask: Task = {
      process_id: stepOrProcessId,
      name: "Untitled",
      task_identifier_name: "",
      type: taskType.type,
      previous_task_id: previousTask?.id,
      id: uuid(),
      execution_role_id:
        previousTask?.execution_role_id != CommonRole.AUTOMATION
          ? previousTask?.execution_role_id
          : undefined,
    };

    const newTask = createTask(process, defaultTask);
    const persistedTask = await Tasks.create(newTask);

    if (persistedTask?.execution_role_id) {
      persistedTask.role = roles.find(
        (role) => role.id == persistedTask?.execution_role_id,
      );
    }

    // Determine where to insert the new task into task array
    if (!task) {
      taskContext.setTasks(stepOrProcessId, [...tasks, persistedTask]);
      taskContext.setSelectedTask(persistedTask);
      return;
    } else {
      const insertIndex = insertAbove
        ? tasks.indexOf(task)
        : tasks.indexOf(task) + 1;

      // Insert new task into proper position
      const newTasks = [
        ...tasks.slice(0, insertIndex),
        persistedTask,
        ...tasks.slice(insertIndex),
      ];
      taskContext.setTasks(stepOrProcessId, newTasks);
      taskContext.setSelectedTask(persistedTask);

      // Send task array update to handle next/previous ids
      const tasksForUpdate = newTasks.map((task) => {
        return {
          task,
          operation: ResourceModificationOperationType.Put,
        };
      });
      Processes.updateTasks(stepOrProcessId, tasksForUpdate).then(() => {
        taskContext.fetchTasks(stepOrProcessId);
      });
    }
  };

  switch (variant) {
    case ButtonType.CARD: {
      return (
        <>
          <Grid container onClick={() => setOpen(!open)}>
            <Paper
              elevation={4}
              sx={{
                borderRadius: 3,
              }}
            >
              <Tooltip title={"Add Task"}>
                <Button
                  ref={buttonRef}
                  sx={{
                    borderRadius: 3,
                    padding: 0.5,
                  }}
                >
                  <Add fontSize="small" />
                </Button>
              </Tooltip>
            </Paper>
          </Grid>
          <TaskTypeMenu
            open={open}
            anchorEl={buttonRef?.current}
            onClose={() => setOpen(false)}
            onSelectTaskType={onSelectTaskType}
          />
        </>
      );
    }
    default: {
      return (
        <>
          <Grid container onClick={() => setOpen(!open)}>
            <Button
              ref={buttonRef}
              sx={(theme) => {
                return {
                  borderRadius: 3,
                  padding: theme.spacing(0.5, 1),
                  textTransform: "unset",
                  mb: 1,
                };
              }}
            >
              <Add fontSize="small" sx={{ mr: 0.25 }} />
              {"Task"}
            </Button>
          </Grid>
          <TaskTypeMenu
            open={open}
            anchorEl={buttonRef?.current}
            onClose={() => setOpen(false)}
            onSelectTaskType={onSelectTaskType}
          />
        </>
      );
    }
  }
};
export default AddTaskMenuButton;
