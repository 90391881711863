import { useGlobalOrganizationContext } from "../../hooks/useGlobalOrganizationContext";
import InternalLayout from "./InternalLayout";
import OrganizationDrawerMenu from "./OrganizationDrawerMenu";

const InternalOrgLayout = (
  props: React.PropsWithChildren<{ menu?: () => React.JSX.Element }>,
) => {
  const { isMember, organization } = useGlobalOrganizationContext();
  const isMemberOfOrg = isMember(organization?.id);
  return (
    <InternalLayout menu={isMemberOfOrg ? OrganizationDrawerMenu : undefined}>
      {props.children}
    </InternalLayout>
  );
};

export default InternalOrgLayout;
