import { Grid, TextField } from "@mui/material";
import { useTaskContext } from "../../../hooks";
import { TaskCreateAccount, TaskSideSectionEditorProps } from "../../../types";
import { AccountTypeSelect } from "../../../../accounts";
import { useGlobalOrganizationContext } from "../../../../../hooks/useGlobalOrganizationContext";
import { useCallback } from "react";
import { Account } from "../../../../../model";
import { RoleAPI } from "../../../../../api";

const CreateAccountSideSectionEditor = ({
  task,
}: TaskSideSectionEditorProps<TaskCreateAccount>) => {
  const taskContext = useTaskContext();
  const { organization } = useGlobalOrganizationContext();
  const updateAccountData = useCallback(
    (account: Partial<Account>, data?: Partial<TaskCreateAccount["data"]>) => {
      // Delete existing id and org_id references that were added erroneously
      // eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
      const { org_id, id, ...existingAccount } =
        task.execution_data?.account ?? {};
      taskContext.setValue({
        data: {
          ...task.data,
          ...data,
          accountTypeId: undefined, // migrate away from accountTypeId to account_type_key
        },
        execution_data: {
          ...task.execution_data,
          account: {
            ...existingAccount,
            ...account,
            account_type_id: undefined, // migrate away from account_type_id to account_type_key
          },
        },
      });
    },
    [task.data, task.execution_data, taskContext],
  );
  return (
    <>
      {/* TODO! default to account type of process */}
      <Grid item xs={12}>
        <AccountTypeSelect
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          organizationId={organization!.id}
          onChange={async (accountType) => {
            updateAccountData(
              {
                account_type_key: accountType?.key,
              },
              {
                accountTypeKey: accountType?.key,
              },
            );
            if (accountType?.role_id && !task.execution_role_id) {
              const role = await RoleAPI.get(accountType.role_id);
              taskContext.setValue({
                role,
              });
            }
          }}
          value={task.data?.accountTypeKey}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          helperText={`The external organization id this account is for.`}
          name={"externalorgId"}
          fullWidth
          value={task.execution_data?.account.external_org_id}
          onChange={(event) => {
            updateAccountData({
              external_org_id: event.target.value,
            });
          }}
          label={"External Organization ID"}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          helperText={`The account name this account is for.`}
          name={"accountName"}
          fullWidth
          value={task.execution_data?.account.account_name}
          onChange={(event) => {
            updateAccountData({
              account_name: event.target.value,
            });
          }}
          label={"Account Name"}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          helperText={`The primary email address for the account.`}
          name={"emailAddress"}
          fullWidth
          value={task.execution_data?.account.primary_email}
          onChange={(event) => {
            updateAccountData({
              primary_email: event.target.value,
            });
          }}
          label={"Email Address"}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    </>
  );
};

export default CreateAccountSideSectionEditor;
