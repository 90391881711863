import { Grid, Button, Box, IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useIntl } from "react-intl";
import Dialog from "../../../../components/Dialog";
import { PageHeader } from "../../../../components/layout/page";
import { Process, ProcessStatus } from "../../../../model/Process";
import messages from "../../messages";
import ProcessDetailsEditDialog from "../ProcessDetailsEditDialog";
import { ProcessDto } from "../../../../types";
import { useAlert } from "../../../../lib/alert";
import PATHS from "../../../../components/navigation/_paths";
import { PlayCircleFilled, Settings } from "@mui/icons-material";
import { ProcessExecutionAPI } from "../../../../api";
import {
  ExecutionType,
  ProcessExecution,
} from "../../../../model/ProcessExecution";
import { useNavigate } from "react-router-dom";

type VerticalSteppedProcessEditorHeaderProps = {
  process: Process;
  handleUpdateProcess: (changes: Partial<Process>) => Promise<void>;
  handlePublishProcess: () => Promise<void>;
};

const VerticalSteppedProcessEditorHeader = ({
  process,
  handleUpdateProcess,
  handlePublishProcess,
}: VerticalSteppedProcessEditorHeaderProps) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { handleRejectionWithError, handleRejectionWithWarning } = useAlert();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [publishOpen, setPublishOpen] = useState(false);

  const handleExecuteProcessRequest = (metaOrId: string, name: string) => {
    const configuration = { name: name, execution_type: ExecutionType.Test };
    ProcessExecutionAPI.start(metaOrId, configuration as ProcessExecution).then(
      (processExecution: ProcessExecution) => {
        navigate(
          PATHS.EXECUTION.linkTo(
            processExecution.process_id,
            processExecution.id,
          ),
        );
      },
      (err) => {
        handleRejectionWithWarning(
          `Could not start process execution for process ${metaOrId}`,
        )(err);
      },
    );
  };

  const processMsg = intl.formatMessage(messages.process.props);
  const processesMsg = intl.formatMessage(messages.processes.props);

  const processTitle = (
    <Typography variant="body1" sx={{ fontSize: "1.25rem" }}>
      {"Publish "}
      {<strong>{process.name}</strong>}
      {"?"}
    </Typography>
  );

  return (
    <Grid
      container
      sx={{
        pb: 1,
      }}
    >
      <PageHeader
        links={[
          {
            link: PATHS.PROCESSES.link,
            titleIntl: PATHS.PROCESSES.titleIntl,
            title: processesMsg,
          },
        ]}
        title={`Edit ${processMsg} - ${process.name}`}
        actions={
          <Box display="flex" sx={{ gap: "16px" }}>
            <IconButton onClick={() => setEditDialogOpen(true)}>
              <Settings />
            </IconButton>
            <Button
              variant="text"
              sx={{ color: "text.secondary" }}
              onClick={() =>
                handleExecuteProcessRequest(process.id, `TEST ${process.name}`)
              }
            >
              <PlayCircleFilled sx={{ mr: 0.5 }} />
              Test
            </Button>
            <Button
              disabled={process.status === ProcessStatus.Published}
              variant="contained"
              onClick={() => setPublishOpen(true)}
            >
              Publish
            </Button>
          </Box>
        }
      ></PageHeader>
      <Dialog
        open={publishOpen}
        title={processTitle}
        confirmText={"Publish"}
        cancelText={"Cancel"}
        handleConfirm={async () => {
          try {
            return await handlePublishProcess();
          } catch (e) {
            setPublishOpen(false);
            throw e;
          }
        }}
        handleCancel={() => setPublishOpen(false)}
      >
        {`Are you sure you want to publish this ${processMsg.toLocaleLowerCase()}?`}
      </Dialog>
      <ProcessDetailsEditDialog
        open={editDialogOpen}
        handleConfirm={async (processDetails) => {
          handleUpdateProcess({
            ...process,
            ...(processDetails as ProcessDto),
          })
            .finally(() => {
              setEditDialogOpen(false);
            })
            .catch(handleRejectionWithError);
        }}
        handleCancel={() => setEditDialogOpen(false)}
        process={process}
      ></ProcessDetailsEditDialog>
    </Grid>
  );
};

export default VerticalSteppedProcessEditorHeader;
