import { FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { Task, TaskForm, TaskFormExecution, TaskExecution } from "../../task";
import { useAlert } from "../../../lib/alert";
import { TaskExecutionAPI } from "../../../api";
import NotFound from "../../app/NotFound";
import FormExecutionComponent from "../../task/components/task-types/form/FormExecutionComponent";

type LongTaskFormRoute = {
  taskExecutionId: string;
};

const ProcessExecutionLongTaskFormSharePage: FC<unknown> = () => {
  const { error } = useAlert();

  // fetch data from route
  const { taskExecutionId } = useParams<LongTaskFormRoute>();

  // state to control the task object according to the route, child component depends
  const [task, setTask] = useState<TaskForm>();

  // state to control the task execution object per route, child component depends
  const [execution, setExecution] = useState<TaskFormExecution>();

  // state to control whether current data is fully loaded
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const fetchTask = useCallback(
    async (taskExecutionId: string): Promise<Task> => {
      return TaskExecutionAPI.getSharedTask(taskExecutionId).then(
        (task: Task) => {
          setTask(task as TaskForm);
          return task;
        },
      );
    },
    [],
  );

  const fetchTaskExecution = useCallback(async (taskExecutionId: string) => {
    return TaskExecutionAPI.getShared(taskExecutionId).then(
      (execution: TaskExecution) => {
        setExecution(execution as TaskFormExecution);
        return execution;
      },
    );
  }, []);

  useEffect(() => {
    if (!taskExecutionId) return;
    const fetchData = async () => {
      const [task, execution] = await Promise.all([
        fetchTask(taskExecutionId),
        fetchTaskExecution(taskExecutionId),
      ]);
      setTask(task as TaskForm);
      setExecution(execution as TaskFormExecution);
    };

    try {
      setIsLoaded(false);
      fetchData();
    } catch {
      error("Failed to load task execution");
    } finally {
      setIsLoaded(true);
    }
  }, [error, fetchTask, fetchTaskExecution, taskExecutionId]);

  if (!taskExecutionId) {
    return <NotFound />;
  }

  return (
    <>
      {isLoaded && (
        <Grid
          container
          sx={{
            width: "100%",
            justifyContent: "center",
            my: 2,
          }}
        >
          <FormExecutionComponent
            task={task as TaskForm}
            taskExecution={execution as TaskFormExecution}
          />
        </Grid>
      )}
    </>
  );
};

export default ProcessExecutionLongTaskFormSharePage;
