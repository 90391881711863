import { Box, Button, Popover } from "@mui/material";
import { TaskTypeOption, TaskTypes } from "../../types";

const TaskTypeMenu = ({
  open,
  onClose,
  onSelectTaskType,
  anchorEl,
}: {
  open: boolean;
  onClose: () => void;
  onSelectTaskType: (taskType: TaskTypeOption) => void;
  anchorEl: Element | null | undefined;
}) => {
  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          marginRight: "16px",
          width: "400px",
          maxWidth: "400px",
          flexWrap: "wrap",
        }}
      >
        {TaskTypes.filter((type) => !type.disabled).map((taskType) => {
          return (
            <Box key={taskType.type}>
              <Button
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "16px",
                  marginTop: "16px",
                  marginBottom: "16px",
                  maxWidth: "100px",
                  width: "100px",
                  "&:hover": {
                    boxShadow: 3,
                  },
                }}
                size="large"
                onClick={() => {
                  onClose();
                  onSelectTaskType(taskType);
                }}
              >
                <taskType.icon fontSize="large" />
                <Box sx={{ flex: "1", fontSize: "10px" }}>{taskType.title}</Box>
              </Button>
            </Box>
          );
        })}
      </Box>
    </Popover>
  );
};
export default TaskTypeMenu;
