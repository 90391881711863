import { Box, Button } from "@mui/material";

const RunwayApiCallInlineEditor = () => {
  return (
    <Box>
      <Button variant="contained" size="small" sx={{ m: 1 }} disabled={true}>
        Make API Call
      </Button>
    </Box>
  );
};

export default RunwayApiCallInlineEditor;
