import { useIntl } from "react-intl";
import messages from "../../messages";
import { Grid, Paper, Typography, Button } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { useGlobalOrganizationContext } from "../../../../hooks/useGlobalOrganizationContext";

type AccountProfileProgramBlankProps = {
  accountName: string;
};

const AccountProfileProgramBlank = ({
  accountName,
}: AccountProfileProgramBlankProps) => {
  const intl = useIntl();
  const { organization, isMember } = useGlobalOrganizationContext();
  const isMemberOfOrg = isMember(organization?.id);
  const programName = intl.formatMessage(messages.account.program.props);
  const navigate = useNavigate();

  return (
    <Grid
      style={{
        backgroundColor: "#EFF3F6",
        height: "100%",
      }}
    >
      <div style={{ height: "10vh" }}></div>
      <Paper
        sx={{
          width: "40%",
          minWidth: "250px",
          margin: "auto",
          padding: 4,
          textAlign: "center",
        }}
      >
        {isMemberOfOrg ? (
          <>
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", fontSize: "1.1em" }}
            >
              Get started by creating a new {programName} for {accountName}
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                navigate(`./editor`);
              }}
              sx={{ backgroundColor: "primary.main", marginTop: 2 }}
            >
              Create New Plan
            </Button>
          </>
        ) : (
          <Typography
            variant="body1"
            sx={{ fontWeight: "bold", fontSize: "1.1em" }}
          >
            {accountName} does not have a {programName} yet
          </Typography>
        )}
      </Paper>
    </Grid>
  );
};

export default AccountProfileProgramBlank;
