import {
  Box,
  FormControlLabel,
  FormGroup,
  ListItemIcon,
  MenuItem,
  SvgIcon,
  Switch,
} from "@mui/material";
import { FC } from "react";
import TestModeStatus from "./TestModeStatus";
import { Check } from "@mui/icons-material";
import { useAtom } from "jotai";
import { testModeAtom } from "../../../../features/process/state";

type TestModeToggleProps = {
  onUpdate?: () => void;
  showStatus?: boolean;
  objectTitle?: string;
  variant?: "menuItem" | "switch";
};

const TestModeToggle: FC<TestModeToggleProps> = ({
  objectTitle,
  showStatus = true,
  variant = "switch",
  ...props
}) => {
  const [testMode, setTestMode] = useAtom(testModeAtom);
  const label = objectTitle ? `Show Test ${objectTitle}` : "Show Tests";

  const toggleTestMode = () => {
    const newState = !testMode;
    setTestMode(newState);
    props.onUpdate?.();
  };

  if (variant === "menuItem") {
    return (
      <MenuItem onClick={toggleTestMode}>
        <ListItemIcon>{testMode ? <Check /> : <SvgIcon />}</ListItemIcon>
        {label}
      </MenuItem>
    );
  }
  return (
    <Box sx={{ display: "flex" }}>
      {showStatus && <TestModeStatus objectTitle={objectTitle} />}
      <FormGroup>
        <FormControlLabel
          label={label}
          control={<Switch checked={testMode} onChange={toggleTestMode} />}
        />
      </FormGroup>
    </Box>
  );
};

export default TestModeToggle;
