import {
  Step,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  Stepper,
  styled,
  StepperProps,
  stepLabelClasses,
  SvgIcon,
  Tooltip,
} from "@mui/material";
import { useProcessExecutionContext } from "../../hooks";
import {
  TaskExecution,
  TaskExecutionState,
  TaskType,
  getTaskTypeIcon,
} from "../../../task";
import { useCallback, useMemo } from "react";
import { Pending } from "@mui/icons-material";
import { useGlobalOrganizationContext } from "../../../../hooks/useGlobalOrganizationContext";

const Connector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.root}`]: {
    margin: "auto",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.progress.progress,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.progress.progress,
    },
  },
}));

const TaskStepLabel = styled(
  ({
    taskExecution,
    className,
  }: {
    taskExecution?: TaskExecution;
    taskExecutions: Array<TaskExecution | undefined>;
    className?: string;
  }) => {
    const { setSelectedTaskExecution } = useProcessExecutionContext();
    const TaskIcon = useMemo(() => {
      if (!taskExecution) {
        return Pending;
      }
      if (!taskExecution.task?.type) {
        return SvgIcon;
      }
      const TaskTypeIcon = getTaskTypeIcon(taskExecution.task?.type);
      if (TaskTypeIcon) {
        return TaskTypeIcon;
      }
      return SvgIcon;
    }, [taskExecution]);
    return (
      <Tooltip
        title={taskExecution?.task?.name ?? "Locked"}
        placement={"right"}
      >
        <StepLabel
          className={className}
          icon={
            <TaskIcon
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setSelectedTaskExecution(taskExecution);
              }}
            />
          }
        />
      </Tooltip>
    );
  },
)(({ taskExecution, theme }) => {
  const {
    currentTaskExecution,
    selectedTaskExecution,
    getContextTaskExecution,
  } = useProcessExecutionContext();

  const isComplete = taskExecution?.state === TaskExecutionState.Completed;
  const isError = taskExecution?.state === TaskExecutionState.Error;
  const isCurrent = taskExecution?.task_id === currentTaskExecution?.task_id;
  const isSelected = taskExecution?.task_id === selectedTaskExecution?.task_id;

  const isDisplayedContext = useMemo(() => {
    const context = getContextTaskExecution(selectedTaskExecution);
    return taskExecution?.task_id === context?.task_id;
  }, [getContextTaskExecution, selectedTaskExecution, taskExecution?.task_id]);

  const borderColor = useCallback(
    // eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
    (isSelected: boolean) => {
      if (isDisplayedContext) {
        if (isError) return theme.palette.error.main;
        if (isComplete) return theme.palette.progress.complete;
        if (isCurrent) return theme.palette.grey[800];
        return theme.palette.grey[400];
      }
      return "transparent";
    },
    [
      isComplete,
      isCurrent,
      isDisplayedContext,
      isError,
      theme.palette.error.main,
      theme.palette.grey,
      theme.palette.progress.complete,
    ],
  );

  const backgroundColor = useCallback(
    (isSelected: boolean) => {
      if (isSelected) {
        if (isCurrent) return theme.palette.grey[800];
        if (isError) return theme.palette.error.main;
        if (isComplete) return theme.palette.progress.complete;
        return theme.palette.grey[400];
      }
    },
    [
      isComplete,
      isCurrent,
      isError,
      theme.palette.error.main,
      theme.palette.grey,
      theme.palette.progress.complete,
    ],
  );

  const color = useCallback(
    (isSelected: boolean) => {
      if (isSelected) return theme.palette.grey[200];
      if (isCurrent) return theme.palette.grey[800];
      if (isError) return theme.palette.error.main;
      if (isComplete) return theme.palette.progress.complete;
      return theme.palette.grey[400];
    },
    [
      isComplete,
      isCurrent,
      isError,
      theme.palette.error.main,
      theme.palette.grey,
      theme.palette.progress.complete,
    ],
  );

  const opacity = useCallback(
    (isSelected: boolean) => {
      if (isSelected) return 1;
      if (isCurrent) return 1;
      if (isDisplayedContext) return 1;
      return 0.5;
    },
    [isCurrent, isDisplayedContext],
  );

  const borderStyle = useCallback((isSelected: boolean) => {
    return isSelected ? "solid" : "dashed";
  }, []);

  return {
    [`& .${stepLabelClasses.iconContainer}`]: {
      padding: "1px",
      borderRadius: "20%",
      border: `1px ${borderStyle(isSelected)}`,
      borderColor: borderColor(isSelected),
      color: color(isSelected),
      backgroundColor: backgroundColor(isSelected),
      opacity: opacity(isSelected),
      [`:hover`]: {
        borderColor: borderColor(true),
        color: color(true),
        backgroundColor: backgroundColor(true),
        opacity: opacity(true),
      },
    },
  };
});

const TaskExecutionStepper = (props: StepperProps) => {
  const useConnector = true;
  const { isMember } = useGlobalOrganizationContext();
  const {
    taskExecutions: unfilteredTaskExecutions,
    currentTaskExecution,
    processExecution,
  } = useProcessExecutionContext();

  // Hide task executions from account users
  const taskExecutions = useMemo(() => {
    return unfilteredTaskExecutions.filter((execution) => {
      if (isMember(processExecution?.org_id)) {
        return true;
      }
      return execution?.type != TaskType.CreateAccount;
    });
  }, [isMember, processExecution?.org_id, unfilteredTaskExecutions]);

  const currentIndex = useMemo(() => {
    const index = taskExecutions.findIndex((taskExecution) => {
      return taskExecution?.task_id === currentTaskExecution?.task_id;
    });
    return index < 0 ? taskExecutions.length - 1 : index;
  }, [currentTaskExecution, taskExecutions]);

  return (
    <Stepper
      connector={useConnector ? <Connector /> : undefined}
      activeStep={currentIndex}
      {...props}
    >
      {taskExecutions.map((taskExecution, index) => {
        return (
          <Step key={taskExecution?.task_id ?? `blank-${index}`}>
            <TaskStepLabel
              taskExecution={taskExecution}
              taskExecutions={taskExecutions}
            />
          </Step>
        );
      })}
    </Stepper>
  );
};
export default TaskExecutionStepper;
