import { Box, Button } from "@mui/material";
import { TaskAcceptInvitation, TaskInlineEditorProps } from "../../../types";

const AcceptInvitationInlineEditor = ({
  task,
}: TaskInlineEditorProps<TaskAcceptInvitation>) => {
  return (
    <Box>
      <Button
        variant="contained"
        color="error"
        size="small"
        sx={{ m: 1 }}
        disabled={true}
      >
        {task.data?.declineLabel}
      </Button>
      <Button variant="contained" size="small" sx={{ m: 1 }} disabled={true}>
        {task.data?.acceptLabel}
      </Button>
    </Box>
  );
};

export default AcceptInvitationInlineEditor;
