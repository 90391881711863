import { ProcessExecution } from "../model/Process";
import API from "./base";

const processWithCopilot = (
  taskExecutionId: string,
): Promise<ProcessExecution> => {
  return new Promise((resolve, reject) => {
    API.post(`/copilot/${taskExecutionId}/process`).then(
      (result) => resolve(result.data.payload as ProcessExecution),
      reject,
    );
  });
};

export default { processWithCopilot: processWithCopilot };
