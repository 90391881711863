export type Executor = {
  id: string;
  process_id: string;
  executor_type: ExecutorType;
  process_execution_name?: string;
};

export enum ExecutorType {
  Public = "public",
}
