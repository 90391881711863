import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useTaskContext } from "../../../hooks";
import { TaskSideSectionEditorProps } from "../../../types";
import { TaskRunwayApiCall } from "../../../types/task-types/TaskRunwayApiCall";
import { useEffect, useState } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { json } from "@codemirror/lang-json";

const RunwayApiCallSideSectionEditor = ({
  task,
}: TaskSideSectionEditorProps<TaskRunwayApiCall>) => {
  const taskContext = useTaskContext();

  const [headersError, setHeadersError] = useState<string>("");
  const [bodyError, setBodyError] = useState<string>("");

  const [unformattedHeaderState, setUnformattedHeaderState] =
    useState<string>("");
  const [unformattedBodyState, setUnformattedBodyState] = useState<string>("");

  useEffect(() => {
    taskContext.setValue({
      execution_data: {
        ...task.execution_data,
        request: {
          method: "GET",
          url: "",
          ...task.execution_data?.request,
        },
      },
      data: {
        useIntegration: true,
        ...task.data,
      },
    });

    setUnformattedHeaderState(
      JSON.stringify(task.execution_data?.request.headers, null, "  "),
    );
    setUnformattedBodyState(
      JSON.stringify(task.execution_data?.request.body, null, "  "),
    );
    /*
     * As strange as this looks, this really does seem to be the correct way to
     * do what we want do accomplish here.  We want to run this effect whenever
     * the selected task changes, which happens when a new task is selected in
     * the sidebar and ALSO the first time this task is loaded.  In both cases,
     * we need to restore the unformatted state to complete the rendering loop
     * and also allow us to display the formatted values in CodeMirror.
     *
     * Because we only want this block to run under those specific circumstances,
     * we explicitly DO NOT include the deps for taskContext and the task data and
     * execution data.  This keeps us from incorrectly running this code when the
     * task data changes, which happens when the CodeMirror editors are updated and
     * formatting/error checking is run.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskContext.selectedTask]);

  return (
    <>
      <Grid item xs={12}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                name={"useIntegration"}
                checked={task.data?.useIntegration}
                onChange={(event) => {
                  taskContext.setValue({
                    data: {
                      ...task.data,
                      useIntegration: event.target.checked,
                    },
                  });
                }}
              />
            }
            label="Use Integration"
          />
        </FormGroup>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="method">Method</InputLabel>
          <Select
            labelId="method"
            id="method-select"
            value={task.execution_data?.request.method}
            label="Method"
            onChange={(event) => {
              taskContext.setValue({
                execution_data: {
                  ...task.execution_data,
                  request: {
                    ...task.execution_data?.request,
                    method: event.target.value,
                  },
                },
              });
            }}
          >
            <MenuItem value={"GET"}>GET</MenuItem>
            <MenuItem value={"POST"}>POST</MenuItem>
            <MenuItem value={"PUT"}>PUT</MenuItem>
            <MenuItem value={"DELETE"}>DELETE</MenuItem>
            <MenuItem value={"PATCH"}>PATCH</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField
          helperText={`The endpoint to request`}
          name={"endpoint"}
          fullWidth
          value={task.execution_data?.request.url}
          onChange={(event) => {
            taskContext.setValue({
              execution_data: {
                ...task.execution_data,
                request: {
                  ...task.execution_data?.request,
                  url: event.target.value,
                },
              },
            });
          }}
          label={"Endpoint"}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">Headers</Typography>
        <CodeMirror
          value={unformattedHeaderState}
          height="200px"
          extensions={[json()]}
          onChange={(value) => {
            try {
              let parsed = undefined;
              if (value) {
                parsed = JSON.parse(value);
              }
              setUnformattedHeaderState(value);
              taskContext.setValue({
                execution_data: {
                  ...task.execution_data,
                  request: {
                    ...task.execution_data?.request,
                    headers: parsed,
                  },
                },
              });
              setHeadersError("");
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (err: any) {
              setHeadersError(err.toString());
            } finally {
              setUnformattedHeaderState(value);
            }
          }}
        />
        <Typography variant="body1">{headersError}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">Body</Typography>
        <CodeMirror
          value={unformattedBodyState}
          height="200px"
          extensions={[json()]}
          onChange={(value) => {
            try {
              let parsed = undefined;
              if (value) {
                parsed = JSON.parse(value);
              }
              setUnformattedBodyState(value);
              taskContext.setValue({
                execution_data: {
                  ...task.execution_data,
                  request: {
                    ...task.execution_data?.request,
                    body: parsed,
                  },
                },
              });
              setBodyError("");
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (err: any) {
              setBodyError(err.toString());
            } finally {
              setUnformattedBodyState(value);
            }
          }}
        />
        <Typography variant="body1">{bodyError}</Typography>
      </Grid>
    </>
  );
};

export default RunwayApiCallSideSectionEditor;
