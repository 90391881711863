import { useState } from "react";

import DebouncedTextField from "../../../form-builder/autocomplete/DebouncedTextField";
import axios from "axios";
import jp from "jsonpath";
import { AutocompleteFormElement } from "../../../../types";
import { useAtom } from "jotai";
import { eventsAtom } from "../../../../hooks/useEvents";
import { FormLabel } from "@mui/material";

type TaskAutocompleteFormComponentProps = {
  formId?: string;
  disabled?: boolean;
  onValueChange: (value: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  execution: any;
} & AutocompleteFormElement;

const TaskAutocompleteFormComponent = ({
  execution,
  onValueChange,
  ...element
}: TaskAutocompleteFormComponentProps) => {
  const [events] = useAtom(eventsAtom);
  const [value, setValue] = useState(execution?.value || element.value || "");
  return (
    <DebouncedTextField
      value={value}
      label={<FormLabel required={element.required}>{element.label}</FormLabel>}
      onValueChange={async (newValue) => {
        setValue(newValue);
        const response = await axios.get(
          element.query.replace("{search}", newValue),
        );
        onValueChange(newValue);
        if (element.error) {
          const errorPresence = jp.query(response.data, element.error);
          if (errorPresence && errorPresence.length > 0) {
            return false;
          }
        }
        events.emit(`object`, response.data);
        return true;
      }}
      helperText={element.description}
    />
  );
};

export default TaskAutocompleteFormComponent;
