import { DateFormElement } from "../../../types";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { FormLabel, Typography } from "@mui/material";
import { DatePicker } from "../../../../../components";
export const DateInlineDisplay = ({
  element,
}: {
  element: DateFormElement;
}) => {
  return (
    <>
      {element.label && (
        <FormLabel required={element.required}>{element.label}</FormLabel>
      )}
      {element.description && (
        <Typography sx={{ pt: 1, pb: 2, fontSize: "14px" }}>
          {element.description}
        </Typography>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker {...element} />
      </LocalizationProvider>
    </>
  );
};
