import { Task, TaskSideSectionEditorProps } from "../../../types";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useTaskContext } from "../../../hooks";
import { UnstructuredTypes } from ".";
import { useCallback } from "react";
import StripeCheckoutSideSectionEditor from "./stripe-checkout/StripeCheckoutSideSectionEditor";

const UnstructuredSideSectionEditor = ({
  task,
}: TaskSideSectionEditorProps<Task>) => {
  const taskContext = useTaskContext();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data = task.data as any;

  const handleTypeChange = useCallback(
    (event: SelectChangeEvent) => {
      taskContext.setValue({
        data: {
          ...data,
          type: event.target.value,
        },
      });
    },
    [data, taskContext],
  );

  return (
    <>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="task-select-label">
            Select Unstructured Type
          </InputLabel>
          <Select
            labelId="task-type-select-label"
            id="task-type-select"
            value={data?.type}
            label={"Select Unstructured Type"}
            onChange={handleTypeChange}
          >
            {Object.values(UnstructuredTypes).map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {data?.type == UnstructuredTypes.StripeCheckout && (
        <StripeCheckoutSideSectionEditor task={task} />
      )}
    </>
  );
};

export default UnstructuredSideSectionEditor;
