import { Process } from "../../../model/Process";
import ProcessDetailsEditDialog from "./ProcessDetailsEditDialog";
import messages from "../messages";
import { useIntl } from "react-intl";
const CreateProcessDialog = ({
  open,
  categoryId,
  handleConfirm,
  handleCancel,
  showSubjectField = true,
}: {
  open: boolean;
  categoryId?: string;
  handleConfirm: (process: Partial<Process>) => Promise<void>;
  handleCancel: () => void;
  showSubjectField?: boolean;
}) => {
  const { formatMessage } = useIntl();
  return (
    <ProcessDetailsEditDialog
      open={open}
      handleConfirm={handleConfirm}
      handleCancel={handleCancel}
      process={{ name: "", description: "", category_id: categoryId }}
      title={`Create New ${formatMessage(messages.process.props)}`}
      confirmText={"Create"}
      cancelText={"Cancel"}
      showSubjectField={showSubjectField}
    ></ProcessDetailsEditDialog>
  );
};
export default CreateProcessDialog;
