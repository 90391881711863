import { SubmitButton } from "../../../../../components/elements";
import {
  TaskExecutionComponentProps,
  TaskExecutionState,
} from "../../../types";
import {
  TaskContent,
  TaskContentExecution,
} from "../../../types/task-types/TaskContent";

const ContentExecutionComponent = ({
  taskExecution,
  onExecute,
  children,
  ...props
}: TaskExecutionComponentProps<TaskContent, TaskContentExecution>) => {
  const actions =
    taskExecution?.state != TaskExecutionState.Completed ? (
      <SubmitButton
        variant="contained"
        onSubmit={async () => {
          const execution = {
            ...taskExecution,
            task_id: props.task.id,
            state: TaskExecutionState.Completed,
            data: {},
          };
          if (onExecute) {
            await onExecute(execution);
          }
        }}
      >
        {"Continue"}
      </SubmitButton>
    ) : (
      <></>
    );
  if (children) {
    return children({ actions });
  }
  return actions;
};
export default ContentExecutionComponent;
