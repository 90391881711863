import { ListAltOutlined, ViewKanbanOutlined } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

const SingleProcessVisualizeMenu: FC = () => {
  const routes = ["../table", "../board"];
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "10vh",
        px: "1vh",
      }}
    >
      <Typography variant="body2">Visualize</Typography>
      <Box
        sx={{ display: "flex", border: "1px solid rgba(221, 221, 221, 0.12)" }}
      >
        <Button
          sx={{
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
          }}
          onClick={() => navigate(routes[0])}
        >
          <ListAltOutlined />
          <Typography variant="body2">Table</Typography>
        </Button>
        <Button
          sx={{
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
          }}
          onClick={() => navigate(routes[1])}
        >
          <ViewKanbanOutlined />
          <Typography variant="body2">Board</Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default SingleProcessVisualizeMenu;
