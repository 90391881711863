import { DeleteOutline } from "@mui/icons-material";
import {
  Box,
  FormGroup,
  TextField,
  FormControlLabel,
  Checkbox,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  SideDetailsContainer,
  SideDetailsSection,
} from "../../../process/components/side-details";
import {
  FieldDefinitionFormElement,
  FormDefinitionType,
  FormElementType,
  FormExecutionType,
} from "../../types";
import FormBuilderElement from "./FormBuilderElement";
import { useFormBuilderContext } from "./FormBuilderProvider";
import { FieldDefinitionAPI } from "../../../../api";
import FieldDefinition from "../../../../model/FieldDefinition";
import DataModelAutocomplete from "../../../data/components/DataModelAutocomplete";

export const findFieldDefinitionChildren = async (
  id: string,
  elements: FormDefinitionType[],
): Promise<{
  child?: FormDefinitionType;
  parent?: FieldDefinitionFormElement;
  fieldDefinition?: FieldDefinition;
}> => {
  const fieldDefinitionElements = elements.filter(
    (element) => element.type == FormElementType.FieldDefinition,
  ) as FieldDefinitionFormElement[];

  for (const fieldDefElement of fieldDefinitionElements) {
    if (fieldDefElement.field_definition_id) {
      const fieldDefinition = await FieldDefinitionAPI.get(
        fieldDefElement.field_definition_id,
      );
      //TODO make field definition get root parent endpoint
      const found = fieldDefinition.data.elements.find((e) => e.id == id);
      if (found) {
        return {
          child: found,
          parent: fieldDefElement,
          fieldDefinition: fieldDefinition,
        };
      }
    }
  }
  return {};
};

const FormBuilderSideSectionContainer = () => {
  const { elements, executionData, selectedElementId, onFormElementChange } =
    useFormBuilderContext();

  const labelRef = useRef<HTMLInputElement>();
  const [editingFieldDef, setEditingFieldDef] = useState(false);
  const element: FormDefinitionType | undefined = useMemo(() => {
    const e = elements.find((element) => element.id == selectedElementId);
    if (!e && selectedElementId) {
      return elements
        .filter(
          (e) =>
            e.type == FormElementType.FieldDefinition &&
            (e as FieldDefinitionFormElement).elements,
        )
        .flatMap((e) => (e as FieldDefinitionFormElement).elements!)
        .find((e) => e.id == selectedElementId);
    } else {
      setEditingFieldDef(false);
      return e;
    }
  }, [elements, selectedElementId]);
  const execution: FormExecutionType | undefined = useMemo(() => {
    return executionData.find((e) => e.id == selectedElementId);
  }, [executionData, selectedElementId]);

  useEffect(() => {
    if (element?.id) {
      labelRef.current?.focus();
    }
  }, [element?.id]);

  return (
    <SideDetailsContainer
      maxWidth={600}
      scrollable
      sx={{
        height: "100%",
      }}
    >
      <SideDetailsSection>
        {element && (
          <FormGroup sx={{ gap: 2, flex: 1 }}>
            <TextField
              inputRef={labelRef}
              fullWidth
              label={"Label"}
              value={element?.label ?? ""}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                onFormElementChange({
                  changeType: "update",
                  newElement: {
                    ...element,
                    label: e.target.value,
                  },
                });
              }}
            />
            <TextField
              fullWidth
              label={"Description"}
              value={element?.description ?? ""}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                onFormElementChange({
                  changeType: "update",
                  newElement: {
                    ...element,
                    description: e.target.value,
                  },
                });
              }}
            />
            <TextField
              fullWidth
              label={"Variable Name"}
              value={element?.name ?? ""}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                onFormElementChange({
                  changeType: "update",
                  newElement: {
                    ...element,
                    name: e.target.value,
                  },
                });
              }}
            />
            {element.type !== FormElementType.Heading && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!element.required}
                    onChange={(e) => {
                      onFormElementChange({
                        changeType: "update",
                        newElement: {
                          ...element,
                          required: e.target.checked,
                        },
                      });
                    }}
                  />
                }
                label={"Required"}
              />
            )}
            <DataModelAutocomplete
              modelRefs={element.modelRefs}
              onChange={(modelFields) => {
                onFormElementChange({
                  changeType: "update",
                  newElement: {
                    ...element,
                    modelRefs: modelFields,
                  },
                });
              }}
            />
            <TextField
              fullWidth
              label={"Link"}
              value={element?.link ?? ""}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                onFormElementChange({
                  changeType: "update",
                  newElement: {
                    ...element,
                    link: e.target.value,
                  },
                });
              }}
            />
            {element.type === FormElementType.Heading && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!element.isAnchored}
                    onChange={(e) => {
                      onFormElementChange({
                        changeType: "update",
                        newElement: {
                          ...element,
                          isAnchored: e.target.checked,
                        },
                      });
                    }}
                  />
                }
                label={"Anchored"}
              />
            )}
            <FormBuilderElement
              variant="side-section"
              element={element}
              executionData={execution}
              onChange={onFormElementChange}
            />
            <Divider />
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: editingFieldDef ? "space-between" : "flex-end",
              }}
            >
              {editingFieldDef && (
                <Typography>Editing Field Definition</Typography>
              )}
              <Tooltip title={"Delete Element"} placement={"bottom"}>
                <IconButton
                  color={"default"}
                  onClick={() => {
                    onFormElementChange({
                      changeType: "remove",
                      elementId: element.id,
                    });
                  }}
                >
                  <DeleteOutline />
                </IconButton>
              </Tooltip>
            </Box>
          </FormGroup>
        )}
      </SideDetailsSection>
    </SideDetailsContainer>
  );
};

export default FormBuilderSideSectionContainer;
