import { FC, PropsWithChildren } from "react";
import { Box, SxProps, Theme } from "@mui/material";

const SideDetailsSection: FC<PropsWithChildren<{ sx?: SxProps<Theme> }>> = ({
  children,
  sx,
}) => (
  <Box
    component={"section"}
    sx={{
      px: 3,
      py: 2,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      ...sx,
    }}
  >
    {children}
  </Box>
);

export default SideDetailsSection;
