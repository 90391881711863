import { Button, FormLabel } from "@mui/material";
import { UploadFormElement } from "../../../types";
import { FileUpload } from "@mui/icons-material";
import FormElementTaskDescription from "../FormElementTaskDescription";

export const UploadInlineDisplay = ({
  element,
}: {
  element: UploadFormElement;
}) => {
  return (
    <>
      <FormLabel required={element.required}>{element.label}</FormLabel>
      <FormElementTaskDescription>
        {element.description}
      </FormElementTaskDescription>
      <Button
        variant="contained"
        size="medium"
        component="label"
        disabled
        startIcon={<FileUpload />}
        sx={{
          width: "7rem",
          textTransform: "unset",
          fontWeight: 600,
          p: 1,
          mt: 1,
        }}
      >
        Upload
      </Button>
    </>
  );
};
