import { DialogContentText, FormGroup, TextField } from "@mui/material";
import Dialog from "../../components/Dialog";
import { FC, useRef, useState } from "react";
import { useAlert } from "../../lib/alert";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Integration, { IntegrationType } from "../../model/Integration";
import Integrations from "../../api/integrations/integrations";
import { v4 as uuid } from "uuid";

type AddIntegrationDialogProps = {
  open: boolean;
  organizationId: string;
  setAddIntegrationDialogOpen: (open: boolean) => void;
  integration?: Integration;
  onClose: () => void;
  onConfirm: () => void;
};

const AddIntegrationDialog: FC<AddIntegrationDialogProps> = (props) => {
  const { error, success } = useAlert();
  const initialIntegration = props.integration
    ? props.integration
    : {
        id: uuid(),
        organization_id: props.organizationId,
        type: IntegrationType.Calendly,
      };
  const [integrationType, setIntegrationType] = useState<IntegrationType>(
    initialIntegration.type,
  );

  const integration = useRef<Integration>(initialIntegration);
  const addOrUpdate = props.integration ? "Update" : "Add";

  const handleTypeChange = (event: SelectChangeEvent) => {
    setIntegrationType(event.target.value as IntegrationType);
    integration.current = {
      ...integration.current,
      type: event.target.value as IntegrationType,
    };
  };

  type IntegrationFormProps = {
    type: IntegrationType;
  };

  const IntegrationForm: FC<IntegrationFormProps> = (props) => {
    switch (props.type) {
      case IntegrationType.Calendly:
      case IntegrationType.Square:
      case IntegrationType.Runway:
        return (
          <FormGroup>
            <TextField
              autoFocus
              margin="dense"
              id="api-key"
              label="API Key"
              type="password"
              fullWidth
              variant="standard"
              value={integration.current?.data?.token}
              onChange={(e) => {
                integration.current = {
                  ...integration.current,
                  data: { token: e.target.value.trim() },
                };
              }}
            />
          </FormGroup>
        );
      case IntegrationType.DocuSign:
        return <FormGroup />;
      case IntegrationType.QuickBooks:
        return (
          <FormGroup>
            <TextField
              autoFocus
              margin="dense"
              id="client-id"
              label="Client ID"
              type="text"
              fullWidth
              variant="standard"
              value={integration.current?.data?.client_id}
              onChange={(e) => {
                integration.current = {
                  ...integration.current,
                  data: {
                    ...integration.current.data,
                    auth_uri: "https://appcenter.intuit.com/connect/oauth2",
                    token_uri:
                      "https://oauth.platform.intuit.com/oauth2/v1/tokens/bearer",
                    client_id: e.target.value.trim(),
                  },
                };
              }}
            />
            <TextField
              autoFocus
              margin="dense"
              id="client-secret"
              label="Client Secret"
              type="password"
              fullWidth
              variant="standard"
              value={integration.current?.data?.client_secret}
              onChange={(e) => {
                integration.current = {
                  ...integration.current,
                  data: {
                    ...integration.current.data,
                    client_secret: e.target.value.trim(),
                  },
                };
              }}
            />
          </FormGroup>
        );
      default:
        return (
          <FormGroup>
            <TextField
              autoFocus
              margin="dense"
              id="api-key"
              label="API Key"
              type="password"
              fullWidth
              variant="standard"
              value={integration.current?.data?.token}
              onChange={(e) => {
                integration.current = {
                  ...integration.current,
                  data: { token: e.target.value.trim() },
                };
              }}
            />
          </FormGroup>
        );
    }
  };

  const handleSuccess = () => {
    props.setAddIntegrationDialogOpen(false);
    props.onConfirm();
    props.onClose();
  };

  const handleRejection = () => {
    props.setAddIntegrationDialogOpen(false);
    props.onClose();
    error(`Failed to ${addOrUpdate.toLowerCase()} integration`);
  };

  return (
    <Dialog
      open={props.open}
      title={`${addOrUpdate} Integration`}
      confirmText={addOrUpdate}
      cancelText={"Cancel"}
      handleConfirm={(): Promise<void> => {
        if (!props.organizationId || !integration.current) {
          return Promise.resolve();
        }
        if (props.integration) {
          return Integrations.update(
            integration.current.id,
            props.organizationId,
            integration.current,
          ).then(() => {
            handleSuccess();
            success("Integration updated");
          }, handleRejection);
        } else {
          return Integrations.create(
            integration.current,
            props.organizationId,
          ).then(() => {
            handleSuccess();
            success("Integration added");
          }, handleRejection);
        }
      }}
      handleCancel={() => {
        if (props.setAddIntegrationDialogOpen) {
          props.setAddIntegrationDialogOpen(false);
        }
        props.onClose();
      }}
    >
      {!props.integration && (
        <>
          <DialogContentText>
            Select an integration to {addOrUpdate.toLowerCase()}.
          </DialogContentText>
          <InputLabel id="integration-select-label" sx={{ marginTop: "10px" }}>
            Integration
          </InputLabel>
          <Select
            labelId="integration-select-label"
            id="integration-select"
            value={integrationType}
            label="Integration"
            onChange={handleTypeChange}
            fullWidth
            sx={{ marginTop: "5px" }}
          >
            <MenuItem value={IntegrationType.Calendly}>Calendly</MenuItem>
            <MenuItem value={IntegrationType.DocuSign}>DocuSign</MenuItem>
            <MenuItem value={IntegrationType.QuickBooks}>QuickBooks</MenuItem>
            <MenuItem value={IntegrationType.Square}>Square</MenuItem>
            <MenuItem value={IntegrationType.Runway}>Runway</MenuItem>
          </Select>
        </>
      )}
      <IntegrationForm type={integrationType} />
    </Dialog>
  );
};

export default AddIntegrationDialog;
