import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import VerticalStepper from "../../../../components/core/VerticalStepper";
import {
  ExecutionState,
  Process,
  ProcessStructure,
} from "../../../../model/Process";
import { ProcessExecution } from "../../../../model/ProcessExecution";
import { Step } from "../../../../model/step";
import { AssignedRoleUserDto } from "../../../../types";
import { TaskExecution } from "../../../task";
import FlatProcessExecution from "./FlatProcessExecution";

export type VerticalSteppedProcessExecutionProps = {
  assignedRoles: AssignedRoleUserDto[];
  process?: Process;
  processExecution: ProcessExecution;
  steps: Array<Step>;
  stepExecutions: Array<ProcessExecution>;
  taskExecutions: Array<TaskExecution>;
  onShare?: () => void;
};

const VerticalSteppedProcessExecution = ({
  assignedRoles,
  process,
  processExecution,
  steps,
  stepExecutions,
  taskExecutions,
  ...props
}: VerticalSteppedProcessExecutionProps) => {
  return (
    <>
      {process?.structure === ProcessStructure.Flat && (
        <VerticalStepper
          steps={[
            {
              id: "0",
              title: () => (
                <Box sx={{ display: "flex", alignContent: "center" }}>
                  <Box sx={{ flex: 1 }}>
                    <Typography
                      variant="h4"
                      component="h2"
                      sx={{ lineHeight: "34px" }}
                    >
                      {process.name ?? ""}
                    </Typography>
                  </Box>
                </Box>
              ),
              content: () => {
                return (
                  <FlatProcessExecution
                    assignedRoles={assignedRoles}
                    processExecution={processExecution}
                    taskExecutions={taskExecutions}
                    stepId={process.id}
                    onShare={props.onShare}
                  />
                );
              },
              active: true,
            },
          ]}
        />
      )}
      {process?.structure === ProcessStructure.Stepped && (
        <VerticalStepper
          steps={steps.map((step, index) => ({
            id: index.toString(),
            ...step,
            title: () => (
              <Box sx={{ display: "flex", alignContent: "center" }}>
                <Box sx={{ flex: 1 }}>
                  <Typography
                    variant="h4"
                    component="h2"
                    sx={{
                      lineHeight: "34px",
                      fontSize: "22px",
                      fontWeight: 700,
                    }}
                  >
                    {step.name ?? ""}
                  </Typography>
                </Box>
              </Box>
            ),
            content: () => {
              return (
                <FlatProcessExecution
                  assignedRoles={assignedRoles}
                  processExecution={processExecution}
                  taskExecutions={taskExecutions}
                  stepId={step.id}
                  onShare={props.onShare}
                />
              );
            },
            completed:
              stepExecutions?.find((s) => s.process_id == step.id)?.state ==
              ExecutionState.Completed,
            active: step.id == processExecution.next_step_id,
          }))}
        />
      )}
    </>
  );
};
export default VerticalSteppedProcessExecution;
