import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  AutocompleteFormElement,
  CheckboxFormElement,
  DateFormElement,
  FieldDefinitionFormElement,
  FormDefinitionType,
  FormElementType,
  FormExecutionType,
  HeadingFormElement,
  MultiSelectionFormElement,
  NestedFormElement,
  NestedFormElementExecution,
  RadioFormElement,
  SelectionFormElement,
  TaskExecutionComponentProps,
  TaskExecutionState,
  TaskForm,
  TaskFormExecution,
  TaskViews,
  TextFormElement,
  UploadFormElement,
} from "../../../types";
import {
  TaskCheckboxFormComponent,
  TaskMultiSelectFormComponent,
  TaskRadioFormComponent,
  TaskSelectionFormComponent,
  TaskTextFormComponent,
  TaskUploadFormComponent,
} from "./task-form-elements";
import TaskHeadingFormComponent from "./task-form-elements/TaskHeadingFormComponent";
import TaskNestedFormComponent from "./task-form-elements/TaskNestedFormComponent";
import TaskDateFormComponent from "./task-form-elements/TaskDateFormComponent";
import executeFormTask from "./executeFormTask";
import { useAlert } from "../../../../../lib/alert";
import TaskFieldDefinitionFormComponent from "./task-form-elements/TaskFieldDefinitionFormComponent";
import { DEFAULT_RENDER } from "../TaskBody";
import { useGlobalUserContext } from "../../../../../hooks/useGlobalUserContext";
import { useProcessExecutionContext } from "../../../../process/hooks";
import { useAtom } from "jotai";
import {
  taskEditingAtom,
  useTaskExecutionEditingState,
} from "../../../hooks/useTaskExecutionEditingState";
import ConfirmationModal from "../../../../process/components/confirmation-modal/ConfirmationModal";
import { SubmitButton } from "../../../../../components/elements";
import TaskAutocompleteFormComponent from "./task-form-elements/TaskAutocompleteFormComponent";
import { ErrorBoundary } from "react-error-boundary";
import { publicPageAtom } from "../../../../organization/state/publicPageAtom";
import { useNavigate } from "react-router";
import PATHS from "../../../../../components/navigation/_paths";
import { Print } from "@mui/icons-material";
import { useValidation } from "../../../hooks/useValidation";

export type FormExecutionComponentProps = TaskExecutionComponentProps<
  TaskForm,
  TaskFormExecution
> & {
  isShortTaskForm?: boolean;
  elements?: Array<FormDefinitionType>;
  executionElements?: Array<FormExecutionType>;
  removeNestedForm?: () => void;
  hideEditButton?: boolean;
  showPrint?: boolean;
};

const FormExecutionComponent = ({
  children = DEFAULT_RENDER,
  onExecute,
  hideCompleteButton,
  hideEditButton,
  showPrint = false,
  ...props
}: FormExecutionComponentProps) => {
  const navigate = useNavigate();
  const { error } = useAlert();
  const { executeTask } = useProcessExecutionContext();
  const [editing, setEditing] = useAtom(taskEditingAtom);
  const [publicPage] = useAtom(publicPageAtom);

  const { validationFailures } = useValidation();

  const { editable } = useTaskExecutionEditingState(
    props.taskExecution,
    props.task,
  );
  const [openConfirmationModal, setOpenConfirmationModal] =
    useState<boolean>(false);

  const { user } = useGlobalUserContext();

  // instead of doing this check multiple times we can do it once here
  const isEditableAndEnabled = !props.isDisabled && (editable || publicPage);

  // cast input data so TS compiler doesn't bark
  const propsTask = props.task as TaskForm;
  const propsExecution = props.taskExecution as TaskFormExecution | undefined;

  useEffect(() => {
    if (editing === undefined) {
      setEditing(propsExecution?.state !== TaskExecutionState.Completed);
    }
  }, [editing, propsExecution?.state, setEditing]);

  const trueElements = useMemo(() => {
    return props.elements ?? propsTask.data?.form?.elements ?? [];
  }, [props.elements, propsTask.data?.form?.elements]);

  const nested = props.elements && props.elements.length > 0;
  const padDescription =
    props.view === TaskViews.CONDENSED &&
    !!props.task.description &&
    props.task.description.length > 0;

  const contents = useMemo(
    () =>
      trueElements
        .map((element, index) => {
          const formElementExecution = (
            props.executionElements ??
            propsExecution?.data?.form?.elements ??
            []
          ).find(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (e: any) => e.id == element.id,
          );

          const disabled = !(editing ?? !props.isDisabled);
          switch (element.type) {
            case FormElementType.Heading:
              return (
                <TaskHeadingFormComponent
                  {...(element as HeadingFormElement)}
                  nested={nested}
                  key={element.id}
                  removeNestedComponent={props.removeNestedForm}
                  taskExecutionState={props.taskExecution?.state}
                />
              );
            case FormElementType.Text:
              return (
                <TaskTextFormComponent
                  {...(element as TextFormElement)}
                  formId={propsTask.id}
                  key={element.id}
                  disabled={disabled}
                  taskExecutionState={props.taskExecution?.state}
                  onValueChange={(value) => {
                    (trueElements[index] as TextFormElement).value = value;
                    if (props.onValueChange) {
                      props.onValueChange(propsTask);
                    }
                  }}
                  execution={formElementExecution}
                />
              );
            case FormElementType.Radio:
              return (
                <TaskRadioFormComponent
                  {...(element as RadioFormElement)}
                  formId={propsTask.id}
                  key={element.id}
                  disabled={disabled}
                  taskExecutionState={props.taskExecution?.state}
                  onValueChange={(value) => {
                    (trueElements[index] as RadioFormElement).selection = value;
                    if (props.onValueChange) {
                      props.onValueChange(propsTask);
                    }
                  }}
                  execution={formElementExecution}
                  formExecutionComponentProps={props}
                />
              );
            case FormElementType.MultiSelection:
              return (
                <TaskMultiSelectFormComponent
                  {...(element as MultiSelectionFormElement)}
                  formId={propsTask.id}
                  key={element.id}
                  taskExecutionState={props.taskExecution?.state}
                  disabled={disabled}
                  onValueChange={(value) => {
                    (
                      trueElements[index] as MultiSelectionFormElement
                    ).selection = value;
                    if (props.onValueChange) {
                      props.onValueChange(propsTask);
                    }
                  }}
                  execution={formElementExecution}
                />
              );
            case FormElementType.Selection:
              return (
                <TaskSelectionFormComponent
                  {...(element as SelectionFormElement)}
                  formId={propsTask.id}
                  key={element.id}
                  taskExecutionState={props.taskExecution?.state}
                  onValueChange={(value) => {
                    (trueElements[index] as SelectionFormElement).selection =
                      value;
                    if (props.onValueChange) {
                      props.onValueChange(propsTask);
                    }
                  }}
                  execution={formElementExecution}
                  disabled={disabled}
                />
              );
            case FormElementType.Upload:
              return (
                <TaskUploadFormComponent
                  {...(element as UploadFormElement)}
                  formId={propsTask.id}
                  key={element.id}
                  taskExecutionState={props.taskExecution?.state}
                  onValueChange={(value) => {
                    (trueElements[index] as UploadFormElement).value = value;
                    if (props.onValueChange) {
                      props.onValueChange(propsTask);
                    }
                  }}
                  execution={formElementExecution}
                  disabled={disabled}
                />
              );
            case FormElementType.NestedForm:
              return (
                <TaskNestedFormComponent
                  {...props}
                  {...(element as NestedFormElement)}
                  disabled={disabled}
                  taskExecutionState={props.taskExecution?.state}
                  execution={formElementExecution as NestedFormElementExecution}
                  onButtonClick={(value) => {
                    (trueElements[index] as NestedFormElementExecution).value =
                      value;
                    if (props.onValueChange) {
                      props.onValueChange(propsTask);
                    }
                  }}
                  executionElements={
                    (formElementExecution as NestedFormElementExecution)
                      ?.elements
                  }
                  key={element.id}
                />
              );
            case FormElementType.Date:
              return (
                <TaskDateFormComponent
                  {...(element as DateFormElement)}
                  key={element.id}
                  taskExecutionState={props.taskExecution?.state}
                  disabled={disabled}
                  onValueChange={(value) => {
                    (trueElements[index] as DateFormElement).value = value;
                    if (props.onValueChange) {
                      props.onValueChange(propsTask);
                    }
                  }}
                  execution={formElementExecution}
                />
              );
            case FormElementType.Checkbox:
              return (
                <TaskCheckboxFormComponent
                  {...(element as CheckboxFormElement)}
                  key={element.id}
                  taskExecutionState={props.taskExecution?.state}
                  disabled={disabled}
                  onValueChange={(value) => {
                    (trueElements[index] as CheckboxFormElement).value = value;
                    if (props.onValueChange) {
                      props.onValueChange(propsTask);
                    }
                  }}
                  execution={formElementExecution}
                  formExecutionComponentProps={props}
                />
              );
            case FormElementType.FieldDefinition:
              return (
                <TaskFieldDefinitionFormComponent
                  {...(element as FieldDefinitionFormElement)}
                  key={element.id}
                  taskExecutionState={props.taskExecution?.state}
                  disabled={disabled}
                  onValueChange={(newValue) => {
                    const valueIndex = (
                      (trueElements[index] as FieldDefinitionFormElement)
                        .elementValues ?? []
                    ).findIndex((e) => e.id == newValue.elementId);

                    if (valueIndex >= 0) {
                      (
                        trueElements[index] as FieldDefinitionFormElement
                      ).elementValues![valueIndex] = newValue.value;
                    } else {
                      (
                        trueElements[index] as FieldDefinitionFormElement
                      ).elementValues = [
                        ...((trueElements[index] as FieldDefinitionFormElement)
                          .elementValues ?? []),
                        newValue.value,
                      ];
                    }

                    if (props.onValueChange) {
                      props.onValueChange(propsTask);
                    }
                  }}
                  execution={formElementExecution}
                  formExecutionComponentProps={props}
                />
              );
            case FormElementType.Autocomplete:
              return (
                <TaskAutocompleteFormComponent
                  {...(element as AutocompleteFormElement)}
                  formId={propsTask.id}
                  key={element.id}
                  disabled={disabled}
                  onValueChange={(value) => {
                    (trueElements[index] as AutocompleteFormElement).value =
                      value;
                    if (props.onValueChange) {
                      props.onValueChange(propsTask);
                    }
                  }}
                  execution={formElementExecution}
                />
              );
            default:
              console.error(
                `Unable to map form task element with type ${element.type} and id ${element.id}`,
              );
              return;
          }
        })
        .filter((e) => e)
        .map((component) => {
          return (
            <ErrorBoundary
              key={component?.key}
              fallback={
                <Alert severity="warning" sx={{ mt: 1 }}>
                  {`Something went wrong displaying ${
                    component?.props?.label ?? "this element"
                  }`}
                </Alert>
              }
            >
              {component}
            </ErrorBoundary>
          );
        }),
    [
      editing,
      nested,
      props,
      propsExecution?.data?.form?.elements,
      propsTask,
      trueElements,
    ],
  );

  const submitExecution = useCallback(
    async (saveOnly?: boolean) => {
      try {
        const execution = {
          ...propsExecution,
          task_id: propsTask.id,
          state: TaskExecutionState.Completed,
          ...executeFormTask(propsTask, saveOnly, props.taskExecution),
        };

        if (onExecute) {
          await onExecute(execution, !saveOnly);
        } else {
          await executeTask(execution, !saveOnly);
        }

        setEditing(false);
        // Error should be of type any so that it can be used directly in the error
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        error(err.toString());
      }
    },
    [
      error,
      executeTask,
      onExecute,
      props.taskExecution,
      propsExecution,
      propsTask,
      setEditing,
    ],
  );

  const SubmitExecutionButton = useCallback(
    ({
      tooltipTitle,
      buttonText,
      buttonVariant = "contained",
      saveOnly,
    }: {
      tooltipTitle?: string;
      buttonText: string;
      buttonVariant?: "outlined" | "contained";
      saveOnly?: boolean;
    }) => {
      return (
        <Tooltip arrow placement="bottom-start" title={tooltipTitle}>
          <Box>
            <SubmitButton
              variant={buttonVariant}
              disabled={Object.keys(validationFailures).length > 0}
              onSubmit={async () =>
                !saveOnly && props.task?.data?.confirmation
                  ? setOpenConfirmationModal(true)
                  : submitExecution(saveOnly)
              }
            >
              {buttonText}
            </SubmitButton>
          </Box>
        </Tooltip>
      );
    },
    [props.task?.data?.confirmation, submitExecution, validationFailures],
  );

  const EditButtons = useCallback(() => {
    if (hideEditButton) return <></>;
    return (
      <>
        {!editing && (
          <Button
            variant="outlined"
            onClick={() => {
              setEditing(true);
            }}
          >
            Edit
          </Button>
        )}
        {editing &&
          props.taskExecution?.state == TaskExecutionState.Completed && (
            <>
              <Button variant="outlined" onClick={() => setEditing(false)}>
                Cancel
              </Button>
              <SubmitExecutionButton
                tooltipTitle={"Update form submission"}
                buttonText={"Update"}
                buttonVariant={"contained"}
                saveOnly
              />
            </>
          )}
      </>
    );
  }, [
    hideEditButton,
    editing,
    props.taskExecution?.state,
    SubmitExecutionButton,
    setEditing,
  ]);

  const actions = (
    <Grid
      container
      gap={1}
      mt={1}
      width={"100%"}
      justifyContent={"flex-end"}
      alignItems={"center"}
    >
      {props.taskExecution?.state == TaskExecutionState.Completed &&
        props.taskExecution?.completed_at &&
        !nested && (
          <Typography variant="body1">
            {`Completed: ${new Date(
              props.taskExecution?.completed_at,
            ).toLocaleString()}`}
          </Typography>
        )}
      <Box flex={1}></Box>
      {(editable || publicPage) && <EditButtons />}
      {showPrint && (
        <IconButton
          onClick={() =>
            navigate(
              PATHS.EXECUTION_TASK_FORM.linkTo(
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                (props.processExecution?.process_id ?? props.task.process_id)!,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                (props.processExecution?.id ??
                  props.taskExecution?.process_execution_id)!,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                props.task.id!,
              ),
            )
          }
        >
          <Print />
        </IconButton>
      )}
      {props.taskExecution?.state !== TaskExecutionState.Completed &&
        isEditableAndEnabled &&
        !hideCompleteButton && (
          <>
            {user.id && editing && (
              <SubmitExecutionButton
                tooltipTitle={"Save your progress and complete the form later."}
                buttonText={"Save Draft"}
                buttonVariant={"outlined"}
                saveOnly
              />
            )}
            <SubmitExecutionButton
              tooltipTitle={
                "Submitting this form finalizes your entries, and modifications are no longer possible. You will still be able to view your entries."
              }
              buttonText={"Complete"}
            />
          </>
        )}
    </Grid>
  );

  const content = (
    <Grid
      container
      rowSpacing={props.isDisabled || !editing ? 1 : 3}
      direction="column"
      alignContent="stretch"
      pt={padDescription ? 2 : 0}
      pl={nested ? 3 : 0}
      width={"100%"}
      sx={{
        "@media print": {
          paddingBottom: "64px", // Make all content visible for printing
        },
      }}
    >
      {contents}
      <ConfirmationModal
        executeModal={submitExecution}
        openModal={openConfirmationModal}
        confirmationData={props.task?.data?.confirmation}
        handleCloseModal={() => {
          setOpenConfirmationModal(false);
        }}
      />
    </Grid>
  );
  return children({ actions, content });
};

export default FormExecutionComponent;
