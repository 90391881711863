import { AccountTypeAPI } from "../../api";
import PublicExecutor from "../../api/public_executor";
import { AccountType } from "../../model/AccountType";
import { ACCOUNT_TYPE } from "../../model/EntityRef";
import { Process } from "../../model/Process";
import { Task, TaskExecution } from "../task";

export type ProcessMetadataType = {
  process: Process;
  tasks: Task[];
  accountType?: AccountType;
};

export const loadTaskExecutionsByPublicExecutorId = async (
  publicExecutorId: string,
  tasks: Task[],
  organizationId: string,
  executionId?: string,
): Promise<TaskExecution[]> => {
  if (!executionId) {
    return Promise.resolve([]);
  }

  return PublicExecutor.getProcessExecutionTasksByPublicExecutorIdAndProcessExecutionId(
    publicExecutorId,
    organizationId,
    executionId,
  ).then((taskExecutions: TaskExecution[]) => {
    // The task executions come back unordered, sort them according
    // to the order of the tasks
    const newTaskExecutions: TaskExecution[] = [];
    for (const task of tasks) {
      const taskExecution = taskExecutions.find(
        (taskExecution) => taskExecution.task_id == task.id,
      );
      if (taskExecution) {
        newTaskExecutions.push(taskExecution);
      }
    }

    return newTaskExecutions;
  });
};

export const loadTasksByPublicExecutorId = async (
  publicExecutorId: string,
  organizationId: string,
): Promise<ProcessMetadataType> => {
  const process = await PublicExecutor.getTasksByPublicExecutorId(
    publicExecutorId,
    organizationId,
  );
  const tasks = process.tasks || [];

  let accountType;
  if (process.entity_type === ACCOUNT_TYPE && process.entity_id) {
    accountType = await AccountTypeAPI.get(process.entity_id);
  }

  return {
    process,
    tasks,
    accountType,
  };
};
