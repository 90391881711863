import {
  Box,
  Button,
  Checkbox,
  Container,
  IconButton,
  TextField,
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { CheckboxFormElement, TaskCheck } from "../../../types";
import { Close } from "@mui/icons-material";
import { useTaskContext } from "../../../hooks";

const ChecklistInlineEditor = ({ task }: { task: TaskCheck }) => {
  const { setValue } = useTaskContext();

  const [subtasks, setSubtasks] = useState<CheckboxFormElement[]>(
    (task.execution_data?.form?.elements as CheckboxFormElement[]) ?? [],
  );

  const getSubtaskId = useCallback(
    (index: number) => task.id + "_" + index,
    [task.id],
  );

  const createSubtask = () => {
    const newSubtasks = subtasks.slice();

    newSubtasks.push({
      id: getSubtaskId(subtasks.length + 1),
      name: "",
      label: "",
      value: false,
    });

    setSubtasks(newSubtasks);
    setValue({ execution_data: { form: { elements: newSubtasks } } });
  };

  const updateSubtask = useCallback(
    (index: number, patch: Partial<CheckboxFormElement>) => {
      const newSubtasks = subtasks.slice();

      newSubtasks[index] = { ...newSubtasks[index], ...patch };
      setSubtasks(newSubtasks);
      setValue({ execution_data: { form: { elements: newSubtasks } } });
    },
    [setValue, subtasks],
  );

  const deleteSubtask = useCallback(
    (index: number) => {
      const newSubtasks = subtasks.slice();

      newSubtasks.splice(index, 1);

      setSubtasks(newSubtasks);
      setValue({ execution_data: { form: { elements: newSubtasks } } });
    },
    [setValue, subtasks],
  );

  const subtaskList = useMemo(() => {
    return subtasks.map((subtask, index) => {
      const subtaskId = getSubtaskId(index);
      return (
        <Box sx={{ display: "flex", alignItems: "center" }} key={subtaskId}>
          <Checkbox
            checked={subtask.value}
            onChange={(event) =>
              updateSubtask(index, { value: event.target.checked })
            }
          />
          <TextField
            variant="standard"
            value={subtask.label}
            placeholder={`Subtask ${index + 1}`}
            onChange={(event) =>
              updateSubtask(index, { label: event.target.value })
            }
          />
          <IconButton onClick={() => deleteSubtask(index)}>
            <Close />
          </IconButton>
        </Box>
      );
    });
  }, [deleteSubtask, getSubtaskId, subtasks, updateSubtask]);

  return (
    <Container disableGutters>
      {subtaskList}
      <Button variant="outlined" sx={{ mt: 1 }} onClick={() => createSubtask()}>
        Add new Task
      </Button>
    </Container>
  );
};

export default ChecklistInlineEditor;
