import { PropsWithChildren, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { authenticated, login } from "../../lib/auth";

const RequireAuth = ({ children }: PropsWithChildren) => {
  const [contents, setContents] = useState(<></>);

  useEffect(() => {
    if (authenticated()) {
      setContents(<>{children}</>);
    }

    login().then(
      () => {
        setContents(<>{children}</>);
      },
      () => {
        setContents(<Navigate to="/" replace />);
      },
    );
  }, [children]);

  return <>{contents}</>;
};

export default RequireAuth;
