import { Box, Button } from "@mui/material";
import { TaskInlineEditorProps, TaskShortCircuit } from "../../../types";

const ShortCircuitInlineEditor = ({
  task,
}: TaskInlineEditorProps<TaskShortCircuit>) => {
  return (
    <Box>
      <Button
        variant="contained"
        color="error"
        size="small"
        sx={{ m: 1 }}
        disabled={true}
      >
        {task.data?.haltLabel}
      </Button>
      <Button variant="contained" size="small" sx={{ m: 1 }} disabled={true}>
        {task.data?.continueLabel}
      </Button>
    </Box>
  );
};

export default ShortCircuitInlineEditor;
