import { Box, Container, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { setEmailAndCodeForPasswordReset } from "../../lib/auth";
import { useSearchParams } from "react-router-dom";
import { Buffer } from "buffer";
import OrganizationLogo from "../organization/components/OrganizationLogo";
import useAppNavigation from "../../hooks/useAppNavigation";
import SignInComponent from "./components/SignInComponent";

export default function SignInPage() {
  const navigate = useNavigate();
  const { navigateHome } = useAppNavigation();

  const [searchParams] = useSearchParams();
  const [showSignIn, setShowSignIn] = useState(false);

  const manageInvitationLink = "/manage-invitation";
  const resetPasswordLink = "/reset-password";
  const registerLink = "/register";

  useEffect(() => {
    const encodedData = searchParams.get("data");
    const encodedResetData = searchParams.get("resetData");
    // If user is directed from an email link with user and password queries auto login
    if (encodedData) {
      const decodedData = JSON.parse(atob(encodedData));
      navigate(manageInvitationLink, { state: decodedData });
    } else if (encodedResetData) {
      const decodedResetData = JSON.parse(
        Buffer.from(encodedResetData, "base64").toString(),
      );
      setEmailAndCodeForPasswordReset(
        decodedResetData.user,
        decodedResetData.code,
      );
      navigate(resetPasswordLink);
    } else {
      setShowSignIn(true);
    }
  }, [navigate, navigateHome, searchParams]);

  if (!showSignIn) return <></>;
  return (
    <Container component="main" maxWidth={false} sx={{ mt: { xs: 3, md: 6 } }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        maxWidth="xs"
      >
        <Container maxWidth="xs">
          <Box sx={{ p: 2, position: "relative" }}>
            <OrganizationLogo height={72} />
          </Box>
          <SignInComponent
            onSuccess={() => navigateHome()}
            onClickRegister={() => navigate(registerLink)}
          />
        </Container>
      </Grid>
    </Container>
  );
}
