export type AuditEvent = {
  id: string;
  subject_id: string;
  timestamp: string;
  user_id?: string;
  event_type: AuditEventType;
  external_subject_id?: string;
  metadata?: unknown;
  org_id?: string;
  process_id?: string;
  process_execution_id?: string;
  task_id?: string;
  task_execution_id: string;
};

export enum AuditEventType {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
  TASK_EXECUTION_ASSIGNMENT = "TASK_EXECUTION_ASSIGNMENT",
  TASK_EXECUTION_UNASSIGNMENT = "TASK_EXECUTION_UNASSIGNMENT",
  ROLE_ASSIGNMENT = "ROLE_ASSIGNMENT",
  ROLE_UNASSIGNMENT = "ROLE_UNASSIGNMENT",
  SIGN_IN = "SIGN_IN",
  ADD_MEMBER = "ADD_MEMBER",
  REMOVE_MEMBER = "REMOVE_MEMBER",
  TASK_EXECUTION_DATA_SUBMITTED = "TASK_EXECUTION_DATA_SUBMITTED",
  TASK_EXECUTION_COMPLETE = "TASK_EXECUTION_COMPLETE",
  TASK_EXECUTION_ERROR = "TASK_EXECUTION_ERROR",
  PROCESS_EXECUTION_START = "PROCESS_START",
  PROCESS_EXECUTION_COMPLETE = "PROCESS_COMPLETE",
  PUBLISH = "PUBLISH",
  REVOKE_API_KEY = "REVOKE_API_KEY",
}
