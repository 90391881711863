import { FC } from "react";
import { Chip } from "@mui/material";

const StatusChipCell: FC<{
  isPending: boolean;
  label: string;
  isTest?: boolean;
}> = ({ isPending, label, isTest }) => {
  if (isPending) {
    return <Chip label={"Pending"} sx={{ width: 1 }} />;
  }
  return (
    <Chip
      label={label}
      color={isTest ? "warning" : "success"}
      sx={{
        width: 1,
      }}
    />
  );
};

export default StatusChipCell;
