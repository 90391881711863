import Dialog from "../../../../components/Dialog";
import { Account, Organization } from "../../../../model";
import { useState } from "react";
import AccountSelectWrapper from "../AccountSelectWrapper";

type ChooseAccountDialogProps = {
  open: boolean;
  organization: Organization;
  onClose?: () => void;
  onSelect?: (account: Account) => Promise<void>;
};

const ChooseAccountDialog = ({
  open,
  organization,
  onClose,
  onSelect,
}: ChooseAccountDialogProps) => {
  const [account, setAccount] = useState<Account | undefined>();
  return (
    <Dialog
      open={open}
      title="Choose Account"
      confirmText={"Select"}
      cancelText={"Cancel"}
      disableConfirm={!account}
      handleConfirm={async () => {
        if (account && onSelect) {
          await onSelect(account).finally(() => {
            onClose && onClose();
          });
        }
      }}
      handleCancel={() => onClose && onClose()}
      maxWidth={"xs"}
      fullWidth={true}
    >
      <AccountSelectWrapper
        value={account}
        organization={organization}
        onSelect={(option) => {
          // since organizations are not shown, the option will be an account
          if (option) {
            const account = option.data as Account;
            setAccount(account);
          }
        }}
        hideOrganizations
      />
    </Dialog>
  );
};
export default ChooseAccountDialog;
