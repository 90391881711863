import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { Link } from "@mui/material";
import runwayLogo from "../../assets/images/Runway.svg";
import { useNavigate } from "react-router-dom";
import { environment } from "../../util";
import { useEffect } from "react";

export default function LandingPage() {
  const navigate = useNavigate();
  const registerLink = "/register";
  const signInLink = "/sign-in";
  useEffect(() => {
    if (environment.organization?.organizationId) {
      // For now don't allow registration for enterprise organizations
      navigate("/sign-in");
    }
  }, [navigate]);

  if (environment.organization?.organizationId) {
    return <></>;
  }

  return (
    <>
      <AppBar position="relative">
        <Toolbar />
      </AppBar>
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: "background.paper",
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="sm">
            <img alt="Application Wordmark" src={runwayLogo} />
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              {process.env.REACT_APP_ENVIRONMENT == "local" && (
                <Link href={registerLink}>
                  <Button variant="contained">Register</Button>
                </Link>
              )}
              <Link href={signInLink} underline="none">
                <Button variant="outlined">Sign In</Button>
              </Link>
            </Stack>
          </Container>
        </Box>
      </main>
    </>
  );
}
