import { AccountProgram } from "../model/AccountProgram";
import API from "./base";

const create = (program: AccountProgram): Promise<AccountProgram> => {
  return new Promise((resolve, reject) => {
    API.post(`/account-programs/`, program).then(
      (result) => resolve(result.data.payload as AccountProgram),
      reject,
    );
  });
};

const get = (accountProgramId: string): Promise<AccountProgram> => {
  return new Promise((resolve, reject) => {
    API.get(`/account-programs/${accountProgramId}`).then((result) => {
      resolve(result.data.payload as AccountProgram);
    }, reject);
  });
};

const update = (accountProgram: AccountProgram): Promise<AccountProgram> => {
  return new Promise((resolve, reject) => {
    API.put(`/account-programs/${accountProgram.id}`, accountProgram).then(
      (result) => {
        resolve(result.data.payload as AccountProgram);
      },
      reject,
    );
  });
};

const remove = (accountProgramId: string): Promise<void> => {
  return API.delete(`/account-programs/${accountProgramId}`);
};

const loadByAccountId = (accountId: string): Promise<AccountProgram[]> => {
  return new Promise((resolve, reject) => {
    API.get(`/account-programs/load/${accountId}`).then((result) => {
      resolve(result.data.payload as AccountProgram[]);
    }, reject);
  });
};

export default {
  create,
  get,
  update,
  remove,
  loadByAccountId,
};
