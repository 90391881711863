import { FC } from "react";
import { useAlert } from "../../lib/alert";
import AccountUserAffinityList from "./components/AccountUserAffinityList";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress, Box } from "@mui/material";
import { AccountTypeAPI } from "../../api";
import { AccountType } from "../../model/AccountType";
import NotFound from "../app/NotFound";

const AccountTypeAffinityPage: FC<unknown> = () => {
  const { accountTypeId } = useParams();
  const navigate = useNavigate();
  const { error } = useAlert();

  const [accountType, setAccountType] = useState<AccountType | undefined>();

  useEffect(() => {
    if (!accountTypeId) {
      return;
    }

    AccountTypeAPI.get(accountTypeId).then(
      (accountType: AccountType) => {
        setAccountType(accountType);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (err: any) => {
        if (err.statusCode == 404) {
          return <NotFound />;
        }
        error(err);
      },
    );
  }, [accountTypeId, error, navigate]);

  if (!accountTypeId) {
    return <NotFound />;
  }

  return (
    <Box>
      {accountType ? (
        <AccountUserAffinityList accountType={accountType} />
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
};

export default AccountTypeAffinityPage;
