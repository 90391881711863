import { Box, Button } from "@mui/material";
import { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PATHS from "../../../../../components/navigation/_paths";
import ProcessProgress from "../../../../process/components/ProcessProgress";
import ProcessExecutions from "../../../../../api/process_executions";
import { ProcessExecution } from "../../../../../model/ProcessExecution";

type ProcessMonitorExecutionComponentProps = {
  processExecutionId?: string;
};

const ProcessExecutionProgressComponent = ({
  processExecutionId,
}: ProcessMonitorExecutionComponentProps) => {
  const navigate = useNavigate();

  const [processExecution, setProcessExecution] = useState<ProcessExecution>();

  const fetchProcessExecution = useCallback(async () => {
    if (processExecutionId) {
      try {
        const processExecution =
          await ProcessExecutions.getDto(processExecutionId);
        setProcessExecution(processExecution);
      } catch {
        console.error("Failed to fetch process execution");
      }
    }
  }, [processExecutionId]);

  useEffect(() => {
    fetchProcessExecution();
  }, [processExecutionId, fetchProcessExecution]);

  return (
    <>
      <Box sx={{ pt: 2, pb: 2 }}>
        {processExecution && (
          <ProcessProgress processExecution={processExecution} />
        )}
      </Box>
      <Button
        variant="contained"
        sx={{ textTransform: "none" }}
        onClick={() => {
          navigate(
            PATHS.EXECUTION.linkTo(
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              processExecution!.process_id!,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              processExecutionId!,
            ),
          );
        }}
      >
        View Process
      </Button>
    </>
  );
};

export default ProcessExecutionProgressComponent;
