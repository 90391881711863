import { Box, Grid, Typography } from "@mui/material";
import { FC } from "react";
import { HeadingFormElement } from "../../../../types";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import FormElementTaskDescription from "../../../form-builder/FormElementTaskDescription";

type TaskHeadingFormComponentProps = HeadingFormElement & {
  nested?: boolean;
  removeNestedComponent?: () => void;
  required?: boolean; // for use with field definition components
};

const TaskHeadingFormComponent: FC<TaskHeadingFormComponentProps> = ({
  id,
  headingType,
  label,
  name,
  description,
  nested,
  removeNestedComponent,
  required,
}) => {
  return (
    <Grid item id={id}>
      <Box
        sx={(theme) => ({
          background: theme.palette.grey[300],
          padding: 1,
          pl: 2,
          borderRadius: "5px",
          display: "flex",
          alignItems: "center",
        })}
      >
        {nested && <SubdirectoryArrowRightIcon sx={{ mr: 1 }} />}
        <Typography
          sx={(theme) => ({
            color: theme.palette.getContrastText(theme.palette.grey[300]),
          })}
          variant={headingType || "h4"}
          id={name}
        >
          {label}
          {required && (
            <Typography sx={{ display: "inline", color: "red", ml: 0.5 }}>
              *
            </Typography>
          )}
        </Typography>
        {nested && removeNestedComponent && (
          <>
            <Grid item flex={1} />
            <DeleteOutline
              sx={{ mr: 1, cursor: "pointer" }}
              onClick={() => {
                removeNestedComponent();
              }}
            />
          </>
        )}
      </Box>

      <FormElementTaskDescription variant="body1">
        {description}
      </FormElementTaskDescription>
    </Grid>
  );
};

export default TaskHeadingFormComponent;
