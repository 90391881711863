import {
  CircularProgress,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import processes from "../../../../../api/processes";
import messages from "../../../../process/messages";
import { Process } from "../../../../../model/Process";
import { useTaskContext } from "../../../hooks";
import {
  TaskForm,
  TaskFormExecution,
  TaskProcessStart,
  TaskSideSectionEditorProps,
  TaskType,
} from "../../../types";
import ProcessSelect from "../../editor/ProcessSelect";
import FormExecutionComponent from "../form/FormExecutionComponent";
import { json } from "@codemirror/lang-json";
import { CodeMirror } from "../../../../../components/core/CodeMirror/CodeMirror";
import { useGlobalOrganizationContext } from "../../../../../hooks/useGlobalOrganizationContext";
import { useIntl } from "react-intl";

enum ParameterEditor {
  Form,
  Raw,
}

const ProcessStartSideSelectionEditor = ({
  task,
}: TaskSideSectionEditorProps<TaskProcessStart>) => {
  const { formatMessage } = useIntl();
  const taskContext = useTaskContext();
  const { organization } = useGlobalOrganizationContext();
  const [process, setProcess] = useState<Process>();
  const [targetProcess, setTargetProcess] = useState<Process>();
  const [targetProcessExecutionName, setTargetProcessExecutionName] =
    useState<string>();
  const [parameterForm, setParameterForm] = useState<TaskForm>();
  const [parameterEditor, setParameterEditor] = useState(ParameterEditor.Form);
  const [parametersError, setParametersError] = useState<string>("");
  const [parametersRaw, setParametersRaw] = useState<string>("");

  useEffect(() => {
    processes
      .get(task.process_id)
      .then((response) => {
        setProcess(response);
      })
      .catch(() => {
        setProcess(undefined);
      });
  }, [task.process_id]);

  useEffect(() => {
    if (task.data?.targetProcessKey && organization) {
      processes
        .getByKey(task.data?.targetProcessKey, organization.id)
        .then((p) => {
          processes.getTasks(p.id).then((tasks) => {
            const parameterTask = tasks.find((t) => {
              return t.type === TaskType.Form;
            });
            setParameterForm(parameterTask as TaskForm);
          });
          setTargetProcess(p);
        });
    }
  }, [task.data?.targetProcessKey, organization]);
  useEffect(() => {
    if (!parametersRaw) {
      setParametersRaw(
        JSON.stringify(task.execution_data?.parameters, null, "  "),
      );
    }
  }, [task.execution_data?.parameters, parametersRaw]);
  useEffect(() => {
    setTargetProcessExecutionName(
      task.execution_data?.targetProcessExecutionName,
    );
  }, [
    targetProcessExecutionName,
    task.execution_data?.targetProcessExecutionName,
  ]);

  if (!process) {
    return <CircularProgress />;
  }

  return (
    <>
      <Grid item xs={12}>
        <ProcessSelect
          label={`Select a ${formatMessage(messages.process.props)}`}
          organizationId={process.org_id}
          onChange={(selectedProcess) => {
            taskContext.setValue({
              data: {
                ...task.data,
                targetProcessKey: selectedProcess?.process_key,
              },
            });
          }}
          value={targetProcess}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          helperText={`The name that is set on the ${formatMessage(
            messages.execution.props,
          )} started from this task`}
          name={"target-process-execution-name"}
          fullWidth
          value={task.execution_data?.targetProcessExecutionName}
          onChange={(event) => {
            taskContext.setValue({
              execution_data: {
                ...task.execution_data,
                targetProcessExecutionName: event.target.value,
              },
            });
          }}
          label={`Target ${formatMessage(messages.execution.props)} Name`}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          Parameters
        </Typography>
        <Tabs
          value={parameterEditor}
          onChange={(e, value) => setParameterEditor(value)}
        >
          <Tab
            label={
              <Typography
                variant="subtitle2"
                sx={{ display: "flex", alignItems: "center" }}
              >
                Input
              </Typography>
            }
            value={ParameterEditor.Form}
          />
          <Tab
            label={
              <Typography
                variant="subtitle2"
                sx={{ display: "flex", alignItems: "center" }}
              >
                Raw
              </Typography>
            }
            value={ParameterEditor.Raw}
          />
        </Tabs>
      </Grid>

      <Grid item xs={12}>
        {parameterForm && parameterEditor == ParameterEditor.Form && (
          <FormExecutionComponent
            task={parameterForm}
            taskExecution={
              (task.execution_data
                ?.parameters as unknown as TaskFormExecution) ?? {}
            }
            onValueChange={(formTask) => {
              taskContext.setValue({
                execution_data: {
                  ...task.execution_data,
                  parameters: {
                    ...task.execution_data?.parameters,
                    data: formTask.data,
                  },
                },
              });
            }}
            isShortTaskForm={true}
            hideCompleteButton={true}
          />
        )}

        {parameterEditor == ParameterEditor.Raw && (
          <>
            <CodeMirror
              value={parametersRaw}
              height="200px"
              extensions={[json()]}
              onChange={(value: string) => {
                try {
                  setParametersError("");
                  const parsed = JSON.parse(value);
                  taskContext.setValue({
                    execution_data: {
                      parameters: parsed,
                    },
                  });
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                } catch (error: any) {
                  setParametersError(error.toString());
                } finally {
                  setParametersRaw(value);
                }
              }}
            />
            <Typography variant="body1">{parametersError}</Typography>
          </>
        )}
      </Grid>
    </>
  );
};

export default ProcessStartSideSelectionEditor;
