import { FC } from "react";
import { Outlet, useLocation } from "react-router-dom";
import LandingPage from "./LandingPage";
import { environment } from "../../util";
import { useDisplayOptions } from "../../hooks/useDisplayOptions";

const App: FC<unknown> = () => {
  const location = useLocation();

  useDisplayOptions({
    title: environment.app.name,
    displayIcon: environment.app.logoIcon,
  });

  let children = <Outlet />;
  if (location.pathname === "/") {
    children = <LandingPage />;
  }

  return children;
};

export default App;
