import { useParams } from "react-router-dom";
import OrganizationAccountTypePublicProcessCards from "./OrganizationAccountTypePublicProcessCards";
import { useEffect, useState } from "react";
import { AccountType } from "../../../model/AccountType";
import { AccountTypeAPI } from "../../../api";
import NotFound from "../../app/NotFound";
import { useGlobalOrganizationContext } from "../../../hooks/useGlobalOrganizationContext";

const OrganizationAccountTypePublicPage = () => {
  const { organization } = useGlobalOrganizationContext();
  const { accountTypeKey } = useParams<{ accountTypeKey: string }>();
  const [accountType, setAccountType] = useState<AccountType | undefined>();
  const [show404, setShow404] = useState(false);
  useEffect(() => {
    if (organization?.id && accountTypeKey) {
      AccountTypeAPI.byKey(organization?.id, accountTypeKey).then(
        (accountType) => {
          setAccountType(accountType);
        },
        () => {
          setShow404(true);
        },
      );
    }
  }, [accountTypeKey, organization?.id]);
  if (show404) {
    return <NotFound />;
  }
  if (!accountType) {
    return <></>;
  }
  return (
    <OrganizationAccountTypePublicProcessCards accountType={accountType} />
  );
};
export default OrganizationAccountTypePublicPage;
