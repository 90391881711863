import { FC, useState } from "react";
import OrganizationScopedPage from "../../components/OrganizationScopedPage";

import { useDisplayOptions } from "../../hooks/useDisplayOptions";
import CommonOrganizationUsers from "./components/CommonOrganizationUsers";

import InvitationDialog from "./components/InvitationDialog";
import { useGlobalOrganizationContext } from "../../hooks/useGlobalOrganizationContext";

const OrganizationPage: FC<unknown> = () => {
  useDisplayOptions({});

  // State used to hold information about possible organizations the user can switch to
  // and the organization they have selected
  const { organization } = useGlobalOrganizationContext();

  // State used to integrate the invitation call to action hero and the user
  // datagrid
  const [invitationDialogOpen, setInvitationDialogOpen] =
    useState<boolean>(false);

  // State used to power the invitation dialog
  const [invitationsAndUsersDirty, setInvitationsAndUsersDirty] = useState<
    Date | undefined
  >();

  return (
    <OrganizationScopedPage
      category="users"
      displayOrganizationActionMenu={true}
    >
      {organization && (
        <CommonOrganizationUsers
          isDirty={invitationsAndUsersDirty}
          invitationDialogOpen={invitationDialogOpen}
          onOpenInvitationDialog={() => setInvitationDialogOpen(true)}
          organizationId={organization.id}
        />
      )}
      {organization && (
        <InvitationDialog
          organization={organization}
          open={invitationDialogOpen}
          handleInvitation={() => setInvitationsAndUsersDirty(new Date())}
          setInvitationDialogOpen={(openState) => {
            setInvitationDialogOpen(openState);
          }}
        />
      )}
    </OrganizationScopedPage>
  );
};

export default OrganizationPage;
