import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { styled } from "@mui/material";
import ReactQuill, { ReactQuillRefType } from "./ReactQuill";
import { FileAPI } from "../../../api";
import { useAlert } from "../../../lib/alert";
import { useAtom } from "jotai";
import onDropAtom from "../atoms/onDropAtom";
import { MAX_SIZE_MB } from "../../../transfer/FileUploadResponse";

const handleFileUpload = async (
  files: FileList | null,
): Promise<string | undefined> => {
  if (!files) {
    return;
  }

  const fileSizeMb = files[0].size / 1024 ** 2;
  if (fileSizeMb > MAX_SIZE_MB) {
    return Promise.reject();
  }

  try {
    const value = await FileAPI.uploadFile(files);
    const keyedFiles = value.map((fileUploadResponse) => {
      return `${process.env.REACT_APP_REST_API}/files/${encodeURIComponent(
        fileUploadResponse.key,
      )}`;
    });

    return keyedFiles[0];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    console.error(`Error uploading file: ${err}`);
    return undefined;
  }
};

type ReactQuillEditorProps = {
  onChange: (value: string) => void;
  value: string;
  id: string;
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  onAttachment?: (files: FileList | null) => void;
};

const ReactQuillEditor = ({
  onChange,
  value,
  className,
  id,
  placeholder,
  onAttachment,
  disabled = false,
}: ReactQuillEditorProps) => {
  const [, setOnDrop] = useAtom(onDropAtom);
  const { warning } = useAlert();
  const quillRef = useRef<ReactQuillRefType>(null);
  const [keys, setKeys] = useState([""]);
  const attachAndInsertFileLinks = useCallback(
    async (files: FileList) => {
      if (!quillRef.current?.getEditor()) return;
      const quill = quillRef.current.getEditor();
      const selection = quill.getSelection(true);
      let range = selection.index;
      await handleFileUpload(files).then(
        (url) => {
          if (!url || !files) return;
          // Should we insert multiple links here?!
          for (let i = 0; i < files.length; i++) {
            quill.insertText(range, files[i].name, "link", url);
            range = range + files[i].name.length;
            if (i < files.length - 1) {
              quill.insertText(range, " ");
              range++;
            }
          }
          quill.setSelection(range, selection.length);
        },
        () => {
          warning(`File too large.  Max file size: ${MAX_SIZE_MB}MB`);
        },
      );
    },
    [warning],
  );

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike"],
          [{ list: "ordered" }, { list: "bullet" }],
          ["link", "image", "attachment"],
          ["clean"],
        ],
        handlers: {
          image: function () {
            const input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", "image/*");
            input.click();
            input.onchange = async () => {
              if (!quillRef.current?.getEditor()) return;
              const quill = quillRef.current.getEditor();
              const selection = quill.getSelection(true);
              const range = selection.index;
              await handleFileUpload(input.files).then(
                (url) => {
                  quill.insertEmbed(range, "image", url);
                  quill.setSelection(range + 1, selection.length + 1); // Move cursor to right of image
                },
                () => {
                  warning(`File too large.  Max file size: ${MAX_SIZE_MB}MB`);
                },
              );
            };
          },
          attachment: function () {
            const input = document.createElement("input");
            input.setAttribute("type", "file");
            input.click();
            input.onchange = async () => {
              if (input.files) {
                onAttachment
                  ? onAttachment(input.files)
                  : attachAndInsertFileLinks(input.files);
              }
            };
          },
        },
      },
      clipboard: {
        matchVisual: false,
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [attachAndInsertFileLinks, warning],
  );

  useEffect(() => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(value, "text/html");

    const links = htmlDoc.getElementsByTagName("a");
    const images = htmlDoc.getElementsByTagName("img");

    // Handle Links
    let linkSourceKeys: string[] = [""];
    if (links) {
      linkSourceKeys = new Array(links.length);
      for (let i = 0; i < links.length; i++) {
        const linkSource = links[i].href.match("([^/]+$)");
        if (linkSource) {
          linkSourceKeys[i] = linkSource[0];
        }
      }
    }

    // Handle Images
    let imageSourceKeys: string[] = [""];
    if (images) {
      imageSourceKeys = new Array(images.length);
      for (let i = 0; i < images.length; i++) {
        const imageSource = images[i].src.match("([^/]+$)");
        if (imageSource) {
          imageSourceKeys[i] = imageSource[0];
        }
      }
    }

    setKeys(imageSourceKeys.concat(linkSourceKeys));
  }, [value]);

  useEffect(() => {
    setOnDrop((e) => {
      onAttachment
        ? onAttachment(e.dataTransfer.files)
        : attachAndInsertFileLinks(e.dataTransfer?.files);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachAndInsertFileLinks, setOnDrop]);

  // There is a bug in react quill where the editor does not properly
  // update after the readonly value has been toggled.  Related to
  // https://github.com/zenoamaro/react-quill/issues/588 and
  // https://github.com/zenoamaro/react-quill/issues/829.  This is
  // the workaround.  We currently must have two separate editors
  // and toggle between them based on the disabled state until
  // the bug is resolved.
  return disabled ? (
    <ReactQuill
      value={value}
      readOnly={true}
      ref={quillRef}
      placeholder={placeholder}
      modules={modules}
    />
  ) : (
    <ReactQuill
      key={id}
      readOnly={false}
      onChange={
        disabled
          ? undefined
          : (newVal) => {
              onChange(newVal);
              // Check for deleted images in editor and remove them
              keys.map(async (k) => {
                if (k != "" && !newVal.includes(k)) {
                  // XXXX - This code will delete linked files when content is cut and pasted within or without the editor.
                  //        Once deleted, the files cannot be restored.
                  //        Additionally if content is copied between different editors, there may be multiple references
                  //        to the same files.
                  // FileAPI.deleteFile(k);
                }
              });
              const newKeys = keys.filter((k) => k != "" && newVal.includes(k));
              setKeys(newKeys);
            }
      }
      value={value}
      placeholder={placeholder}
      className={className}
      ref={quillRef}
      formats={[
        "bold",
        "italic",
        "underline",
        "strike",
        "list",
        "bullet",
        "link",
        "image",
        "attachment",
      ]}
      modules={modules}
    />
  );
};

const StyledReactQuillEditor = styled(ReactQuillEditor)<{
  borderRadius?: number;
}>(({ borderRadius = 0 }) => {
  return {
    "& .ql-toolbar": {
      borderTopLeftRadius: borderRadius,
      borderTopRightRadius: borderRadius,
    },
    "& .ql-container": {
      maxHeight: "300px",
      overflow: "auto",
      borderBottomLeftRadius: borderRadius,
      borderBottomRightRadius: borderRadius,
    },
  };
});

export default StyledReactQuillEditor;
