import { Paper, PaperProps, SxProps } from "@mui/material";

interface HeroContainerProps extends PaperProps {
  displayTopographic?: boolean;
  sx?: SxProps;
}
const HeroContainer = ({
  variant,
  elevation,
  displayTopographic,
  sx = [],
  children,
}: HeroContainerProps) => {
  return (
    <Paper
      elevation={elevation ?? 4}
      variant={variant}
      sx={[
        //standard styles
        {
          position: "relative",
          display: "inline-block",
          px: 2,
          pt: 4,
          pb: 2,
          zIndex: 1,
          backgroundColor: "background.paper",
          borderRadius: "10px",
          width: "100%",
        },
        //show topographic background image
        displayTopographic && {
          "&::after": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "block",
            width: "100%",
            height: "auto",
            backgroundImage: `url('/assets/images/lightBlueLines.png')`,
            backgroundSize: "cover",
            opacity: 0.07,
            backgroundRepeat: "no-repeat",
            borderRadius: "10px",
            zIndex: -1,
          },
        },
        //apply any styles passed
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Paper>
  );
};
export default HeroContainer;
