import { Box, Button } from "@mui/material";
import {
  TaskAcceptInvitation,
  TaskAcceptInvitationExecution,
  TaskExecutionComponentProps,
  TaskExecutionState,
} from "../../../types";

const AcceptInvitationExecutionComponent = ({
  task,
  taskExecution,
  isDisabled,
  ...props
}: TaskExecutionComponentProps<
  TaskAcceptInvitation,
  TaskAcceptInvitationExecution
>) => {
  const onExecute = (accepted: boolean) => {
    props.onExecute &&
      props
        .onExecute({
          ...taskExecution,
          task_id: task.id,
          state: TaskExecutionState.Completed,
          data: {
            accept: accepted,
          },
        })
        .then(() => {
          // Reload the current browser page
          location.reload();
        });
  };

  return (
    <Box>
      <Button
        variant="contained"
        color="error"
        size="small"
        sx={{ m: 1 }}
        disabled={isDisabled}
        onClick={() => onExecute(false)}
      >
        {task.data?.declineLabel}
      </Button>
      <Button
        variant="contained"
        size="small"
        sx={{ m: 1 }}
        disabled={isDisabled}
        onClick={() => onExecute(true)}
      >
        {task.data?.acceptLabel}
      </Button>
    </Box>
  );
};

export default AcceptInvitationExecutionComponent;
