import { useCallback } from "react";
import { TextFormElement, TextFormElementExecution } from "../../../types";
import { TaskTextFormComponent } from "../../task-types/form/task-form-elements";
import { useFormBuilderContext } from "../FormBuilderProvider";

export const TextInlineDisplay = ({
  element,
  executionData,
}: {
  element: TextFormElement;
  executionData?: TextFormElementExecution;
}) => {
  const { onFormElementChange } = useFormBuilderContext();
  const onValueChange = useCallback(
    (value: string) => {
      const newExecutionData: TextFormElementExecution = {
        ...(executionData ?? {
          id: element.id,
          name: element.name,
          label: element.label,
        }),
        value: value,
      };
      if (value !== executionData?.value) {
        onFormElementChange({
          changeType: "update",
          newExecutionData,
        });
      }
    },
    [
      element.id,
      element.label,
      element.name,
      executionData,
      onFormElementChange,
    ],
  );
  return (
    <TaskTextFormComponent
      disabled={false}
      onValueChange={onValueChange}
      execution={executionData}
      {...element}
    />
  );
};
