import { CompleteUnstructuredExecutionComponent, UnstructuredTypes } from ".";
import { TaskExecutionComponentProps } from "../../../types";
import { Task } from "../../../types/Task";
import { TaskExecution } from "../../../types/TaskExecution";
import CreateOrganizationExecutionComponent from "./create-organization/CreateOrganizationExecutionComponent";
import RegisterExecutionComponent from "./register/RegisterExecutionComponent";
import StripeCheckoutExecutionComponent from "./stripe-checkout/StripeCheckoutExecutionComponent";

const UnstructuredExecutionComponent = (
  props: TaskExecutionComponentProps<Task, TaskExecution>,
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data = props.task.data as any;
  switch (data?.type) {
    case UnstructuredTypes.CreateOrganization:
      return <CreateOrganizationExecutionComponent {...props} />;
    case UnstructuredTypes.Register:
      return <RegisterExecutionComponent {...props} />;
    case UnstructuredTypes.StripeCheckout:
      return <StripeCheckoutExecutionComponent {...props} />;
    default:
      return <CompleteUnstructuredExecutionComponent {...props} />;
  }
};

export default UnstructuredExecutionComponent;
