// TODO likely remove entire frontend task api, replace with multi-task api #307
import { Task } from "../features/task";
import API from "./base";

const create = (task: Task): Promise<Task> => {
  return new Promise((resolve, reject) => {
    API.post("/tasks", task).then(
      (result) => resolve(result.data.payload as Task),
      reject,
    );
  });
};

const get = (processId: string, taskId: string): Promise<Task> => {
  return new Promise((resolve, reject) => {
    API.get("/tasks/" + taskId, { params: { processId: processId } }).then(
      (result) => {
        resolve(result.data.payload as Task);
      },
      reject,
    );
  });
};

const update = (task: Task): Promise<Task> => {
  return new Promise((resolve, reject) => {
    API.put("/tasks/" + task.id, task).then((result) => {
      resolve(result.data.payload as Task);
    }, reject);
  });
};

const remove = (task: Task): Promise<void> => {
  return API.delete("/tasks/" + task.id, {
    params: { processId: task.process_id },
  });
};

const move = (task: Task, processId: string): Promise<void> => {
  return API.put("/tasks/" + task.id, {
    ...task,
    root_process_id: processId,
    process_id: processId,
  });
};

const duplicate = (task: Task): Promise<Task> => {
  //duplicate selected task
  const newTask = { ...task };
  newTask.previous_task_id = task.id;
  delete newTask.id;
  delete newTask.created_at;
  return create(newTask);
};

export default {
  create: create,
  get: get,
  move,
  update: update,
  delete: remove,
  duplicate: duplicate,
};
