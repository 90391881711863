import { Task } from "../features/task";
import { ProcessExecutionDisplay } from "./ProcessExecution";

export enum ProcessStructure {
  Flat = "FLAT",
  Stepped = "STEPPED",
}
export type Process = {
  id: string;
  org_id: string;
  name: string;
  icon?: string;
  description?: string;
  created_by_user_id?: string;
  created_at?: Date;
  updated_at?: Date;
  entity_type?: string;
  entity_id?: string;
  counterparty_can_execute?: boolean;
  structure?: ProcessStructure;
  status: ProcessStatus;
  meta?: string;
  display?: ProcessExecutionDisplay;
  tasks?: Task[];
  category_id?: string;
  category_name?: string;
  entity_type_name?: string;
  entity_type_key?: string;
  process_key: string;
};

export enum ExecutionState {
  Created = "CREATED",
  InProgress = "IN_PROGRESS",
  Completed = "COMPLETED",
}

export enum ProcessStatus {
  Unpublished = "UNPUBLISHED",
  Published = "PUBLISHED",
  Draft = "DRAFT",
  Deleted = "DELETED",
}

export type ProcessExecution = {
  id?: string;
  process_id: string;
  name: string;
  created_by_user_id?: string;
  completed_by_user_id?: string;
  created_at?: Date;
  completed_at?: Date;
  state?: ExecutionState;
  next_task_id?: string;
  parameters?: {
    account?: string;
  };
};
