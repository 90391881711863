import { Box, Button, TextField } from "@mui/material";

const CalendlyScheduledEventInlineEditor = () => {
  return (
    <Box>
      <TextField
        disabled
        id="filled-disabled"
        label="Meeting Date"
        variant="filled"
        size="small"
      />
      <Button variant="contained" size="small" sx={{ m: 1 }} disabled>
        {"Mark Complete"}
      </Button>
    </Box>
  );
};

export default CalendlyScheduledEventInlineEditor;
