import axios from "axios";
import { authenticated, login, refreshLogin, tokens } from "../lib/auth";

declare module "axios" {
  interface AxiosRequestConfig {
    public?: boolean;
    format?: "json" | "xlsx";
  }
}

const API = axios.create({
  baseURL: `${process.env.REACT_APP_REST_API}`,
});

// Request interceptor for API calls
API.interceptors.request.use(
  async (config) => {
    // TODO - move this logic to gated pages
    if (!config.public && !authenticated()) {
      await login();
    }
    if (config.format) {
      config.url += `?format=${config.format}`;
      config.responseType = "blob";
    }

    const accessToken = tokens.accessToken;
    if (accessToken) {
      // Don't populate for public requests
      config.headers.set("Authorization", `Bearer ${accessToken}`);
    }
    config.headers.set("Accept", "application/json");
    if (!config.headers.get("Content-Type")) {
      config.headers.set("Content-Type", "application/json");
    }
    config.headers.set(
      "runway-mode",
      localStorage.getItem("runway.testtoggle"),
    );
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

// Response interceptor for API calls
API.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (
      error.response?.status === 403 &&
      !originalRequest._retry &&
      authenticated()
    ) {
      originalRequest._retry = true;

      try {
        await refreshLogin();
      } catch (e) {
        // If the refresh token is invalid, redirect to the sign-in page
        // This cannot be done via navigate because we are not ensured
        // to be in a react-component or context when this is invoked
        window.location.href = "/sign-in";
        return Promise.reject(error);
      }

      axios.defaults.headers.common["Authorization"] =
        "Bearer " + tokens.accessToken;
      return API(originalRequest);
    }
    return Promise.reject(error);
  },
);

// The unauthenticated API optionally uses a bearer and refresh token if one is present
export const unauthenticatedAPI = axios.create({
  baseURL: `${process.env.REACT_APP_REST_API}`,
});

// Request interceptor for API calls
unauthenticatedAPI.interceptors.request.use(
  async (config) => {
    config.headers.set("Accept", "application/json");
    config.headers.set("Content-Type", "application/json");
    config.headers.set(
      "runway-mode",
      localStorage.getItem("runway.testtoggle"),
    );

    // Don't attempt to login, just use the existing access token
    // if one is already present
    const accessToken = tokens.accessToken;
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

// Response interceptor for API calls
unauthenticatedAPI.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (
      error.response?.status === 403 &&
      !originalRequest._retry &&
      authenticated()
    ) {
      originalRequest._retry = true;

      try {
        await refreshLogin();
      } catch (e) {
        return Promise.reject(error);
      }

      axios.defaults.headers.common["Authorization"] =
        "Bearer " + tokens.accessToken;
      return unauthenticatedAPI(originalRequest);
    }
    return Promise.reject(error);
  },
);

export default API;
