import { useEffect, useState } from "react";
import { ChatAPI } from "../../api";
import { Thread } from "./model";
import Chat from "./Chat";
import { Provider } from "jotai";

export type SubjectChatProps = {
  subjectId: string;
};
const SubjectChat = ({ subjectId }: SubjectChatProps) => {
  const [threads, setThreads] = useState<Thread[]>();
  useEffect(() => {
    ChatAPI.getChatsForSubject(subjectId).then(
      (subjectThreads) => {
        setThreads(subjectThreads);
      },
      () => {
        // TODO - display warning in chat
      },
    );
  }, [subjectId]);
  if (!threads || threads.length === 0) {
    return <></>;
  }
  // Wrap in provider so that drag and drop context is scoped to the Chat widget only
  return (
    <Provider>
      <Chat threadId={threads[0].id} />
    </Provider>
  );
};
export default SubjectChat;
