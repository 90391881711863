import API from "./base";
import { Executor } from "../features/task/components/execution/types/ExecutorModel";

const byProcessId = (processId: string): Promise<Executor[]> => {
  return new Promise((resolve, reject) => {
    API.get(`/processes/${processId}/executors`).then(
      (result) => resolve(result.data.payload as Executor[]),
      reject,
    );
  });
};

const create = (processId: string, executor: Executor): Promise<Executor> => {
  return new Promise((resolve, reject) => {
    API.post(`/processes/${processId}/executors`, executor).then(
      (result) => resolve(result.data.payload as Executor),
      reject,
    );
  });
};

const update = (id: string, executor: Executor): Promise<Executor> => {
  return new Promise((resolve, reject) => {
    API.put(`/processes/${executor.process_id}/executors/${id}`, executor).then(
      (result) => resolve(result.data.payload as Executor),
      reject,
    );
  });
};

const remove = (processId: string, executorId: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    API.delete(`/processes/${processId}/executors/${executorId}`).then(
      () => resolve(),
      reject,
    );
  });
};

export default {
  byProcessId,
  create,
  update,
  remove,
};
