import { Box, BoxProps, SxProps, Theme } from "@mui/material";
import { PropsWithChildren } from "react";

export type PageContainerVariant = "padded" | "full";

export type PageContainerProps = PropsWithChildren<{
  variant?: PageContainerVariant;
  GridProps?: BoxProps;
}>;
const VARIANTS: Record<PageContainerVariant, SxProps<Theme>> = {
  padded: {
    p: 3,
    width: "100%",
  },
  full: {
    p: 0,
    height: "100%",
    flexDirection: "column",
    width: "100%",
  },
};
const PageContainer = ({
  variant = "padded",
  GridProps = {},
  children,
}: PageContainerProps) => {
  return (
    <Box
      id="page-container"
      display={"flex"}
      flexDirection="column"
      sx={VARIANTS[variant]}
      rowGap={2}
      {...GridProps}
    >
      {children}
    </Box>
  );
};
export default PageContainer;
