import { Box, Typography } from "@mui/material";
import { Organization } from "../../../model";
import { environment } from "../../../util";

export type OrganizationLogoProps = {
  organization?: Organization;
  onClick?: () => void;
  height?: number;
};
const OrganizationLogo = ({
  organization,
  onClick,
  height = 48,
}: OrganizationLogoProps) => {
  const isEnterprise = !!environment.organization?.key;
  const logo =
    isEnterprise || !organization
      ? environment.app.logo
      : organization?.theme?.longLogoUrl;
  return (
    <>
      {!logo && (
        <Typography
          variant="h4"
          component="h1"
          fontWeight="bold"
          sx={{
            textAlign: "center",
            marginTop: "18px",
            marginBottom: "10px",
          }}
        >
          {organization?.name}
        </Typography>
      )}
      {logo && (
        <Box
          sx={{
            height: `${height}px`,
            minHeight: `${height}px`,
            mx: 2,
            my: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={logo}
            style={{
              maxHeight: "100%",
              maxWidth: "100%",
              height: "auto",
              width: "auto",
              cursor: "pointer",
            }}
            onClick={onClick}
          />
        </Box>
      )}
    </>
  );
};
export default OrganizationLogo;
