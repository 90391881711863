import { FormDefinitionType, SelectionOption } from "../features/task";

export enum FieldDefinitionTypes {
  AccountType = "ACCOUNT_TYPE",
  Organization = "ORGANIZATION",
}

export type FieldValueObject = {
  [key: string]: FieldValue;
};
export type FieldValue =
  | string
  | string[]
  | number
  | boolean
  | SelectionOption
  | SelectionOption[]
  | { source: string; contentType?: string | undefined }[];
export default class FieldDefinition {
  public id = "";

  public label = "";

  public entity_type: FieldDefinitionTypes = FieldDefinitionTypes.Organization;

  public entity_id = "";

  public parent_id?: string;

  public updated_at?: Date;

  public meta = "";

  public data: { elements: FormDefinitionType[] } = { elements: [] };
}
