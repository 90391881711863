/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Box, Button, Card, Container, Grid, Typography } from "@mui/material";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { CardActionBar, CardTitle } from "../../../components/elements";
import { useGlobalOrganizationContext } from "../../../hooks/useGlobalOrganizationContext";
import { Process } from "../../../model/Process";
import { CARD_HEIGHT } from "./constants";
type OrganizationPublicProcessCardsProps = {
  processes: Array<Process>;
};
const OrganizationPublicProcessCards = ({
  processes,
}: OrganizationPublicProcessCardsProps) => {
  const navigate = useNavigate();
  const { organization } = useGlobalOrganizationContext();
  const navigateToPublicProcess = useCallback(
    (process: Process) => {
      const path = `/${organization!.key}/works/${
        process.process_key ?? process.id
      }`;
      navigate(path);
    },
    [navigate, organization],
  );
  return (
    <Container maxWidth="lg">
      <Grid
        container
        sx={{
          alignContent: "center",
          alignItems: "center",
          justifyContent: "flex-start",
          p: 3,
        }}
        spacing={3}
      >
        {processes?.length === 1 && <Grid item xs={12} md={3} lg={3} xl={3} />}
        {processes?.map((process) => (
          <Grid item xs={12} md={6} lg={6} xl={6} key={process.id}>
            <Card
              elevation={1}
              sx={{
                minHeight: CARD_HEIGHT,
                maxHeight: CARD_HEIGHT,
                position: "relative",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box sx={{ p: 2, pb: 0, flex: 0 }}>
                <CardTitle
                  variant="h2"
                  component="h1"
                  icon={process.icon}
                  sx={{ lineHeight: 1, alignItems: "start" }}
                >
                  {process.name}
                </CardTitle>
              </Box>
              <Box sx={{ px: 2, flex: 1, overflow: "scroll" }}>
                <Typography>{process.description}</Typography>
              </Box>
              <CardActionBar>
                <Button
                  variant="contained"
                  onClick={() => navigateToPublicProcess(process)}
                >
                  Start
                </Button>
              </CardActionBar>
            </Card>
          </Grid>
        ))}
        {processes?.length === 1 && <Grid item xs={12} md={3} lg={3} xl={3} />}
      </Grid>
    </Container>
  );
};
export default OrganizationPublicProcessCards;
