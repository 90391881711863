import Dashboard from "../model/Dashboard";
import API from "./base";

const create = (dashboard: Dashboard): Promise<Dashboard> => {
  return new Promise((resolve, reject) => {
    API.post("/dashboards", dashboard).then(
      (result) => resolve(result.data.payload as Dashboard),
      reject,
    );
  });
};

const get = (dashboardId: string): Promise<Dashboard> => {
  return new Promise((resolve, reject) => {
    API.get("/dashboards/" + dashboardId).then((result) => {
      resolve(result.data.payload as Dashboard);
    }, reject);
  });
};

const update = (dashboard: Dashboard): Promise<Dashboard> => {
  return new Promise((resolve, reject) => {
    API.put("/dashboards/" + dashboard.id, dashboard).then((result) => {
      resolve(result.data.payload as Dashboard);
    }, reject);
  });
};

// Delete not allowed as variable name
const remove = (dashboardId: string): Promise<void> => {
  return API.delete("/dashboards/" + dashboardId);
};

const byOrganization = (organizationId: string): Promise<Array<string>> => {
  return new Promise((resolve, reject) => {
    API.get("/dashboards/byOrganization/" + organizationId).then((result) => {
      resolve(result.data.payload as Array<string>);
    }, reject);
  });
};

const byKey = (
  organizationKey: string,
  dashboardKey: string,
): Promise<Dashboard> => {
  return new Promise((resolve, reject) => {
    API.get(
      `/dashboards/byOrganization/${organizationKey}/byKey/${dashboardKey}`,
      {
        public: true,
      },
    ).then((result) => {
      resolve(result.data.payload as Dashboard);
    }, reject);
  });
};

const byUser = (organizationId: string, userId: string): Promise<Dashboard> => {
  return new Promise((resolve, reject) => {
    API.get(
      `/dashboards/byOrganization/${organizationId}/byUser/${userId}`,
    ).then((result) => {
      resolve(result.data.payload as Dashboard);
    }, reject);
  });
};

export default {
  create: create,
  get: get,
  update: update,
  delete: remove,
  byOrganization: byOrganization,
  byKey: byKey,
  byUser: byUser,
};
