import { FC } from "react";
import { Box } from "@mui/material";
import { useDisplayOptions } from "../../hooks/useDisplayOptions";
import { useGlobalUserContext } from "../../hooks/useGlobalUserContext";
import { CommonAccountOrganizations } from "./components/data-grid";
import { OrganizationAccountsProvider } from "../../providers";

const AccountsIsMemberOfListPage: FC<unknown> = () => {
  useDisplayOptions({ showDrawer: false });
  const { user } = useGlobalUserContext();

  return (
    <Box>
      <CommonAccountOrganizations
        accounts={OrganizationAccountsProvider}
        userId={user.id}
      />
    </Box>
  );
};

export default AccountsIsMemberOfListPage;
