import {
  Task,
  TaskCheckExecution,
  TaskEmail,
  TaskEmailExecutionData,
  TaskExecution,
  TaskExecutionComponentProps,
  TaskForm,
  TaskInlineEditorProps,
  TaskShortCircuit,
  TaskSideSectionEditorProps,
  TaskType,
} from "../../types";
import AcceptInvitationExecutionComponent from "./accept-invitation/AcceptInvitationExecutionComponent";
import AcceptInvitationInlineEditor from "./accept-invitation/AcceptInvitationInlineEditor";
import AssignmentExecutionComponent from "./assignment/AssignmentExecutionComponent";
import AssignmentInlineEditor from "./assignment/AssignmentInlineEditor";
import AssignmentSideSectionEditor from "./assignment/AssignmentSideSectionEditor";
import CalendlyEventInlineEditor from "./calendly/event/CalendlyEventInlineEditor";
import CalendlyEventSideSectionEditor from "./calendly/event/CalendlyEventSideSectionEditor";
import CalendlyScheduledEventExecutionComponent from "./calendly/scheduled-event/CalendlyScheduledEventExecutionComponent";
import CalendlyScheduledEventInlineEditor from "./calendly/scheduled-event/CalendlyScheduledEventInlineEditor";
import CalendlyScheduledEventSideSectionEditor from "./calendly/scheduled-event/CalendlyScheduledEventSideSectionEditor";
import EmailExecutionComponent from "./email/EmailExecutionComponent";
import EmailInlineEditor from "./email/EmailInlineEditor";
import EmailSideSectionEditor from "./email/EmailSideSectionEditor";
import FormInlineEditor from "./form/FormInlineEditor";
import FormSideSectionEditor from "./form/FormSideSectionEditor";
import FormExecutionComponentWrapper from "./form/FormExecutionComponentWrapper";
import ShortCircuitExecutionComponent from "./short-circuit/ShortCircuitExecutionComponent";
import ShortCircuitInlineEditor from "./short-circuit/ShortCircuitInlineEditor";
import ShortCircuitSideSectionEditor from "./short-circuit/ShortCircuitSideSectionEditor";
import SquareLinkExecutionComponent from "./square/link/SquareLinkExecutionComponent";
import OAuthLinkExecutionComponent from "./oauth/OAuthLinkExecutionComponent";
import CreateAccountExecutionComponent from "./account/CreateAccountExecutionComponent";
import CreateAccountInlineEditor from "./account/CreateAccountInlineEditor";
import CreateAccountSideSectionEditor from "./account/CreateAccountSideSectionEditor";
import ProcessStartSideSelectionEditor from "./process-start/ProcessStartSideSelectionEditor";
import ProcessStartExecutionComponent from "./process-start/ProcessStartExecutionComponent";
import ProcessMonitorExecutionComponent from "./process-monitor/ProcessMonitorExecutionComponent";
import ProcessStartInlineEditor from "./process-start/ProcessStartInlineEditor";
import RunwayApiCallExecutionComponent from "./runway-api-call/RunwayApiCallExecutionComponent";
import RunwayApiCallInlineEditor from "./runway-api-call/RunwayApiCallInlineEditor";
import RunwayApiCallSideSectionEditor from "./runway-api-call/RunwayApiCallSideSectionEditor";
import BeginPhaseExecutionComponent from "./phase/BeginPhaseExecutionComponent";
import BeginPhaseInlineEditor from "./phase/BeginPhaseInlineEditor";
import executeFormTask from "./form/executeFormTask";
import { CommonRole } from "../../../../types";
import { Process } from "../../../../model/Process";
import { ACCOUNT_TYPE } from "../../../../model/EntityRef";
import UnstructuredExecutionComponent from "./unstructured/UnstructuredExecutionComponent";
import UnstructuredInlineEditor from "./unstructured/UnstructuredInlineEditor";
import UnstructuredSideSectionEditor from "./unstructured/UnstructuredSideSectionEditor";
import ContentExecutionComponent from "./content/ContentExecutionComponent";
import ContentInlineEditor from "./content/ContentInlineEditor";
import { authenticated } from "../../../../lib/auth";
import { Organization } from "../../../../model";
import { TaskContent } from "../../types/task-types/TaskContent";
import ChecklistInlineEditor from "./check/ChecklistInlineEditor";
import CheckExecutionComponentWrapper from "./check/CheckExecutionComponentWrapper";

type TaskTypeComponents = {
  ExecutionComponent?: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props: TaskExecutionComponentProps<any, any>,
  ) => JSX.Element;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  InlineEditor?: (props: TaskInlineEditorProps<any>) => JSX.Element;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  SideSectionEditor?: (props: TaskSideSectionEditorProps<any>) => JSX.Element;
  executeTask?: (task: Task, validate?: boolean) => TaskExecution;
  create?: (process: Process, defaultTask: Task) => Task;
  getTitle?: (
    process: Process,
    task: Task,
    isPublicPage: boolean,
  ) => React.ReactNode;
  getDescription?: (
    organization: Organization,
    process: Process,
    task: Task,
    taskExecution?: TaskExecution,
    isPublicPage?: boolean,
  ) => string;
};

const taskByType: Map<TaskType, TaskTypeComponents> = new Map([
  [
    TaskType.AcceptInvitation,
    {
      ExecutionComponent: AcceptInvitationExecutionComponent,
      InlineEditor: AcceptInvitationInlineEditor,
    },
  ],
  [
    TaskType.Assignment,
    {
      ExecutionComponent: AssignmentExecutionComponent,
      InlineEditor: AssignmentInlineEditor,
      SideSectionEditor: AssignmentSideSectionEditor,
    },
  ],
  [
    TaskType.BeginPhase,
    {
      ExecutionComponent: BeginPhaseExecutionComponent,
      InlineEditor: BeginPhaseInlineEditor,
    },
  ],
  [
    TaskType.CalendlyEvent,
    {
      ExecutionComponent: EmailExecutionComponent,
      InlineEditor: CalendlyEventInlineEditor,
      SideSectionEditor: CalendlyEventSideSectionEditor,
    },
  ],
  [
    TaskType.CalendlyScheduledEvent,
    {
      ExecutionComponent: CalendlyScheduledEventExecutionComponent,
      InlineEditor: CalendlyScheduledEventInlineEditor,
      SideSectionEditor: CalendlyScheduledEventSideSectionEditor,
    },
  ],
  [
    TaskType.Check,
    {
      ExecutionComponent: CheckExecutionComponentWrapper,
      InlineEditor: ChecklistInlineEditor,
      executeTask: (task: Task) => {
        if (!task.data) {
          return {
            data: {
              value: false,
            },
          };
        }
        return {
          data: {
            value: true,
          },
        };
      },
      getDescription: (
        organization: Organization,
        process: Process,
        task: Task,
        taskExecution?: TaskExecution,
      ) => {
        return (
          (taskExecution as TaskCheckExecution | undefined)?.data
            ?.description ??
          task.description ??
          ""
        );
      },
    },
  ],
  [
    TaskType.CreateAccount,
    {
      ExecutionComponent: CreateAccountExecutionComponent,
      InlineEditor: CreateAccountInlineEditor,
      SideSectionEditor: CreateAccountSideSectionEditor,
      getTitle: (process: Process, task: Task, isPublicPage: boolean) => {
        const isLoggedIn = authenticated();
        if (!isLoggedIn || isPublicPage) {
          return `Enter Your Email`;
        }
        return;
      },
      getDescription: (
        organization: Organization,
        process: Process,
        task: Task,
        taskExecution?: TaskExecution,
        isPublicPage?: boolean,
      ) => {
        const isLoggedIn = authenticated();
        if (!isLoggedIn || isPublicPage) {
          return `Enter the email used to correspond with ${organization.name}.`;
        }
        return task.description ?? "";
      },
    },
  ],
  [
    TaskType.DocuSign,
    {
      ExecutionComponent: EmailExecutionComponent,
    },
  ],
  [
    TaskType.Email,
    {
      ExecutionComponent: EmailExecutionComponent,
      InlineEditor: EmailInlineEditor,
      SideSectionEditor: EmailSideSectionEditor,
      create: (process: Process, defaultTask: Task) => {
        let execution_data: TaskEmailExecutionData = {
          to: [],
        };
        if (process.entity_type === ACCOUNT_TYPE) {
          execution_data = {
            to: ["${Account.primary_email}"],
          };
        }
        const task: TaskEmail = {
          ...(defaultTask as TaskEmail),
          execution_role_id: CommonRole.AUTOMATION,
          execution_data,
        };
        return task;
      },
    },
  ],
  [
    TaskType.Form,
    {
      ExecutionComponent: FormExecutionComponentWrapper,
      InlineEditor: FormInlineEditor,
      SideSectionEditor: FormSideSectionEditor,
      executeTask: executeFormTask,
      create: (process: Process, defaultTask: Task) => {
        const task: TaskForm = {
          ...(defaultTask as TaskForm),
          is_context: true,
        };
        return task;
      },
    },
  ],
  [
    TaskType.OAuthLink,
    {
      ExecutionComponent: OAuthLinkExecutionComponent,
    },
  ],
  [
    TaskType.ProcessStart,
    {
      ExecutionComponent: ProcessStartExecutionComponent,
      SideSectionEditor: ProcessStartSideSelectionEditor,
      InlineEditor: ProcessStartInlineEditor,
    },
  ],
  [
    TaskType.ProcessMonitor,
    {
      ExecutionComponent: ProcessMonitorExecutionComponent,
    },
  ],
  [
    TaskType.RunwayApiCall,
    {
      ExecutionComponent: RunwayApiCallExecutionComponent,
      InlineEditor: RunwayApiCallInlineEditor,
      SideSectionEditor: RunwayApiCallSideSectionEditor,
    },
  ],
  [
    TaskType.ShortCircuit,
    {
      ExecutionComponent: ShortCircuitExecutionComponent,
      InlineEditor: ShortCircuitInlineEditor,
      SideSectionEditor: ShortCircuitSideSectionEditor,
      create: (process: Process, defaultTask: Task) => {
        const task: TaskShortCircuit = {
          ...(defaultTask as TaskShortCircuit),
          execution_role_id: CommonRole.AUTOMATION,
          data: {
            haltLabel: "Halt",
            continueLabel: "Continue",
          },
        };
        return task;
      },
    },
  ],
  [
    TaskType.SquareLink,
    {
      ExecutionComponent: SquareLinkExecutionComponent,
    },
  ],
  [TaskType.SquareFetch, {}],
  [
    TaskType.Unstructured,
    {
      ExecutionComponent: UnstructuredExecutionComponent,
      InlineEditor: UnstructuredInlineEditor,
      SideSectionEditor: UnstructuredSideSectionEditor,
    },
  ],
  [
    TaskType.Content,
    {
      ExecutionComponent: ContentExecutionComponent,
      InlineEditor: ContentInlineEditor,
      create: (process: Process, defaultTask: Task) => {
        const task: TaskContent = {
          ...(defaultTask as TaskContent),
          is_context: true,
        };
        return task;
      },
    },
  ],
]);

export const getTaskByType = (type?: TaskType) => {
  return type && taskByType.get(type);
};

const DEFAULT_EXECUTE_TASK = (task: Task) => ({
  data: task.data,
});

export const createTaskExecution = (
  task: Task,
  validate?: boolean,
): TaskExecution => {
  const executeTaskFn =
    getTaskByType(task.type)?.executeTask ?? DEFAULT_EXECUTE_TASK;
  return {
    task,
    task_id: task.id,
    ...executeTaskFn(task, validate),
  } as TaskExecution;
};

export const createTaskExecutions = (
  tasks: Array<Task>,
  validate?: boolean,
) => {
  return tasks.map((task) => createTaskExecution(task, validate));
};

const DEFAULT_TASK_CONSTRUCTOR = (process: Process, task: Task) => {
  return task;
};

export const createTask = (process: Process, task: Task) => {
  const taskTypeComponents = getTaskByType(task.type);
  if (!taskTypeComponents) {
    return task;
  }
  const constructor = taskTypeComponents.create ?? DEFAULT_TASK_CONSTRUCTOR;
  return constructor(process, task);
};

export const getTitle = (
  organization: Organization,
  process: Process,
  task: Task,
  isPublicPage: boolean,
) => {
  const taskTypeComponents = getTaskByType(task.type);
  if (taskTypeComponents && taskTypeComponents.getTitle) {
    return taskTypeComponents.getTitle(process, task, isPublicPage);
  }
  return task.name;
};

export const getDescription = (
  organization: Organization,
  process: Process,
  task: Task,
  taskExecution?: TaskExecution,
  isPublicPage?: boolean,
) => {
  const taskTypeComponents = getTaskByType(task.type);
  if (taskTypeComponents && taskTypeComponents.getDescription) {
    return taskTypeComponents.getDescription(
      organization,
      process,
      task,
      taskExecution,
      isPublicPage || false,
    );
  }
  return task.description;
};
