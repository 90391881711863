import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { TaskForm, TaskInlineEditorProps } from "../../../types";
import PATHS from "../../../../../components/navigation/_paths";

const FormInlineEditor = ({ task }: TaskInlineEditorProps<TaskForm>) => {
  const navigate = useNavigate();
  return (
    <Box component="section" sx={{ pt: 2 }}>
      <Button
        variant="outlined"
        onClick={() => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          navigate(PATHS.PROCESS_TASK.linkTo(task.process_id, task.id!));
        }}
      >
        {task.data?.form && task.data?.form?.elements.length > 0
          ? "View/Edit Form"
          : "Build Form"}
      </Button>
    </Box>
  );
};

export default FormInlineEditor;
