import { FC } from "react";
import {
  OrganizationAccountInvitationsProvider,
  OrganizationAccountsProvider,
} from "../../providers";
import OrganizationScopedPage from "../../components/OrganizationScopedPage";
import { CommonOrganizationAccounts } from "./components";
import useAccountType from "./hooks/useAccountType";
import { useGlobalOrganizationContext } from "../../hooks/useGlobalOrganizationContext";

const AccountTypeAccountsListPage: FC<unknown> = () => {
  const { organization } = useGlobalOrganizationContext();
  const accountType = useAccountType();

  return (
    <OrganizationScopedPage
      category="accounts"
      displayOrganizationActionMenu={false}
    >
      {organization && accountType?.id && (
        <CommonOrganizationAccounts
          key={accountType?.id}
          organizationId={organization.id}
          accounts={OrganizationAccountsProvider}
          fetchAccounts={(download) =>
            OrganizationAccountsProvider.fetchAccountsByOrganizationAndAccountType(
              organization.id,
              accountType?.id,
              download,
            )
          }
          accountType={accountType}
          invitations={OrganizationAccountInvitationsProvider}
          allowInvitation={false}
        />
      )}
    </OrganizationScopedPage>
  );
};

export default AccountTypeAccountsListPage;
