import {
  CheckBox,
  Wysiwyg,
  Email,
  AssignmentInd,
  PlayCircleFilledWhiteRounded,
  CalendarToday,
  CalendarMonth,
  Square,
  PersonAddAlt,
  Link,
  Route,
  Api,
  AirlineStops,
  Category,
  Description,
} from "@mui/icons-material";
import { SvgIconProps } from "@mui/material";

export enum TaskType {
  Check = "CHECK",
  Form = "FORM",
  Email = "EMAIL",
  Assignment = "ASSIGNMENT",
  ShortCircuit = "SHORT_CIRCUIT",
  CalendlyEvent = "CALENDLY_EVENT",
  CalendlyScheduledEvent = "CALENDLY_SCHEDULED_EVENT",
  SquareFetch = "SQUARE_FETCH",
  SquareLink = "SQUARE_LINK",
  DocuSign = "DOCUSIGN",
  Invitation = "INVITATION",
  AcceptInvitation = "ACCEPT_INVITATION",
  UserAssignment = "USER_ASSIGNMENT",
  CompleteProcessDependency = "COMPLETE_PROCESS_DEPENDENCY",
  ProcessStart = "PROCESS_START",
  ProcessMonitor = "PROCESS_MONITOR",
  CreateAccount = "CREATE_ACCOUNT",
  RunwayApiCall = "RUNWAY_API_CALL",
  OAuthLink = "OAUTH_LINK",
  BeginPhase = "BEGIN_PHASE",
  Unstructured = "UNSTRUCTURED",
  Content = "CONTENT",
}

export type TaskTypeOption = {
  disabled?: boolean;
  type: TaskType;
  icon: React.ComponentType<SvgIconProps>;
  title: string;
};

export const TaskTypes: Array<TaskTypeOption> = [
  { type: TaskType.Check, icon: CheckBox, title: "Check" },
  {
    type: TaskType.Form,
    icon: Wysiwyg,
    title: "Form",
  },
  { type: TaskType.Email, icon: Email, title: "Email" },
  { type: TaskType.Assignment, icon: AssignmentInd, title: "Assign" },
  { type: TaskType.ShortCircuit, icon: Route, title: "Short Circuit" },
  {
    type: TaskType.ProcessStart,
    icon: PlayCircleFilledWhiteRounded,
    title: "Process Start",
  },
  {
    type: TaskType.CalendlyEvent,
    icon: CalendarToday,
    title: "Calendly Event",
    disabled: true,
  },
  {
    type: TaskType.CalendlyScheduledEvent,
    icon: CalendarMonth,
    title: "Calendly Scheduled Event",
    disabled: true,
  },
  {
    type: TaskType.SquareFetch,
    icon: Square,
    title: "Square Fetch",
    disabled: true,
  },
  {
    type: TaskType.SquareLink,
    icon: Link,
    title: "Square Link",
    disabled: true,
  },
  {
    type: TaskType.CreateAccount,
    icon: PersonAddAlt,
    title: "Create Account",
    disabled: false,
  },
  {
    type: TaskType.RunwayApiCall,
    icon: Api,
    title: "Runway API Call",
    disabled: false,
  },
  {
    type: TaskType.OAuthLink,
    icon: Link,
    title: "OAuth Link",
    disabled: true,
  },
  { type: TaskType.BeginPhase, icon: AirlineStops, title: "Phase" },
  { type: TaskType.Unstructured, icon: Category, title: "Unstructured" },
  { type: TaskType.Content, icon: Description, title: "Content" },
];

export const getTaskTypeIcon = (type: TaskType) => {
  return TaskTypes.find((taskTypeDef) => taskTypeDef.type === type)?.icon;
};
