import { Box, Grid, Typography } from "@mui/material";
import OnboardingSidebar from "./OnboardingSidebar";
import { FC, useRef } from "react";
import { environment } from "../../util";

export type OnboardingPageStep = {
  title: string;
  element: JSX.Element;
};

const OnboardingBasePage: FC<{
  steps: OnboardingPageStep[];
  activeStep: number;
}> = ({ steps, activeStep }) => {
  const isEnterprise = !!environment.organization?.key;
  const panelContainer = useRef<HTMLElement>();

  return (
    <Box
      sx={{
        bgcolor: (theme) => theme.palette.background.drawer,
        width: "100%",
        height: "100%",
      }}
    >
      <Grid sx={{ height: "100%", width: "100%" }} columnSpacing={0} container>
        <Grid item xs={4}>
          <OnboardingSidebar activeStep={activeStep} />
        </Grid>
        <Grid item xs={8}>
          <Box
            sx={{
              width: "100%",
              height: "92.5%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              px: "20%",
            }}
          >
            <Box ref={panelContainer}>
              {activeStep < steps.length && (
                <Box>{steps[activeStep].element}</Box>
              )}
            </Box>
          </Box>
          {!isEnterprise && (
            <Box // footer
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "7.5%",
                bgcolor: "white",
                boxShadow:
                  "0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontFamily: "Roboto",
                  fontWeight: "bold",
                  letterSpacing: "1.67px",
                  fontSize: "16px",
                }}
              >
                POWERED BY
                <img
                  src="https://www.runway.works/wp-content/uploads/2023/02/Runway-Wordmark-Blue-Window-2-1024x199.png" // uses the same link as the emails
                  width="140px"
                  style={{ marginLeft: "15px" }}
                />
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default OnboardingBasePage;
