import { Box } from "@mui/material";
import { FC } from "react";
import { AuditEvent } from "../../../../model/AuditEvent";
import { filterAuditEvents } from "../../utils";
import { AuditLogEventItem } from "./AuditLogEventItem";

type ActivityLogProps = {
  auditEvents: Array<AuditEvent>;
};

const ActivityLog: FC<ActivityLogProps> = ({ auditEvents }) => {
  const displayEvents = filterAuditEvents(auditEvents) as Array<AuditEvent>;

  return (
    <Box>
      {displayEvents.map((auditEvent) => {
        return (
          <Box key={auditEvent.id} sx={{ mt: 3, mb: 3 }}>
            <AuditLogEventItem key={auditEvent.id} {...auditEvent} />
          </Box>
        );
      })}
    </Box>
  );
};

export default ActivityLog;
