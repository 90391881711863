import { Grid, Card, IconButton } from "@mui/material";
import {
  FormDefinitionType,
  FormElementType,
  FormExecutionType,
} from "../../types";
import FormBuilderElement from "./FormBuilderElement";
import { ChevronRight, ExpandMore } from "@mui/icons-material";
import { useState } from "react";
import { useFormBuilderContext } from "./FormBuilderProvider";

const FormBuilderChild = ({
  element,
  executionData,
  isSelected,
  onClick,
  hideChildren,
}: {
  element: FormDefinitionType;
  executionData?: FormExecutionType;
  isSelected?: boolean;
  onClick?: (element: FormDefinitionType) => void;
  hideChildren?: (hide: boolean) => void;
}) => {
  const { task } = useFormBuilderContext();
  const [childrenVisible, setChildrenVisible] = useState(true);
  const [raised, setRaised] = useState(false);
  return (
    <Card
      key={element.id}
      sx={(theme) => ({
        cursor: "pointer",
        padding: element.type == FormElementType.Heading ? 1 : 2,
        outline: isSelected ? "1px solid " + theme.palette.action.active : "",
        background:
          element.type == FormElementType.Heading
            ? theme.palette.grey["300"]
            : undefined,
      })}
      elevation={isSelected || raised ? 3 : 0}
      onClick={(event) => {
        onClick && onClick(element);
        event.stopPropagation(); // makes field definition selection work
      }}
      onMouseEnter={() => {
        setRaised(true);
      }}
      onMouseLeave={() => {
        setRaised(false);
      }}
    >
      <Grid
        container
        flexDirection={"row"}
        alignItems={"center"}
        sx={{
          cursor: "pointer",
        }}
      >
        <Grid item flex={1}>
          <Grid container flexDirection={"column"}>
            <FormBuilderElement
              variant={"inline"}
              task={task}
              element={element}
              executionData={executionData}
            />
          </Grid>
        </Grid>

        {element.type == FormElementType.Heading && (
          <IconButton
            onClick={() => {
              setChildrenVisible((prev) => {
                hideChildren && hideChildren(prev);
                return !prev;
              });
            }}
          >
            {!childrenVisible ? <ChevronRight /> : <ExpandMore />}
          </IconButton>
        )}
      </Grid>
    </Card>
  );
};

export default FormBuilderChild;
