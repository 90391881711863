import { Provider, createStore, useAtom } from "jotai";
import { PropsWithChildren, useEffect, useMemo } from "react";
import { publicPageAtom } from "../features/organization/state/publicPageAtom";
import { eventsAtom } from "../features/task/hooks/useEvents";
import { EventEmitter } from "eventemitter3";
import { accountTypesAtom } from "../features/organization/state/accountTypesAtom";

const ScopedProvider = ({ children }: PropsWithChildren) => {
  const [isPublicPage] = useAtom(publicPageAtom);
  const [accountTypes] = useAtom(accountTypesAtom);
  const scopedStore = useMemo(() => {
    return createStore();
  }, []);
  useEffect(() => {
    // Until we figure out a better way to do this, pass global atoms down to scoped provider
    scopedStore.set(publicPageAtom, isPublicPage);
    scopedStore.set(accountTypesAtom, accountTypes);
    scopedStore.set(eventsAtom, new EventEmitter());
  }, [accountTypes, isPublicPage, scopedStore]);
  return <Provider store={scopedStore}>{children}</Provider>;
};
export default ScopedProvider;
