import { User } from "../../../model";

const getUserFullName = (user?: User, reverse = false) => {
  if (!user) {
    return "";
  } else if (user.first_name && user.last_name) {
    if (reverse) {
      return `${user.last_name}, ${user.first_name}`;
    }
    return `${user.first_name} ${user.last_name}`;
  } else if (user.first_name) {
    return user.first_name;
  } else {
    return user.email;
  }
};

export default getUserFullName;
