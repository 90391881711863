import { useCallback } from "react";
import { useNavigate } from "react-router";
import PATHS from "../components/navigation/_paths";

export default () => {
  const navigate = useNavigate();
  const navigateHome = useCallback(() => {
    // For now navigate to the Home page which will trigger the organizations to load and then determine where to route the user.
    // In the future some of that logic can be moved here if the organization loading is also kicked off here and the organization context moved higher.
    navigate(PATHS.HOME.link);
  }, [navigate]);
  return { navigateHome };
};
