import PATHS from "../components/navigation/_paths";
import { useGlobalOrganizationContext } from "./useGlobalOrganizationContext";

export default () => {
  const { accountListIsMemberOf, isMember, organization } =
    useGlobalOrganizationContext();
  const isMemberOfOrg = isMember(organization?.id);
  if (isMemberOfOrg) {
    return {
      EXECUTIONS: PATHS.ORGANIZATION_EXECUTIONS,
      EXECUTION: PATHS.EXECUTION,
    };
  }
  const account = accountListIsMemberOf.find(
    (account) => account.org_id === organization?.id,
  );
  return {
    EXECUTIONS: {
      ...PATHS.ACCOUNT_PORTAL,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      link: PATHS.ACCOUNT_PORTAL.linkTo(account?.id ?? ""),
    },
    EXECUTION: PATHS.ACCOUNT_EXECUTION,
  };
};
