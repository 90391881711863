import { FC, ReactElement } from "react";
import { alpha, Box, Typography } from "@mui/material";
import {
  RadioButtonChecked,
  LightbulbOutlined,
  QueryStatsOutlined,
  CheckCircleOutline,
  InsightsOutlined,
  LockOutlined,
} from "@mui/icons-material";
import { Task, TaskExecution, TaskExecutionState } from "../../task";

const getTaskIcon = (task?: Task) => {
  const iconRenderer: Map<string, ReactElement> = new Map([
    [
      "Quick Explore",
      <LightbulbOutlined
        key={task?.id}
        fontSize="large"
        sx={{ color: "common.white" }}
      />,
    ],
    [
      "Validation",
      <QueryStatsOutlined
        key={task?.id}
        fontSize="large"
        sx={{ color: "common.black" }}
      />,
    ],
    [
      "Incubation",
      <InsightsOutlined
        key={task?.id}
        fontSize="large"
        sx={{ color: "common.white" }}
      />,
    ],
  ]);

  return task?.name ? iconRenderer.get(task?.name) : undefined;
};

const getTaskFillColor = (task?: Task) => {
  const fillColorRenderer: Map<string, string> = new Map([
    ["Validation", "#FFD151"],
    ["Quick Explore", "#467D93"],
    ["Incubation", "#594E95"],
  ]);

  return task?.name ? fillColorRenderer.get(task?.name) : undefined;
};

const HeroProgressBar: FC<{
  task: Task;
  taskExecution?: TaskExecution;
  nextTask?: Task;
  nextTaskExecution?: TaskExecution;
  processComplete: boolean;
}> = ({ task, taskExecution, nextTaskExecution, processComplete }) => {
  const fillColor = getTaskFillColor(task);

  const currentPhaseHasStarted =
    taskExecution && taskExecution?.state == TaskExecutionState.Completed;
  const nextPhaseHasStarted =
    (nextTaskExecution &&
      nextTaskExecution?.state == TaskExecutionState.Completed) ||
    processComplete;

  //By using Number it allows null or blank strings to be coerced to 0
  //source: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number#number_coercion
  const progress = nextPhaseHasStarted ? 100 : currentPhaseHasStarted ? 50 : 0;

  return (
    <Box
      sx={{
        flex: "1 1 0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* icon - TODO: replace hard code fallback color*/}
      {getTaskIcon(task) && (
        <Box
          sx={{
            backgroundColor: alpha(
              fillColor ?? "#343434",
              currentPhaseHasStarted ? 1 : 0.6,
            ),
            p: 1,
            borderRadius: "6px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {getTaskIcon(task)}
        </Box>
      )}

      {/* progress bar  */}
      <Box
        sx={{
          background: `linear-gradient(to right, ${alpha(
            fillColor ?? "#343434",
            1,
          )} 0% ${progress}%, ${alpha(
            fillColor ?? "#343434",
            0.6,
          )} ${progress}% 100%)`,
          width: "100%",
          height: (theme) => theme.spacing(2),
          textAlign: "center",
          margin: "15px 0",
        }}
      />

      {/* title */}
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        {nextPhaseHasStarted ? (
          <CheckCircleOutline />
        ) : currentPhaseHasStarted ? (
          <RadioButtonChecked />
        ) : (
          <LockOutlined />
        )}
        <Typography variant="h4">{task.name}</Typography>
      </Box>
    </Box>
  );
};

export default HeroProgressBar;
