import { FormattedMessage } from "react-intl";

/**
 *  All common messages for common app pages.
 *
 *  @see {@link ../../../docs/intl.md | Internationalization Code Style Guidelines}
 */
export default {
  pageNotFoundTitle: (
    <FormattedMessage
      id="pageNotFound.title"
      defaultMessage="Unexpected Turbulence."
      description="Common term for the repeatable series of steps and tasks to achieve a desired goal."
    />
  ),
  pageNotFoundDescription: (
    <FormattedMessage
      id="pageNotFound.description"
      defaultMessage="Ladies and gentleman, we're experiencing some turbulence. Please return to the home page."
      description="Description for 404 page"
    />
  ),
  activityLog: (
    <FormattedMessage
      id="activity.log"
      defaultMessage="Flight Log"
      description="Title for a log of activity for an execution."
    />
  ),
  portal: (
    <FormattedMessage
      id="app.portal"
      defaultMessage="Portal"
      description="Term describing where a user is logged in."
    />
  ),
};
