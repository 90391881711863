import { DashboardAPI } from "../../../../api";
import { useAlert } from "../../../../lib/alert";
import { useGlobalOrganizationContext } from "../../../../hooks/useGlobalOrganizationContext";
import Dashboard, { DashboardConfig } from "../../../../model/Dashboard";
import { DashboardDialog } from "./DashboardDialog";
import { useState } from "react";
import useFeature from "../../../../hooks/useFeature";

const DEFAULT_DASHBOARD_CONFIG: DashboardConfig = {
  name: "Admin Dashboard",
  roles: [],
  elements: [
    [
      {
        type: "number",
        label: "Innovation Process Phases",
        config: {
          dataSource: "accountsPerPhase",
          phaseTaskIdentifier: "<task_identifier>",
        },
      },
    ],
  ],
};

type CreateDashboardDialogProps = {
  open: boolean;
  handleConfirm: (newDashboard: Dashboard) => void | Promise<void>;
  handleCancel: () => void | Promise<void>;
  dashboardKey: string;
};

export const CreateDashboardDialog = ({
  open,
  handleConfirm,
  handleCancel,
  dashboardKey,
}: CreateDashboardDialogProps) => {
  const canEditDashboard = useFeature("editDashboard");
  const { success, error } = useAlert();
  const { organization } = useGlobalOrganizationContext();
  const [dashboard, setDashboard] = useState<Dashboard>({
    id: "",
    key: dashboardKey,
    org_id: organization?.id ?? "",
    config: { ...DEFAULT_DASHBOARD_CONFIG },
  });

  return (
    <DashboardDialog
      open={open && canEditDashboard}
      title={"Create Dashboard"}
      confirmText={"Create"}
      dashboard={dashboard}
      updateDashboard={(updates) => {
        setDashboard((prev) => ({ ...prev, ...updates }));
      }}
      handleConfirm={async () => {
        try {
          const newDashboard = await DashboardAPI.create(dashboard);
          success(
            `${newDashboard?.config?.name ?? "Dashboard"} created successfully`,
          );
          handleConfirm(newDashboard);
        } catch {
          error(`Error creating dashboard`);
        }
      }}
      handleCancel={handleCancel}
    />
  );
};
