import { FC } from "react";
import EditAccountTypeDialog, {
  EditAccountTypeDialogProps,
} from "./EditAccountTypeDialog";
import { AccountType } from "../../../model/AccountType";
import accountTypes from "../../../api/accountTypes";
const saveAccountType = (accountType: AccountType) => {
  return accountTypes.create(accountType);
};
const CreateAccountTypeDialog: FC<EditAccountTypeDialogProps> = (props) => {
  return (
    <EditAccountTypeDialog
      title="Create Account Type"
      confirmText="Create"
      save={saveAccountType}
      {...props}
    ></EditAccountTypeDialog>
  );
};

export default CreateAccountTypeDialog;
