import { useEffect, useState } from "react";
import { AuditEvent } from "../../../../model/AuditEvent";
import AuditEvents from "../../../../api/audit";
import { ProcessExecution } from "../../../../model/ProcessExecution";
import { ActivityLog } from "../../../activity-log";
import { Box, CircularProgress } from "@mui/material";

export type ActivityLogProps = {
  processExecution: ProcessExecution;
  organizationId: string;
};
const ProcessExecutionActivityLog = ({
  processExecution,
  organizationId,
}: ActivityLogProps) => {
  const [auditEvents, setAuditEvents] = useState<Array<AuditEvent>>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchAuditEvents = async (
    organizationId: string | undefined,
    processExecutionId: string,
  ) => {
    try {
      setIsLoading(true);
      if (organizationId) {
        setAuditEvents(
          await AuditEvents.getByProcessExecutionId(
            organizationId,
            processExecutionId,
          ),
        );
      }
    } catch (e) {
      // Silently fail from the perspective of the user on purpose.  Audit events
      // will render as a blank entry
      console.warn("Unable to fetch audit events", e);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchAuditEvents(organizationId, processExecution.id);
  }, [processExecution, organizationId]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ pt: 2 }}
      >
        <CircularProgress />
      </Box>
    );
  } else {
    return <ActivityLog auditEvents={auditEvents} />;
  }
};
export default ProcessExecutionActivityLog;
