import API from "../base";

const sendReminder = (taskExecutionId: string): Promise<void> => {
  return API.post(
    "/integrations/calendly/" + taskExecutionId + "/sendReminder",
  );
};

export default {
  sendReminder: sendReminder,
};
