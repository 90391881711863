import Integration from "../../model/Integration";
import API from "../base";

const getAll = (organizationId: string): Promise<Integration[]> => {
  return new Promise((resolve, reject) => {
    API.get("/organizations/" + organizationId + "/integrations").then(
      (result) => resolve(result.data.payload as Integration[]),
      reject,
    );
  });
};

const create = (
  integration: Integration,
  organizationId: string,
): Promise<Integration> => {
  return new Promise((resolve, reject) => {
    API.post(
      "/organizations/" + organizationId + "/integrations",
      integration,
    ).then((result) => resolve(result.data.payload as Integration), reject);
  });
};

const remove = (
  integrationId: string,
  organizationId: string,
): Promise<Integration> => {
  return new Promise((resolve, reject) => {
    API.delete(
      "/organizations/" + organizationId + "/integrations/" + integrationId,
    ).then((result) => resolve(result.data.payload as Integration), reject);
  });
};

const update = (
  integrationId: string,
  organizationId: string,
  integration: Integration,
): Promise<Integration> => {
  return new Promise((resolve, reject) => {
    API.put(
      "/organizations/" + organizationId + "/integrations/" + integrationId,
      integration,
    ).then((result) => resolve(result.data.payload as Integration), reject);
  });
};
export default {
  getAll,
  create,
  remove,
  update,
};
