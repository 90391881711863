import EmailIcon from "@mui/icons-material/Email";
import { Box, Link, Paper, Typography } from "@mui/material";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { UserIcon } from "../../components/elements";
import { useGlobalOrganizationContext } from "../../hooks/useGlobalOrganizationContext";
import { useGlobalUserContext } from "../../hooks/useGlobalUserContext";

const UserProfilePage = () => {
  const { user } = useGlobalUserContext();

  const { setOrganization } = useGlobalOrganizationContext();

  useEffect(() => {
    setOrganization(undefined);
  }, [setOrganization]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: 220,
          alignItems: "center",
          padding: 4,
        }}
      >
        <Paper
          sx={{
            width: "100%",
            height: "20%",
            display: "flex",
            flexDirection: "row",
            flex: 1,
            borderRadius: 2,
            alignItems: "center",
            overflow: "auto",
            backgroundColor: (theme) => theme.palette.grey[100],
            "@media print": {
              overflow: "visible",
              border: "none",
              boxShadow: "unset",
            },
            zIndex: 12,
          }}
          elevation={3}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <Paper
              elevation={3}
              sx={{
                width: 105,
                height: 105,
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                ml: 5,
              }}
            >
              <UserIcon user={user} size="large" />
            </Paper>
            <Box
              sx={{
                alignItems: "left",
                flexDirection: "column",
                display: "flex",
                ml: 3,
              }}
            >
              <Typography variant="h2" sx={{ marginBottom: 0 }}>
                {user.first_name + " " + user.last_name}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "left",
                  marginTop: 1,
                }}
              >
                <EmailIcon sx={{ marginRight: 0.5 }} />
                <Link
                  href={`mailto:${user.email}`}
                  sx={{
                    textDecoration: "none",
                    color: "primary.main",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  <Typography variant="body2" sx={{ margin: 0 }}>
                    {user.email}
                  </Typography>
                </Link>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
      <Box sx={{ ml: 3 }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default UserProfilePage;
