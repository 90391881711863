import {
  Box,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import processes from "../../../../../api/processes";
import roles from "../../../../../api/roles";
import { Role } from "../../../../../model";
import { Process } from "../../../../../model/Process";
import { RoleIcon } from "../../../../../components/elements";
import { TaskAssignment, TaskInlineEditorProps } from "../../../types";

const AssignmentInlineEditor = ({
  task,
}: TaskInlineEditorProps<TaskAssignment>) => {
  const [process, setProcess] = React.useState<Process>();
  const [targetRole, setTargetRole] = React.useState<Role>();

  useEffect(() => {
    processes
      .get(task.process_id)
      .then((response) => {
        setProcess(response);
      })
      .catch(() => {
        setProcess(undefined);
      });
  }, [task.process_id]);

  useEffect(() => {
    if (task.data?.targetRoleId) {
      roles
        .get(task.data?.targetRoleId)
        .then((response) => {
          setTargetRole(response);
        })
        .catch((error) => {
          console.error(`Could not get role ${task.data?.targetRoleId}`, error);
        });
    }
  }, [task.data?.targetRoleId]);

  if (!process) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ pb: 2 }}>
      <TextField
        disabled
        fullWidth
        label={"Target Role"}
        value={targetRole?.name ?? ""}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {targetRole?.id && (
                <RoleIcon role={targetRole} toolTipPosition="left" />
              )}
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default AssignmentInlineEditor;
