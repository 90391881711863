import {
  TaskForm,
  TaskFormExecutionData,
  FormElement,
  FormExecutionType,
  FormElementType,
  TextFormElement,
  MultiSelectionFormElement,
  RadioFormElement,
  SelectionFormElement,
  UploadFormElement,
  TaskType,
  NestedFormElement,
  HeadingFormElement,
  Task,
  TaskCheck,
  TaskExecution,
  TaskCheckExecution,
  CheckboxFormElement,
  DateFormElement,
  FieldDefinitionFormElement,
  AutocompleteFormElement,
} from "../../..";

const createFormElementSubmission = (
  formElement: FormElement,
  isSubmission?: boolean,
): FormExecutionType => {
  switch (formElement.type) {
    case FormElementType.Heading: {
      return formElement as HeadingFormElement;
    }
    case FormElementType.Text: {
      const textFormElement = formElement as TextFormElement;
      if (!isSubmission && textFormElement.required && !textFormElement.value) {
        throw new Error(`"${textFormElement.label}" is required`);
      }
      return { ...textFormElement, value: textFormElement.value || "" };
    }
    case FormElementType.Date: {
      const dateFormElement = formElement as DateFormElement;
      if (
        !isSubmission &&
        dateFormElement.required &&
        (!dateFormElement.value || dateFormElement.value.length === 0)
      ) {
        throw new Error(`"${dateFormElement.label}" is required`);
      }
      return { ...dateFormElement, value: dateFormElement.value || [] };
    }
    case FormElementType.Autocomplete: {
      const autocompleteFormElement = formElement as AutocompleteFormElement;
      if (
        !isSubmission &&
        autocompleteFormElement.required &&
        !autocompleteFormElement.value
      ) {
        throw new Error(`"${autocompleteFormElement.label}" is required`);
      }
      return {
        ...autocompleteFormElement,
        value: autocompleteFormElement.value,
      };
    }
    case FormElementType.MultiSelection: {
      // Do not validate required here, empty is a valid response for a multi
      // selection even when the field is required
      const multiSelectionElement = formElement as MultiSelectionFormElement;
      return {
        ...multiSelectionElement,
        value: multiSelectionElement.selection || [],
      };
    }
    case FormElementType.Radio: {
      const radioElement = formElement as RadioFormElement;
      if (!isSubmission && radioElement.required && !radioElement.selection) {
        throw new Error(`"${radioElement.label}" is required`);
      }
      return { ...radioElement, value: radioElement.selection };
    }
    case FormElementType.Selection: {
      const selectElement = formElement as SelectionFormElement;
      if (!isSubmission && selectElement.required && !selectElement.selection) {
        throw new Error(`"${selectElement.label}" is required`);
      }
      return { ...selectElement, value: selectElement.selection };
    }
    case FormElementType.Upload: {
      const uploadElement = formElement as UploadFormElement;
      if (
        !isSubmission &&
        uploadElement.required &&
        (!uploadElement.value || uploadElement.value.length === 0)
      ) {
        throw new Error(`"${uploadElement.label}" is required`);
      }
      return { ...uploadElement, value: uploadElement.value };
    }
    case FormElementType.NestedForm: {
      const nestedFormElement = formElement as NestedFormElement;
      return {
        ...nestedFormElement,
        value: (nestedFormElement.value ?? []).map((elGroup) => {
          // Build the submission object for each value set
          return elGroup.map((el) => {
            // Recursively create submissions and if the submission doesn't exist use the element
            return createFormElementSubmission(el, isSubmission);
          });
        }),
      };
    }
    case FormElementType.Checkbox: {
      const CheckboxFormElement = formElement as CheckboxFormElement;
      return {
        ...CheckboxFormElement,
        value: CheckboxFormElement.value ?? false,
      };
    }
    case FormElementType.FieldDefinition: {
      const FieldDefinitionFormElement =
        formElement as FieldDefinitionFormElement;
      return {
        ...FieldDefinitionFormElement,
        value: FieldDefinitionFormElement.elementValues ?? [],
      };
    }
    default: {
      throw new Error(
        `Unable to create submission data for form element of type ${formElement.type}`,
      );
    }
  }
};

const executeFormTask = (
  task: Task,
  isSubmission?: boolean,
  taskExecution?: TaskExecution,
): { data: TaskFormExecutionData } => {
  switch (task.type) {
    case TaskType.Form: {
      const formElements = (task as TaskForm).data?.form?.elements || [];
      const elements = formElements.map((formElement) => {
        return createFormElementSubmission(
          formElement as FormElement,
          isSubmission,
        );
      });
      return {
        data: {
          ...task.data,
          form: {
            elements: elements,
          },
        },
      };
    }
    case TaskType.Check: {
      const checkTask = task as TaskCheck;
      const formElements = (taskExecution as TaskCheckExecution).data?.value
        ? checkTask.data?.form?.yes
        : checkTask.data?.form?.no;
      const elements = (formElements ?? []).map((formElement) =>
        createFormElementSubmission(formElement as FormElement, isSubmission),
      );
      return {
        data: {
          ...task.data,
          form: {
            elements: elements,
          },
        },
      };
    }
    default: {
      throw new Error(
        `Unable to create submission data for task of type ${task.type}`,
      );
    }
  }
};
export default executeFormTask;
