import { Typography } from "@mui/material";

const CalendlyScheduledEventSideSectionEditor = () => {
  return (
    <>
      <Typography>Task Calendly Scheduled Event Placeholder</Typography>
    </>
  );
};

export default CalendlyScheduledEventSideSectionEditor;
