import MoreVert from "@mui/icons-material/MoreVert";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import { PropsWithChildren, ReactElement, useRef, useState } from "react";

export type IconButtonDropDownProps = PropsWithChildren<{
  icon?: ReactElement;
  IconButtonProps?: Partial<IconButtonProps>;
  onOpen?: React.MouseEventHandler<HTMLButtonElement>;
  onClose?: React.MouseEventHandler<HTMLDivElement>;
}>;

const IconButtonDropDown = ({
  icon,
  children,
  onOpen,
  onClose,
  IconButtonProps,
}: IconButtonDropDownProps) => {
  const [open, setOpen] = useState(false);
  const anchorElRef = useRef<HTMLButtonElement>(null);
  return (
    <>
      <IconButton
        onClick={(e) => {
          setOpen((open) => !open);
          onOpen && onOpen(e);
        }}
        {...IconButtonProps}
        ref={anchorElRef}
      >
        {icon ?? <MoreVert />}
      </IconButton>
      <Menu
        open={open}
        anchorEl={anchorElRef.current}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={() => {
          setOpen(false);
        }}
        onClick={(e) => {
          setOpen(false);
          onClose && onClose(e);
        }}
      >
        {children}
      </Menu>
    </>
  );
};
export default IconButtonDropDown;
