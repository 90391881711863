import { FC, PropsWithChildren } from "react";
import { Organization } from "../model";
import { PageContainer } from "./layout";

type OrganizationScopedPageProps = {
  onOrganizationChange?: (organization: Organization | undefined) => void;
  displayOrganizationActionMenu: boolean;
  category: string;
};

const OrganizationScopedPage: FC<
  PropsWithChildren<OrganizationScopedPageProps>
> = (props) => {
  return (
    <PageContainer variant="full" GridProps={{ alignItems: "stretch" }}>
      {props.children}
    </PageContainer>
  );
};

export default OrganizationScopedPage;
