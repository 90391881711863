import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import {
  CheckboxFormElement,
  TaskCheck,
  TaskCheckExecution,
  TaskExecutionComponentProps,
  TaskExecutionRenderProps,
  TaskExecutionState,
} from "../../../types";
import CheckCircle from "@mui/icons-material/CheckCircle";
import { DEFAULT_RENDER } from "../TaskBody";

const CheckExecutionComponentWrapper = ({
  taskExecution,
  onExecute,
  onValueChange,
  ...props
}: TaskExecutionComponentProps<TaskCheck, TaskCheckExecution>) => {
  const isChecklist =
    taskExecution?.data?.form?.elements &&
    taskExecution.data.form.elements.length > 0;

  const isDisabled = (props.isDisabled || taskExecution?.data?.value) ?? false;

  const isComplete = taskExecution?.data?.value ?? false;

  const handleSubmit = useCallback(async () => {
    // ensures all the subtasks are set to checked before submitting
    const subtasks = taskExecution?.data?.form
      ?.elements as CheckboxFormElement[];

    if (subtasks) subtasks.forEach((subtask) => (subtask.value = true));
    const executionData = { data: { ...taskExecution?.data, value: true } };

    const execution = {
      ...taskExecution,
      task_id: props.task.id,
      state: TaskExecutionState.Completed,
      ...executionData,
    };

    if (onExecute) {
      await onExecute(execution);
    }
    onValueChange &&
      onValueChange({ ...props.task, ...executionData } as TaskCheck);
  }, [onExecute, onValueChange, props.task, taskExecution]);

  const updateTask = useCallback(
    async (executionPatch: Partial<TaskCheckExecution>) => {
      onExecute && onExecute({ ...taskExecution, ...executionPatch }, false);
    },
    [onExecute, taskExecution],
  );

  return isChecklist ? (
    <ChecklistExecutionComponent
      complete={isComplete}
      taskExecution={taskExecution}
      isDisabled={isDisabled}
      handleSubmit={handleSubmit}
      updateTask={updateTask}
      {...props}
    />
  ) : (
    <CheckExecutionComponent
      complete={isComplete}
      isDisabled={isDisabled}
      handleSubmit={handleSubmit}
      {...props}
    />
  );
};

const CheckExecutionComponent = ({
  task,
  complete,
  isDisabled,
  handleSubmit,
  children = DEFAULT_RENDER,
}: {
  task: TaskCheck;
  complete: boolean;
  isDisabled: boolean;
  handleSubmit: () => void;
  children?: (renderProps: TaskExecutionRenderProps) => React.ReactElement;
}) => {
  const [checked, setChecked] = useState<boolean>(complete);

  // state to manage checkbox display (depending on the value of the task)
  const content = (
    <FormGroup>
      <FormControlLabel
        label={<>{task.name}</>}
        control={
          <Checkbox
            data-cy="check-task-checkbox"
            disabled={isDisabled}
            checked={checked}
            checkedIcon={<CheckCircle data-cy="check-task-checked-checkbox" />}
            color={checked ? "success" : "default"}
            sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
            onChange={() => {
              if (!checked) {
                setChecked(true);
                handleSubmit();
              }
            }}
          />
        }
      />
    </FormGroup>
  );

  return children({
    content,
    title: "To Complete",
  });
};

const ChecklistExecutionComponent = ({
  taskExecution,
  complete,
  isDisabled,
  handleSubmit,
  updateTask,
  children = DEFAULT_RENDER,
}: {
  taskExecution: TaskCheckExecution;
  complete: boolean;
  isDisabled: boolean;
  handleSubmit: () => void;
  updateTask: (executionPatch: Partial<TaskCheckExecution>) => void;
  children?: (renderProps: TaskExecutionRenderProps) => React.ReactElement;
}) => {
  const [subtasks, setSubtasks] = useState<CheckboxFormElement[]>(
    (taskExecution?.data?.form?.elements as CheckboxFormElement[]) ?? [],
  );

  const updateExecution = useCallback(() => {
    const executionData = { form: { elements: subtasks }, value: false };
    updateTask({ data: executionData });
  }, [subtasks, updateTask]);

  const handleSubtaskCheck = (index: number) => {
    const newSubtasks = subtasks!.slice();

    const isChecked = newSubtasks[index].value;
    newSubtasks[index].value = !isChecked;
    setSubtasks(newSubtasks);

    const unchecked = subtasks.filter((subtask) => !subtask.value);

    if (unchecked.length === 0) handleSubmit();
    else updateExecution();
  };

  const handleCheckAll = () => {
    const newSubtasks = subtasks.slice();

    newSubtasks.forEach((subtask) => {
      subtask.value = true;
    });

    setSubtasks(newSubtasks);
    handleSubmit();
  };

  const ChecklistExecutionHeader = useCallback(() => {
    return (
      <Container disableGutters sx={{ display: "flex", alignItems: "center" }}>
        <Checkbox
          color="success"
          sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
          checked={complete}
          checkedIcon={<CheckCircle data-cy="check-task-checked-checkbox" />}
        />
        <Typography variant="inherit">
          {taskExecution.task?.name ?? taskExecution.name ?? ""}
        </Typography>
      </Container>
    );
  }, [complete, taskExecution]);

  const actions = (
    <Grid
      container
      gap={1}
      mt={1}
      width={"100%"}
      justifyContent={"flex-end"}
      alignItems={"center"}
    >
      <Button
        variant="outlined"
        size="small"
        onClick={() => !isDisabled && handleCheckAll()}
      >
        Check All
      </Button>
    </Grid>
  );

  const content = (
    <Container disableGutters>
      {subtasks.map((subtask, index) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }} key={subtask.id}>
            <Checkbox
              color="success"
              checked={subtask.value}
              disabled={complete || isDisabled}
              onChange={() => handleSubtaskCheck(index)}
            />
            <Typography>{subtask.label}</Typography>
          </Box>
        );
      })}
    </Container>
  );

  return children({
    content,
    title: <ChecklistExecutionHeader />,
    actions: !complete && actions,
  });
};

export default CheckExecutionComponentWrapper;
