import React, { FC, useState } from "react";
import {
  Alert,
  Avatar,
  Box,
  CircularProgress,
  Container,
  Grid,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import successCheckMark from "../../assets/images/Success_Check_Mark.svg";
import {
  getEmailAndCodeForPasswordReset,
  sendPasswordResetEmail,
} from "../../lib/auth";
import { mapCognitoError } from "../../lib/cognito";
import { ForgotPasswordPageProps } from "./ForgotPasswordPage";

const ForgotPasswordEmailSentPage: FC<ForgotPasswordPageProps> = (props) => {
  const [authenticating, setAuthenticating] = useState<boolean>(false);
  const [error, setError] = useState("");

  const signInLink = "/sign-in";
  const forgotPasswordEmailSentLink = "/forgot-password-email-sent";

  const resendResetPasswordEmail = (event: React.MouseEvent<unknown>) => {
    event.preventDefault();

    setAuthenticating(true);

    const { emailForPasswordReset } = getEmailAndCodeForPasswordReset();

    sendPasswordResetEmail(emailForPasswordReset, props.clientMetadata)
      .then((result) => {
        setAuthenticating(false);
        if (!result.success) {
          setError("Unknown password reset error occured.  Please try again.");
        }
      })
      .catch((err) => {
        setAuthenticating(false);
        setError(mapCognitoError(err));
      });
  };

  return (
    <>
      <Container component="main" maxWidth="xs" sx={{ mt: { xs: 3, md: 18 } }}>
        <Paper elevation={9}>
          {authenticating ? (
            <Box
              sx={{
                width: "100%",
                height: "300px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box sx={{ px: 4, py: 4 }}>
              {error && (
                <Alert sx={{ width: "100%" }} severity="error">
                  {error}
                </Alert>
              )}
              {/* Header */}
              <Box component="header" sx={{ mb: 3 }}>
                <Avatar
                  alt="Password reset email successfully sent"
                  src={successCheckMark}
                  sx={{
                    // Possibly change the sizing of this icon
                    width: 48,
                    height: 48,
                  }}
                />
              </Box>

              {/* Body */}
              <Box>
                <Typography component="h1" variant="h5">
                  We have sent a verification code to your email address.
                </Typography>
                <Typography component="h2" variant="body2" sx={{ mt: 1 }}>
                  Click the link in your email address to reset your password
                  and login.
                </Typography>
              </Box>

              {/* Footer */}
              <Box sx={{ mt: 4 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Link href={signInLink} underline="hover">
                      Back to Login
                    </Link>
                  </Grid>
                  <Grid item xs={6}>
                    <Link
                      href={forgotPasswordEmailSentLink}
                      onClick={resendResetPasswordEmail}
                      underline="hover"
                    >
                      Resend Link
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default ForgotPasswordEmailSentPage;
