import { useState } from "react";
import { useGlobalUserContext } from "../../../../hooks/useGlobalUserContext";

import { Box, Divider } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { Link as RouterLink } from "react-router-dom";
import { useAlert } from "../../../../lib/alert";
import { federatedLogoutEndpoint, logout } from "../../../../lib/auth";
import { UserIcon } from "../../../elements";
import IconButtonDropdownMenu from "../../../elements/IconButtonDropdownMenu";
import PATHS from "../../../navigation/_paths";

const AccountButton = () => {
  const { user } = useGlobalUserContext();
  const { handleRejectionWithError } = useAlert();

  const [isMenuClosed, setIsMenuClosed] = useState<boolean>(true);

  const handleLogout = () => {
    logout().then(() => {
      window.location.href = federatedLogoutEndpoint(window.location.origin);
    }, handleRejectionWithError("Could not complete logout"));
  };
  if (!user?.id) {
    return;
  }
  return (
    <Box
      sx={{
        "@media print": {
          display: "none", // Make all content visible for printing
        },
      }}
    >
      <IconButtonDropdownMenu
        element={<UserIcon user={user} />}
        closed={isMenuClosed}
        setClosed={setIsMenuClosed}
        size="medium"
      >
        <MenuItem>{user.email}</MenuItem>
        <Divider />
        <MenuItem
          component={RouterLink}
          to={PATHS.PROFILE.link}
          onClick={() => {
            setIsMenuClosed(true);
          }}
        >
          Profile
        </MenuItem>
        <MenuItem data-cy={"logout-btn"} onClick={handleLogout}>
          Log out
        </MenuItem>
      </IconButtonDropdownMenu>
    </Box>
  );
};

export default AccountButton;
